import React, { useState, useEffect } from 'react';
import '../styles/Recommendations.css';
import { Header, Footer } from '../components';
import { Dots, Button, SelectionChip, MobileRadio } from '../v4/components';
import { colour } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { checkOnAuthStateChanged, recordEvent, trackingProfileUpdate } from '../functions';

function Recommendations() {
    const navigate = useNavigate();
    const [acquisition, setAcquisition] = useState([]);
    const [condition, setCondition] = useState(null);
    const [dealsDone, setDealsDone] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        document.title = "Personalization | Coffee Clozers";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
            }
        };

        fetchUserData();
    }, [])

    const acquisitionOptions = [
        {
            label: "Mortgage",
            value: "m",
            selected: acquisition.includes("m") ? true : false
        },
        {
            label: "Cash",
            value: "c",
            selected: acquisition.includes("c") ? true : false
        },
        {
            label: "Seller-finance/subject to",
            value: "sf/sub",
            selected: acquisition.includes("sf/sub") ? true : false
        }
    ];

    const conditionOptions = [
        {
            label: "Any",
            value: "a",
            selected: condition === "a" ? true : false
        },
        {
            label: "Good condition",
            value: "g",
            selected: condition === "g" ? true : false
        },
        {
            label: "Bad condition",
            value: "b",
            selected: condition === "b" ? true : false
        }
    ];

    const dealsDoneOptions = [
        {
            label: "None",
            value: "None",
            selected: dealsDone === "None" ? true : false
        },
        {
            label: "1-2",
            value: "1-2",
            selected: dealsDone === "1-2" ? true : false
        },
        {
            label: "3-6",
            value: "3-6",
            selected: dealsDone === "3-6" ? true : false
        },
        {
            label: "7-9",
            value: "7-9",
            selected: dealsDone === "7-9" ? true : false
        },
        {
            label: "10+",
            value: "10+",
            selected: dealsDone === "10+" ? true : false
        }
    ];

    const changeAcquisition = (item) => {
        const acquisitionClone = [...acquisition];
        const index = acquisitionClone.indexOf(item.value);
        if (index === -1) {
            acquisitionClone.push(item.value);
        } else {
            acquisitionClone.splice(index, 1);
        }
        setAcquisition(acquisitionClone);
    };

    const changeCondition = (item) => {
        setCondition(item.value);
    };

    const changeDealsDone = (item) => {
        setDealsDone(item.value);
    };

    const skipPersonalisations = () => {
        recordEvent("Personalisation Skipped", {});
        navigate("/results", {
            state: {
                personalised: false
            }
        });
    };

    const complete = async() => {
        const creative = acquisition.includes("sf/sub") ? true : false;
        const conditionFilterOptions = condition === "a" ? "" : condition === "g" ? `["rr","wm"]` : `["mr","gr"]`;
        const standardCondition = condition === "a" ? "" : condition === "g" ? ["rr", "wm"] : ["mr", "gr"];
        const conditionFilter = condition === "a" ? "" : `&filters=[{"label":"condition","value":${conditionFilterOptions},"type":"condition"}]`;
        const filters = `?page=1&strategy=0&creative=${creative}${conditionFilter}`;

        const acquisitionStrategies = acquisitionOptions.filter(item => acquisition.includes(item.value)).map(item => item.label);
        const conditionLabels = conditionOptions.filter(item => item.value === condition).map(item => item.label);

        // Save to mixpanel
        const mpObject = {
            "$distinct_id": userId,
            "Acquisition Strategies": acquisitionStrategies,
            "Condition": conditionLabels,
            "Deals Done": dealsDone
        };
        await trackingProfileUpdate(mpObject);

        recordEvent("Personalisation Complete", {});
        navigate("/recommendations-loading", {
            state: {
                personalised: true,
                filters: filters,
                buyBoxFilters: {
                    condition: standardCondition,
                    acquisitionStrategy: acquisition.includes("sf/sub") ? "sf" : acquisition.includes("m") ? "m" : "c"
                }
            }
        });
    };

    return (
        <div className="recommendations-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={false}
                desktopControl={false}
            />
            <div className="recommendations-inner-container">
                <Dots
                    limit={3}
                    selectedFill={colour.grayScaleGray03}
                    selectedStroke=""
                    unselectedFill={colour.grayScaleGray01}
                    unselectedStroke=""
                    selectedIndex={1}
                    className=""
                />
                <div className="recommendations-body-container">
                    <div className="recommendations-title-container">
                        <h1 className="heading-large-semibold colour-primary">
                            Get better recommendations
                        </h1>
                        <span className="body-regular colour-secondary">
                            By answering these 2 quick questions.
                        </span>
                        <div className="recommendations-divider-container">
                        </div>
                    </div>
                    <div className="recommendations-element-container">
                        <span className="body-regular colour-primary">
                            How do you plan to purchase your investment property?
                        </span>
                        <div className="recommendations-radio-row">
                            {
                                acquisitionOptions.map((item, index) =>
                                    <SelectionChip
                                        key={index}
                                        label={item.label}
                                        selected={item.selected}
                                        disabled={false}
                                        func={() => changeAcquisition(item)}
                                        index={index}
                                        leftIcon={null}
                                        rightIcon={null}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className="recommendations-element-container">
                        <span className="body-regular colour-primary">
                            What condition properties are you looking for?
                        </span>
                        <div className="recommendations-radio-row mobile-none">
                            {
                                conditionOptions.map((item, index) =>
                                    <SelectionChip
                                        key={index}
                                        label={item.label}
                                        selected={item.selected}
                                        disabled={false}
                                        func={() => changeCondition(item)}
                                        index={index}
                                        leftIcon={null}
                                        rightIcon={null}
                                    />
                                )
                            }
                        </div>
                        <div className="recommendations-radio-row recommendations-radio-mobile desktop-none">
                            {
                                conditionOptions.map((item, index) =>
                                    <MobileRadio
                                        key={index}
                                        label={item.label}
                                        selected={item.selected}
                                        disabled={false}
                                        clickFunc={() => changeCondition(item)}
                                        index={index}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className="recommendations-element-container">
                        <span className="body-regular colour-primary">
                            How many <span className="body-semibold">buy and hold</span> deals have you personally done?
                        </span>
                         <div className="recommendations-radio-row mobile-none">
                            {
                                dealsDoneOptions.map((item, index) =>
                                    <SelectionChip
                                        key={index}
                                        label={item.label}
                                        selected={item.selected}
                                        disabled={false}
                                        func={() => changeDealsDone(item)}
                                        index={index}
                                        leftIcon={null}
                                        rightIcon={null}
                                    />
                                )
                            }
                        </div>
                        <div className="recommendations-radio-row recommendations-radio-mobile desktop-none">
                            {
                                dealsDoneOptions.map((item, index) =>
                                    <MobileRadio
                                        key={index}
                                        label={item.label}
                                        selected={item.selected}
                                        disabled={false}
                                        clickFunc={() => changeDealsDone(item)}
                                        index={index}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className="recommendations-element-container">
                        <span className="body-regular colour-secondary">
                            (Don't worry, you can change this later)
                        </span>
                    </div>
                    <div className="recommendations-button-container-row">
                        <div className="recommendations-button-container">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={"Next"}
                                cta={complete}
                                disabled={acquisition.length === 0 || condition === null || dealsDone === null ? true : false}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                rightIconClass=""
                                alt=""
                                standardIcon={true}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                        <div 
                            className="text-button"
                            onClick={() => skipPersonalisations()}
                        >
                            <span className="body-regular text-link">
                                Skip personalization
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Recommendations;