import React, { useEffect, useState } from 'react';
import '../styles/Home.css';
import { Header, Footer, SideNavigation, Loading, LiveCounties }  from '../components';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkOnAuthStateChanged, cloudFunctionV2, formatFilters, getUserDocuments, goToCreativeCheckout } from '../functions';
import { PropertyGallery, Modal } from '../v4/components';
import { Upgrade } from '../v4/modalbody';

function Home() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [recentlyViewed, setRecentlyViewed] = useState([]);
    const [loading, setLoading] = useState(true);
    const [premiumModal, setPremiumModal] = useState(false);
    const [favourites, setFavourites] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const galleryLimits = 4;

    useEffect(() => {
        document.title = "Coffee Clozers | Home";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const collections = [
                    "Users",
                    "Subscriptions",
                    "Recently Viewed",
                    "Favourites"
                ];
                const queryData = await getUserDocuments(collections, user.userId);
                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);
                }
                if ( queryData[1].status === 200 ) {
                    setSubscriptions(queryData[1].data);
                }
                if ( queryData[2].status === 200 ) {
                    fetchProperties(queryData[2].data.slice(0, galleryLimits));
                }
                else {
                    const defaultCounties = [
                        {
                            msaCode: "CTY26163",
                            msaTitle: "Wayne County, MI",
                            icon: ""
                        },
                        {
                            msaCode: "Cuyahoga County, OH",
                            msaTitle: "CTY39035",
                            icon: ""
                        },
                        {
                            msaCode: "Monroe County, NY",
                            msaTitle: "CTY36055",
                            icon: ""
                        },
                        {
                            msaCode: "CTY12057",
                            msaTitle: "Hillsborough County, FL",
                            icon: ""
                        }
                    ];
                    fetchProperties(defaultCounties);
                }

                if ( queryData[3].status === 200 ) {
                    setFavourites(queryData[3].data);
                }
            }
            else {
                navigate("/sign-up");
            }
        };

        const fetchProperties = async(data) => {
            // JUST CITY OR ZIP CODE: regionOnly = 1
            // otherwise it will return the county data instead of just the zip/city data

            // Page value: default = 1...param = "page"

            const queryArray = [];
            for (let index = 0; index < data.length; index++) {
                const element = data[index];
                const msaCode = element.msaCode;
                if ( msaCode === null ) {
                    continue;
                }
                const countyType = msaCode.includes("CTY") ? true : false;
                const newObject = {
                    regionId: msaCode.replace("CTY", ""),
                    regionType: countyType === true ? "county" : "city", 
                    strategy: "ltr", 
                    acquisition: "traditional"
                };
                queryArray.push(newObject);
            }
            const limit = `&limit=4&limitExactFlag=1&regionOnly=1`;
            const queryString = `${await formatFilters("regionIds", queryArray)}${limit}`;
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
            const gatewayData = {
                type: "gateway",
                resourceId: "listingsV4",
                queryString: `abstractListings?${queryString}`
            };
            const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
            if ( getGateway.status === 200 ) {
                const body = getGateway.body;
                for (let index = 0; index < body.length; index++) {
                    const element = body[index];
                    const msaCode = `${element.regionType === "county" ? "CTY" : ""}${element.regionId}`;
                    const props = element.data.props;
                    const countyIndex = data.findIndex(e => e.msaCode === msaCode);
                    if ( countyIndex !== -1 ) {
                        data[countyIndex].properties = props;
                    }
                };

                for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    if ( element.properties === undefined ) {
                        data[index].properties = [];
                    }
                };
                setRecentlyViewed(data);
            }
            setLoading(false);
        };

        fetchUserData();
    }, [navigate]);

    const closePremiumModal = () => {
        setPremiumModal(false);
    };

    const viewPricing = () => {
        navigate("/pricing");
    };

    const upgradeNow = () => {
        goToCreativeCheckout(navigate);
    };

    return (
        <div className="home-outer-container bg-colour-white">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={premiumModal === true ? false : true}
                desktopControl={true}
            />
            <div className={"home-inner-container " + (premiumModal === true ? "relative-container" : "")}>
                {
                    premiumModal === true &&
                    <Modal
                        heading="Plus Feature 👀 "
                        subheading={"You've discovered a Plus Plan feature!"}
                        body={<Upgrade />}
                        type="secondary"
                        cancelText="Maybe later"
                        cancelButtonCTA={closePremiumModal}
                        actionButtonText="Upgrade now"
                        actionButtonCTA={upgradeNow}
                        actionButtonDisabled={false}
                        secondaryButtonText="See pricing plans"
                        secondaryButtonCTA={viewPricing}
                        exitButtonCTA={closePremiumModal}
                        loading={false}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                {
                    premiumModal === true &&
                    <div className="dark-bg">
                    </div>
                }
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                    subscriptions={subscriptions}
                />
                <div className="home-body-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        <div className="home-inner-body-container">
                            <div className="home-title-container">
                                <h1 className="heading-large-semibold colour-primary">
                                    {
                                        userData === null ?
                                        "Explore cash-flowing markets"
                                        :
                                        `Hello, ${userData.firstName}`
                                    }
                                </h1>
                                <h2 className="body-regular colour-secondary">
                                    {
                                        userData === null ?
                                        "Browse the most popular markets on Coffee Clozers, or search for a city to get started."
                                        :
                                        "Jump back in or search for a city, county, or address to get started."
                                    }
                                </h2>
                            </div>
                            <div className="home-galleries-container">
                                {
                                    recentlyViewed.map((item, index) =>
                                        item.properties.length > 0 &&
                                        <PropertyGallery
                                            item={item}
                                            key={index}
                                            userId={userId}
                                            favourites={favourites}
                                            setFavourites={setFavourites}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        loading === false &&
                        <LiveCounties
                            userId={userId}   
                            userData={userData}
                            subscriptions={subscriptions} 
                            setPremiumModal={setPremiumModal}
                            title={true}
                            featured={false}
                        />
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Home;