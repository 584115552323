import React, { useState } from 'react';
import '../styles/RehabTab.css';
import { numberFormatter } from '../styles/GlobalStyles';
import { FilterReset, FinancialsInput, CustomSlider, Tooltip, Banner } from '../v4/components';
import { checkNaN, formatPrice } from '../functions';

function RehabTab(props) {
    const property = props.property;
    const costPerSqFoot = props.costPerSqFoot;
    const totalRehabCost = props.totalRehabCost;
    const defaultTotalCost = props.defaultTotalCost;
    const onChangeCostPerSqFoot = props.onChangeCostPerSqFoot;
    const onChangeTotalCost = props.onChangeTotalCost;
    const changeRehabCost = props.changeRehabCost;
    const arv = props.arv;
    const onChangeARV = props.onChangeARV;
    const onChangeARVSlider = props.onChangeARVSlider;
    const customArv = props.customArv;
    const maxPercentage = props.maxPercentage;
    const defaultArv = props.defaultArv;
    const [arvTooltip, setArvTooltip] = useState(false);

    const openARVTooltip = () => {
        setArvTooltip(true);
    };

    const closeARVTooltip = () => {
        setArvTooltip(false);
    };

    return (
        <div className="rehab-tab-outer-container">
            <div className="rehab-tab-inner-container">
                <div className="rehab-tab-title-container">
                    <h3 className="body-regular colour-primary">
                        Estimate rehab cost
                    </h3>
                </div>
                <div className="rehab-tab-inputs-row">
                    <div className="rehab-tab-input-element rehab-tab-sq-foot-input">
                        <FinancialsInput
                            label={"Per squarefoot"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(costPerSqFoot)}
                            onChange={(text) => onChangeCostPerSqFoot(text.target.value.replaceAll(",", ""))}
                            placeholder={0}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                            multiline={false}
                            error={checkNaN(costPerSqFoot)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                    <span className="body-regular colour-secondary">
                        x{numberFormatter.format(property.livingArea)} =
                    </span>
                    <div className="rehab-tab-input-element rehab-tab-total-input">
                        <FinancialsInput
                            label={"Total"}
                            labelType={"default"}
                            info={false}
                            value={formatPrice(totalRehabCost)}
                            onChange={(text) => onChangeTotalCost(text.target.value.replaceAll(",", ""))}
                            placeholder={"40,000"}
                            type="text"
                            leftUnit={"$"}
                            rightUnit={null}
                            unitSwitch={false}
                            leftUnitSelected={true}
                            unitSwitchFunc={null}
                            disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                            multiline={false}
                            error={checkNaN(totalRehabCost)}
                            onMouseEnter={null}
                            onMouseLeave={null}
                            reset={false}
                            resetFunc={null}
                            resetPosition={null}
                        />
                    </div>
                </div>
                {
                    totalRehabCost === defaultTotalCost ?
                    null
                    :
                    <FilterReset
                        clickFunc={() => onChangeTotalCost(defaultTotalCost)}
                    />
                }
                <div className="rehab-tab-slider-container">
                    <CustomSlider
                        disabled={property.livingArea === 0 || property.livingArea === null ? true : false}
                        value={Number(costPerSqFoot)}
                        label="Total rehab cost"
                        onChange={changeRehabCost}
                        min={0}
                        max={100}
                    />
                </div>
                <div className="property-spacing-border">
                </div>
                <Banner
                    label="Our ARV estimate"
                    value={defaultArv === property.price ? "Unknown" : formatPrice(defaultArv)}
                />
                <div className="rehab-tab-arv-container">
                    <div className="strategies-panel-financials-input">
                        <div className="rehab-tab-input-row">
                            <div className="rehab-tab-arv-input-container relative-container">
                                <FinancialsInput
                                    label={"After repair value (ARV)"}
                                    labelType={"default"}
                                    info={true}
                                    value={formatPrice(arv)}
                                    onChange={(text) => onChangeARV(text.target.value.replaceAll(",", ""))}
                                    placeholder={defaultArv}
                                    type="text"
                                    leftUnit={"$"}
                                    rightUnit={null}
                                    unitSwitch={false}
                                    leftUnitSelected={true}
                                    unitSwitchFunc={null}
                                    disabled={false}
                                    multiline={false}
                                    error={checkNaN(arv)}
                                    onMouseEnter={openARVTooltip}
                                    onMouseLeave={closeARVTooltip}
                                    reset={arv === defaultArv ? false : true}
                                    resetFunc={() => onChangeARV(defaultArv)}
                                    resetPosition={"bottom"}
                                />
                                {
                                    arvTooltip &&
                                    <Tooltip
                                        type="info"
                                        title="After repair value (ARV)"
                                        body="This is the estimated selling price of the property after repairs are completed."
                                        position="top"
                                        contextTooltipBg={null}
                                        exit={false}
									    exitFunc={null}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="rehab-tab-slider-container relative-container">
                        <CustomSlider
                            disabled={false}
                            value={customArv}
                            label="ARV"
                            onChange={onChangeARVSlider}
                            min={maxPercentage}
                            max={30}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RehabTab;