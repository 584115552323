import React from 'react';
import '../styles/PropertyGallery.css';
import { Button } from './';
import { NewPropertyCard } from '../../components';
import { colour, formatterLong } from '../../styles/GlobalStyles';
import { Arrow } from '../../assets/icons';

function PropertyGallery(props) {
    const item = props.item;
    const favourites = props.favourites;
    const setFavourites = props.setFavourites;

    const seeMore = () => {
        window.open(`/properties-list/${item.msaCode}`, "_blank");
    };

    return (
        <div className="property-gallery-outer-container">
            <div className="property-gallery-top-container">
                <h3 
                    className="heading-small-semibold colour-link"
                    onClick={() => seeMore()}
                >
                    Properties in {item.msaTitle}
                </h3>
                <div 
                    className="text-button button-row gap-x-small"
                    onClick={() => seeMore()}
                >
                    <span className="body-regular text-link">
                        See more
                    </span>
                    <Arrow
                        fill={colour.blueBlue03}
                        className="property-gallery-arrow"
                    />
                </div>
            </div>
            <div className="property-gallery-property-row">
                {
                    item.properties.map((property, index) =>
                        <NewPropertyCard
                            item={property}
                            index={index}
                            key={index}
                            userDetails={null}
                            formatter={formatterLong}
                            abstract={true}
                            viewedProperties={null}
                            setViewedProperties={null}
                            cityId={property.cityId}
                            setHoverCard={null}
                            propertyRefs={[]}
                            setCardClick={null}
                            temporaryId={false}
                            overlay={true}
                            setOverlay={null}
                            propertySearch={false}
                            partnerView={false}
                            partnerEditProperty={null}
                            partnerSoldProperty={null}
                            partnerData={null}
                            changeStatus={null}
                            setShowExitScreen={null}
                            selectOption={null}
                            openStatus={null}
                            chooseStatus={null}
                            state={null}
                            favourites={favourites}
                            setFavourites={setFavourites}
                            strategy={null}
                            arrowClicked={null}
                            imageLimit={10}
                            bulkActions={false}
                            selectedProperty={null}
                            toggleSelected={null}
                            note={null}
                            openNoteModal={null}
                            openUnfavouritePropertiesModal={null}
                            creativeStrategy={false}
                            activeMarket={true}
                            onboarding={false}
                        />
                    )
                }
            </div>
            <div className="property-gallery-button-container">
                <Button
                    buttonType="secondary-button"
                    text="Explore more"
                    cta={seeMore}
                    disabled={false}
                    leftIcon={null}
                    rightIcon={null}
                    alt="Explore more"
                    size="large"
                    standardIcon={true}
                    badge={false}
                    badgeVal={null}
                    badgeBG={null}
                    buttonRef={null}
                />
            </div>
        </div>
    )
};

export default PropertyGallery;