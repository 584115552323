import React from 'react';
import { Polygon } from '@react-google-maps/api';
import { mapColours, colour, neighbourhoodColours } from '../styles/GlobalStyles';

function NeighbourhoodGradeMap(props) {
    const bounds = props.bounds;
    const tracts = props.tracts;
    const hoverMapLegend = props.hoverMapLegend;
    const cardClick = props.cardClick;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const neighbourhoodDataOptions = [
        {
			label: "Neighbourhood grade",
			location: ""
		},
		{
			label: "Median household income",
			location: "economics",
            subLocation: "medianHouseholdIncome"
		},
		{
			label: "Education (graduate degree)",
			location: "economics",
            subLocation: "ratioEducationGraduateDegree",
		},
		{
			label: "Employed",
            location: "economics",
			subLocation: "ratioEmployed"
		},
		{
			label: "Owner occupied",
			location: "economics",
            subLocation: "ratioOwnerOccupied"
		},
		{
			label: "Vacant",
			location: "economics",
            subLocation: "ratioVacant"
		},
		{
			label: "Total population",
			location: "economics",
            subLocation: "totalPopulation"
		},
		{
			label: "Median days on market",
			location: "market",
            subLocation: "medianDaysOnMarket"
		},
		{
			label: "Median list price",
			location: "market",
            subLocation: "medianListPrice"
		},
		{
			label: "Median living area",
			location: "market",
            subLocation: "medianLivingArea"
		},
		{
			label: "Median rent",
			location: "market",
            subLocation: "medianRent"
		},
		{
			label: "Number of listings",
			location: "market",
            subLocation: "numberOfListings"
		},
		{
			label: "Median ARV spread",
			location: "metrics",
            subLocation: "medianArvSpread"
		},
		{
			label: "Median cash on cash",
			location: "metrics",
            subLocation: "medianCashOnCash"
		},
		{
			label: "Median price per sq. foot",
			location: "metrics",
            subLocation: "medianPricePerSqft"
		},
		{
			label: "Price to rent ratio",
			location: "metrics",
            subLocation: "priceToRentRatio"
		},
		{
			label: "Median bathrooms",
			location: "propAttr",
            subLocation: "medianBathrooms"
		},
		{
			label: "Median bedrooms",
			location: "propAttr",
            subLocation: "medianBedrooms"
		},
		{
			label: "Median sq. feet",
			location: "propAttr",
            subLocation: "medianSqft"
		},
		{
			label: "Median year",
			location: "propAttr",
            subLocation: "medianYear"
		},
        {
            label: "Recent new build growth",
            location: "region",
            subLocation: "recentNewBuildGrowth"
        }
	];
    const neighbourhoodOption = props.neighbourhoodOption;
    const neighbourhoodData = props.neighbourhoodData;
    const dataLocation = neighbourhoodOption === 0 ? null : neighbourhoodDataOptions[neighbourhoodOption].location;
    const dataSubLocation = neighbourhoodOption === 0 ? null : neighbourhoodDataOptions[neighbourhoodOption].subLocation;
    const clickTract = props.clickTract;
    const clickedTract = props.clickedTract;

    const styles = {
		inactive: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: '#FFFFFF',
			strokeWeight: 1,
			zIndex: 1
		},
		inactiveHover: {
			fillColor: mapColours.mapColorsMapZoneInactive,
			fillOpacity: 1,
			strokeColor: colour.blueBlue02,
			strokeWeight: 3,
			zIndex: 2
		},
		first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    const generateColor = (darkYellow, lightYellow, percentage) => {
        percentage = Math.max(0, Math.min(1, percentage));
      
        const deltaRed = lightYellow.red - darkYellow.red;
        const deltaGreen = lightYellow.green - darkYellow.green;
        const deltaBlue = lightYellow.blue - darkYellow.blue;
      
        const red = Math.round(darkYellow.red + (deltaRed * percentage));
        const green = Math.round(darkYellow.green + (deltaGreen * percentage));
        const blue = Math.round(darkYellow.blue + (deltaBlue * percentage));
        const getHex = rgbaToHex(red, green, blue, 0.7);
      
        return getHex;
    };

    const rgbaToHex = (r, g, b, a) => {
        // Convert the RGB to a hexadecimal value
        const rHex = r.toString(16).padStart(2, '0');
        const gHex = g.toString(16).padStart(2, '0');
        const bHex = b.toString(16).padStart(2, '0');
    
        // Convert the alpha value to a hexadecimal value
        const aHex = Math.round(a * 255).toString(16).padStart(2, '0');
    
        // Combine the RGB and alpha values to get the hexadecimal color code
        const hexCode = `#${rHex}${gHex}${bHex}${aHex}`;
    
        return hexCode.toUpperCase(); // Convert to uppercase for consistency
    };

	const checkNeighbourhoodStyles = (index) => {
        if ( hoverMapLegend !== null ) {
            if ( (index + 1) > neighbourhoodData.length ) {
                return styles.inactive;
            }

            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const gradeTranslation = [
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1
            ];

            if ( tractValue === gradeTranslation[hoverMapLegend] ) {
                const newStyle = {
                    fillColor: neighbourhoodColours[hoverMapLegend],
                    fillOpacity: 1,
                    strokeColor: colour.blueBlue02,
                    strokeWeight: 3,
                    zIndex: 2
                }
                
                return newStyle;
            }
            else {
                const newStyle = {
                    fillColor: styles.inactive.fillColor,
                    fillOpacity: 1,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1,
                    zIndex: 1
                }
                
                return newStyle;
            }
        }
        else if ( neighbourhoodOption === 0 ) {
            if ( (index + 1) > neighbourhoodData.length ) {
                return styles.inactive;
            }

            const tractValue = neighbourhoodData[index].neighborhoodGrade;
            const getHex = (tractValue < neighbourhoodGradeMin && clickedTract !== neighbourhoodData[index].region.tractId) || (tractValue > neighbourhoodGradeMax && clickedTract !== neighbourhoodData[index].region.tractId) ? styles.inactive.fillColor
            :
            tractValue === 8 ? styles.first.backgroundColor 
            :
            tractValue === 7 ? styles.second.backgroundColor
            :
            tractValue === 6 ? styles.third.backgroundColor
            :
            tractValue === 5 ? styles.fourth.backgroundColor
            :
            tractValue === 4 ? styles.fifth.backgroundColor
            :
            tractValue === 3 ? styles.sixth.backgroundColor
            :
            tractValue === 2 ? styles.seventh.backgroundColor
            :
            styles.eighth.backgroundColor;

            if ( clickedTract === neighbourhoodData[index].region.tractId ) {
                const newStyle = {
                    fillColor: getHex,
                    fillOpacity: 1,
                    strokeColor: colour.blueBlue02,
                    strokeWeight: 3,
                    zIndex: 2
                }
                
                return newStyle;
            }
            else {
                const newStyle = {
                    fillColor: getHex,
                    fillOpacity: 1,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1,
                    zIndex: 1
                }
                
                return newStyle;
            }
        }
        else if ( neighbourhoodOption === 20 ) {
            const allValues = [];
            for (let i = 0; i < neighbourhoodData.length; i++) {
                const element = neighbourhoodData[i][dataLocation];
                if ( element !== null ) {
                    const subElement = element[dataSubLocation];
                    if ( subElement !== null ) {
                        if ( subElement === true ) {
                            allValues.push(1);
                        }
                        else {
                            allValues.push(0);
                        }
                    }
                }
            }
            
            const tractElement = neighbourhoodData[index][dataLocation];
            if ( tractElement === null ) {
                return styles.inactive
            }
            else if ( tractElement[dataSubLocation] === null ) {
                return styles.inactive
            }
            else {
                const tractValue = tractElement[dataSubLocation] === true ? 1 : 0;
                const minVal = Math.min(...allValues);
                const maxVal = Math.max(...allValues);
                const range = maxVal - minVal;
                const distanceFromMin = tractValue - minVal;
                if ( range === 0 && (clickedTract === neighbourhoodData[index].region.tractId) ) {
                    const newStyle = {
                        fillColor: styles.inactive,
                        fillOpacity: 1,
                        strokeColor: colour.blueBlue02,
                        strokeWeight: 3,
                        zIndex: 2
                    }
                    
                    return newStyle;
                }
                else if ( range === 0 ) {
                    const newStyle = {
                        fillColor: styles.inactive,
                        fillOpacity: 1,
                        strokeColor: '#FFFFFF',
                        strokeWeight: 1,
                        zIndex: 1
                    }
                    
                    return newStyle;
                }
                const percentage = (distanceFromMin / range);
                const darkYellow = colour.heatmapHot;
					const lightYellow = colour.heatmapCold;
                const getHex = generateColor(lightYellow, darkYellow, percentage);

                if ( clickedTract === neighbourhoodData[index].region.tractId ) {
                    const newStyle = {
                        fillColor: getHex,
                        fillOpacity: 1,
                        strokeColor: colour.blueBlue02,
                        strokeWeight: 3,
                        zIndex: 2
                    }
                    
                    return newStyle;
                }
                else {
                    const newStyle = {
                        fillColor: getHex,
                        fillOpacity: 1,
                        strokeColor: '#FFFFFF',
                        strokeWeight: 1,
                        zIndex: 1
                    }
                    
                    return newStyle;
                }
            }
        }
        else {
            const allValues = [];
            for (let i = 0; i < neighbourhoodData.length; i++) {
                const element = neighbourhoodData[i].stats[dataLocation];
                if ( element !== null ) {
                    const subElement = element[dataSubLocation];
                    if ( subElement !== null ) {
                        allValues.push(subElement);
                    }
                }
            }

            const tractElement = neighbourhoodData[index].stats[dataLocation];
            if ( tractElement === null ) {
                return styles.inactive
            }
            else if ( tractElement[dataSubLocation] === null ) {
                return styles.inactive
            }
            else {
                const tractValue = tractElement[dataSubLocation];
                const minVal = Math.min(...allValues);
                const maxVal = Math.max(...allValues);
                const range = maxVal - minVal;
                const distanceFromMin = tractValue - minVal;
                if ( range === 0 && (clickedTract === neighbourhoodData[index].region.tractId) ) {
                    const newStyle = {
                        fillColor: styles.inactive,
                        fillOpacity: 1,
                        strokeColor: colour.blueBlue02,
                        strokeWeight: 3,
                        zIndex: 2
                    }
                    
                    return newStyle;
                }
                else if ( range === 0 ) {
                    const newStyle = {
                        fillColor: styles.inactive,
                        fillOpacity: 1,
                        strokeColor: '#FFFFFF',
                        strokeWeight: 1,
                        zIndex: 1
                    }
                    
                    return newStyle;
                }
                const percentage = (distanceFromMin / range);
                const darkYellow = colour.heatmapHot;
				const lightYellow = colour.heatmapCold;
                const getHex = generateColor(lightYellow, darkYellow, percentage);

                if ( clickedTract === neighbourhoodData[index].region.tractId ) {
                    const newStyle = {
                        fillColor: getHex,
                        fillOpacity: 1,
                        strokeColor: colour.blueBlue02,
                        strokeWeight: 3,
                        zIndex: 2
                    }
                    
                    return newStyle;
                }
                else {
                    const newStyle = {
                        fillColor: getHex,
                        fillOpacity: 1,
                        strokeColor: '#FFFFFF',
                        strokeWeight: 1,
                        zIndex: 1
                    }
                    
                    return newStyle;
                }
            }
        }
	};

    return (
        bounds.map((item, index) =>
            <Polygon
                editable={false}
                paths={item}
                options={checkNeighbourhoodStyles(index)}
                key={index}
                onClick={() => cardClick === true ? null : clickTract(tracts[index])}
            />	
        )
    )
};

export default NeighbourhoodGradeMap;