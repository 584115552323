import React, { useEffect, useState, useRef } from 'react';
import '../styles/SubtoCalculator.css';
import { Accordion, AccordionDetails, AccordionSummary, colour, formatterCompact, percentage } from '../styles/GlobalStyles';
import { calculateInterestPaid, calculateSubToMortgagePayment, formatPrice, checkNaN } from '../functions';
import { CustomSlider, FilterChip, FinancialsInput } from '../v4/components';
import { Chevron } from '../assets/icons';

function SubtoCalculator(props) {
    const property = props.property;
    const customPrice = props.customPrice;
    const setCustomPrice = props.setCustomPrice;
    const downPayment = props.downPayment;
    const setDownPayment = props.setDownPayment;
    const interestRate = props.interestRate;
    const setInterestRate = props.setInterestRate;
    const balloonPayment = props.balloonPayment;
    const setBalloonPayment = props.setBalloonPayment;
    const duration = props.duration;
    const setDuration = props.setDuration;
    const amortisation = props.amortisation;
    const setAmortisation = props.setAmortisation;
    const monthlyPayment = props.monthlyPayment;
    const setMonthlyPayment = props.setMonthlyPayment;
    const setSellerCompensation = props.setSellerCompensation;
    const defaultDownPayment = props.defaultDownPayment;
    const ownerEquity = props.ownerEquity;
    const setOwnerEquity = props.setOwnerEquity;
    const remainingMortgage = props.remainingMortgage;
    const setRemainingMortgage = props.setRemainingMortgage;
    const sellersMortgage = props.sellersMortgage;
    const setSellersMortgage = props.setSellersMortgage;
    const onChangeSubto = props.onChangeSubto;
    const closingPercentage = props.closingPercentage;
    const setClosingPercentage = props.setClosingPercentage;
    const closingCost = props.closingCost;
    const onboarding = props.onboarding;
    const defaultInterestRate = 3.5;

    const [selectedPanel, setSelectedPanel] = useState(0);
    const [firstQuery, setFirstQuery] = useState(false);
    const [mortgageAccordion, setMortgageAccordion] = useState(true);
    const [purchaseAccordion, setPurchaseAccordion] = useState(false);
    const [dollarDownPayment, setDollarDownPayment] = useState(customPrice * (downPayment / 100));
    const [priceSlider, setPriceSlider] = useState(20);
    const [downPaymentSlider, setDownPaymentSlider] = useState(defaultDownPayment);
    const [ownerEquityDollar, setOwnerEquityDollar] = useState(Number(customPrice * ownerEquity));
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(false);

    const defaultDollarEquity = Number((property.price * ownerEquity));
    const defaultRemainingMortgage = Number(property.ownerAttributes.estMortgageBalance);
    const defaultEquity = (property.price - defaultRemainingMortgage) / property.price;
    const defaultMortgagePayment = Number(property.ownerAttributes.estMortgagePayment);

    const ownerEquityRef = useRef(null);

    const ownerEquityInfo = [
        {
            label: "Est. equity",
            value: percentage.format(defaultEquity)
        },
        {
            label: "Est. mort. balance",
            value: formatterCompact.format(defaultRemainingMortgage)
        },
        {
            label: "Est. mort. payment",
            value: formatterCompact.format(defaultMortgagePayment)
        }
    ];

    const handleMortgageAccordion = () => {
        setMortgageAccordion(!mortgageAccordion);
    };

    const handlePurchaseAccordion = () => {
        setPurchaseAccordion(!purchaseAccordion);
    };

    useEffect(() => {

        const calculateFirstMonthlyPayment = async() => {
            const newMortgage = await calculateSubToMortgagePayment(Number(customPrice), downPayment, interestRate, ownerEquity, (amortisation / 12));
            setMonthlyPayment(newMortgage);
            const newOwnerEquityDollar = ownerEquity * customPrice;

            const totalInterest = await calculateInterestPaid(newOwnerEquityDollar, downPayment, interestRate, (amortisation / 12), (duration / 12));
            const newTotalCompensation = Number(newOwnerEquityDollar) + totalInterest;
            setSellerCompensation(newTotalCompensation);
            const downPaymentFee = customPrice * (downPayment / 100);
            const totalMonthlyPayments = newMortgage * duration;
            const remainingBalance = (newTotalCompensation - downPaymentFee) - totalMonthlyPayments;

            setBalloonPayment(remainingBalance);
        };

        if ( firstQuery === false ) {
            setFirstQuery(true);
            calculateFirstMonthlyPayment();

            if ( onboarding === true ) {
                setPurchaseAccordion(true);

                setTimeout(() => {
                    if ( ownerEquityRef.current !== null ) {
                        ownerEquityRef.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end'
                        });
                    }
                }, 500);
            }
        }
    }, [
        amortisation,
        customPrice,
        downPayment,
        duration,
        firstQuery,
        interestRate,
        onboarding,
        ownerEquity,
        setBalloonPayment,
        setMonthlyPayment,
        setSellerCompensation
    ]);

    const termOptions = [
        "Purchase",
        "Terms"
    ];

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    const roundToNearestTenth = (num) => {
        return Math.round(num * 10) / 10;
    };

    const changeMortgageBalance = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        setRemainingMortgage(value);

        const newOwnerEquity = (customPrice - Number(value)) / customPrice;
        const newEquity = (newOwnerEquity * customPrice);
        setOwnerEquityDollar(newEquity);

        recalculateMonthlyPayment(customPrice, downPayment, interestRate, duration, amortisation, newOwnerEquity, sellersMortgage, closingCost);
    };

    const onChangeSellersMortgage = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        setSellersMortgage(value);
        recalculateMonthlyPayment(customPrice, downPayment, interestRate, duration, amortisation, ownerEquity, Number(value), closingCost);
    };

    const onChangePrice = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        setCustomPrice(value);
        const newDownPayment = Number(value) === 0 ? 0 : (dollarDownPayment / Number(value)) * 100;

        const newOwnerEquity = Number(value) === 0 ? 0 : (Number(value) - remainingMortgage) / Number(value);
        const newEquity = newOwnerEquity * Number(value);
        setOwnerEquityDollar(newEquity);
        setOwnerEquity(newOwnerEquity);

        const oneSliderUnit = property.price / 20;
        const newSliderValue = roundToNearestTenth(Number(value) / oneSliderUnit);
        setPriceSlider(newSliderValue);

        const newClosingCost = (Number(value) * (closingPercentage / 100));
        recalculateMonthlyPayment(value, newDownPayment, interestRate, duration, amortisation, newOwnerEquity, sellersMortgage, newClosingCost);
    };

    const onChangePriceSlider = (event, value) => {
        setPriceSlider(value);

        const oneUnit = property.price / 20;
        const newPrice = (oneUnit * value);
        onChangePrice(newPrice);
    };

    const onChangeDownPayment = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        else if ( Number(value) > 100 ) {
            return;
        }
        setDownPayment(value);
        const newDollar = (Number(value) / 100) * customPrice;
        setDollarDownPayment(newDollar);

        const newSliderValue = roundToNearestTenth(Number(value));
        setDownPaymentSlider(newSliderValue);

        recalculateMonthlyPayment(customPrice, value, interestRate, duration, amortisation, ownerEquity, sellersMortgage, closingCost);
    };

    const onChangeDownPaymentDollar = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        const newPercentage = (Number(value) / customPrice) * 100;
        setDownPayment(newPercentage);
        setDollarDownPayment(value);
        setDownPaymentSlider(Math.round(newPercentage));

        recalculateMonthlyPayment(customPrice, newPercentage, interestRate, duration, amortisation, ownerEquity, sellersMortgage, closingCost);
    };

    const toggleDPPercentage = (val) => {
        setDownPaymentPercentage(val);
    };

    const onChangeDownPaymentSlider = (event, value) => {
        setDownPaymentSlider(value);

        const newDownPayment = ((customPrice * value) / 100);
        onChangeDownPaymentDollar(newDownPayment);
    };

    const onChangeOwnerEquity = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        setOwnerEquityDollar(value);

        const newPrice = Math.round(Number(value) / ownerEquity);
        setCustomPrice(newPrice);

        recalculateMonthlyPayment(newPrice, downPayment, interestRate, duration, amortisation, ownerEquity, sellersMortgage, closingCost);
    };

    const onChangeInterestRate = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        setInterestRate(value);
        recalculateMonthlyPayment(customPrice, downPayment, value, duration, amortisation, ownerEquity, sellersMortgage, closingCost);
    };

    const onChangeAmortisation = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        const newVal = value === "" ? "" : Number(value) * 12;
        setAmortisation(newVal);
        recalculateMonthlyPayment(customPrice, downPayment, interestRate, duration, newVal, ownerEquity, sellersMortgage, closingCost);
    };

    const onChangeClosing = async(value) => {
        if ( checkNaN(value) === true && value !== "" )  {
			return;
		}

		if ( Number(value) <= 100 ) {
			setClosingPercentage(value);
            const newClosingCost = (customPrice * (Number(value) / 100));
            recalculateMonthlyPayment(customPrice, downPayment, interestRate, duration, amortisation, ownerEquity, sellersMortgage, newClosingCost);
		}
    };

    const onChangeBalloon = (value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        setBalloonPayment(value);
    };

    const onChangeDuration = async(value) => {
        if ( checkNaN(value) === true ) {
            return;
        }
        const newVal = value === "" ? "" : Number(value) * 12;
        setDuration(newVal);
        recalculateMonthlyPayment(customPrice, downPayment, interestRate, newVal, amortisation, ownerEquity, sellersMortgage, closingCost);
    };
    
    const recalculateMonthlyPayment = async(
        newPrice, 
        newDown, 
        newInt, 
        newDuration, 
        newAmortisation,
        newEquity,
        newSellersMortgage,
        newClosingCost
    ) => {
        const checkedNewSellersMortgage = Number(newSellersMortgage);
        const newMortgage = await calculateSubToMortgagePayment(Number(newPrice), newDown, newInt, newEquity, (newAmortisation / 12));
        const newMonthlyPayment = (newMortgage + checkedNewSellersMortgage);
        setMonthlyPayment(newMortgage);

        const newOwnerEquityDollar = newEquity * newPrice;
        const totalInterest = await calculateInterestPaid(newOwnerEquityDollar, newDown, newInt, (newAmortisation / 12), (newDuration / 12));
        const newTotalCompensation = Number(newOwnerEquityDollar) + totalInterest;
        setSellerCompensation(newTotalCompensation);
        const downPaymentFee = newPrice * (newDown / 100);
        const totalMonthlyPayments =  Number(newDuration) === 0 ? 0 : newMortgage * Number(newDuration);
        const remainingBalance = (newTotalCompensation - downPaymentFee) - totalMonthlyPayments;
        setBalloonPayment(remainingBalance);

        const res = {
            downPayment: downPaymentFee,
            sellersEquityMortgage: newMortgage,
            sellersBankMortgage: checkedNewSellersMortgage,
            totalMonthlyPayment: newMonthlyPayment,
            sellerFinance: false,
            price: newPrice,
            closingCost: newClosingCost
        };
        onChangeSubto(res);
    };

    return (
        <div className="subto-calculator-container">
            <div className="subto-grid-container">
                {
                    ownerEquityInfo.map((item, index) =>
                        <div 
                            className="subto-owner-equity-element bg-colour-grey"
                            key={index}
                        >
                            <span className="body-regular colour-secondary">
                                {item.label}
                            </span>
                            <span className="body-semibold colour-primary">
                                {item.value}
                            </span>
                        </div>
                    )
                }
            </div>
            <div className="subto-divider-line">
            </div>
            <div className="subto-step-container">
                <Accordion
                    expanded={mortgageAccordion}
                    onChange={handleMortgageAccordion}
                    sx={{ 
                            boxShadow: 'none',
                            '&:before': {
                            display: 'none',
                        }}
                    }
                >
                    <AccordionSummary
                        sx={{
                            margin: 0,
                            minHeight: 0,
                            '& .MuiAccordionSummary-content': {
                                margin: 0
                            }
                        }}
                    >
                        <div className="subto-accordion-header">
                            <h3 className="body-semibold text-link">
                                1. Pay the seller's mortgage
                            </h3>
                            <Chevron
                                fill={colour.blueBlue03}
                                className={mortgageAccordion === true ? "reverse" : ""}
                            />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="subto-accordion-body-container">
                            <div className="subto-step-row-container">
                                <div className="subto-step-element">
                                    <FinancialsInput
                                        label={"Remaining mort. balance"}
                                        labelType={"default"}
                                        info={false}
                                        value={formatPrice(remainingMortgage)}
                                        onChange={(text) => changeMortgageBalance(text.target.value.replaceAll(",", ""))}
                                        placeholder={formatPrice(defaultRemainingMortgage)}
                                        type="text"
                                        leftUnit={"$"}
                                        rightUnit={null}
                                        unitSwitch={false}
                                        leftUnitSelected={true}
                                        unitSwitchFunc={null}
                                        disabled={false}
                                        multiline={false}
                                        error={checkNaN(remainingMortgage)}
                                        onMouseEnter={null}
                                        onMouseLeave={null}
                                        reset={Number(remainingMortgage) !== defaultRemainingMortgage ? true : false}
                                        resetFunc={() => changeMortgageBalance(defaultRemainingMortgage)}
                                        resetPosition={"default"}
                                    />
                                </div>
                                <div className="subto-step-element subto-red-bg">
                                    <FinancialsInput
                                        label={"Mortgage payment to Bank"}
                                        labelType={"default"}
                                        info={false}
                                        value={formatPrice(sellersMortgage)}
                                        onChange={(text) => onChangeSellersMortgage(text.target.value.replaceAll(",", ""))}
                                        placeholder={formatPrice(defaultMortgagePayment)}
                                        type="text"
                                        leftUnit={"$"}
                                        rightUnit={null}
                                        unitSwitch={false}
                                        leftUnitSelected={true}
                                        unitSwitchFunc={null}
                                        disabled={false}
                                        multiline={false}
                                        error={checkNaN(sellersMortgage)}
                                        onMouseEnter={null}
                                        onMouseLeave={null}
                                        reset={Number(sellersMortgage) !== defaultMortgagePayment ? true : false}
                                        resetFunc={() => onChangeSellersMortgage(defaultMortgagePayment)}
                                        resetPosition={"default"}
                                    />
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="subto-divider-line">
            </div>
            <div className="subto-step-container">
                <Accordion
                    expanded={purchaseAccordion}
                    onChange={handlePurchaseAccordion}
                    sx={{ 
                            boxShadow: 'none',
                            '&:before': {
                            display: 'none',
                        }}
                    }
                >
                    <AccordionSummary
                        sx={{
                            margin: 0,
                            minHeight: 0,
                            '& .MuiAccordionSummary-content': {
                                margin: 0
                            }
                        }}
                    >
                        <div className="subto-accordion-header">
                            <h3 className="body-semibold text-link">
                                2. Seller-finance the seller's equity
                            </h3>
                            <Chevron
                                fill={colour.blueBlue03}
                                className={purchaseAccordion === true ? "reverse" : ""}
                            />
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="subto-accordion-body-container">
                            <div className="subto-accordion-panels-row">
                                {
                                    termOptions.map((item, index) =>
                                        <FilterChip
                                            label={item}
                                            selected={selectedPanel}
                                            index={index}
                                            disabled={false}
                                            leftIcon={null}
                                            rightIcon={null}
                                            func={changeSelectedPanel}
                                            key={index}
                                        />
                                    )
                                }
                            </div>
                            {
                                selectedPanel === 0 ?
                                <div className="subto-step-category-container">
                                    <div className="subto-step-row-container">
                                        <div className="subto-step-element">
                                            <FinancialsInput
                                                label={"Offer price"}
                                                labelType={"default"}
                                                info={false}
                                                value={formatPrice(customPrice)}
                                                onChange={(text) => onChangePrice(text.target.value.replaceAll(",", ""))}
                                                placeholder={formatPrice(property.price)}
                                                type="text"
                                                leftUnit={"$"}
                                                rightUnit={null}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(customPrice)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={Number(customPrice) !== property.price ? true : false}
                                                resetFunc={() => onChangePrice(property.price)}
                                                resetPosition={"default"}
                                            />
                                            <CustomSlider
                                                disabled={false}
                                                value={priceSlider}
                                                label="Offer price"
                                                onChange={onChangePriceSlider}
                                                min={0}
                                                max={25}
                                            />
                                        </div>
                                        <div className="subto-step-element">
                                            <FinancialsInput
                                                label={"Down payment"}
                                                labelType={"default"}
                                                info={false}
                                                value={downPaymentPercentage === false ? formatPrice(dollarDownPayment) : `${downPayment}`.length > 4 ? formatPrice(downPayment) : downPayment}
                                                onChange={(text) => downPaymentPercentage === false ? onChangeDownPaymentDollar(text.target.value.replaceAll(",", "")) : onChangeDownPayment(text.target.value)}
                                                placeholder={downPaymentPercentage === true ? 10 : formatPrice(property.price / 10)}
                                                type="text"
                                                leftUnit={"$"}
                                                rightUnit={"%"}
                                                unitSwitch={true}
                                                leftUnitSelected={!downPaymentPercentage}
                                                unitSwitchFunc={(val) => toggleDPPercentage(val)}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(downPaymentPercentage === false ? dollarDownPayment : downPayment)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={Number(downPayment) !== Number(defaultDownPayment) ? true : false}
                                                resetFunc={() => onChangeDownPayment(defaultDownPayment)}
                                                resetPosition={"default"}
                                            />
                                            <CustomSlider
                                                disabled={false}
                                                value={downPaymentSlider}
                                                label="Down payment"
                                                onChange={onChangeDownPaymentSlider}
                                                min={0}
                                                max={100}
                                            />
                                        </div>
                                    </div>
                                    <div 
                                        className="subto-step-row-container"
                                        ref={ownerEquityRef}
                                    >
                                        <div className="subto-step-element-small">
                                            <FinancialsInput
                                                label={"Owner equity"}
                                                labelType={"default"}
                                                info={false}
                                                value={formatPrice(ownerEquityDollar)}
                                                onChange={(text) => onChangeOwnerEquity(text.target.value.replaceAll(",", ""))}
                                                placeholder={defaultDollarEquity}
                                                type="text"
                                                leftUnit={"$"}
                                                rightUnit={null}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(ownerEquityDollar)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={formatPrice(defaultDollarEquity) === formatPrice(ownerEquityDollar) ? false : true}
                                                resetFunc={() => onChangeOwnerEquity(defaultDollarEquity)}
                                                resetPosition={"default"}
                                            />
                                        </div>
                                        <div className="subto-step-element subto-red-bg">
                                            <FinancialsInput
                                                label={"Monthly payment to seller"}
                                                labelType={"default"}
                                                info={false}
                                                value={formatPrice(monthlyPayment)}
                                                onChange={null}
                                                placeholder={defaultMortgagePayment}
                                                type="text"
                                                leftUnit={"$"}
                                                rightUnit={null}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={true}
                                                multiline={false}
                                                error={false}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={false}
                                                resetFunc={null}
                                                resetPosition={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="subto-step-category-container">
                                    <div className="subto-step-row-container">
                                        <div className="subto-step-element-small">
                                            <FinancialsInput
                                                label={"Interest rate"}
                                                labelType={"default"}
                                                info={false}
                                                value={interestRate}
                                                onChange={(text) => onChangeInterestRate(text.target.value.replaceAll(",", ""))}
                                                placeholder={3.5}
                                                type="text"
                                                leftUnit={null}
                                                rightUnit={"%"}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(interestRate)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={Number(interestRate) !== Number(defaultInterestRate) ? true : false}
                                                resetFunc={() => onChangeInterestRate(defaultInterestRate)}
                                                resetPosition={"default"}
                                            />
                                        </div>
                                        <div className="subto-step-element">
                                            <FinancialsInput
                                                label={"Amortization"}
                                                labelType={"default"}
                                                info={false}
                                                value={amortisation === "" ? "" : amortisation / 12}
                                                onChange={(text) => onChangeAmortisation(text.target.value.replaceAll(",", ""))}
                                                placeholder={30}
                                                type="text"
                                                leftUnit={null}
                                                rightUnit={"years"}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(amortisation)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={false}
                                                resetFunc={null}
                                                resetPosition={null}
                                            />
                                        </div>
                                        <div className="subto-step-element">
                                            <FinancialsInput
                                                label={"Closing costs"}
                                                labelType={"default"}
                                                info={false}
                                                value={closingPercentage}
                                                onChange={(text) => onChangeClosing(text.target.value.replaceAll(",", ""))}
                                                placeholder={"3"}
                                                type="text"
                                                leftUnit={null}
                                                rightUnit={"%"}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(closingPercentage)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={false}
                                                resetFunc={null}
                                                resetPosition={null}
                                            />
                                        </div>
                                    </div>
                                    <div className="subto-step-row-container">
                                        <div className="subto-step-element">
                                            <FinancialsInput
                                                label={"Balloon payment"}
                                                labelType={"default"}
                                                info={false}
                                                value={formatPrice(balloonPayment)}
                                                onChange={(text) => onChangeBalloon(text.target.value.replaceAll(",", ""))}
                                                placeholder={formatPrice(200000)}
                                                type="text"
                                                leftUnit={"$"}
                                                rightUnit={null}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={true}
                                                multiline={false}
                                                error={checkNaN(balloonPayment)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={false}
                                                resetFunc={null}
                                                resetPosition={null}
                                            />
                                        </div>
                                        <div className="subto-step-element">
                                            <FinancialsInput
                                                label={"Balloon due in"}
                                                labelType={"default"}
                                                info={false}
                                                value={duration === "" ? "" : duration / 12}
                                                onChange={(text) => onChangeDuration(text.target.value.replaceAll(",", ""))}
                                                placeholder={5}
                                                type="text"
                                                leftUnit={null}
                                                rightUnit={"years"}
                                                unitSwitch={false}
                                                leftUnitSelected={true}
                                                unitSwitchFunc={null}
                                                disabled={false}
                                                multiline={false}
                                                error={checkNaN(duration)}
                                                onMouseEnter={null}
                                                onMouseLeave={null}
                                                reset={false}
                                                resetFunc={null}
                                                resetPosition={null}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
};

export default SubtoCalculator;