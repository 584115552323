import React from 'react';

function Beak(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="18" 
            height="15" 
            viewBox="0 0 18 15" 
            fill="none"
            className={className}
        >
            <path 
                d="M9 15L17.6603 0H0.339746L9 15Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Beak;