import React, { useState } from 'react';
import '../styles/IconButton.css';
import { Tooltip } from './';

function IconButton(props) {
    const size = props.size;
    const icon = props.icon;
    const iconParentClass = `icon-button-element-${size} `;
    const iconClass = `icon-button-${size}`;
    const disabled = props.disabled;
    const clickFunc = props.clickFunc;
    const standardIcon = props.standardIcon;
    const contextTooltip = props.contextTooltip;
    const contextTooltipBG = props.contextTooltipBG;
    const contextTooltipText = props.contextTooltipText;
    const customClass = props.customClass;
    const newClass = customClass === null ? "" : `${customClass} `;

    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipTimer, setTooltipTimer] = useState(null);

    const handleMouseEnter = () => {
        if (contextTooltip) {
            const timer = setTimeout(() => setTooltipVisible(true), 500);
            setTooltipTimer(timer);
        }
    };

    const handleMouseLeave = () => {
        clearTimeout(tooltipTimer);
        setTooltipVisible(false);
    };

    return (
        <div 
            className={"icon-button-element " + (iconParentClass) + (newClass) + (disabled === false ? "cursor-pointer " : "disabled ") + (contextTooltip === true ? "relative-container" : "")}
            onClick={() => clickFunc()}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
        >
            {
                standardIcon === false ?
                icon
                :
                <img
                    src={icon}
                    className={iconClass}
                    alt="Icon Button"
                />
            }
            {
                contextTooltip === true &&
                <Tooltip
                    type="context"
                    contextTooltipBg={contextTooltipBG}
                    body={contextTooltipText}
                    visible={isTooltipVisible}
                    exit={false}
					exitFunc={null}
                />
            }
        </div>
    )
};

export default IconButton;