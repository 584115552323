import React, { useState, useEffect } from 'react';
import '../styles/LoginComponent.css';
import { GoogleLogo } from '../assets';
import { getUser, getDocument, signInWithGoogle, userDeletion, recordEvent } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading';
import TagManager from 'react-gtm-module';
import { Button, FinancialsInput } from '../v4/components';
import { OrDivider } from '../components';

function LoginComponent(props) {

	const signUpRoute = props.signUpRoute;
	const state = props.state;
	const route = props.route;

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [disabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Coffee Clozers | Login";

	}, []);

	const googleSignIn = async() => {
		const loginUser = await signInWithGoogle();
		setDisabled(true);
		setLoading(true);
		if ( loginUser.status === 200 ) {
			const colRef = "Users";
			const userId = loginUser.user.uid;
			const checkUserDocument = await getDocument(colRef, userId);
			if ( checkUserDocument.status === 200 ) {
				complete(loginUser, true);
			}
			else {
				await userDeletion(loginUser.user);
				alert("Please create an account at the sign-up page!");

				navigate("/sign-up", {
					state: state
				});
			}
		}
		else {
			if ( loginUser.code === "auth/wrong-password" ) {
				setErrorMessage("Incorrect password 😔");
			}
			else if ( loginUser.code === "auth/user-not-found" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/invalid-email" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else if ( loginUser.code === "auth/popup-closed-by-user" || loginUser.code === "auth/cancelled-popup-request") {
				console.log("User closed the popup");
			}
			else {
				alert(loginUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	};

	const signInWithEmail = async() => {
		setLoading(true);
		setDisabled(true);
		const loginUser = await getUser(email, password);

		if ( loginUser.status === 200 ) {
			complete(loginUser, false);
		}
		else {
			if ( loginUser.code === "auth/wrong-password" ) {
				setErrorMessage("Incorrect password 😔");
			}
			else if ( loginUser.code === "auth/user-not-found" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/invalid-email" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else {
				alert(loginUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	}

	const complete = async(loginUser, googleAuth) => {
		const colRef = "Users";
		const userId = loginUser.user.uid;
		const queryUserDoc = await getDocument(colRef, userId);

		if ( queryUserDoc.status === 200 ) {
			recordEvent("Login", {
				userId: userId
			});
			const tagManagerArgs = {
				dataLayer: {
					event: "login",
					userId: loginUser.user.uid
				}
			};
			TagManager.dataLayer(tagManagerArgs);

			setErrorMessage("");
			if ( route !== undefined && route !== null && route !== "" ) {
				navigate(route, {
					state: state
				});
			}
			else {
				navigate('/home', {
					state: state
				});
			}
			setLoading(false);
			setDisabled(false);
		}
		else {
			if ( loginUser.code === "auth/wrong-password" ) {
				setErrorMessage("Incorrect password 😔");
			}
			else if ( loginUser.code === "auth/user-not-found" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/invalid-email" ) {
				setErrorMessage("Email not found 🤔");
			}
			else if ( loginUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else {
				alert(loginUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	};

	const handleKeyDown = event => {
		if ( event.key === "Enter" ) {
			signInWithEmail();
		}
	};

	const signUpPage = () => {
		navigate(signUpRoute, {
			state: state
		});
	};

	const onChangeEmail = (e) => {
		setEmail(e.target.value);
	};

	const onChangePassword = (e) => {
		setPassword(e.target.value);
	};

	const forgotPasswordNav = () => {
		const newState = state === null ? {} : state;
		newState.email = email;
		navigate("/password-forgotten", {
			state: newState
		});
	};

	return (
		<div 
			onKeyDown={handleKeyDown} 
			className="login-component-outer-container bg-colour-white"
		>
			<div className="login-component-inner-container">
				{
					loading === true ?
					<Loading />
					:
					null
				}
				<div className="login-component-body-container">
					<div className="login-component-element-container">
						<FinancialsInput
							label={"Email"}
							labelType={"inside"}
							info={false}
							value={email}
							onChange={onChangeEmail}
							placeholder={"youremail@email.com"}
							type="email"
							leftUnit={null}
							rightUnit={null}
							unitSwitch={false}
							leftUnitSelected={true}
							unitSwitchFunc={null}
							disabled={false}
							multiline={false}
							error={false}
							onMouseEnter={null}
							onMouseLeave={null}
							reset={false}
							resetFunc={null}
							resetPosition={null}
						/>
					</div>
					<div className="login-component-element-container">
						<FinancialsInput
							label={"Password"}
							labelType={"inside"}
							info={false}
							value={password}
							onChange={onChangePassword}
							placeholder={"••••••••••"}
							type="password"
							leftUnit={null}
							rightUnit={null}
							unitSwitch={false}
							leftUnitSelected={true}
							unitSwitchFunc={null}
							disabled={false}
							multiline={false}
							error={false}
							onMouseEnter={null}
							onMouseLeave={null}
							reset={false}
							resetFunc={null}
							resetPosition={null}
						/>
						{
							errorMessage !== "" ?
							<div className="login-component-incorrect-password-container">
								<span className="body-regular colour-error">
									{errorMessage}
								</span>
							</div>
							:
							null
						}
					</div>
				</div>
				<div className="login-component-bottom-container">
					<div className="login-component-element-container">
						<span
							className="body-regular colour-link block-text text-align-center"
							onClick={() => forgotPasswordNav()}
						>
							Forgot your password?
						</span>
					</div>
					<div className="login-component-button-container">
						<Button
							buttonType="primary-button"
							size="large"
							text={"Login"}
							cta={signInWithEmail}
							disabled={email === "" ? true : password === "" ? true : disabled === true ? true : false}
							leftIcon={null}
							leftIconClass=""
							rightIcon={null}
							rightIconClass=""
							alt=""
							standardIcon={true}
							badge={false}
							badgeVal={null}
							badgeBG={null}
							buttonRef={null}
						/>
					</div>
					<OrDivider />
					<div className="login-component-button-container">
						<Button
							buttonType="third-party-button"
							size="large"
							text={"Login with Google"}
							cta={googleSignIn}
							disabled={false}
							leftIcon={GoogleLogo}
							leftIconClass=""
							rightIcon={null}
							rightIconClass=""
							alt=""
							standardIcon={true}
							badge={false}
							badgeVal={null}
							badgeBG={null}
							buttonRef={null}
						/>
					</div>
					<div className="login-component-sign-up-container">
						<span
							onClick={() => signUpPage()}
							className="body-regular colour-primary block-text text-align-center"
						>
							Don't have an account? <span className="body-regular-underline colour-link">Sign up here</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginComponent;