import React from 'react';
import '../styles/OnboardingTooltip.css';
import { Beak } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Button, Dots } from './';

function OnboardingTooltip(props) {
    const body = props.body;
    const position = props.position;
    const className = `onboarding-tooltip-beak-${position}`;
    const cta = props.cta;
    const skip = props.skip;
    const totalSteps = props.totalSteps;
    const step = props.step;
    const customClass = props.customClass;

    return (
        <div className={"onboarding-tooltip-outer-container bg-colour-white " + (customClass)}>
            <div className="onboarding-tooltip-inner-container relative-container">
                <div className="onboarding-tooltip-beak-container">
                    <Beak
                        className={"onboarding-tooltip-beak " + (className)}
                        fill={colour.grayScaleWhite}
                    />
                </div>
                {body}
                <div className="onboarding-tooltip-buttons-container">
                    <div className="onboarding-tooltip-bottom-left-container">
                        <Dots
                            limit={totalSteps}
                            selectedFill={colour.grayScaleGray03}
                            selectedStroke=""
                            unselectedFill={colour.grayScaleGray01}
                            unselectedStroke=""
                            selectedIndex={step}
                            className=""
                        />
                        <span className="body-regular colour-secondary">
                            {"(<2 mins)"}
                        </span>
                    </div>
                    <div className="onboarding-tooltip-button-row-container">
                        {
                            (totalSteps - 1) !== step &&
                            <div 
                                className="text-button"
                                onClick={() => skip()}
                            >
                                <span className="label-regular text-link">
                                    Skip
                                </span>
                            </div>
                        }
                        <Button
                            buttonType="primary-button"
                            text={(totalSteps - 1) === step ? "Finish" : "Next"}
                            cta={() => cta()}
                            disabled={false}
                            leftIcon={null}
                            rightIcon={null}
                            alt=""
                            size={"x-small"}
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OnboardingTooltip;