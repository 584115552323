import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../styles/SignUpComponent.css';
import { createUser, setData, getStripeCustomer, signInWithGoogle, getDocument, recordEvent, mailchimpAPI, generateEmptyEmailVerification, setMixpanelAlias } from '../functions/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleLogo } from '../assets';
import { ExitScreen, Loading, OrDivider } from '../components';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import { Button, FinancialsInput } from '../v4/components';

function SignUpComponent(props) {

	const route = props.route;
	const loginRoute = props.loginRoute;
	const state = props.state;
	const signUpWithEmail = props.signUpWithEmail;
	const activateEmailSignup = props.activateEmailSignup;

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [disabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");;
	const [loading, setLoading] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [searchParams] = useSearchParams({});
	const emailParam = searchParams.get("email");
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const devMode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") ? true : false;

	useEffect(() => {
		document.title = "Coffee Clozers | Sign Up";

		if ( emailParam !== null ) {
			setEmail(emailParam);
		}

	}, [dispatch, emailParam]);

	const authenticateEmail = async() => {
		setLoading(true);
		setDisabled(true);
		
		const signUpUser = await createUser(email, password);
		if ( signUpUser.status === 200 ) {
			complete(signUpUser, false);
		}
		else {
			if ( signUpUser.errorCode === "auth/weak-password" ) {
				setErrorMessage("Please use at least 6 characters for your password 🙃");
			}
			else if ( signUpUser.errorCode === "auth/invalid-email" ) {
				setErrorMessage("Please enter a valid email address 👍");
			}
			else if ( signUpUser.errorCode === "auth/email-already-in-use" ) {
				setErrorMessage("Email already in use 😔");
			}
			else if ( signUpUser.errorCode === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else {
				alert(signUpUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	}

	const getNames = (words) => {
		const n = words.split(" ");
		const object = {
			firstName: n[0],
			lastName: n[n.length - 1]
		}
		return object;
	}

	const googleSignIn = async() => {
		const signUpUser = await signInWithGoogle();
		if ( signUpUser.status === 200 ) {
			setLoading(true);
			setDisabled(true);
			const displayName = signUpUser.user.displayName;
			const displayEmail = signUpUser.user.email;
			const names = getNames(displayName);
			signUpUser.user.firstName = names.firstName;
			signUpUser.user.lastName = names.lastName;
			signUpUser.user.displayName = displayName;
			signUpUser.user.email = displayEmail;
			
			const colRef = "Users";
			const userId = signUpUser.user.uid;
			const checkUserDocument = await getDocument(colRef, userId);
			if ( checkUserDocument.status !== 200 ) {
				complete(signUpUser, true);
			}
			else {
				navigate("/home", {
					state: state
				});
			}
		}
		else {
			if ( signUpUser.code === "auth/weak-password" ) {
				setErrorMessage("Please use at least 6 characters for your password 🙃");
			}
			else if ( signUpUser.code === "auth/invalid-email" ) {
				setErrorMessage("Please enter a valid email address 👍");
			}
			else if ( signUpUser.code === "auth/email-already-in-use" ) {
				setErrorMessage("Email already in use 😔");
			}
			else if ( signUpUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else if ( signUpUser.code === "auth/popup-closed-by-user" || signUpUser.code === "auth/cancelled-popup-request") {
				console.log("User closed the popup");
			}
			else {
				alert(signUpUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	};

	const complete = async(signUpUser, googleAuth) => {
		await setMixpanelAlias(signUpUser.user.uid);
		setErrorMessage("");
		const mode = window.location.hostname.includes("coffeeclozers") ? "live" : "test";
		const customer = {
			email: googleAuth === false ? email : signUpUser.user.email,
			name: googleAuth === false ? `${firstName} ${lastName}` : signUpUser.user.displayName,
			phone: "",
			mode: mode
		};
		const getCustomer = await getStripeCustomer(customer);
		const startDate = new Date();
		const endDate = moment(startDate).add(7, "days");
		const val = [
			{
				startDate: new Date(),
				endDate: endDate._d
			}
		];

		const colRef = "Users";
		const docRef = signUpUser.user.uid;
		const data = {
			acquisitionChannels: [],
			buyBoxAsk: true,
			credits: 5,
			email: googleAuth === false ? email : signUpUser.user.email,
			favourites: [],
			firstName: googleAuth === false ? firstName : signUpUser.user.firstName,
			freeTrial: val,
			lastName: googleAuth === false ? lastName : signUpUser.user.lastName,
			onboardingV4: false,
			payments: [],
			phoneNumber: "",
			phoneNumberPrefix: "+1",
			phoneNumberVerified: false,
			repeatUser: false,
			sidePanel: false,
			subscriptions: [],
			viewedProperties: []
		};

		if ( getCustomer.status === 200 ) {
			data.customerId = getCustomer.customerId;
		}

		const mailchimpObject = {
			list_id: "d7f5358698",
			get: false,
			values: {
				email_address: data.email,
				status: "subscribed",
				merge_fields: {
					FNAME: data.firstName,
					LNAME: data.lastName,
					PHONE: ""
				}

			}
		}
		await mailchimpAPI(mailchimpObject);
		await generateEmptyEmailVerification(docRef);

		const createDoc = await setData(colRef, docRef, data);
		if ( createDoc.status === 200 ) {
			recordEvent("Account Created", {
				userId: docRef,
				email: googleAuth === false ? email : signUpUser.user.email,
			});
			const tagManagerArgs = {
				dataLayer: {
					event: "sign_up",
					userId: signUpUser.user.uid
				}
			};
			TagManager.dataLayer(tagManagerArgs);

			if ( googleAuth === true ) {
				recordEvent("Email Verified", {});
			}

			if ( route !== "" ) {
				navigate(route, {
					state: state
				});
			}
			else if ( state !== undefined ) {
				navigate(state.freeTrial, {
					state: state
				});
			}
			else {
				navigate('/profile');
			}

			if ( devMode === false ) {
				window.fbq('track', 'StartTrial');
			}
			setLoading(false);
			setDisabled(false);
		}
	};

	const handleKeyDown = event => {
		if ( event.key === "Enter" ) {
			authenticateEmail();
		}
	};

	const termsAndConditions = () => {
		navigate("/terms-conditions", {
			state: state
		});
	};

	const loginPage = () => {
		navigate(loginRoute, {
			state: state
		});
	};

	const onChangeFirstName = (text) => {
		setFirstName(text.target.value);
	};

	const onChangeLastName = (text) => {
		setLastName(text.target.value);
	};

	const onChangeEmail = (text) => {
		setEmail(text.target.value);
	};

	const onChangePassword = (text) => {
		setPassword(text.target.value);
	};

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	return (
		<div 
			onKeyDown={handleKeyDown} 
			className="sign-up-component-outer-container relative-container bg-colour-white"
		>
			{
				dropdownOpen === true &&
				<ExitScreen
					clickScreen={toggleDropdown}
				/>
			}
			<div className="sign-up-component-inner-container">
				{
					loading === true ?
					<Loading />
					:
					null
				}
				{
					signUpWithEmail === false ?
					null
					:
					<div className="sign-up-component-body-container">
						<div className="sign-up-component-row">
							<div className="sign-up-component-element-container sign-up-component-margin-right">
								<FinancialsInput
									label={"First name"}
									labelType={"inside"}
									info={false}
									value={firstName}
									onChange={onChangeFirstName}
									placeholder={"Ben"}
									type="text"
									leftUnit={null}
									rightUnit={null}
									unitSwitch={false}
									leftUnitSelected={true}
									unitSwitchFunc={null}
									disabled={false}
									multiline={false}
									error={false}
									onMouseEnter={null}
									onMouseLeave={null}
									reset={false}
									resetFunc={null}
									resetPosition={null}
								/>
							</div>
							<div className="sign-up-component-element-container sign-up-component-margin-left">
								<FinancialsInput
									label={"Last name"}
									labelType={"inside"}
									info={false}
									value={lastName}
									onChange={onChangeLastName}
									placeholder={"Avery"}
									type="text"
									leftUnit={null}
									rightUnit={null}
									unitSwitch={false}
									leftUnitSelected={true}
									unitSwitchFunc={null}
									disabled={false}
									multiline={false}
									error={false}
									onMouseEnter={null}
									onMouseLeave={null}
									reset={false}
									resetFunc={null}
									resetPosition={null}
								/>
							</div>
						</div>
						<div className="sign-up-component-long-element-container">
							<FinancialsInput
								label={"Email"}
								labelType={"inside"}
								info={false}
								value={email}
								onChange={onChangeEmail}
								placeholder={"youremail@email.com"}
								type="email"
								leftUnit={null}
								rightUnit={null}
								unitSwitch={false}
								leftUnitSelected={true}
								unitSwitchFunc={null}
								disabled={false}
								multiline={false}
								error={false}
								onMouseEnter={null}
								onMouseLeave={null}
								reset={false}
								resetFunc={null}
								resetPosition={null}
							/>
						</div>
						<div className="sign-up-component-long-element-container">
							<FinancialsInput
								label={"Password"}
								labelType={"inside"}
								info={false}
								value={password}
								onChange={onChangePassword}
								placeholder={"••••••••••"}
								type="password"
								leftUnit={null}
								rightUnit={null}
								unitSwitch={false}
								leftUnitSelected={true}
								unitSwitchFunc={null}
								disabled={false}
								multiline={false}
								error={false}
								onMouseEnter={null}
								onMouseLeave={null}
								reset={false}
								resetFunc={null}
								resetPosition={null}
							/>
							{
								errorMessage !== "" ?
								<div className="sign-up-component-incorrect-password-container">
									<span className="body-regular colour-error">
										{errorMessage}
									</span>
								</div>
								:
								null
							}
						</div>
					</div>
				}
				<div className="sign-up-component-bottom-container">
					<span className="body-regular colour-secondary block-text text-align-center">
						By signing up, you agree to our <span onClick={() => termsAndConditions()} className="colour-link underline cursor-pointer">terms of service.</span>
					</span>
					<div className={"sign-up-component-buttons-outer-container " + (signUpWithEmail === false ? "column-reverse" : "")}>
						<div className="sign-up-component-button-container">
							<Button
								buttonType="primary-button"
								size="large"
								text={"Sign up"}
								cta={signUpWithEmail === false ? activateEmailSignup : authenticateEmail}
								disabled={signUpWithEmail === false ? false : firstName === "" ? true : lastName === "" ? true : email === "" ? true : password === "" ? true : disabled === true ? true : false}
								leftIcon={null}
								leftIconClass=""
								rightIcon={null}
								rightIconClass=""
								alt=""
								standardIcon={true}
								badge={false}
								badgeVal={null}
								badgeBG={null}
								buttonRef={null}
							/>
						</div>
						<OrDivider />
						<div className="sign-up-component-google-button-container">
							<Button
								buttonType="third-party-button"
								size="large"
								text={"Sign up with Google"}
								cta={googleSignIn}
								disabled={false}
								leftIcon={GoogleLogo}
								leftIconClass=""
								rightIcon={null}
								rightIconClass=""
								alt=""
								standardIcon={true}
								badge={false}
								badgeVal={null}
								badgeBG={null}
								buttonRef={null}
							/>
						</div>
					</div>
					<div className="sign-up-component-login-container">
						<span
							className="body-regular colour-primary block-text text-align-center"
						>
							Already have an account? <span onClick={() => loginPage()} className="body-regular-underline colour-link">Login</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SignUpComponent;