import React from 'react';
import '../styles/ListingsOnboarding.css';
import { MoneyBag02Icon, Location04Icon, ChessPawnIcon } from 'hugeicons-react';
import { colour } from '../../styles/GlobalStyles';

function ListingsOnboarding(props) {
    const step = props.step;
    const onboardingHover = props.onboardingHover;
    const setOnboardingHover = props.setOnboardingHover;
    const setOnboardingAuto = props.setOnboardingAuto;

    const attributes = [
        {
            text: "Cash-flow",
            icon: <MoneyBag02Icon color={onboardingHover === 0 ? colour.grayScaleBlack : colour.grayScaleGray03} size={16} className="cursor-pointer" />
        },
        {
            text: "Location",
            icon: <Location04Icon color={onboardingHover === 1 ? colour.grayScaleBlack : colour.grayScaleGray03} size={16} className="cursor-pointer" />
        },
        {
            text: "Strategy",
            icon: <ChessPawnIcon color={onboardingHover === 2 ? colour.grayScaleBlack : colour.grayScaleGray03} size={16} className="cursor-pointer" />
        }
    ];

    const changeHover = (index) => {
        setOnboardingHover(index);

        if ( index === null ) {
            setOnboardingAuto(true);
        }
        else {
            setOnboardingAuto(false);
        }
    };

    return (
        <div className="listings-onboarding-container">
            {
                step === 0 ?
                <div className="listings-onboarding-text-container">
                    <h2 className="body-semibold">
                        Ranked list
                    </h2>
                    <span className="body-regular colour-primary">
                        Properties are ranked for your needs on these criteria:
                    </span>
                    {
                        attributes.map((item, index) =>
                            <div 
                                className="listings-onboarding-element cursor-pointer"
                                key={index}
                                onMouseEnter={() => changeHover(index)}
                                onMouseLeave={() => changeHover(null)}
                            >
                                <span className={"" + (index === onboardingHover ? "body-semibold colour-primary" : "body-regular colour-secondary")}>
                                    {index + 1}{")"} {item.text}
                                </span>
                                <div 
                                    className={"listings-onboarding-icon-container relative-container " + (index === 2 ? "" : "")}
                                >
                                    {item.icon}
                                </div>
                            </div>
                        )
                    }
                </div>
                :
                step === 1 ?
                <span className="body-regular colour-primary">
                    Let's close some deals. <span className="body-semibold">Click on a property</span> to see what happens next.
                </span>
                :
                <div className="listings-onboarding-text-container">
                    <h2 className="body-semibold">
                        {
                            step === 2 ?
                            "Let's make this cash-flow more."
                            :
                            "Generate an offer"
                        }
                    </h2>
                    <span className="body-regular colour-primary">
                        {
                            step === 2 ?
                            "Drag the slider to lower offer price and see how it impacts cash-flow."
                            :
                            "Looks good! Click here to generate your creative finance offer!"
                        }
                    </span>
                </div>
            }
        </div>
    );
};

export default ListingsOnboarding;