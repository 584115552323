import React from 'react';
import '../styles/Dots.css';
import { Dot } from '../../assets/icons';

function Dots(props) {
    const limit = props.limit;
    const selectedFill = props.selectedFill;
    const selectedStroke = props.selectedStroke;
    const unselectedFill = props.unselectedFill;
    const unselectedStroke = props.unselectedStroke;
    const selectedIndex = props.selectedIndex;
    const className = props.className;
    return (
        <div className="dots-container">
            {
                Array(limit).fill().map((_, i) =>
                    <Dot
                        fill={selectedIndex === i ? selectedFill : unselectedFill}
                        stroke={selectedIndex === i ? selectedStroke : unselectedStroke}
                        className={className}
                        key={i}
                    />
                )
            }
        </div>
    )
};

export default Dots;