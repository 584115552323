import React from 'react';
import { ActionButton } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function TrialExpiration(props) {
    const freeTrial = props.freeTrial;
    const temporaryId = props.temporaryId;
    const navigate = useNavigate();
    const endDate = freeTrial === null || freeTrial.length === 0 ? 0 : moment.unix(freeTrial[0].endDate.seconds);
    const today = new Date();
    const todaySeconds = moment.unix(today.getTime() / 1000);
    const freeTrialDays = freeTrial === null || freeTrial.length === 0 ? "" : endDate.diff(todaySeconds, 'days');
    const freeTrialEnding = freeTrialDays === 0 ? "today" : `${freeTrialDays} day${freeTrialDays === 1 ? "" : "s"}`;

    return (
        freeTrial !== false && freeTrial !== null ?
        <div className="header-free-trial-container">
            <div className="header-tutorial-video-container">
            </div>
            {
                freeTrialDays < 0 ?
                <span className="body-regular colour-secondary">
                    ⏳ Trial expired
                </span>
                :
                <span className="body-regular colour-secondary">
                    ⏳ Free trial
                </span>
            }
            {
                freeTrialDays >= 0 ?
                    <span className="body-semibold text-link">
                    {freeTrialEnding}
                </span>
                :
                null
            }
        </div>
        :
        temporaryId === true ?
        <div className="header-free-trial-container">
            <div className="header-free-trial-button-container">
                <ActionButton
                    onClick={() => navigate("/sign-up")}
                    disabled={false}
                >
                    Signup
                </ActionButton>
            </div>
        </div>
        :
        null
    )
}

export default TrialExpiration;