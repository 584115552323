import React, { useState, useRef } from 'react';
import '../styles/Filters.css';
import '../styles/Modal.css';
import { BathroomFilter, BedroomFilter, BudgetFilter, Button, FilterSpacing, IconButton, PropertyTypeFilter, VerticalTab, SquareFootageFilter, YearBuiltFilter, ConditionFilter, CreativeFinanceFilter, ListingTypeFilter, DaysOnMarketFilter, NeighbourhoodGradeFilter, MoreLocationFilter, OpportunitiesFilter, OwnerTypeFilter, OwnershipLengthFilter, DistressFlagsFilter, EstimatedInterestFilter, ROIFilter, ZipcodeFilter, MobileTab, MortgageTypeFilter } from './';
import { ModalExit } from '../../assets';
import { Condition, SingleFamily, Dollar, MagnifyingGlass, Location, User } from '../../assets/icons';
import { ExitScreen, Loading } from '../../components';
import { colour } from '../../styles/GlobalStyles';
import { MoneyBag02Icon, Plant03Icon } from 'hugeicons-react';
import { defaultVals } from '../../defaults';

function Filters(props) {
    const setChangeMade = props.setChangeMade;
    const setLoading = props.setLoading;
    const exitButtonCTA = props.exitButtonCTA;
    const clearAllFilters = props.clearAllFilters;
    const actionButtonCTA = props.actionButtonCTA;
    const loading = props.loading;
    const actionButtonDisabled = props.actionButtonDisabled;
    const budgetRange = props.budgetRange;
    const setBudgetRange = props.setBudgetRange;
    const propertyTypes = props.propertyTypes;
    const setPropertyTypes = props.setPropertyTypes;
    const units = props.units;
    const setUnits = props.setUnits;
    const unitExactMatch = props.unitExactMatch;
    const setUnitExactMatch = props.setUnitExactMatch;
    const bedrooms = props.bedrooms;
    const setBedrooms = props.setBedrooms;
    const bedroomExactMatch = props.bedroomExactMatch;
    const setBedroomExactMatch = props.setBedroomExactMatch;
    const bathrooms = props.bathrooms;
    const setBathrooms = props.setBathrooms;
    const bathroomExactMatch = props.bathroomExactMatch;
    const setBathroomExactMatch = props.setBathroomExactMatch;
    const squareFootage = props.squareFootage;
    const setSquareFootage = props.setSquareFootage;
    const yearBuilt = props.constructionYears;
    const setYearBuilt = props.setConstructionYears;
    const conditionValues = props.conditionValues;
    const setConditionValues = props.setConditionValues;
    const creativeFinanceType = props.creativeFinanceType;
    const setCreativeFinanceType = props.setCreativeFinanceType;
    const listingType = props.listingType;
    const setListingType = props.setListingType;
    const daysOnMarket = props.daysOnMarket;
    const setDaysOnMarket = props.setDaysOnMarket;
    const backOnMarket = props.backOnMarket;
    const setBackOnMarket = props.setBackOnMarket;
    const recentPriceReduction = props.recentPriceReduction;
    const setRecentPriceReduction = props.setRecentPriceReduction;
    const neighbourhoodGrades = props.neighbourhoodGrades;
    const setNeighbourhoodGrades = props.setNeighbourhoodGrades;
    const hideFloodzone = props.hideFloodzone;
    const setHideFloodzone = props.setHideFloodzone;
    const hideHOA = props.hideHOA;
    const setHideHOA = props.setHideHOA;
    const onlyADU = props.onlyADU;
    const setOnlyADU = props.setOnlyADU;
    const onlyExtraBedroom = props.onlyExtraBedroom;
    const setOnlyExtraBedroom = props.setOnlyExtraBedroom;
    const oversizedLot = props.oversizedLot;
    const setOversizedLot = props.setOversizedLot;
    const ownerType = props.ownerType;
    const setOwnerType = props.setOwnerType;
    const ownershipLength = props.ownershipLength;
    const setOwnershipLength = props.setOwnershipLength;
    const distressFlags = props.distressFlags;
    const setDistressFlags = props.setDistressFlags;
    const estimatedInterest = props.estimatedInterest;
    const setEstimatedInterest = props.setEstimatedInterest;
    const equityLevel = props.equityLevel;
    const setEquityLevel = props.setEquityLevel;
    const mortgageType = props.mortgageType;
    const setMortgageType = props.setMortgageType;
    const minCoCR = props.minCoCR;
    const setMinCoCR = props.setMinCoCR;
    const creativeStrategy = props.creativeStrategy;
    const zipcodes = props.zipcodes;
    const setZipcodes = props.setZipcodes;
    const allZipCodes = props.allZipCodes;
    const openCreateBuyBoxModal = props.openCreateBuyBoxModal;

    const searchParams = props.searchParams;
    const setSearchParams = props.setSearchParams;
    const getFilters = searchParams.get('filters');
	const parsedFilters = getFilters !== null ? JSON.parse(getFilters) : null;
    const basicsFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "basics").length;
    const conditionFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "condition").length;
    const creativeFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "creative").length;
    const listingFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "listing").length;
    const locationFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "location").length;
    const opportunitiesFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "opportunities").length;
    const ownershipFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "ownership").length;
    const roiFilters = parsedFilters === null ? 0 : parsedFilters.filter(e => e.type === "roi").length;
    const [activeCategory, setActiveCategory] = useState(0);
    const [budgetError, setBudgetError] = useState(false);
    const [yearBuiltError, setYearBuiltError] = useState(false);
    const [cocrError, setCocrError] = useState(false);
    const [zipcodeDropdownOpen, setZipcodeDropdownOpen] = useState(false);
    const modalBodyRef = useRef(null);

    const filterCategories = [
        {
            title: "Basics",
            icon: <SingleFamily className="" fill={activeCategory === 0 ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: activeCategory === 0 ? true : false,
            standardIcon: false,
            badge: basicsFilters === 0 ? false : true,
            badgeVal: basicsFilters
        },
        {
            title: "Condition",
            icon: <Condition className="" fill={activeCategory === 1 ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: activeCategory === 1 ? true : false,
            standardIcon: false,
            badge: conditionFilters === 0 ? false : true,
            badgeVal: conditionFilters
        },
        {
            title: "Creative Finance",
            icon: <Dollar className="" fill={activeCategory === 2 ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: activeCategory === 2 ? true : false,
            standardIcon: false,
            badge: creativeFilters === 0 ? false : true,
            badgeVal: creativeFilters
        },
        {
            title: "Listing",
            icon: <MagnifyingGlass className="" clickFunc={null} fill={activeCategory === 3 ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: activeCategory === 3 ? true : false,
            standardIcon: false,
            badge: listingFilters === 0 ? false : true,
            badgeVal: listingFilters
        },
        {
            title: "Location",
            icon: <Location className="" fill={activeCategory === 4 ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: activeCategory === 4 ? true : false,
            standardIcon: false,
            badge: locationFilters === 0 ? false : true,
            badgeVal: locationFilters
        },
        {
            title: "Opportunites",
            icon: <Plant03Icon color={activeCategory === 5 ? colour.grayScaleBlack : colour.blueBlue03} size={16} />,
            active: activeCategory === 5 ? true : false,
            standardIcon: false,
            badge: opportunitiesFilters === 0 ? false : true,
            badgeVal: opportunitiesFilters
        },
        {
            title: "Ownership",
            icon: <User className="" fill={activeCategory === 6 ? colour.grayScaleBlack : colour.blueBlue03} />,
            active: activeCategory === 6 ? true : false,
            standardIcon: false,
            badge: ownershipFilters === 0 ? false : true,
            badgeVal: ownershipFilters
        },
        {
            title: "ROI",
            icon: <MoneyBag02Icon color={activeCategory === 7 ? colour.grayScaleBlack : colour.blueBlue03} size={16} />,
            active: activeCategory === 7 ? true : false,
            standardIcon: false,
            badge: roiFilters === 0 ? false : true,
            badgeVal: roiFilters
        }
    ];

    const selectFilterCategory = (index) => {
        setActiveCategory(index);
    };

    const toggleZipcodeDropdown = () => {
        setZipcodeDropdownOpen(!zipcodeDropdownOpen);
    };

    const checkReset = (val) => {
        if ( parsedFilters === null ) {
            return false;
        }

        const filter = parsedFilters.filter(e => e.label === val);
        if ( filter.length === 0 ) {
            return false;
        }
        else {
            return true;
        }
    };

    const resetFilter = (val) => {
        const valIndex = parsedFilters.findIndex(e => e.label === val);
        if ( valIndex !== -1 ) {
            parsedFilters.splice(valIndex, 1);
            if ( parsedFilters.length === 0 ) {
                searchParams.delete("filters");
                setSearchParams({
                    ...searchParams
                });
            }
            else {
                setSearchParams({
                    ...searchParams,
                    filters: JSON.stringify(parsedFilters)
                });
            }

            if ( val === "price" ) {
                setBudgetRange(defaultVals.budgetRange);
            }
            else if ( val === "propertyType" ) {
                const newPropertyTypes = [...propertyTypes];
                newPropertyTypes[0].included = false;
                newPropertyTypes[1].included = false;
                setPropertyTypes(newPropertyTypes);
                setUnits(defaultVals.units);
                setUnitExactMatch(defaultVals.unitExactMatch);
            }
            else if ( val === "beds" ) {
                setBedrooms(defaultVals.bedrooms);
                setBedroomExactMatch(defaultVals.bedroomExactMatch);
            }
            else if ( val === "baths" ) {
                setBathrooms(defaultVals.bathrooms);
                setBathroomExactMatch(defaultVals.bathroomExactMatch);
            }
            else if ( val === "sqft" ) {
                setSquareFootage(defaultVals.squareFootage);
            }
            else if ( val === "construction" ) {
                setYearBuilt(defaultVals.constructionYears);
            }
            else if ( val === "condition" ) {
                setConditionValues(defaultVals.conditionValues);
            }
            else if ( val === "creative" ) {
                setCreativeFinanceType(defaultVals.creativeFinanceType);
            }
            else if ( val === "listingType" ) {
                setListingType(defaultVals.listingType);
            }
            else if ( val === "daysOnMarket" ) {
                setDaysOnMarket(defaultVals.daysOnMarket);
            }
            else if ( val === "neighbourhoodGrades" ) {
                const newNeighbourhoodGrades = [...neighbourhoodGrades];
                newNeighbourhoodGrades[0] = 1;
                newNeighbourhoodGrades[1] = 8;
                setNeighbourhoodGrades(newNeighbourhoodGrades);
            }
            else if ( val === "zipcodes" ) {
                setZipcodes(defaultVals.zipcodes);
            }
            else if ( val === "ownerType" ) {
                setOwnerType(defaultVals.ownerType);
            }
            else if ( val === "ownershipLength" ) {
                setOwnershipLength(defaultVals.ownershipLength);
            }
            else if ( val === "distress" ) {
                setDistressFlags(defaultVals.distressFlags);
            }
            else if ( val === "interest" ) {
                setEstimatedInterest(defaultVals.estimatedInterest);
            }
            else if ( val === "equity" ) {
                setEquityLevel(defaultVals.equityLevel);
            }
            else if ( val === "mortgageType" ) {
                setMortgageType(defaultVals.mortgageType);
            }
            else if ( val === "cocr" ) {
                setMinCoCR(defaultVals.cocr);
            }

            setChangeMade("filters");
		    setLoading(true);
        }
    };

    const saveFilters = async() => {
        if ( (budgetRange[0] !== "" && budgetRange[1] !== "" && Number(budgetRange[0]) > Number(budgetRange[1])) ) {
            setBudgetError(true);
            selectFilterCategory(0);

            setTimeout(() => {
                if ( modalBodyRef.current ) {
                    modalBodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setBudgetError(false);
        }

        if ( (yearBuilt[0] !== "" && yearBuilt[1] !== "" && Number(yearBuilt[0]) > Number(yearBuilt[1])) ) {
            setYearBuiltError(true);
            selectFilterCategory(0);

            setTimeout(() => {
                if ( modalBodyRef.current ) {
                    modalBodyRef.current.scrollTo({
                        top: modalBodyRef.current.scrollHeight,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setYearBuiltError(false);
        }

        if ( isNaN(minCoCR) === true ) {
            setCocrError(true);
            selectFilterCategory(7);

            setTimeout(() => {
                if ( modalBodyRef.current ) {
                    modalBodyRef.current.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }
            }, 200);
            return;
        }
        else {
            setCocrError(false);
        }

        await actionButtonCTA();
    };

    return (
        <div className="modal-outer-container filters-modal-container">
            <div className="relative-container modal-exit-outer-container">
                {
                    loading &&
                    <Loading />
                }
                <div className="modal-exit-container cursor-pointer">
                    <IconButton
                        size="medium"
                        icon={ModalExit}
                        disabled={false}
                        clickFunc={exitButtonCTA}
                        standardIcon={true}
                        contextTooltip={false}
                        contextTooltipBG={null}
                        contextTooltipText={null}
                        customClass={null}
                    />
                </div>
            </div>
            <div className="modal-heading-container margin-medium">
                <h2 className="heading-large-semibold colour-primary">
                    Filters
                </h2>
            </div>
            <div className="grey-divider">
            </div>
            <div className="modal-body-container">
                <div className="filters-modal-body-row">
                    <div className="filters-modal-categories-container mobile-none">
                        {
                            filterCategories.map((item, index) =>
                                <VerticalTab
                                    selected={item.active}
                                    label={item.title}
                                    icon={item.icon}
                                    cta={() => selectFilterCategory(index)}
                                    external={false}
                                    account={false}
                                    userData={null}
                                    key={index}
                                    standardIcon={item.standardIcon}
                                    badge={item.badge}
                                    badgeVal={item.badgeVal}
                                    badgeBG={"badge-standard-bg"}
                                    reset={false}
                                />
                            )
                        }
                    </div>
                    <div className="filters-modal-mobile-tabs-container desktop-none">
                        {
                            filterCategories.map((item, index) =>
                                <MobileTab
                                    item={item}
                                    index={index}
                                    title={item.title}
                                    active={item.active}
                                    cta={() => selectFilterCategory(index)}
                                    icon={item.icon}
                                    disabled={false}
                                    line={true}
                                    key={index}
                                />
                            )
                        }
                    </div>
                    <div 
                        className="filters-modal-content-container"
                        ref={modalBodyRef}
                    >
                        {
                            zipcodeDropdownOpen === true &&
                            <ExitScreen
                                clickScreen={toggleZipcodeDropdown}
                            />
                        }
                        {
                            activeCategory === 0 &&
                            <BudgetFilter
                                label="Price"
                                budgetRange={budgetRange}
                                setBudgetRange={setBudgetRange}
                                error={budgetError}
                                reset={checkReset("price")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 0 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 0 &&
                            <PropertyTypeFilter
                                propertyTypes={propertyTypes}
                                setPropertyTypes={setPropertyTypes}
                                units={units}
                                setUnits={setUnits}
                                exactMatch={unitExactMatch}
                                setExactMatch={setUnitExactMatch}
                                error={false}
                                reset={checkReset("propertyType")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 0 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 0 &&
                            <BedroomFilter
                                bedrooms={bedrooms}
                                setBedrooms={setBedrooms}
                                exactMatch={bedroomExactMatch}
                                setExactMatch={setBedroomExactMatch}
                                reset={checkReset("beds")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 0 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 0 &&
                            <BathroomFilter
                                bathrooms={bathrooms}
                                setBathrooms={setBathrooms}
                                exactMatch={bathroomExactMatch}
                                setExactMatch={setBathroomExactMatch}
                                reset={checkReset("baths")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 0 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 0 &&
                            <SquareFootageFilter
                                squareFootage={squareFootage}
                                setSquareFootage={setSquareFootage}
                                reset={checkReset("sqft")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 0 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 0 &&
                            <YearBuiltFilter
                                yearBuilt={yearBuilt}
                                setYearBuilt={setYearBuilt}
                                reset={checkReset("construction")}
                                resetFilterType={resetFilter}
                                error={yearBuiltError}
                            />
                        }
                        {
                            activeCategory === 1 &&
                            <ConditionFilter
                                conditionValues={conditionValues}
                                setConditionValues={setConditionValues}
                                reset={checkReset("condition")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 2 &&
                            <CreativeFinanceFilter
                                creativeFinanceType={creativeFinanceType}
                                setCreativeFinanceType={setCreativeFinanceType}
                                creativeStrategy={creativeStrategy}
                                reset={checkReset("creative")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 3 &&
                            <ListingTypeFilter
                                listingType={listingType}
                                setListingType={setListingType}
                                reset={checkReset("listingType")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 3 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 3 &&
                            <DaysOnMarketFilter
                                daysOnMarket={daysOnMarket}
                                setDaysOnMarket={setDaysOnMarket}
                                backOnMarket={backOnMarket}
                                setBackOnMarket={setBackOnMarket}
                                recentPriceReduction={recentPriceReduction}
                                setRecentPriceReduction={setRecentPriceReduction}
                                reset={checkReset("daysOnMarket")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 4 &&
                            <NeighbourhoodGradeFilter
                                neighbourhoodGrades={neighbourhoodGrades}
                                setNeighbourhoodGrades={setNeighbourhoodGrades}
                                reset={checkReset("neighbourhoodGrades")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 4 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 4 &&
                            <ZipcodeFilter
                                dropdownOpen={zipcodeDropdownOpen}
                                setDropdownOpen={setZipcodeDropdownOpen}
                                zipcodes={zipcodes}
                                allZipCodes={allZipCodes}
                                entireMarket={zipcodes.length === 0 ? true : false}
                                zipcodeError={false}
                                switchLabel={""}
                                setZipcodes={setZipcodes}
                                setZipcodeError={null}
                                buyBox={false}
                                reset={checkReset("zipcodes")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 4 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 4 &&
                            <MoreLocationFilter
                                hideFloodzone={hideFloodzone}
                                setHideFloodzone={setHideFloodzone}
                                hideHOA={hideHOA}
                                setHideHOA={setHideHOA}
                            />
                        }
                        {
                            activeCategory === 5 &&
                            <OpportunitiesFilter
                                onlyADU={onlyADU}
                                setOnlyADU={setOnlyADU}
                                onlyExtraBedroom={onlyExtraBedroom}
                                setOnlyExtraBedroom={setOnlyExtraBedroom}
                                oversizedLot={oversizedLot}
                                setOversizedLot={setOversizedLot}
                            />
                        }
                        {
                            activeCategory === 6 &&
                            <OwnerTypeFilter
                                ownerType={ownerType}
                                setOwnerType={setOwnerType}
                                reset={checkReset("ownerType")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 6 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 6 &&
                            <OwnershipLengthFilter
                                ownershipLength={ownershipLength}
                                setOwnershipLength={setOwnershipLength}
                                reset={checkReset("ownershipLength")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 6 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 6 &&
                            <DistressFlagsFilter
                                distressFlags={distressFlags}
                                setDistressFlags={setDistressFlags}
                                reset={checkReset("distress")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 6 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 6 &&
                            <EstimatedInterestFilter
                                estimatedInterest={estimatedInterest}
                                setEstimatedInterest={setEstimatedInterest}
                                resetInterest={checkReset("interest")}
                                resetFilterTypeInterest={resetFilter}
                                equityLevel={equityLevel}
                                setEquityLevel={setEquityLevel}
                                resetEquity={checkReset("equity")}
                                resetFilterTypeEquity={resetFilter}
                            />
                        }
                        {
                            activeCategory === 6 &&
                            <FilterSpacing />
                        }
                        {
                            activeCategory === 6 &&
                            <MortgageTypeFilter
                                mortgageType={mortgageType}
                                setMortgageType={setMortgageType}
                                reset={checkReset("mortgageType")}
                                resetFilterType={resetFilter}
                            />
                        }
                        {
                            activeCategory === 7 &&
                            <ROIFilter
                                minCoCR={minCoCR}
                                setMinCoCR={setMinCoCR}
                                reset={checkReset("cocr")}
                                resetFilterType={resetFilter}
                                error={cocrError}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="modal-buttons-container">
                <div 
                    className="text-button"
                    onClick={() => clearAllFilters()}
                >
                    <span className="body-regular text-link">
                        Clear all
                    </span>
                </div>
                <div className="filters-buttons-right-container">
                    <Button
                        buttonType="secondary-button"
                        size="large"
                        text={"Save as Buy Box"}
                        cta={openCreateBuyBoxModal}
                        disabled={false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={true}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                    <Button
                        buttonType="primary-button"
                        size="large"
                        text={"Save"}
                        cta={saveFilters}
                        disabled={actionButtonDisabled}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={true}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
        </div>
    )
};

export default Filters;