import React from 'react';
import '../styles/FinancialsCalculatorHeader.css';
import { Dropdown, Button, IconButton } from './';
import { Wand } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';
import { Expand, Exit } from '../../assets/icons';

function FinancialsCalculatorHeader(props) {
    const acquisitionDropdownOpen = props.acquisitionDropdownOpen;
    const toggleAcquisitionDropdown = props.toggleAcquisitionDropdown;
    const selectAcquisition = props.selectAcquisition;
    const exitDropdownOpen = props.exitDropdownOpen;
    const toggleExitDropdown = props.toggleExitDropdown;
    const selectExit = props.selectExit;
    const optimiseDeal = props.optimiseDeal;
    const acquisitionStrategy = props.acquisitionStrategy;
    const exitStrategy = props.exitStrategy;
    const creativeUser = props.creativeUser;
    const fullscreenFinancials = props.fullscreenFinancials;
    const setFullscreenFinancials = props.setFullscreenFinancials;

    const acquisitionOptions = [
        {
            selected: false,
            label: "TRADITIONAL",
            disabled: false,
            labelOnly: true
        },
        {
            selected: acquisitionStrategy === "m" ? true : false,
            label: "Mortgage",
            disabled: exitStrategy === "lo" || exitStrategy === "brrrr" ? true : false,
            labelOnly: false,
            value: "m"
        },
        {
            selected: acquisitionStrategy === "c" ? true : false,
            label: "Cash",
            disabled: exitStrategy === "lo" ? true : false,
            labelOnly: false,
            value: "c"
        },
        {
            selected: false,
            label: "CREATIVE",
            disabled: false,
            labelOnly: true
        },
        {
            selected: acquisitionStrategy === "sf" ? true : false,
            label: "Seller-finance",
            disabled: exitStrategy === "brrrr" ? true : !creativeUser,
            labelOnly: false,
            value: "sf"
        },
        {
            selected: acquisitionStrategy === "sub" ? true : false,
            label: "Subto",
            disabled: exitStrategy === "brrrr" ? true : !creativeUser,
            labelOnly: false,
            value: "sub"
        }
    ];

    const exitOptions = [
        {
            selected: exitStrategy === "ltr" ? true : false,
            label: "Long term rental (LTR)",
            disabled: false,
            labelOnly: false,
            value: "ltr"
        },
        {
            selected: exitStrategy === "brrrr" ? true : false,
            label: "BRRRR",
            disabled: acquisitionStrategy !== "c" ? true : false,
            labelOnly: false,
            value: "brrrr"
        },
        {
            selected: exitStrategy === "hh" ? true : false,
            label: "Househack",
            disabled: false,
            labelOnly: false,
            value: "hh"
        },
        {
            selected: exitStrategy === "str" ? true : false,
            label: "Short term rental (STR)",
            disabled: false,
            labelOnly: false,
            value: "str"
        },
        {
            selected: exitStrategy === "lo" ? true : false,
            label: "Lease option",
            disabled: acquisitionStrategy === "c" || acquisitionStrategy === "m" ? true : !creativeUser,
            labelOnly: false,
            value: "lo"
        }
    ];

    const checkSelected = (options, label) => {
        const selectedIndex = options.findIndex(e => e.selected === true);
        if ( selectedIndex === -1 ) {
            return label;
        }
        return options[selectedIndex].label;
    };

    const toggleFullscreen = () => {
        setFullscreenFinancials(!fullscreenFinancials);
    };

    return (
        <div className="financials-calculator-header-container">
            <div className="financials-calculator-header-left-container">
                <div className="financials-calculator-header-element">
                    <h3 className="body-semibold colour-primary">
                        Acquisition
                    </h3>
                    <Dropdown
                        open={acquisitionDropdownOpen}
                        icon={null}
                        label={checkSelected(acquisitionOptions, "Acquisition")}
                        disabled={false}
                        options={acquisitionOptions}
                        multiselect={false}
                        toggleDropdown={toggleAcquisitionDropdown}
                        cta={selectAcquisition}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={false}
                        selectedIndex={null}
                    />
                </div>
                <div className="financials-calculator-header-element">
                    <h3 className="body-semibold colour-primary">
                        Exit
                    </h3>
                    <Dropdown
                        open={exitDropdownOpen}
                        icon={null}
                        label={checkSelected(exitOptions, "Exit")}
                        disabled={false}
                        options={exitOptions}
                        multiselect={false}
                        toggleDropdown={toggleExitDropdown}
                        cta={selectExit}
                        sidealign={"left"}
                        topalign={"bottom"}
                        indexSelect={false}
                        selectedIndex={null}
                    />
                </div>
            </div>
            <div className="financials-calculator-header-right-container relative-container">
                {
                    acquisitionStrategy === "sf" &&
                    <Button
                        buttonType="accent-button"
                        size="small"
                        text={"Optimize the deal"}
                        cta={optimiseDeal}
                        disabled={false}
                        leftIcon={<Wand fill={colour.grayScaleWhite} className="financials-calculator-header-sparkle" />}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                }
                <IconButton
                    size="medium"
                    icon={fullscreenFinancials === false ? <Expand fill={colour.blueBlue03} className="" /> : <Exit fill={colour.blueBlue03} className="" clickFunc={null} />}
                    disabled={false}
                    clickFunc={toggleFullscreen}
                    standardIcon={false}
                    contextTooltip={true}
                    contextTooltipBG={"dark"}
                    contextTooltipText={fullscreenFinancials === false ? "Go fullscreen" : "Close fullscreen"}
                    customClass={null}
                />
            </div>
        </div>
    );
};

export default FinancialsCalculatorHeader;