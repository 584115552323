import React from 'react';
import '../styles/AISummaryTab.css';
import { AISummary, StrategyScores, BestStrategy, Highlights, ScoreBreakdown } from './';
import { colour } from '../../styles/GlobalStyles';
import { ADUBorder, BedroomBorder, CashOnly, FSBO } from '../../assets/icons';
import { checkHighlights } from '../../functions';

function AISummaryTab(props) {
    const property = props.property;
    const creativeUser = props.creativeUser;
    const setPremiumModal = props.setPremiumModal;
    const highlightElements = [
        {
            icon: <ADUBorder className="" fill={colour.grayScaleBlack} />,
            title: "Accessory Dwelling Unit (ADU)",
            description: "This property either has an ADU or is zoned for ADU.",
            active: property.zonedForAdu
        },
        {
            icon: <BedroomBorder className="" fill={colour.grayScaleBlack} />,
            title: "Add a bedroom",
            description: "This property may have space to add a bedroom.",
            active: property.addBedOpportunity
        },
        {
            icon: <CashOnly className="" fill={colour.grayScaleBlack} />,
            title: "Cash only",
            description: "The seller has indicated they want cash only.",
            active: property.cashOnly
        },
        {
            icon: <FSBO className="" fill={colour.grayScaleBlack} />,
            title: "For sale by owner (FSBO)",
            description: "The seller listed this property themself on Zillow.",
            active: property.fsbo
        }
    ];

    const isEmptyObject = (obj) => Object.keys(obj).length === 0;

    return (
        <div className="ai-summary-tab-outer-container">
            {
                isEmptyObject(property.dripScoreSummaryDescription.overall) === false &&
                <AISummary
                    property={property}
                    creativeUser={creativeUser}
                />
            }
            <div className="property-double-inner-body-left-container">
                <StrategyScores
                    property={property}
                    creativeUser={creativeUser}
                />
                <div className="property-spacing-border">
                </div>
                <BestStrategy
                    property={property}
                    creativeUser={creativeUser}
                />
                {
					checkHighlights(highlightElements) === false && (property.propertyCondition === undefined || property.propertyCondition === null || property.propertyCondition === "Unclear") ?
					null
					:
					<div className="property-spacing-border">
                	</div>
				}
                {
					checkHighlights(highlightElements) === false && (property.propertyCondition === undefined || property.propertyCondition === null || property.propertyCondition === "Unclear") ?
					null
					:
					<Highlights
                        property={property}
                        label="Highlights"
                        elements={highlightElements}
                        conditionView={property.propertyCondition === undefined || property.propertyCondition === null || property.propertyCondition === "Unclear" ? false : true}
                    />
				}
                <div className="property-spacing-border">
                </div>
                <ScoreBreakdown
                    property={property}
                    creativeUser={creativeUser}
                    setPremiumModal={setPremiumModal}
                />
            </div>
        </div>
    )
};

export default AISummaryTab;