import React, { useEffect, useState } from 'react';
import '../styles/Pagination.css';
import { recordEvent } from '../functions';
import { IconButton } from '../v4/components';
import { Chevron } from '../assets/icons';
import { colour } from '../styles/GlobalStyles';

function Pagination(props) {

	const page = props.page; 
	const propertiesOnPage = 20
	const propertiesOnPageChange = props.propertiesOnPageChange; 
	const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
	const cityId = props.cityId;
	const setPage = props.setPage;
	const cardsRef = props.cardsRef;
	const searchParams = props.searchParams;
	const setSearchParams = props.setSearchParams;
	const allProperties = props.allProperties;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const setChangeMade = props.setChangeMade;
	const filteredPropertyCount = props.filteredPropertyCount;

	const length = filteredPropertyCount;
	const numberOfPages = length !== 0 ? Math.ceil(length / (propertiesOnPage === undefined || propertiesOnPage === 0 ? 20 : propertiesOnPage)) : 0;
	const [pages, setPages] = useState([]);
	const [pageRange, setPageRange] = useState([0, 19]);

	useEffect(() => {
		if ( pages.length === 0 || propertiesOnPageChange === true ) {
			setPropertiesOnPageChange(false);
			const emptyArray = [];
			for (let index = 0; index < numberOfPages; index++) {

				const lowerBound = index * 20
				const upperBound = lowerBound + 20;
				const pageProperties = allProperties.slice(lowerBound, upperBound);

				const newObject = {
					properties: pageProperties
				}
				emptyArray.push(newObject);
				setPages(emptyArray);
			}

			if ( page > 7 ) {
				const minPage = page - 6;
				const maxPage = page + 1;
				setPageRange([minPage, maxPage]);
			}
			else {
				setPageRange([0, 8]);
			}
		}
	}, [numberOfPages, pages, page, length, propertiesOnPageChange, setPropertiesOnPageChange, cityId, allProperties]);

	const nextPage = () => {
		if ( temporaryId === true ) {
			setSignUpModal(true);
		}
		else {
			changePage(page + 1);

			recordEvent("Page Change", {
				oldPage: page,
				newPage: page + 1
			});
		}
	};

	const prevPage = () => {
		if ( temporaryId === true ) {
			setSignUpModal(true);
		}
		else {
			changePage(page - 1);

			recordEvent("Page Change", {
				oldPage: page,
				newPage: page - 1
			});
		}
	};

	const changePage = async(item) => {
		if ( temporaryId === true ) {
			setSignUpModal(true);
		}
		else {
			setChangeMade("page");
			setPage(item);

			setTimeout(() => {
				if ( cardsRef.current !== null ) {
					cardsRef.current.scroll({
						top: 0,
						behavior: 'smooth'
					});
				}
			}, 250);

			const params = Object.fromEntries(searchParams.entries());
			params.page = item;
			setSearchParams(params);

			if ( item > 7 ) {
				const minPage = item - 6;
				const maxPage = item + 1;
				setPageRange([minPage, maxPage]);
			}
			else {
				setPageRange([0, 8]);
			}

			recordEvent("Page Change", {
				oldPage: page,
				newPage: item
			});
		}
	};

	return (
		<div className="pagination-outer-container">
			<div className="pagination-inner-container">
				{
					page !== 1 && pages.length !== 1 ?
					<IconButton
						size="large"
						icon={<Chevron className="pagination-chevron-left cursor-pointer" fill={colour.grayScaleBlack} />}
						disabled={false}
						clickFunc={() => prevPage()}
						standardIcon={false}
						contextTooltip={false}
						contextTooltipBG={null}
						contextTooltipText={null}
						customClass={null}
					/>
					:
					null
				}
				{
					pages.length === 0 ?
					null
					:
					1 < (pageRange[0] + 1) ?
					<div onClick={() => changePage(1)} className={"pagination-element " + (page === 1 ? "pagination-element-selected" : "" )}>
						<span className={page === 1 ? "body-semibold colour-primary" : "body-regular colour-primary"}>
							1
						</span>
					</div>
					:
					null
				}
				{
					pages.length === 0 ?
					null
					:
					1 < (pageRange[0] + 1) ?
					<div className="pagination-element pagination-element-dots">
						<span className="body-regular colour-primary">
							[...]
						</span>
					</div>
					:
					null
				}
				{
					pages.map((item, index) =>
						index <= pageRange[1] && index >= pageRange[0] ? 
						<div onClick={() => changePage(index + 1)} className={"pagination-element " + (index + 1 === page ? "pagination-element-selected" : "" )} key={index}>
							<span className={index + 1 === page ? "body-semibold colour-primary" : "body-regular colour-primary"}>
								{index + 1}
							</span>
						</div>
						:
						null
					)
				}
				{
					pages.length > (pageRange[1] + 2) ?
					<div className="pagination-element pagination-element-dots">
						<span className="body-regular colour-primary">
							[...]
						</span>
					</div>
					:
					null
				}
				{
					pages.length > (pageRange[1] + 1) ?
						<div onClick={() => changePage(pages.length)} className={"pagination-element " + (pages.length === page ? "pagination-element-selected" : "" )}>
							<span className={pages.length === page ? "body-semibold colour-primary" : "body-regular colour-primary"}>
								{pages.length}
							</span>
						</div>
					:
					null
				}
				{
					page !== pages.length && pages.length !== 1 ?
					<IconButton
						size="large"
						icon={<Chevron className="pagination-chevron-right cursor-pointer" fill={colour.grayScaleBlack} />}
						disabled={false}
						clickFunc={() => nextPage()}
						standardIcon={false}
						contextTooltip={false}
						contextTooltipBG={null}
						contextTooltipText={null}
						customClass={null}
					/>
					:
					null
				}
			</div>
		</div>
	)
}

export default Pagination;