import React, { useState } from 'react';
import '../styles/LeaseOptionRent.css';
import { formatterLong, percentage } from '../styles/GlobalStyles';
import { formatPrice } from '../functions';
import { FinancialsInput, Tooltip } from '../v4/components';
import { checkNaN } from '../functions';

function LeaseOptionRent(props) {
    const blurred = props.blurred;
    const property = props.property;
    const leaseOptionValue = props.leaseOptionValue;
    const leaseOptionDownPayment = props.leaseOptionDownPayment;
    const leaseOptionDollarDownPayment = props.leaseOptionDollarDownPayment;
    const leaseOptionInterestRate = props.leaseOptionInterestRate;
    const leaseOptionRent = props.leaseOptionRent;
    const onChangeLeaseValue = props.onChangeLeaseValue;
    const onChangeLeaseDownPayment = props.onChangeLeaseDownPayment;
    const onChangeLeaseDownPaymentDollar = props.onChangeLeaseDownPaymentDollar;
    const onChangeLeaseInterest = props.onChangeLeaseInterest;
    const [saleTooltip, setSaleTooltip] = useState(false);
    const [downPaymentTooltip, setDownPaymentTooltip] = useState(false);
    const [interestTooltip, setInterestTooltip] = useState(false);
    const [downPaymentPercentage, setDownPaymentPercentage] = useState(false);

    const toggleDPPercentage = (val) => {
        setDownPaymentPercentage(val);
    };

    const openLeaseOptionVideo = () => {
        window.open("https://drive.google.com/file/d/1BFw3kERa2QXSdAbPotndyFg1xp_nI5E5/view", "_blank");
    };

    return (
        <div className="lease-option-rent-container">
            <div className="lease-option-price-element relative-container">
                {
                    saleTooltip === true &&
                    <Tooltip
                        type="info"
                        title="Sale price for tenant buyer"
                        body="This is the price you are charging your tenant buyer to buy your property in the future. This is typically 100% to 110% of today's property value."
                        position="top"
                        exit={false}
						exitFunc={null}
                    />
                }
                <FinancialsInput
                    label={"Sale price for tenant buyer"}
                    labelType={"default"}
                    info={true}
                    value={blurred === true ? "999" : formatPrice(leaseOptionValue)}
                    onChange={(text) => onChangeLeaseValue(text.target.value.replaceAll(",", ""))}
                    placeholder={formatPrice(property.price * 1.05)}
                    type="text"
                    leftUnit={"$"}
                    rightUnit={null}
                    unitSwitch={false}
                    leftUnitSelected={true}
                    unitSwitchFunc={null}
                    disabled={blurred}
                    multiline={false}
                    error={checkNaN(leaseOptionValue)}
                    onMouseEnter={() => setSaleTooltip(true)}
                    onMouseLeave={() => setSaleTooltip(false)}
                    reset={false}
                    resetFunc={null}
                    resetPosition={null}
                />
                <span className="label-regular colour-primary">
                    {percentage.format(Math.abs((leaseOptionValue / property.price) - 1))}
                    {" "}
                    {leaseOptionValue > property.price ? "above " : "below "}
                    your purchase price
                </span>
            </div>
            <div className="property-spacing-border">
            </div>
            <div className="lease-option-rent-row">
                <div className="lease-option-option-fee-element relative-container">
                    {
                        downPaymentTooltip === true &&
                        <Tooltip
                            type="info"
                            title="Option fee"
                            body="This is effectively the down payment your tenant buyer will pay you to enter into a lease option agreement. This is usually between 5% and 20% of the sale price."
                            position="top"
                            exit={false}
							exitFunc={null}
                        />
                    }
                    <FinancialsInput
                        label={"Option fee"}
                        labelType={"default"}
                        info={true}
                        value={downPaymentPercentage === false ? formatPrice(leaseOptionDollarDownPayment) : `${leaseOptionDownPayment}`.length > 4 ? Number(leaseOptionDownPayment).toFixed(2) : leaseOptionDownPayment}
                        onChange={(text) => downPaymentPercentage === false ? onChangeLeaseDownPaymentDollar(text.target.value.replaceAll(",", "")) : onChangeLeaseDownPayment(text.target.value)}
                        placeholder={downPaymentPercentage === false ? formatPrice(leaseOptionValue * 0.1) : 10}
                        type="text"
                        leftUnit={"$"}
                        rightUnit={"%"}
                        unitSwitch={true}
                        leftUnitSelected={!downPaymentPercentage}
                        unitSwitchFunc={(val) => toggleDPPercentage(val)}
                        disabled={blurred}
                        multiline={false}
                        error={downPaymentPercentage === false ? checkNaN(leaseOptionDollarDownPayment) : checkNaN(leaseOptionDownPayment)}
                        onMouseEnter={() => setDownPaymentTooltip(true)}
                        onMouseLeave={() => setDownPaymentTooltip(false)}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                </div>
                <div className="lease-option-interest-element relative-container">
                    <FinancialsInput
                        label={"Interest rate"}
                        labelType={"default"}
                        info={true}
                        value={leaseOptionInterestRate}
                        onChange={(text) => onChangeLeaseInterest(text.target.value.replaceAll(",", ""))}
                        placeholder={8}
                        type="text"
                        leftUnit={null}
                        rightUnit={"%"}
                        unitSwitch={false}
                        leftUnitSelected={true}
                        unitSwitchFunc={null}
                        disabled={blurred}
                        multiline={false}
                        error={checkNaN(leaseOptionInterestRate)}
                        onMouseEnter={() => setInterestTooltip(true)}
                        onMouseLeave={() => setInterestTooltip(false)}
                        reset={false}
                        resetFunc={null}
                        resetPosition={null}
                    />
                    {
                        interestTooltip === true &&
                        <Tooltip
                            type="info"
                            title="Interest rate"
                            body="This is the interest rate you will charge your tenant buyer as part of the lease option agreement. This is typically between 7% and 10%, with payments amortized over 30 years."
                            position="top"
                            exit={false}
							exitFunc={null}
                        />
                    }
                </div>
            </div>
            <div className="property-spacing-border">
            </div>
            <div className="lease-option-results-container">
                <span className="body-regular colour-primary">
                    Monthly rent to be collected from tenant buyer
                </span>
                <span className="body-semibold colour-primary">
                    {formatterLong.format(leaseOptionRent).replace(".00", "")}
                </span>
            </div>
            <div className="property-spacing-border">
            </div>
            <div className="lease-option-disclaimer-container">
                <span className="body-regular colour-secondary">
                    Feeling lost?{" "}
                    <span onClick={() => openLeaseOptionVideo()} className="body-regular-underline colour-link">Watch this video.</span>
                </span>
            </div>
        </div>
    )
};

export default LeaseOptionRent;