import React from 'react';
import '../../styles/Schools.css';
import { ProgressBar } from '../../components';

function SchoolElement(props) {
    const item = props.item;
    return (
        <div className="schools-element-container">
            <div className="schools-element-left-container">
                <h4 className="body-regular colour-primary">
                    {item.name}
                </h4>
                <div className="schools-element-text-row">
                    <span className="body-regular colour-secondary">
                        Grades {item.grades}
                    </span>
                    {
                        item.distance !== undefined && item.distance !== null ?
                        <span className="body-regular colour-secondary">
                            •
                        </span>
                        :
                        null
                    }
                    {
                        item.distance !== undefined && item.distance !== null ?
                        <span className="body-regular colour-secondary">
                            Distance: {item.distance} mi
                        </span>
                        :
                        null
                    }
                </div>
            </div>
            <div className="schools-element-right-container">
                <ProgressBar
                    item={item}
                />
                <span className="label-semibold colour-primary schools-score-text">
                    {item.rating !== null ? item.rating : 0}/10
                </span>
            </div>
        </div>
    )
};

export default SchoolElement;