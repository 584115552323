import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Switch from '@mui/material/Switch';
import InputBase from '@mui/material/InputBase';
import Radio from '@mui/material/Radio';
import { 
		Albuquerque,
		Atlanta,
		Austin,
		Baltimore,
		Birmingham,
		Boise,
		Boston,
		Buffalo,
		Charleston,
		Charlotte,
		Chicago,
		Cincinnati,
		Cleveland,
		ColoradoSprings,
		Columbus,
		Dallas,
		Denver,
		Detroit,
		Durham,
		Houston,
		Indianapolis,
		Jacksonville,
		KansasCity,
		Louisville,
		Memphis,
		Miami,
		Minneapolis,
		Nashville,
		NewOrleans,
		Ocala,
		OklahomaCity,
		Omaha,
		Orlando,
		Philadelphia,
		Phoenix,
		Pittsburgh,
		Portland,
		Raleigh,
		Richmond,
		Rochester,
		Sacramento,
		SaintLouis,
		SaltLakeCity,
		SanAntonio,
		Seattle,
		Tampa,
		LasVegas,
		WashingtonDC,
		Newark,
		Fresno
	} from '../assets/city-emojis';
import { Airport, Cafe, Gyms, Jobs, Restaurants, Stores, Supermarkets, TouristAttractions, Transportation, Universities } from '../assets/interestpoints';
import { defaultVals } from '../defaults';

export const fontFamily = 'Figtree';

export const colour = {
	beigeBeige01: "#FFF9F2",
	beigeBeige02: "#FFF3E7",
	beigeBeige03: "#F2E1D0",
	beigeBeige04: "#CFBCAD",

	blueBlue01: "#BCCAD4",
	blueBlue02: "#748EA0",
	blueBlue03: "#2F4858",
	blueBlueTransparent: "#456DB980",

	brownBrown01: "#512E18",

	greenGreen01: "#7FD1AE",
	greenGreen02: "#68AB8E",
	greenGreen03: "#00A8437F",
	greenGreen04: "#6EF0A27F",
	greenGreen05: "#BDFF00B2",
	greenGreen06: "#1C8557",

	grayScaleBlack: "#292621",
	grayScaleGray01: "#DFD9D3",
	grayScaleGray02: "#989793",
	grayScaleGray03: "#716E69",
	grayScaleGray04: "#DFD9D399",
	grayScaleWhite: "#FFFEFC",

	indigoIndigo00: "#AFC0FCB2",
	indigoIndigo01: "#456DB97F",
	indigoIndigo02: "#141B5999",

	redRed01: "#FE8A80",
	redRed02: "#CC564B",
	redRed01Transparent: "#FE8A8033",

	purplePurple01: "#9847FF99",
	purplePurple02: "#3C44FF",

	pinkPink01: "#FF4A88B2",

	orangeOrange01: "#FF955BB2",
	orangeOrange02: "#FFCD70B2",
	orangeOrange03: "#FF955B",

	yellowYellow01: "#E8DA78",
	yellowYellow02: "#F8DB02BF",
	yellowYellow03: "#FFE94AB2",
	yellowYellow04: "#FFE94A",
	yellowYellow05: "#CBB22B",
	yellowYellowTransparent: "#FFE94AB3",

	cyanCyan01: "#39DBFFB2",

	textPrimary: "#292621",
	textSecondary: "#716E69",
	textTertiary: "#989793",

	heatmapHot: {red: 84, green: 3, blue: 212},
	heatmapCold: {red: 255, green: 237, blue: 70},

	heatmapHotHex: "#5304D4",
	heatmapColdHex: "#FFED46"
}

export const mapColours = {
	mapColorsMapZoneA: colour.greenGreen03,
	mapColorsMapZoneB: colour.greenGreen04,
	mapColorsMapZoneBC: colour.yellowYellow02,
	mapColorsMapZoneC: colour.indigoIndigo01,
	mapColorsMapZoneD: colour.indigoIndigo02,
	mapColorsMapZoneInactive: colour.grayScaleGray04,

	mapColorsMapGentrificationGentrified: colour.pinkPink01,
	mapColorsMapGentrificationInProgress: colour.orangeOrange01,
	mapColorsMapGentrificationEarlySigns: colour.yellowYellow03,
	mapColorsMapGentrificationLittleNone: colour.indigoIndigo00,

	mapColorsMapHotspotHipster: colour.purplePurple01,
	mapColorsMapHotspotCollege: colour.greenGreen05,
	mapColorsMapHotspotAffordable: colour.cyanCyan01,

	mapColorsTransparentYellow: colour.yellowYellowTransparent,
	mapColorsTransparentBlue: colour.blueBlueTransparent,
}

export const ActionButton = styled(Button)({
    background: colour.blueBlue03,
    border: 0,
    color: colour.beigeBeige01,
	fontFamily: fontFamily,
	fontWeight: 600,
	lineHeight: '20px',
    borderRadius: '30px',
    fontSize: '14px',
	width: '100%',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.blueBlue03,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.beigeBeige01
   },
   "&:disabled": {
		backgroundColor: colour.blueBlue02,
		boxShadow: '0px 0px 10px 0px #4E2A001A',
		color: colour.beigeBeige01
	}
});

export const LightActionButton = styled(Button)({
    background: colour.blueBlue02,
    border: 0,
    color: colour.beigeBeige01,
	fontFamily: fontFamily,
	fontWeight: 500,
	lineHeight: '23px',
    borderRadius: '30px',
    fontSize: '16px',
	width: '100%',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.blueBlue02,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.beigeBeige01
   },
   "&:disabled": {
		backgroundColor: colour.blueBlue02,
		boxShadow: '0px 0px 10px 0px #4E2A001A',
		color: colour.beigeBeige01
	}
});

  export const WhiteActionButton = styled(Button)({
    background: colour.grayScaleWhite,
    border: 0,
    color: colour.blueBlue03,
	fontFamily: fontFamily,
	fontWeight: 500,
	lineHeight: '23px',
    borderRadius: '30px',
    fontSize: '16px',
	width: '100%',
	height: '40px',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.grayScaleWhite,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.blueBlue03
   },
   "&:disabled": {
		backgroundColor: colour.blueBlue02,
		boxShadow: '0px 0px 10px 0px #4E2A001A',
		color: colour.beigeBeige01
	}
  });

  export const RedActionButton = styled(Button)({
    background: colour.redRed02,
    border: 0,
    color: colour.grayScaleWhite,
	fontFamily: fontFamily,
	fontWeight: 500,
	lineHeight: '23px',
    borderRadius: '30px',
    fontSize: '16px',
	width: '100%',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.redRed02,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.grayScaleWhite
   },
   "&:disabled": {
		backgroundColor: colour.blueBlue02,
		boxShadow: '0px 0px 10px 0px #4E2A001A',
		color: colour.beigeBeige01
	},

	// Custom pixel-based media query for mobile styles
	'@media (max-width: 767px)': {

	  }
  });

  export const SaveButton = styled(Button)({
    background: colour.blueBlue03,
    border: 0,
    color: colour.beigeBeige01,
	fontFamily: fontFamily,
	fontWeight: 600,
	lineHeight: '20px',
    borderRadius: '30px',
    fontSize: '14px',
	width: '100%',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.blueBlue03,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.beigeBeige01
   },
   "&:disabled": {
		opacity: '0.4',
		color: colour.beigeBeige01
	}
  });

  export const GreenActionButton = styled(Button)({
    background: colour.greenGreen02,
    border: 0,
    color: colour.beigeBeige01,
	fontFamily: fontFamily,
	fontWeight: 500,
	lineHeight: '23px',
    borderRadius: '30px',
    fontSize: '16px',
	width: '100%',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.greenGreen02,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.beigeBeige01
   },
   "&:disabled": {
		backgroundColor: colour.blueBlue02,
		boxShadow: '0px 0px 10px 0px #4E2A001A',
		color: colour.beigeBeige01
	}
  });

  export const CardButton = styled(Button)({
    background: colour.blueBlue03,
    border: 0,
    color: colour.beigeBeige01,
	fontFamily: fontFamily,
	fontWeight: 500,
	lineHeight: '23px',
    borderRadius: '30px',
    fontSize: '16px',
	width: '100%',
	boxShadow: '0px 0px 10px 0px #4E2A001A',
	textTransform: 'none',
	padding: '10px 5px',
	minWidth: '0px',
    '&:hover': {
      background: colour.blueBlue03,
      opacity: '0.8',
	  animation: 'ease 2s',
	  color: colour.beigeBeige01
   }
  });

  export const Input = styled(TextField)({
	'& label': {
	color: colour.med,
	fontFamily: fontFamily,
	paddingLeft: '1.5em',
	fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
	color: colour.med,
	paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
	borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
	fontFamily: fontFamily,
	color: colour.dark,
	backgroundColor: colour.formInput,
	borderRadius: '20px',
	height: '48px',
	'& fieldset': {
		borderRadius: '20px',
		borderWidth: 0
	},
	'&:hover fieldset': {
		borderColor: colour.med,
	},
	'&.Mui-focused fieldset': {
		borderColor: colour.primary
	},
	},
	width: '100%'
});

export const PhoneNumberInput = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		transform: 'none',
		maxWidth: 'none',
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '16px',
		top: '4px',
		left: '8px',
		padding: '0px'
	},
	'& label.Mui-focused': {
		color: colour.grayScaleBlack,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		height: 'auto',
		fontSize: '28px',
		fontWeight: '300',
		lineHeight: '34px'
	},
	'& .MuiInputAdornment-root': {
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '28px',
		fontWeight: '300',
		lineHeight: '34px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '2px',
		height: '48px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
			top: '0px',
			borderRadius: '1px'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
});

export const FormInput = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		transform: 'none',
		maxWidth: 'none',
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '16px',
		top: '4px',
		left: '8px',
		padding: '0px'
	},
	'& label.Mui-focused': {
		color: colour.grayScaleBlack,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		paddingRight: '8px',
		marginTop: 'auto',
		lineHeight: '20px',
		height: 'auto'
	},
	'& .MuiInputAdornment-root': {
		marginTop: 'auto',
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '2px',
		height: '40px',
		paddingLeft: '8px',
		paddingBottom: '2px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
			top: '0px',
			borderRadius: '1px'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
});

export const FinancialsInput = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		transform: 'none',
		maxWidth: 'none',
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '16px',
		top: '4px',
		left: '8px',
		padding: '0px'
	},
	'& label.Mui-focused': {
		color: colour.grayScaleBlack,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		paddingRight: '8px',
		marginTop: 'auto',
		lineHeight: '20px',
		height: 'auto'
	},
	'& .MuiInputAdornment-root': {
		marginTop: 'auto',
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '2px',
		height: '44px',
		paddingLeft: '8px',
		paddingBottom: '2px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
			top: '0px',
			borderRadius: '1px'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
  });

  export const FinancialsInputRed = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		transform: 'none',
		maxWidth: 'none',
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '16px',
		top: '4px',
		left: '8px',
		padding: '0px'
	},
	'& label.Mui-focused': {
		color: colour.grayScaleBlack,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		paddingRight: '8px',
		marginTop: 'auto',
		lineHeight: '20px',
		height: 'auto'
	},
	'& .MuiInputAdornment-root': {
		marginTop: 'auto',
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.redRed01Transparent,
		borderRadius: '2px',
		height: '44px',
		paddingLeft: '8px',
		paddingBottom: '2px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
			top: '0px',
			borderRadius: '1px'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
  });

  export const FinancialsInputNoLabel = styled(TextField)({
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		paddingRight: '8px',
		lineHeight: '20px',
		height: 'auto'
	},
	'& .MuiInputAdornment-root': {
		marginTop: 'auto',
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '2px',
		height: '28px',
		paddingLeft: '8px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
			display: 'none'
		},
		'&.Mui-focused fieldset': {
			display: 'none'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
  });

  export const NewInput = styled(TextField)({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '3px',
		height: '40px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '30px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			border: 'none'
		}
	},
	width: '100%'
  });

  export const TextInput = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		transform: 'none',
		maxWidth: 'none',
		fontSize: '10px',
		fontWeight: '600',
		lineHeight: '16px',
		top: '4px',
		left: '8px',
		padding: '0px'
	},
	'& label.Mui-focused': {
		color: colour.grayScaleBlack,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiInputBase-input': {
		padding: '0px',
		paddingRight: '8px',
		marginTop: 'auto',
		lineHeight: '40px',
		height: 'auto'
	},
	'& .MuiInputAdornment-root': {
		marginTop: 'auto',
		height: 'auto'
	},
	'& .MuiInputAdornment-positionEnd': {
		
	},
	'& .MuiTypography-root': {
		color: colour.grayScaleBlack,
		fontSize: '14px',
		fontWeight: '400',
		lineHeight: '20px'
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '2px',
		height: '44px',
		paddingLeft: '8px',
		paddingBottom: '2px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '2px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
			top: '0px',
			borderRadius: '1px'
		},
		'&.Mui-focused fieldset legend': {
			display: 'none'
		}
	},
	width: '100%'
  });

  export const LocationInput = styled(TextField)({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		fontSize: '20px',
		fontWeight: 400,
		color: colour.grayScaleBlack,
		backgroundColor: "#FFFBF7",
		borderRadius: '30px',
		height: '48px',
		boxShadow: '0px 0px 10px 0px #99959180',
		'& fieldset': {
			borderRadius: '30px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.primary
		}
	},
	width: '100%'
  });

  export const ProfitInput = styled(TextField) ({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '15px',
		fontWeight: 500,
		lineHeight: '18px',
		letterSpacing: '0em',
		textAlign: 'right'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
			fontFamily: fontFamily,
			color: colour.dark,
			fontWeight: 500,
			backgroundColor: colour.grayScaleWhite,
			borderRadius: '3px',
			height: '40px',
			boxShadow: '0px 0px 10px 0px #99959140',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.primary
		}
	},
	width: '100%'
  });

  export const RecurringCostsInput = styled(TextField) ({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '15px',
		fontWeight: 500,
		lineHeight: '18px',
		letterSpacing: '0em',
		textAlign: 'right'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
			fontFamily: fontFamily,
			color: colour.dark,
			fontWeight: 500,
			backgroundColor: colour.grayScaleWhite,
			borderRadius: '3px',
			height: '30px',
			fontSize: '12px',
			boxShadow: '0px 0px 10px 0px #99959140',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.primary
		}
	},
	width: '100%'
  });

  export const LowballInput = styled(TextField) ({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '15px',
		fontWeight: 500,
		lineHeight: '18px',
		letterSpacing: '0em',
		textAlign: 'right'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
			fontFamily: fontFamily,
			color: colour.dark,
			fontWeight: 500,
			backgroundColor: colour.grayScaleWhite,
			borderRadius: '3px',
			height: '30px',
			fontSize: '12px',
			boxShadow: '0px 0px 10px 0px #99959140',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.primary
		}
	},
	width: '100%'
  });

  export const FilterCityInput = styled(TextField) ({
	'& label': {
		color: "#F5F6FA",
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '15px',
		fontWeight: 400,
		lineHeight: '18px',
		letterSpacing: '0em',
		textAlign: 'right'
	},
	'& label.Mui-focused': {
		color: "#F5F6FA",
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.blueBlue02
	},
	'& .MuiOutlinedInput-root': {
			fontFamily: fontFamily,
			color: colour.textPrimary,
			fontWeight: 400,
			backgroundColor: "#FFFEFC",
			borderRadius: '2px',
			height: '40px',
			border: `0.5px solid ${colour.blueBlue02}`,
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.blueBlue02
		},
		'&.Mui-focused fieldset': {
			border: 'none'
		}
	},
	width: '100%'
  });

  export const FilterInput = styled(TextField) ({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '15px',
		fontWeight: 400,
		lineHeight: '18px',
		letterSpacing: '0em',
		textAlign: 'right'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
			fontFamily: fontFamily,
			color: colour.dark,
			fontWeight: 400,
			backgroundColor: colour.grayScaleWhite,
			borderRadius: '3px',
			height: '40px',
			boxShadow: '0px 0px 10px 0px #99959140',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.primary
		}
	},
	width: '100%'
  });

  export const NumberInput = styled(TextField)({
	'& label': {
		color: colour.grayScaleBlack,
		fontFamily: fontFamily,
		paddingLeft: '0px',
		fontSize: '15px',
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		boxShadow: '0px 0px 10px 0px #99959140',
		borderRadius: '3px',
		height: '38px',
		fontSize: '15px',
		fontWeight: '400',
		lineHeight: '18px',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.grayScaleWhite,
		},
	},
	width: '100%'
  });

  export const MultilineInput = styled(TextField)({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '3px',
		border: `1px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.blueBlue02,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
		}
	},
	width: '100%'
  });

  export const NewMultilineInput = styled(TextField)({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '3px',
		boxShadow: '0px 0px 8px 0px rgba(153, 149, 145, 0.50)',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.blueBlue02,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
		}
	},
	width: '100%'
  });

export const MultilineNoShadow = styled(TextField)({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '3px',
		border: `0.5px solid ${colour.blueBlue01}`,
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.blueBlue02,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.blueBlue02,
		}
	},
	width: '100%'
});

  export const CapexMultiline = styled(TextField)({
	'& label': {
		color: colour.med,
		fontFamily: fontFamily,
		paddingLeft: '1.5em',
		fontSize: '0.875rem'
	},
	'& label.Mui-focused': {
		color: colour.med,
		paddingLeft: 0
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: colour.med,
	},
	'& .MuiOutlinedInput-root': {
		fontFamily: fontFamily,
		color: colour.dark,
		backgroundColor: colour.grayScaleWhite,
		borderRadius: '3px',
		boxShadow: '0px 0px 10px 0px #99959140',
		'& fieldset': {
			borderRadius: '3px',
			borderWidth: 0,
		},
		'&:hover fieldset': {
			borderColor: colour.med,
		},
		'&.Mui-focused fieldset': {
			borderColor: colour.primary
		},
	},
	width: '100%'
  });

export const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
  ))
  (() => ({
	borderRadius: '0px',
	width: '100%',
	padding: '0px',
	backgroundColor: 'transparent !important',
	boxShadow: 'none',
	margin: '0 auto',
	border: 'none'
}));

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
	  {...props}
	/>
  ))(() => ({
	width: '100%',
	padding: '0px',
	boxShadow: 'none'
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
	padding: '0px !important'
}));
  
export const CustomSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
	  '& .MuiSwitch-thumb': {
		width: 15,
	  },
	  '& .MuiSwitch-switchBase.Mui-checked': {
		transform: 'translateX(9px)',
	  },
	},
	'& .MuiSwitch-switchBase': {
	  padding: 2,
	  '&.Mui-checked': {
		transform: 'translateX(12px)',
		color: '#fff',
		'& + .MuiSwitch-track': {
		  opacity: 1,
		  backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
		},
	  },
	},
	'& .MuiSwitch-thumb': {
	  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
	  width: 12,
	  height: 12,
	  borderRadius: 6,
	  transition: theme.transitions.create(['width'], {
		duration: 200,
	  }),
	},
	'& .MuiSwitch-track': {
	  borderRadius: 16 / 2,
	  opacity: 1,
	  backgroundColor:
		theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
	  boxSizing: 'border-box',
	},
}));

export const CustomSelect = styled(InputBase)(({ theme }) => ({
	'label + &': {
	  marginTop: theme.spacing(3),
	},
	'& .MuiInputBase-input': {
	  borderRadius: 3,
	  position: 'relative',
	  fontSize: 14,
	  padding: '10px 26px 10px 12px',
	  backgroundColor: colour.grayScaleWhite,
	  border: `0.5px solid ${colour.blueBlue01}`,
	  boxShadow: '0px 0px 8px 0px rgba(152, 151, 147, 0.40)',
	  fontFamily: [
		fontFamily
	  ].join(','),
	  '&:focus': {
	  },
	}
}));

export const SortSelect = styled(InputBase)(({ theme }) => ({
	'label + &': {
	  marginTop: theme.spacing(3),
	},
	'& .MuiInputBase-input': {
	  borderRadius: 3,
	  position: 'relative',
	  fontSize: 14,
	  padding: '10px 26px 10px 12px',
	  color: colour.blueBlue03,
	  fontWeight: 600,
	  lineHeight: '20px',
	  fontFamily: [
		fontFamily
	  ].join(','),
	  '&:focus': {
	  },
	},
}));

export const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow: '0px 0px 10px 0px #99959180',
	backgroundColor: colour.grayScaleWhite,
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
	  outline: '2px auto rgba(19,124,189,.6)',
	  outlineOffset: 2,
	},
	'input:hover ~ &': {
	  backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
	  boxShadow: 'none',
	  background:
		theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
  }));
  
export const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#FFFFFF',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,0.1),hsla(0,0%,100%,0))',
	'&:before': {
	  display: 'block',
	  width: 16,
	  height: 16,
	  backgroundImage: `radial-gradient(${colour.grayScaleBlack}, ${colour.grayScaleBlack} 45%,transparent 47%)`,
	  content: '""',
	},
	'input:hover ~ &': {
	  backgroundColor: '#FFFFFF',
	},
});

export const CustomRadio = (props) => {
	return (
		<Radio
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	)
};

export const Highlight = ({ children }) => (
	<span className="remodelled-word">
		{children}
	</span>
);

export const keywordArray = [
	'roof',
	'boiler',
	'pipes',
	'septic system',
	'foundations',
	'foundation',
	'air vents',
	'a/c',
	'central heating',
	'heating',
	'termite',
	'pest control',
	'water control',
	'plumbing',
	'electric',
	'remodel',
	'remodeling',
	'remodelling',
	'renovation',
	'exterior',
	'landscape',
	'landscaping',
	'appliance',
	'kitchen',
	'water',
	'bathroom',
	'air',
	'floor',
	'fix',
	'fixer',
	'upper',
	'fixer-upper',
	'paint',
	'window',
	'washing machine',
	'new',
	'newer',
	'drier',
	'heater',
	'private entrance',
	'AC',
	'cash buyer',
	'cash only',
	'cash deal',
	'hard money',
	'cash offer only',
	'sold as is',
	'tlc',
	'sold as-is',
	'as is condition',
	'needs work',
	'casita',
	' adu ',
	'attached studio',
	'dwelling unit',
	'detachable',
	'separate entrance'
];

export const bullshitAddresses = [
	"14 Aspen Drive, N. Caldwell",
	"183 Satriale's Pork Store",
	"924 Shah of Iran Way",
	"271 Stugots Road",
	"8271 Heavy Soles Drive",
	"9251 Don's Shorts St",
	"6123 Healthy As-A Rhino Park",
	"2671 Flying Saucer, E. Rutherford",
	"6515 Quasimodo Avenue",
	"7152 Fish-Lips Way"
];

// Add the following fields dynamically:
// 1. City
// 2. City ID
// 3. ID
// 4. state
// 5. title
export const defaultBuyBox = {
	activeListings: 0,
	bathrooms: 1,
	bedrooms: 1,
	budgetRange: [0, 1000000000],
	city: "",
	cityId: "",
	constructionYears: [1800, 2025],
	frequency: "daily",
	id: "",
	lastUpdate: new Date(),
	neighbourhoodGradeMin: 1,
    neighbourhoodGradeMax: 8,
	newListings: 0,
	noFixerUpper: false,
	onlyADU: false,
	onlyExtraBedroom: false,
	propertyTypes: [
        {
            code: "MultiFamily",
            included: true,
            property: "Multi-Family",
            abbreviation: "MFH"
        },
        {
            code: "SingleFamily",
            included: true,
            property: "Single-Family",
            abbreviation: "SFH"
        }
    ],
	sent: [],
	squareFootage: [0, 999999],
	state: "",
	strategy: "turnkey",
	title: "",
	totalListings: 0,
	units: 1
};

export const defaultUserData = {
	settings: {
		recurringCosts: [
			{
				label: "Insurance",
				value: "",
				default: true,
				placeholder: "city based",
				info: "The default is based on the average for each city according to Zillow.",
				rentPercentage: false
			},
			{
				label: "Property taxes",
				value: "",
				default: true,
				placeholder: "city based",
				info: "The default is based on Zillow's property tax estimates for each property, which usually does NOT take into account any tax increase after you purchase the property. Please check county websites for this data (we're working on aggregating them)!",
				rentPercentage: false
			},
			{
				label: "Vacancy Rate Allocation",
				value: "",
				default: true,
				placeholder: "city based",
				info: "The default is based on the average vacancy rate for the city according to FRED (Federal Reserve Economic Data)...not just some guy called Fred.",
				rentPercentage: true
			},
			{
				label: "Management fee",
				value: "",
				default: true,
				placeholder: "9",
				info: "The default is 9% of your rental income.",
				rentPercentage: true
			},
			{
				label: "Maintenance",
				value: "",
				default: true,
				placeholder: "2",
				info: "The default is 2% of your rental income.",
				rentPercentage: true
			},
			{
				label: "Other costs",
				value: "",
				default: true,
				placeholder: "0",
				info: "The default is 0%. Use this to put money aside for a rainy day, like fixing the roof when it leaks in 5 years from now!",
				rentPercentage: true
			}
		],
		initialCosts: [
			{
				label: "Down payment",
				value: "",
				placeholder: "20"
			},
			{
				label: "Interest rate",
				value: "",
				default: true,
				placeholder: "7.73",
				info: "The default is automatically updated based on FRED's average for 30 year fixed-rate mortgages."
			}
		],
		loanType: "30",
		customCities: [],
		mortgage: true,
		rehabCosts: {
			value: "",
            default: true
		}
	},
	options: {
		adu: false,
		allZips: true,
		bathrooms: 1,
		bedrooms: 1,
		budgetRange: [0, 1000000000],
		constructionYears: ["1900", "2025"],
		extraBedroom: false,
		hideFixerUppers: false,
		offMarketFilters: [],
		onlyFixerUppers: false,
		paymentInfo: {
			downPayment: 20,
			interestRate: 7,
			loanType: 0,
			mortgage: true,
			paymentType: "mortgage"
		},
		priceReduction: false,
		priceReductionIndex: 0,
		propTypeMislabelledFlag: false,
		propertyTypes: [
			{
				code: "MultiFamily",
				included: false,
				property: "Multi-Family"
			},
			{
				code: "SingleFamily",
				included: true,
				property: "Single Family Home"
			}
		],
		sellerFinancing: false,
		squareFootage: [750, 4000],
		units: 1,
		zips: [],
		startDate: new Date()
	}
};

export const defaultCompData = {
	maxDistance: 2,
	maxSoldDate: 6,
	bedrooms: 1,
	bathrooms: 1,
	squareFootage: [0, 999999],
	priceRange: ["", ""],
	listingStatus: 2,
	numberOfFilters: 0,
	manualComps: [],
	excludedComps: []
};

export const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))
		(({ theme }) => ({
			width: 42,
			height: 26,
			padding: 0,
			margin: '0px !important',
			'& .MuiSwitch-switchBase': {
			  padding: 0,
			  margin: 2,
			  transitionDuration: '300ms',
			  '&.Mui-checked': {
				transform: 'translateX(16px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
				  backgroundColor: theme.palette.mode === 'dark' ? colour.blueBlue01 : colour.blueBlue03,
				  opacity: 1,
				  border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
				  opacity: 0.5,
				},
			  },
			  '&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: '6px solid #fff',
			  },
			  '&.Mui-disabled .MuiSwitch-thumb': {
				color:
				  theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
			  },
			  '&.Mui-disabled + .MuiSwitch-track': {
				opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
			  },
			},
			'& .MuiSwitch-thumb': {
			  boxSizing: 'border-box',
			  width: 22,
			  height: 22,
			},
			'& .MuiSwitch-track': {
			  borderRadius: 26 / 2,
			  backgroundColor: theme.palette.mode === 'light' ? colour.blueBlue01 : '#39393D',
			  opacity: 1,
			  transition: theme.transitions.create(['background-color'], {
				duration: 500,
			  }),
			},
			'& .MuiSwitch-input': {
				width: '100% !important'
			}
		})
);

export const MobileIOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))
		(({ theme }) => ({
			width: 60, //42,
			height: 32, //26,
			padding: 0,
			margin: '0px !important',
			'& .MuiSwitch-switchBase': {
			  padding: 0,
			  margin: 2,
			  top: '5%',
			  transitionDuration: '300ms',
			  '&.Mui-checked': {
				transform: 'translateX(30px)',
				color: '#fff',
				'& .MuiSwitch-thumb:before': {
					position: 'absolute',
					width: '100%',
					height: '10px',
					left: '-80%',
					top: '30%',
					content: `"ON"`,
					fontSize: 8,
					color: colour.grayScaleWhite,
					fontFamily: fontFamily,
					fontStyle: 'normal',
					fontWeight: 400,
					lineHeight: 'normal'
				},
				'& + .MuiSwitch-track': {
				  backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : colour.blueBlue03,
				  opacity: 1,
				  border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
				  opacity: 0.5,
				},
			  },
			  '&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: '6px solid #fff',
			  },
			  '&.Mui-disabled .MuiSwitch-thumb': {
				color:
				  theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
			  },
			  '&.Mui-disabled + .MuiSwitch-track': {
				opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
			  },
			},
			'& .MuiSwitch-thumb': {
			  boxSizing: 'border-box',
			  width: 25,
			  height: 25,
			  '&:before': {
				position: 'absolute',
				width: '100%',
				height: '10px',
				right: '-120%',
				top: '30%',
				content: `"OFF"`,
				fontSize: 8,
				color: colour.blueBlue03,
				fontFamily: fontFamily,
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: 'normal'
			  },
			},
			'& .MuiSwitch-track': {
			  borderRadius: 32 / 2, //26 / 2,
			  backgroundColor: theme.palette.mode === 'light' ? colour.blueBlue01 : '#39393D',
			  opacity: 1,
			  transition: theme.transitions.create(['background-color'], {
				duration: 500,
			  }),
			},
		})
);

export const checkZIPPercentile = (averagePriceArray, zipCodeIndex) => {
	const zipLength = averagePriceArray.length;
	const numerator = zipCodeIndex + 1;
	const percentile = numerator / zipLength;

	return percentile;
}

export const propertyMapStyles = (zipCodeIndex, averagePriceArray, zipCode, hoverZIP, styles, lastClickedZIPCode, mapSidePanelOpen, hoverZIPLegend, searchParams) => {
	if ( zipCodeIndex !== -1 ) {
		const percentile = checkZIPPercentile(averagePriceArray, zipCodeIndex);
		const searchQueryState = searchParams.get('searchQueryState') ===  null ? null : JSON.parse(searchParams.get('searchQueryState'));
		const zipCodeSelections = searchQueryState !== null ? searchQueryState.selections : [];
		const extraZIPIndex = zipCodeSelections.indexOf(zipCode);

		if ( extraZIPIndex !== -1 || zipCodeSelections.length === 0 || hoverZIP === zipCode ) {
			// If Top Picks is not active, and we have no hand selected ZIPs -> show everything
			if ( percentile > 0.8 ) {
				if ( hoverZIPLegend === 0 ) {
					return styles.deepGreen;
				}
				else if ( hoverZIPLegend !== "" && zipCodeSelections.length === 0 ) {
					return styles.default;
				}
				else if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
					return styles.deepGreenSelected;
				}
				else {
					return styles.deepGreen;
				}
			}
			else if ( percentile > 0.6 ) {
				if ( hoverZIPLegend === 1 ) {
					return styles.green;
				}
				else if ( hoverZIPLegend !== "" && zipCodeSelections.length === 0 ) {
					return styles.default;
				}
				else if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
					return styles.greenSelected;
				}
				else {
					return styles.green;
				}
			}
			else if ( percentile > 0.4 ) {
				if ( hoverZIPLegend === 2 ) {
					return styles.amber;
				}
				else if ( hoverZIPLegend !== "" && zipCodeSelections.length === 0 ) {
					return styles.default;
				}
				else if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
					return styles.amberSelected;
				}
				else {
					return styles.amber;
				}
			}
			else if ( percentile > 0.2 ) {
				if ( hoverZIPLegend === 3 ) {
					return styles.blue;
				}
				else if ( hoverZIPLegend !== "" && zipCodeSelections.length === 0 ) {
					return styles.default;
				}
				else if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
					return styles.blueSelected;
				}
				else {
					return styles.blue;
				}
			}
			else if ( percentile <= 0.2 ) {
				if ( hoverZIPLegend === 4 ) {
					return styles.deepBlue;
				}
				else if ( hoverZIPLegend !== "" && zipCodeSelections.length === 0 ) {
					return styles.default;
				}
				else if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
					return styles.deepBlueSelected;
				}
				else {
					return styles.deepBlue;
				}
			}
			else {
				if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
					return styles.defaultSelected;
				}
				else {
					return styles.default;
				}
			}
		}
		else if ( hoverZIPLegend !== "" ) {
			if ( percentile > 0.8 ) {
				if ( hoverZIPLegend === 0 ) {
					return styles.deepGreen;
				}
				else {
					return styles.default;
				}
			}
			else if ( percentile > 0.6 ) {
				if ( hoverZIPLegend === 1 ) {
					return styles.green;
				}
				else {
					return styles.default;
				}
			}
			else if ( percentile > 0.4 ) {
				if ( hoverZIPLegend === 2 ) {
					return styles.amber;
				}
				else {
					return styles.default;
				}
			}
			else if ( percentile > 0.2 ) {
				if ( hoverZIPLegend === 3 ) {
					return styles.blue;
				}
				else {
					return styles.default;
				}
			}
			else {
				if ( hoverZIPLegend === 4 ) {
					return styles.deepBlue;
				}
				else {
					return styles.default;
				}
			}
		}
		else {
			if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
				return styles.defaultSelected;
			}
			else {
				return styles.default;
			}	
		}
	}
	else {
		if ( zipCode === hoverZIP || (lastClickedZIPCode === zipCode && mapSidePanelOpen === true) ) {
			return styles.defaultSelected;
		}
		else {
			return styles.default;
		}
	}
};

export const budgetOptions = [
	{
		value: 0,
		label: "No preference"
	},
	{
		value: 50000
	},
	{
		value: 100000
	},
	{
		value: 150000
	},
	{
		value: 200000
	},
	{
		value: 250000
	},
	{
		value: 300000
	},
	{
		value: 350000
	},
	{
		value: 400000
	},
	{
		value: 450000
	},
	{
		value: 500000
	},
	{
		value: 600000
	},
	{
		value: 700000
	},
	{
		value: 800000
	},
	{
		value: 900000
	},
	{
		value: 1000000
	},
	{
		value: 1500000
	},
	{
		value: 2000000
	},
	{
		value: 2500000
	},
	{
		value: 3000000
	},
	{
		value: 1000000000,
		label: "No preference"
	}
];

export const yearsOwnedOptions = [
	{
		value: 0,
		label: "No preference"
	},
	{
		value: 1
	},
	{
		value: 2
	},
	{
		value: 5
	},
	{
		value: 10
	},
	{
		value: 15
	},
	{
		value: 20
	},
	{
		value: 100,
		label: "No preference"
	}
];

export const priceReductionList = [
	{
		label: "In the last day"
	},
	{
		label: "In the last 3 days"
	},
	{
		label: "In the last 7 days"
	},
	{
		label: "In the last 14 days"
	},
	{
		label: "In the last 30 days"
	},
	{
		label: "More than 30 days"
	},
	{
		label: "More than 60 days"
	},
	{
		label: "More than 120 days"
	}
];

export const loanList = [
	{
		name: "30 Years Fixed"
	},
	{
		name: "20 Years Fixed"
	}
];

export const bedroomOptions = [
	{
		label: "1+",
		value: 1
	},
	{
		label: "2+",
		value: 2
	},
	{
		label: "3+",
		value: 3
	},
	{
		label: "4+",
		value: 4
	},
	{
		label: "5+",
		value: 5
	},
	{
		label: "6+",
		value: 6
	},
	{
		label: "7+",
		value: 7
	},
	{
		label: "8+",
		value: 8
	},
	{
		label: "9+",
		value: 9
	}
];

export const bathroomOptions = [
	{
		label: "1+",
		value: 1
	},
	{
		label: "2+",
		value: 2
	},
	{
		label: "3+",
		value: 3
	},
	{
		label: "4+",
		value: 4
	},
	{
		label: "5+",
		value: 5
	}
];

export const squareFootageRange = [
	{
		value: 0,
		label: "No preference"
	},
	{
		value: 500
	},
	{
		value: 750
	},
	{
		value: 1000
	},
	{
		value: 1250
	},
	{
		value: 1500
	},
	{
		value: 1750
	},
	{
		value: 2000
	},
	{
		value: 3000
	},
	{
		value: 3500
	},
	{
		value: 4000
	},
	{
		value: 5000
	},
	{
		value: 7500
	},
	{
		value: 999999,
		label: "No Max"
	}
];

export const downPaymentRange = [
	{
		label: "3.5%",
		value: 3.5
	},
	{
		label: "5%",
		value: 5
	},
	{
		label: "10%",
		value: 10
	},
	{
		label: "15%",
		value: 15
	},
	{
		label: "20%",
		value: 20
	},
	{
		label: "25%",
		value: 25
	}
];

export const unitsRange = [
	{
		label: "1+",
		value: 1
	},
	{
		label: "2+",
		value: 2
	},
	{
		label: "3+",
		value: 3
	},
	{
		label: "4+",
		value: 4
	}
];

export const citiesForPurchase = [
    {
        city: "Birmingham",
        state: "AL",
		stateFull: "Alabama",
        emoji: "🏈",
        path: "/birmingham",
        city_id: "0110417",
		icon: Birmingham,
		comingSoon: false,
		cityUnchanged: "Birmingham",
		description: "Steeped in Southern charm, Birmingham is known for its Civil Rights history, vibrant cultural scene, and a revitalized downtown.",
		lat: 33.520688,
    	lng: -86.802439
    },
    {
        city: "Phoenix",
        state: "AZ",
		stateFull: "Arizona",
        emoji: "🏜️",
        path: "/phoenix",
        city_id: "0426113",
		icon: Phoenix,
		comingSoon: false,
		cityUnchanged: "Phoenix",
		description: "With a desert landscape, Phoenix is famous for its hot climate, golf courses, and as a center for spa resorts. The tap water is drinkable...just try not to think about where it came from.",
		lat: 33.448377,
    	lng: -112.076203
    },
	{
        city: "Fresno",
        state: "CA",
		stateFull: "California",
        emoji: "🌶️",
        path: "/fresno",
        city_id: "0618203",
		icon: Fresno,
		comingSoon: false,
		cityUnchanged: "Fresno",
		description: "The classy capital of California.",
		lat: 36.746841,
    	lng: -119.772591
    },
    {
        city: "Sacramento",
        state: "CA",
		stateFull: "California",
        emoji: "⛏️",
        path: "/sacramento",
        city_id: "0620288",
		icon: Sacramento,
		comingSoon: false,
		cityUnchanged: "Sacramento",
		description: "The capital of California is known for its Gold Rush history, state government presence, and proximity to Napa Valley. And only a 1.5 hour drive to The French Laundry restaurant, for those who were wondering!",
		lat: 38.575764,
    	lng: -121.478851
    },
	{
        city: "Colorado Springs",
        state: "CO",
		stateFull: "Colorado",
        emoji: "⛷️",
        path: "/colorado-springs",
        city_id: "084172",
		icon: ColoradoSprings,
		comingSoon: false,
		cityUnchanged: "Colorado Springs",
		description: "Nestled at the base of Pikes Peak, Colorado Springs is admired for its stunning natural scenery, outdoor adventures, and military presence.",
		lat: 38.833881,
    	lng: -104.821382
    },
    {
        city: "Denver",
        state: "CO",
		stateFull: "Colorado",
        emoji: "🗻",
        path: "/denver",
        city_id: "0811093",
		icon: Denver,
		comingSoon: false,
		cityUnchanged: "Denver",
		description: "The Mile-High City is celebrated for its outdoor activities, craft breweries, and proximity to the Rocky Mountains.",
		lat: 39.7392358,
    	lng: -104.990251
    },
    {
        city: "Washington",
        state: "DC",
		stateFull: "District of Columbia",
        emoji: "🏛️",
        path: "/washington-dc",
        city_id: "1141568",
		icon: WashingtonDC,
		comingSoon: false,
		cityUnchanged: "Washington",
		description: "Home of the hard-workers.",
		lat: 38.9072,
    	lng: -77.0369
    },
    {
        city: "Jacksonville",
        state: "FL",
		stateFull: "Florida",
        emoji: "🍤",
        path: "/jacksonville",
        city_id: "1225290",
		icon: Jacksonville,
		comingSoon: false,
		cityUnchanged: "Jacksonville",
		description: "Florida's largest city is marked by its extensive coastline, naval presence, and a mix of urban and natural attractions.",
		lat: 30.3322,
    	lng: -81.6557
    },
    {
        city: "Miami",
        state: "FL",
		stateFull: "Florida",
        emoji: "☀️",
        path: "/miami",
        city_id: "1212700",
		icon: Miami,
		comingSoon: false,
		cityUnchanged: "Miami",
		description: "A vibrant, international city, Pitbull’s city is known for its beaches, Art Deco architecture, and careful driving.",
		lat: 25.7617,
    	lng: -80.1918
    },
	{
        city: "Ocala",
        state: "FL",
		stateFull: "Florida",
        emoji: "🚣",
        path: "/ocala",
        city_id: "1253673",
		icon: Ocala,
		comingSoon: false,
		cityUnchanged: "Ocala",
		description: "Surrounded by horse country, Ocala is known for its equestrian culture, natural springs, and outdoor recreation.",
		lat: 29.1872,
    	lng: -82.1401
    },
    {
        city: "Orlando",
        state: "FL",
		stateFull: "Florida",
        emoji: "🐭",
        path: "/orlando",
        city_id: "1213121",
		icon: Orlando,
		comingSoon: false,
		cityUnchanged: "Orlando",
		description: "Renowned for its theme parks, Orlando attracts millions with Walt Disney World and Universal Studios. Unfortunately, this is where many Europeans will get their first taste of American culture...oh well.",
		lat: 28.5384,
    	lng: -81.3789
    },
	{
        city: "Tampa",
        state: "FL",
		stateFull: "Florida",
        emoji: "🏴‍☠️",
        path: "/tampa",
        city_id: "1241176",
		icon: Tampa,
		comingSoon: false,
		cityUnchanged: "Tampa",
		description: "With its waterfront, Cuban influence, and cultural events (such as – and I’m not making this up – a pirate parade 🏴‍☠️), Tampa is a Gulf Coast city known for its diverse attractions. It’s also a place where you are statistically more likely to get struck by lightning ⚡️ right on 😎",
		lat: 27.9517,
    	lng: -82.4588
    },
    {
        city: "Atlanta",
        state: "GA",
		stateFull: "Georgia",
        emoji: "🍑",
        path: "/atlanta",
        city_id: "1337211",
		icon: Atlanta,
		comingSoon: false,
		cityUnchanged: "Atlanta",
		description: "A major Southern hub, Atlanta is renowned for its cultural diversity, historic sites like Martin Luther King Jr. National Historic Site, and as a center for business and entertainment.",
		lat: 33.7490,
    	lng: -84.3880
    },
    {
        city: "Boise",
        state: "ID",
		stateFull: "Idaho",
        emoji: "🥔",
        path: "/boise",
        city_id: "163737",
		icon: Boise,
		comingSoon: false,
		cityUnchanged: "Boise",
		description: "Nestled near the Rocky Mountains, Boise is prized for its outdoor recreation, including hiking, skiing, and a vibrant downtown.",
		lat: 43.6152,
    	lng: -116.2043
    },
    {
        city: "Chicago",
        state: "IL",
		stateFull: "Illinois",
        emoji: "🌭",
        path: "/chicago",
        city_id: "1717426",
		icon: Chicago,
		comingSoon: false,
		cityUnchanged: "Chicago",
		description: "Iconic architecture, vibrant arts, and effective local government.",
		lat: 41.8818,
    	lng: -87.6232
    },
    {
        city: "Indianapolis",
        state: "IN",
		stateFull: "Indiana",
        emoji: "🏎️",
        path: "/indianapolis",
        city_id: "1832149",
		icon: Indianapolis,
		comingSoon: false,
		cityUnchanged: "Indianapolis",
		description: "Famous for the Indianapolis 500, the city combines sports enthusiasm, cultural attractions, and a revitalized downtown.",
		lat: 39.7910,
    	lng: -86.1480
    },
    {
        city: "Louisville",
        state: "KY",
		stateFull: "Kentucky",
        emoji: "🏇",
        path: "/louisville",
        city_id: "2112455",
		icon: Louisville,
		comingSoon: false,
		cityUnchanged: "Louisville",
		description: "Famous for the Kentucky Derby, Louisville boasts bourbon distilleries, cultural events, and the scenic Waterfront Park.",
		lat: 38.2526,
    	lng: -85.7594
    },
    {
        city: "New Orleans",
        state: "LA",
		stateFull: "Louisiana",
        emoji: "🎺",
        path: "/new-orleans",
        city_id: "2219594",
		icon: NewOrleans,
		comingSoon: false,
		cityUnchanged: "New Orleans",
		description: "Known for its lively ,jazz scene, Mardi Gras celebrations, and Creole cuisine, New Orleans is rich in cultural heritage.",
		lat: 29.9511,
    	lng: -90.0715
    },
    {
        city: "Baltimore",
        state: "MD",
		stateFull: "Maryland",
        emoji: "🦀",
        path: "/baltimore",
        city_id: "243523",
		icon: Baltimore,
		comingSoon: false,
		cityUnchanged: "Baltimore",
		description: "Where it gets down to the wire.",
		lat: 39.2904,
    	lng: -76.6122
    },
    {
        city: "Boston",
        state: "MA",
		stateFull: "Massachusetts",
        emoji: "🫖",
        path: "/boston",
        city_id: "2544269",
		icon: Boston,
		comingSoon: false,
		cityUnchanged: "Boston",
		description: "Rich in history and academic prestige, Boston blends colonial charm with modern innovation, featuring iconic landmarks and a fervent sports culture.",
		lat: 42.3601,
    	lng: -71.0589
    },
    {
        city: "Detroit",
        state: "MI",
		stateFull: "Michigan",
        emoji: "🚗",
        path: "/detroit",
        city_id: "2617762",
		icon: Detroit,
		comingSoon: false,
		cityUnchanged: "Detroit",
		description: "At least it's not Cleveland.",
		lat: 42.3314,
    	lng: -83.0458
    },
    {
        city: "Minneapolis",
        state: "MN",
		stateFull: "Minnesota",
        emoji: "☃️",
        path: "/minneapolis",
        city_id: "275983",
		icon: Minneapolis,
		comingSoon: false,
		cityUnchanged: "Minneapolis",
		description: "With a strong arts scene and outdoor activities, Minneapolis is known for its lakes, bike-friendly culture, and diverse communities.",
		lat: 44.9778,
		lng: -93.2650
	},
    {
        city: "Kansas City",
        state: "MO",
		stateFull: "Missouri",
        emoji: "⛲️",
        path: "/kansas-city",
        city_id: "2918795",
		icon: KansasCity,
		comingSoon: false,
		cityUnchanged: "Kansas City",
		description: "Straddling the Kansas-Missouri border, Kansas City is famous for its jazz heritage, barbecue, fountains, and the football team that Taylor Swift’s boyfriend plays on.",
		lat: 39.0997,
    	lng: -94.5783
    },
    {
        city: "St. Louis",
        state: "MO",
		stateFull: "Missouri",
        emoji: "⛽️",
        path: "/st-louis",
        city_id: "296891",
		icon: SaintLouis,
		comingSoon: false,
		cityUnchanged: "St. Louis",
		description: "Famous for the Gateway Arch, St. Louis offers a mix of cultural attractions, blues music, and a vibrant food scene.",
		lat: 38.6270,
		lng: -90.1994
	},
	{
        city: "Charlotte",
        state: "NC",
		stateFull: "North Carolina",
        emoji: "🏁",
        path: "/charlotte",
        city_id: "3724043",
		icon: Charlotte,
		comingSoon: false,
		cityUnchanged: "Charlotte",
		description: "A major banking center, Charlotte combines modern urban amenities with outdoor recreation, including nearby mountain ranges.",
		lat: 35.2271,
		lng: -80.8431
    },
    {
        city: "Durham",
        state: "NC",
		stateFull: "North Carolina",
        emoji: "💉",
        path: "/durham",
        city_id: "3724457",
		icon: Durham,
		comingSoon: false,
		cityUnchanged: "Durham",
		description: "Home to Duke University, Durham is recognized for its educational institutions, tech innovation, and vibrant food scene.",
		lat: 35.9940,
		lng: -78.8986
    },
    {
        city: "Raleigh",
        state: "NC",
		stateFull: "North Carolina",
        emoji: "✈️",
        path: "/raleigh",
        city_id: "3754047",
		icon: Raleigh,
		comingSoon: false,
		cityUnchanged: "Raleigh",
		description: "Part of the Research Triangle, Raleigh is known for its high-tech industry, universities, and green spaces.",
		lat: 35.7796,
		lng: -78.6382
	},
    {
        city: "Omaha",
        state: "NE",
		stateFull: "Nebraska",
        emoji: "🌽",
        path: "/omaha",
        city_id: "3140152",
		icon: Omaha,
		comingSoon: false,
		cityUnchanged: "Omaha",
		description: "Prairies & Warren Buffett.",
		lat: 41.2565,
		lng: -95.9345
	},
	{
        city: "Newark",
        state: "NJ",
		stateFull: "New Jersey",
        emoji: "",
        path: "/newark",
        city_id: "3412970",
		icon: Newark,
		comingSoon: false,
		cityUnchanged: "Newark",
		description: "If you're a come-from-behind kind of guy, Newark is the place for you.",
		lat: 40.7357,
		lng: -74.1724
	},
	{
        city: "Albuquerque",
        state: "NM",
		stateFull: "New Mexico",
        emoji: "🎈",
        path: "/albuquerque",
        city_id: "3523429",
		icon: Albuquerque,
		comingSoon: false,
		cityUnchanged: "Albuquerque",
		description: "Troy Bolton & Walter White.",
		lat: 35.0844,
		lng: -106.6504
    },
    {
        city: "Las Vegas",
        state: "NV",
		stateFull: "Nevada",
        emoji: "🎰",
        path: "/las-vegas",
        city_id: "3218959",
		icon: LasVegas,
		comingSoon: false,
		cityUnchanged: "Las Vegas",
		description: "Famous for its vibrant nightlife, entertainment, and casinos, this artificial city of Sin is a desert oasis in the Nevada desert. But it’s not all fear and loathing – the Las Vegas real estate market shows hope and opportunity.",
		lat: 36.1699,
		lng: -115.1398
	},
	{
        city: "Buffalo",
        state: "NY",
		stateFull: "New York",
        emoji: "🐃",
        path: "/buffalo",
        city_id: "3617222",
		icon: Buffalo,
		comingSoon: false,
		cityUnchanged: "Buffalo",
		description: "Known for its Niagara Falls proximity, Buffalo offers a blend of architectural gems, cultural institutions, and sports enthusiasm.",
		lat: 42.8864,
		lng: -78.8784
    },
	{
        city: "Rochester",
        state: "NY",
		stateFull: "New York",
        emoji: "🦁",
        path: "/rochester",
        city_id: "36832063",
		icon: Rochester,
		comingSoon: false,
		cityUnchanged: "Rochester",
		description: "Renowned for its photography and imaging industry, Rochester is marked by its festivals, parks, and cultural institutions.",
		lat: 43.1566,
		lng: -77.6088
	},
    {
        city: "Cincinnati",
        state: "OH",
		stateFull: "Ohio",
        emoji: "🍻",
        path: "/cincinnati",
        city_id: "394099",
		icon: Cincinnati,
		comingSoon: false,
		cityUnchanged: "Cincinnati",
		description: "Recognized for its riverfront, Cincinnati is marked by its German heritage, sports fervor, and the historic Over-the-Rhine district.",
		lat: 39.1031,
		lng: -84.5120
    },
    {
        city: "Cleveland",
        state: "OH",
		stateFull: "Ohio",
        emoji: "🏥",
        path: "/cleveland",
        city_id: "3924115",
		icon: Cleveland,
		comingSoon: false,
		cityUnchanged: "Cleveland",
		description: "At least it's not Detroit.",
		lat: 41.4993,
		lng: -81.6944
    },
    {
        city: "Columbus",
        state: "OH",
		stateFull: "Ohio",
        emoji: "🦬",
        path: "/columbus",
        city_id: "3910920",
		icon: Columbus,
		comingSoon: false,
		cityUnchanged: "Columbus",
		description: "The obsession of everyone on Bigger Pockets.",
		lat: 39.9612,
		lng: -82.9988
	},
    {
        city: "Oklahoma City",
        state: "OK",
		stateFull: "Oklahoma",
        emoji: "🪶",
        path: "/oklahoma-city",
        city_id: "4033225",
		icon: OklahomaCity,
		comingSoon: false,
		cityUnchanged: "Oklahoma City",
		description: "Marked by its cowboy culture, Oklahoma City is known for the National Cowboy & Western Heritage Museum and a vibrant arts scene – and although a controversial opinion, our favorite bbq ribs!",
		lat: 35.4676,
		lng: -97.5164
	},
	{
        city: "Portland",
        state: "OR",
		stateFull: "Oregon",
        emoji: "🌲",
        path: "/portland",
        city_id: "4113373",
		icon: Portland,
		comingSoon: false,
		cityUnchanged: "Portland",
		description: "A city of blue hair and gray skies, where the youth has too much time on their hands.",
		lat: 45.5051,
		lng: -122.6750
    },
    {
        city: "Philadelphia",
        state: "PA",
		stateFull: "Pennsylvania",
        emoji: "🧀🥩",
        path: "/philadelphia",
        city_id: "4213271",
		icon: Philadelphia,
		comingSoon: false,
		cityUnchanged: "Philadelphia",
		description: "A historic city, Philadelphia is known for Independence Hall, the Liberty Bell, and as the birthplace of the United States.",
		lat: 39.9526,
		lng: -75.1652
    },
	{
        city: "Pittsburgh",
        state: "PA",
		stateFull: "Pennsylvania",
        emoji: "🏗️",
        path: "/pittsburgh",
        city_id: "4226529",
		icon: Pittsburgh,
		comingSoon: false,
		cityUnchanged: "Pittsburgh",
		description: "Former steel hub turned tech center, Pittsburgh blends industrial history with modern innovation, set against a scenic backdrop of three rivers.",
		lat: 40.4406,
		lng: -79.9959
	},
    {
        city: "Charleston",
        state: "SC",
		stateFull: "South Carolina",
        emoji: "🐚",
        path: "/charleston",
        city_id: "454054",
		icon: Charleston,
		comingSoon: false,
		cityUnchanged: "Charleston",
		description: "With its well-preserved historic charm, Charleston is famous for antebellum architecture, Southern hospitality, and Lowcountry cuisine.",
		lat: 32.7765,
		lng: -79.9311
	},
    {
        city: "Memphis",
        state: "TN",
		stateFull: "Tennessee",
        emoji: "🎸",
        path: "/memphis",
        city_id: "4732811",
		icon: Memphis,
		comingSoon: false,
		cityUnchanged: "Memphis",
		description: "Known for its influential music scene, Memphis is celebrated for Graceland, Beale Street, and its barbecue.",
		lat: 35.1495,
		lng: -90.0490
    },
	{
        city: "Nashville",
        state: "TN",
		stateFull: "Tennessee",
        emoji: "🎙️",
        path: "/nashville",
        city_id: "476118",
		icon: Nashville,
		comingSoon: false,
		cityUnchanged: "Nashville",
		description: "The country music capital, Nashville is famous for its music venues, honky-tonks, and as a center for healthcare.",
		lat: 36.1667,
		lng: -86.7844
    },
	{
        city: "Austin",
        state: "TX",
		stateFull: "Texas",
        emoji: "🎭",
        path: "/austin",
        city_id: "4810221",
		icon: Austin,
		comingSoon: false,
		cityUnchanged: "Austin",
		description: "The CA of TX. Or as leading economist Tim Dillon puts it - the city where failures congregate to eat brisket.",
		lat: 30.2672,
    	lng: -97.7431
    },
	{
        city: "Dallas",
        state: "TX",
		stateFull: "Texas",
        emoji: "⭐️",
        path: "/dallas",
        city_id: "4838128",
		icon: Dallas,
		comingSoon: false,
		cityUnchanged: "Dallas",
		description: "the town of infinite layovers.",
		lat: 32.7784,
		lng: -96.8094
    },
    {
        city: "Houston",
        state: "TX",
		stateFull: "Texas",
        emoji: "🐴",
        path: "/houston",
        city_id: "4839051",
		icon: Houston,
		comingSoon: false,
		cityUnchanged: "Houston",
		description: "A diverse metropolis, Houston is known for its space industry, cultural diversity, and as a culinary hotspot.",
		lat: 29.7604,
		lng: -95.3698
    },
    {
        city: "San Antonio",
        state: "TX",
		stateFull: "Texas",
        emoji: "🌯",
        path: "/san-antonio",
        city_id: "486915",
		icon: SanAntonio,
		comingSoon: false,
		cityUnchanged: "San Antonio",
		description: "there was a battle there once, but you'd never know.",
		lat: 29.4241,
		lng: -98.4936
	},
    {
        city: "Salt Lake City",
        state: "UT",
		stateFull: "Utah",
        emoji: "🧂",
        path: "/salt-lake-city",
        city_id: "496909",
		icon: SaltLakeCity,
		comingSoon: false,
		cityUnchanged: "Salt Lake City",
		description: "Surrounded by mountains, this CULTural hub is known for its salt flats, outdoor recreation, and the Utah Jazz.",
		lat: 40.7608,
		lng: -111.8910
    },
    {
        city: "Richmond",
        state: "VA",
		stateFull: "Virginia",
        emoji: "🛥️",
        path: "/richmond",
        city_id: "516752",
		icon: Richmond,
		comingSoon: false,
		cityUnchanged: "Richmond",
		description: "A city with a rich history, Richmond is known for Civil War sites, the James River, and a burgeoning arts scene.",
		lat: 37.5407,
		lng: -77.4360
	},
    {
        city: "Seattle",
        state: "WA",
		stateFull: "Washington",
        emoji: "☕️",
        path: "/seattle",
        city_id: "5316037",
		icon: Seattle,
		comingSoon: false,
		cityUnchanged: "Seattle",
		description: "Seattle is another dull, gray marble of the Pacific Northwest…and also the reason Joey Diaz can't get a passport.",
		lat: 47.6062,
		lng: -122.3321
    }
];

export const metrosForPurchase = [
	{
		city: "Mobile",
		state: "AL",
		msaTitle: "Daphne-Fairhope-Foley"
	},
	{
		city: "Phoenix",
		state: "AZ",
		msaTitle: "Phoenix-Mesa-Chandler",
		city_id: "0426113"
	},
	{
		city: "Fayetteville",
		state: "AR",
		msaTitle: "Fayetteville-Springdale-Rogers"
	},
	{
		city: "Little Rock",
		state: "AR",
		msaTitle: "Little Rock-North Little Rock-Conway"
	},
	{
		city: "Los Angeles",
		state: "CA",
		msaTitle: "Los Angeles-Long Beach-Anaheim"
	},
	{
		city: "Oxnard",
		state: "CA",
		msaTitle: "Oxnard-Thousand Oaks-Ventura"
	},
	{
		city: "Riverside",
		state: "CA",
		msaTitle: "Riverside-San Bernardino-Ontario"
	},
	{
		city: "Sacramento",
		state: "CA",
		msaTitle: "Sacramento-Roseville-Folsom",
		city_id: "0620288"
	},
	{
		city: "San Diego",
		state: "CA",
		msaTitle: "San Diego-Chula Vista-Carlsbad"
	},
	{
		city: "San Francisco",
		state: "CA",
		msaTitle: "San Francisco-Oakland-Fremont"
	},
	{
		city: "San Jose",
		state: "CA",
		msaTitle: "San Jose-Sunnyvale-Santa Clara"
	},
	{
		city: "Denver",
		state: "CO",
		msaTitle: "Denver-Aurora-Centennial",
		city_id: "0811093",
	},
	{
		city: "Bridgeport",
		state: "CT",
		msaTitle: "Bridgeport-Stamford-Danbury"
	},
	{
		city: "Hartford",
		state: "CT",
		msaTitle: "Hartford-West Hartford-East Hartford"
	},
	{
		city: "Norwich",
		state: "CT",
		msaTitle: "Norwich-New London-Willimantic"
	},
	{
		city: "Philadelphia",
		state: "PA",
		msaTitle: "Philadelphia-Camden-Wilmington",
		city_id: "4213271"
	},
	{
		city: "Crestview",
		state: "FL",
		msaTitle: "Crestview-Fort Walton Beach-Destin"
	},
	{
		city: "Deltona",
		state: "FL",
		msaTitle: "Deltona-Daytona Beach-Ormond Beach"
	},
	{
		city: "Miami",
		state: "FL",
		msaTitle: "Miami-Fort Lauderdale-West Palm Beach",
		city_id: "1212700"
	},
	{
		city: "North Port",
		state: "FL",
		msaTitle: "North Port-Bradenton-Sarasota"
	},
	{
		city: "Orlando",
		state: "FL",
		msaTitle: "Orlando-Kissimmee-Sanford",
		city_id: "1213121"
	},
	{
		city: "Palm Bay",
		state: "FL",
		msaTitle: "Palm Bay-Melbourne-Titusville"
	},
	{
		city: "Tampa",
		state: "FL",
		msaTitle: "Tampa-St. Petersburg-Clearwater",
		city_id: "1241176"
	},
	{
		city: "Atlanta",
		state: "GA",
		msaTitle: "Atlanta-Sandy Springs-Roswell",
		city_id: "1337211"
	},
	{
		city: "Chicago",
		state: "IL",
		msaTitle: "Chicago-Naperville-Elgin",
		city_id: "1717426"
	},
	{
		city: "Indianapolis",
		state: "IN",
		msaTitle: "Indianapolis-Carmel-Greenwood",
		city_id: "1832149"
	},
	{
		city: "Davenport",
		state: "IA",
		msaTitle: "Davenport-Moline-Rock Island"
	},
	{
		city: "Kansas City",
		state: "MO",
		msaTitle: "Kansas City",
		city_id: "2918795"
	},
	{
		city: "Slidell",
		state: "LA",
		msaTitle: "Slidell-Mandeville-Covington"
	},
	{
		city: "Baltimore",
		state: "MD",
		msaTitle: "Baltimore-Columbia-Towson",
		city_id: "243523"
	},
	{
		city: "Washington",
		state: "DC",
		msaTitle: "Washington-Arlington-Alexandria",
		city_id: "1141568"
	},
	{
		city: "Boston",
		state: "MA",
		msaTitle: "Boston-Cambridge-Newton",
		city_id: "2544269"
	},
	{
		city: "Detroit",
		state: "MI",
		msaTitle: "Detroit-Warren-Dearborn",
		city_id: "2617762"
	},
	{
		city: "Grand Rapids",
		state: "MI",
		msaTitle: "Grand Rapids-Wyoming-Kentwood"
	},
	{
		city: "Minneapolis",
		state: "MN",
		msaTitle: "Minneapolis-St. Paul-Bloomington",
		city_id: "275983"
	},
	{
		city: "Las Vegas",
		state: "NV",
		msaTitle: "Las Vegas-Henderson-North Las Vegas",
		city_id: "3218959"
	},
	{
		city: "Albany",
		state: "NY",
		msaTitle: "Albany-Schenectady-Troy"
	},
	{
		city: "Kiryas Joel",
		state: "NY",
		msaTitle: "Kiryas Joel-Poughkeepsie-Newburgh"
	},
	{
		city: "Charlotte",
		state: "NC",
		msaTitle: "Charlotte-Concord-Gastonia",
		city_id: "3724043"
	},
	{
		city: "Hickory",
		state: "NC",
		msaTitle: "Hickory-Lenoir-Morganton"
	},
	{
		city: "Dayton",
		state: "OH",
		msaTitle: "Dayton-Kettering-Beavercreek"
	},
	{
		city: "Portland",
		state: "OR",
		msaTitle: "Portland-Vancouver-Hillsboro",
		city_id: "4113373"
	},
	{
		city: "Greenville",
		state: "SC",
		msaTitle: "Greenville-Anderson-Greer"
	},
	{
		city: "Hilton Head Island",
		state: "SC",
		msaTitle: "Hilton Head Island-Bluffton-Port Royal"
	},
	{
		city: "Myrtle Beach",
		state: "SC",
		msaTitle: "Myrtle Beach-Conway-North Myrtle Beach"
	},
	{
		city: "Nashville",
		state: "TN",
		msaTitle: "Nashville-Davidson-Murfreesboro-Franklin",
		city_id: "476118"
	},
	{
		city: "Austin",
		state: "TX",
		msaTitle: "Austin-Round Rock-San Marcos",
		city_id: "4810221"
	},
	{
		city: "Dallas",
		state: "TX",
		msaTitle: "Dallas-Fort Worth-Arlington",
		city_id: "4838128"
	},
	{
		city: "Houston",
		state: "TX",
		msaTitle: "Houston-Pasadena-The Woodlands",
		city_id: "4839051"
	},
	{
		city: "McAllen",
		state: "TX",
		msaTitle: "McAllen-Edinburg-Mission"
	},
	{
		city: "Provo",
		state: "UT",
		msaTitle: "Provo-Orem-Lehi"
	},
	{
		city: "Blacksburg",
		state: "VA",
		msaTitle: "Blacksburg-Christiansburg-Radford"
	},
	{
		city: "Virginia Beach",
		state: "VA",
		msaTitle: "Virginia Beach-Chesapeake-Norfolk"
	},
	{
		city: "Bremerton",
		state: "WA",
		msaTitle: "Bremerton-Silverdale-Port Orchard"
	},
	{
		city: "Olympia",
		state: "WA",
		msaTitle: "Olympia-Lacey-Tumwater"
	},
	{
		city: "Seattle",
		state: "WA",
		msaTitle: "Seattle-Tacoma-Bellevue",
		city_id: "5316037"
	} 
];
  

export const dripScoreText = "Drip Score leverages AI to rank the best investment properties in a city - all based on your strategy! Just like Airbnb ratings, it ranks properties based on different data points. These include location, potential return (ROI), and value-add opportunities. The higher the Drip Score, the hotter the deal! \n\nTop Picks 🔥 highlight the best properties, helping find your next deal faster!";

export const topPickText = "Top Picks are the properties with the highest Drip Scores.";

export const csvHeaders = [
	"Cash on Cash",
	"Cash-flow",
	"Initial Costs",
	"Price",
	"Down Payment",
	"Interest Rate",
	"Closing Costs",
	"Total Renovation Cost",
	"Rent",
	"Other income",
	"Mortgage payment",
	"HOA fees",
	"Insurance",
	"Property taxes",
	"Vacancy",
	"Management fee",
	"Maintenance",
	"Other costs",
	"Street",
	"City",
	"State",
	"ZIP Code",
	"House Type",
	"Year Built",
	"Sq. Footage",
	"Lot Size",
	"Bedrooms",
	"Bathrooms",
	"Image",
	"Description",
	"Coffee Clozers Report"
]

export const favouritesCategories = [
	{
		type: "",
		options: [
			{
				label: "Show/hide columns",
				value: "empty"
			}
		]
	},
	{
		type: "MAP ZONES",
		options: [
			{
				label: "Neighborhood grade",
				value: "neighbourhood-data"
			}
		]
	},
	{
		type: "PROPERTY DETAILS",
		options: [
			{
				label: "Market",
				value: "market"
			},
			{
				label: "Baths",
				value: "baths"
			},
			{
				label: "Beds",
				value: "beds"
			},
			{
				label: "Cash on cash",
				value: "cash-on-cash"
			},
			{
				label: "City",
				value: "city"
			},
			{
				label: "Property type",
				value: "property-type"
			},
			{
				label: "Square footage",
				value: "sq-footage"
			},
			{
				label: "State",
				value: "state"
			},
			{
				label: "Street",
				value: "street"
			},
			{
				label: "Year built",
				value: "year-built"
			},
			{
				label: "ZIP code",
				value: "zip-code"
			}
		]
	},
	{
		type: "FINANCIALS",
		options: [
			{
				label: "Closing costs",
				value: "closing-costs"
			},
			{
				label: "Down payment",
				value: "down-payment"
			},
			{
				label: "HOA fees",
				value: "hoa"
			},
			{
				label: "Initial costs",
				value: "initial-costs"
			},
			{
				label: "Insurance",
				value: "insurance"
			},
			{
				label: "Interest rate",
				value: "interest-rate"
			},
			// {
			// 	label: "Loan amount",
			// 	value: "loan-amount"
			// },
			// {
			// 	label: "Loan years",
			// 	value: "loan-years"
			// },
			{
				label: "Maintenance",
				value: "maintenance"
			},
			{
				label: "Management fee",
				value: "management-fee"
			},
			{
				label: "Cash-flow",
				value: "profit"
			},
			{
				label: "Mortgage payment",
				value: "mortgage-payment"
			},
			{
				label: "Other costs",
				value: "other-costs"
			},
			{
				label: "Other income",
				value: "other-income"
			},
			{
				label: "Payment months",
				value: "payment-months"
			},
			{
				label: "Price",
				value: "price"
			},
			{
				label: "Price per sqft",
				value: "price-per-sqft"
			},
			{
				label: "Property taxes",
				value: "property-taxes"
			},
			// {
			// 	label: "Renovations & CapEx",
			// 	value: "renovations-capex"
			// },
			{
				label: "Rehab",
				value: "rehab"
			},
			{
				label: "Rent",
				value: "rent"
			},
			{
				label: "Vacancy",
				value: "vacancy"
			}
		]
	}
]

// Gentrification

export const gentrificationtTitles = [
	{
		title: "Little to none",
		value: "gentrification-little-none"
	},
	{
		title: "Early signs",
		value: "gentrification-early-signs"
	},
	{
		title: "In progress",
		value: "gentrification-progress"
	},
	{
		title: "Gentrified",
		value: "gentrified"
	}
];

// ZIP Code

export const neighbourhoodTitles = [
	{
		title: "A Neighborhood",
		subtitle: "probably expensive",
		value: "map-zone-a"
	},
	{
		title: "B Neighborhood",
		subtitle: "nice area",
		value: "map-zone-b"
	},
	{
		title: "B/C Neighborhood",
		subtitle: "up-and-coming",
		value: "map-zone-bc"
	},
	{
		title: "C Neighborhood",
		subtitle: "not awful",
		value: "map-zone-c"
	},
	{
		title: "D Neighborhood",
		subtitle: "lotta problems",
		value: "map-zone-d"
	}
];

// Formatting

export const percentage = new Intl.NumberFormat('en-US', {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 1,
});

export const percentageCompact = new Intl.NumberFormat('en-US', {
	style: 'percent',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const formatterLong = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	notation: 'standard'
});

export const formatterCompact = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	notation: 'compact'
});

export const numberFormatter = new Intl.NumberFormat('en-US');

export const interestPoints = [
	{   
		comingSoon: false,
		icon: Airport,
		label: "Airport",
		value: "airport"
	},
	{
		comingSoon: true,
		icon: "",
		label: "Breweries/Wineries",
		value: "breweries/wineries"
	},
	{
		comingSoon: false,
		icon: Cafe,
		label: "Cafes",
		value: "cafe"
	},
	{
		comingSoon: false,
		icon: Restaurants,
		label: "Fast food",
		value: "fast food"
	},
	{
		comingSoon: false,
		icon: Gyms,
		label: "Gyms",
		value: "gym"
	},
	{   
		comingSoon: false,
		icon: Jobs,
		label: "Jobs/Employment",
		value: "jobs/employment"
	},
	{
		comingSoon: false,
		icon: Restaurants,
		label: "Restaurants & Bars",
		value: "restaurant/bar"
	},
	{
		comingSoon: false,
		icon: Stores,
		label: "Stores",
		value: "store"
	},
	{
		comingSoon: false,
		icon: Supermarkets,
		label: "Supermarkets",
		value: "supermarket"
	},
	{
		comingSoon: false,
		icon: TouristAttractions,
		label: "Tourist Attractions",
		value: "tourist attraction"
	},
	{
		comingSoon: false,
		icon: Transportation,
		label: "Transportation",
		value: "transportation"
	},
	{
		comingSoon: false,
		icon: Universities,
		label: "Universities",
		value: "university"
	},
];

export const renovationRanges = [
	{
		lower: 0,
		upper: 0
	},
	{
		lower: 1000,
		upper: 10000
	},
	{
		lower: 10000,
		upper: 30000
	},
	{
		lower: 30000,
		upper: 0
	}
];

export const stackedListCategory = [
	{
		id: "behind_on_payments_list",
		label: "Delinquent"
	},
	{
		id: "vacant_property_list",
		label: "Vacant"
	},
	{
		id: "owned_by_corp_list",
		label: "Corporate"
	},
	{
		id: "rental_not_corp_owned_list",
		label: "Out of state"
	}
]

export const tutorialVideo = "https://www.youtube.com/watch?v=rGojQ0fM2ZU";

export const nonDisclosureStates = [
	"AK", 
	"ID", 
	"KS", 
	"ME", 
	"MS", 
	"MO", 
	"MT", 
	"ND", 
	"TX", 
	"UT", 
	"WY"
];

export const offMarketFilterLabels = [
	{
		label: "🫥 Absentee owner",
		value: "absentee-owner"
	},
	{
		label: "🪧 Auction",
		value: "auction"
	},
	{
		label: "🧾 Free & Clear",
		value: "free-clear"
	},
	{
		label: "🐷🏦 High Equity",
		value: "high-equity"
	},
	{
		label: "🫴 Inherited",
		value: "inherited"
	},
	{
		label: "😶‍🌫️ In-State Absentee owner",
		value: "in-state-absentee-owner"
	},
	{
		label: "🤝 Lien",
		value: "lien"
	},
	{
		label: "💰 Multiple Mortgages",
		value: "multiple-mortgages"
	},
	{
		label: "🧓🏠 Owner Occupied",
		value: "owner-occupied"
	},
	{
		label: "📉 Pre-Foreclosure",
		value: "pre-foreclosure"
	},
	{
		label: "🕊️ Recent death",
		value: "recent-death"
	},
	{
		label: "💍🕊️ Spousal Death",
		value: "spousal-death"
	},
	{
		label: "🗂️🤝 Tax Lien",
		value: "tax-lien"
	},
	{
		label: "🏚️ Vacant",
		value: "vacant"
	}
];

export const propertyStatuses = [
	{
		value: "active",
		label: "Active",
		bgColour: "bg-colour-active-status"
	},
	{
		value: "sold",
		label: "Sold",
		bgColour: "bg-colour-sold-status"
	},
	{
		value: "under-contract",
		label: "Under contract",
		bgColour: "bg-colour-under-contract-status"
	},
	{
		value: "archive",
		label: "Archive",
		bgColour: "bg-colour-archive-status"
	}
];

export const allStates = [
	{ abbreviation: "AL", name: "Alabama" },
	{ abbreviation: "AK", name: "Alaska" },
	{ abbreviation: "AZ", name: "Arizona" },
	{ abbreviation: "AR", name: "Arkansas" },
	{ abbreviation: "CA", name: "California" },
	{ abbreviation: "CO", name: "Colorado" },
	{ abbreviation: "CT", name: "Connecticut" },
	{ abbreviation: "D.C.", name: "District of Columbia" },
	{ abbreviation: "DC", name: "District of Columbia" },
	{ abbreviation: "DE", name: "Delaware" },
	{ abbreviation: "FL", name: "Florida" },
	{ abbreviation: "GA", name: "Georgia" },
	{ abbreviation: "HI", name: "Hawaii" },
	{ abbreviation: "ID", name: "Idaho" },
	{ abbreviation: "IL", name: "Illinois" },
	{ abbreviation: "IN", name: "Indiana" },
	{ abbreviation: "IA", name: "Iowa" },
	{ abbreviation: "KS", name: "Kansas" },
	{ abbreviation: "KY", name: "Kentucky" },
	{ abbreviation: "LA", name: "Louisiana" },
	{ abbreviation: "ME", name: "Maine" },
	{ abbreviation: "MD", name: "Maryland" },
	{ abbreviation: "MA", name: "Massachusetts" },
	{ abbreviation: "MI", name: "Michigan" },
	{ abbreviation: "MN", name: "Minnesota" },
	{ abbreviation: "MS", name: "Mississippi" },
	{ abbreviation: "MO", name: "Missouri" },
	{ abbreviation: "MT", name: "Montana" },
	{ abbreviation: "NE", name: "Nebraska" },
	{ abbreviation: "NV", name: "Nevada" },
	{ abbreviation: "NH", name: "New Hampshire" },
	{ abbreviation: "NJ", name: "New Jersey" },
	{ abbreviation: "NM", name: "New Mexico" },
	{ abbreviation: "NY", name: "New York" },
	{ abbreviation: "NC", name: "North Carolina" },
	{ abbreviation: "ND", name: "North Dakota" },
	{ abbreviation: "OH", name: "Ohio" },
	{ abbreviation: "OK", name: "Oklahoma" },
	{ abbreviation: "OR", name: "Oregon" },
	{ abbreviation: "PA", name: "Pennsylvania" },
	{ abbreviation: "RI", name: "Rhode Island" },
	{ abbreviation: "SC", name: "South Carolina" },
	{ abbreviation: "SD", name: "South Dakota" },
	{ abbreviation: "TN", name: "Tennessee" },
	{ abbreviation: "TX", name: "Texas" },
	{ abbreviation: "UT", name: "Utah" },
	{ abbreviation: "VT", name: "Vermont" },
	{ abbreviation: "VA", name: "Virginia" },
	{ abbreviation: "WA", name: "Washington" },
	{ abbreviation: "WV", name: "West Virginia" },
	{ abbreviation: "WI", name: "Wisconsin" },
	{ abbreviation: "WY", name: "Wyoming" }
];

export const neighbourhoodValues = [
	{
		grade: "D",
		label: "D Neighborhood",
		description: "🧨 Hood"
	},
	{
		grade: "C-",
		label: "C- Neighborhood",
		description: "😰 Hoodish"
	},
	{
		grade: "C",
		label: "C Neighborhood",
		description: "🏚️ Potential hood"
	},
	{
		grade: "C+",
		label: "C+ Neighborhood",
		description: "🧑‍🔧 Some bad tenants, some older homes"
	},
	{
		grade: "B-",
		label: "B- Neighborhood",
		description: "👨‍👩‍👧‍👦 Good tenants, maintained homes"
	},
	{
		grade: "B",
		label: "B Neighborhood",
		description: "📈 Stable"
	},
	{
		grade: "B+",
		label: "B+ Neighborhood",
		description: "👀 Peaking into luxury"
	},
	{
		grade: "A",
		label: "A Neighborhood",
		description: "💎 Luxury"
	}
];

export const neighbourhoodColours = [
	"#FCE724B3",
	"#A0D839B3",
	"#4CC16BB3",
	"#21A186B3",
	"#277D8EB3",
	"#375B8CB3",
	"#45317DB3",
	"#440154B3",
];

export const neighbourhoodDataOptions = [
	{
		label: "Neighborhood grade",
		location: "",
		selected: false
	},
	{
		label: "Median household income",
		location: "economics",
		subLocation: "medianHouseholdIncome",
		selected: false
	},
	{
		label: "Education (graduate degree)",
		location: "economics",
		subLocation: "ratioEducationGraduateDegree",
		selected: false
	},
	{
		label: "Employed",
		location: "economics",
		subLocation: "ratioEmployed",
		selected: false
	},
	{
		label: "Owner occupied",
		location: "economics",
		subLocation: "ratioOwnerOccupied",
		selected: false
	},
	{
		label: "Vacant",
		location: "economics",
		subLocation: "ratioVacant",
		selected: false
	},
	{
		label: "Total population",
		location: "economics",
		subLocation: "totalPopulation",
		selected: false
	},
	{
		label: "Median days on market",
		location: "market",
		subLocation: "medianDaysOnMarket",
		selected: false
	},
	{
		label: "Median list price",
		location: "market",
		subLocation: "medianListPrice",
		selected: false
	},
	{
		label: "Median living area",
		location: "market",
		subLocation: "medianLivingArea",
		selected: false
	},
	{
		label: "Median rent",
		location: "market",
		subLocation: "medianRent",
		selected: false
	},
	{
		label: "Number of listings",
		location: "market",
		subLocation: "numberOfListings",
		selected: false
	},
	{
		label: "Median ARV spread",
		location: "metrics",
		subLocation: "medianArvSpread",
		selected: false
	},
	{
		label: "Median cash on cash",
		location: "metrics",
		subLocation: "medianCashOnCash",
		selected: false
	},
	{
		label: "Median price per sq. foot",
		location: "metrics",
		subLocation: "medianPricePerSqft",
		selected: false
	},
	{
		label: "Price to rent ratio",
		location: "metrics",
		subLocation: "priceToRentRatio",
		selected: false
	},
	{
		label: "Median bathrooms",
		location: "propAttr",
		subLocation: "medianBathrooms",
		selected: false
	},
	{
		label: "Median bedrooms",
		location: "propAttr",
		subLocation: "medianBedrooms",
		selected: false
	},
	{
		label: "Median sq. feet",
		location: "propAttr",
		subLocation: "medianSqft",
		selected: false
	},
	{
		label: "Median year",
		location: "propAttr",
		subLocation: "medianYear",
		selected: false
	},
	{
		label: "Recent new build growth",
		location: "region",
		subLocation: "recentNewBuildGrowth",
		selected: false
	}
];

export const defaultFilters = {
	adu: false,
	bathrooms: 1,
	bedrooms: 1,
	budgetRange: [0, 1000000000],
	constructionYears: [1800, 2025],
	squareFootage: [0, 1000000],
	units: 1,
	propTypeMislabelled: false,
	sellerFinancing: false,
	priceReduction: false,
	extraBedroom: false,
	propertyTypes: [
		{
			code: "MultiFamily",
			included: true,
			property: "Multi-Family",
			abbreviation: "MFH"
		},
		{
			code: "SingleFamily",
			included: true,
			property: "Single-Family",
			abbreviation: "SFH"
		}
	],
	hideFixerUppers: false,
	onlyFixerUppers: false,
	priceReductionIndex: 0,
	neighbourhoodGradeMin: 1,
	neighbourhoodGradeMax: 8,
	fsbo: 0
};

export const stateMetros = [
	{
	  stateFull: "Alabama",
	  state: "AL",
	  metros: [
		{
			msaTitle: "Daphne-Fairhope-Foley",
			cityNames: [
				"Daphne",
				"Fairhope",
				"Gulf Shores",
				"Foley"
			],
			state: "AL"
		}
	  ]
	},
	{
	  stateFull: "Arizona",
	  state: "AZ",
	  metros: [
		{
			msaTitle: "Phoenix-Mesa-Chandler",
			cityNames: [
				"Phoenix",
				"Mesa",
				"Chandler",
				"Casa Grande",
				"Scottsdale",
				"Tempe",
				"Gilbert",
			],
			state: "AZ"
		}
	  ]
	},
	{
	  stateFull: "Arkansas",
	  state: "AR",
	  metros: [
		{
			msaTitle: "Fayetteville-Springdale-Rogers",
			cityNames: [
				"Fayetteville",
				"Rogers",
				"Bentonville",
				"Springdale"
			],
			state: "AR"
		},
		{
			msaTitle: "Little Rock-North Little Rock-Conway",
			cityNames: [
				"Little Rock",
				"North Little Rock",
				"Conway"
			],
			state: "AR"
		}
	  ]
	},
	{
	  stateFull: "California",
	  state: "CA",
	  metros: [
		{
			msaTitle: "Los Angeles-Long Beach-Anaheim",
			cityNames: [
				"Newport Beach",
				"Long Beach",
				"Arcadia",
				"Torrance",
				"Orange",
				"Santa Ana",
				"Pasadena",
				"Glendale",
				"Los Angeles",
				"Burbank",
				"Costa Mesa",
				"Anaheim",
				"Cypress",
				"Gardena",
				"Irvine",
				"Tustin",
				"Fountain Valley",
				"Carson",
				"Santa Monica"
			],
			state: "CA"
		},
		{
			msaTitle: "Oxnard-Thousand Oaks-Ventura",
			cityNames: [
				"Oxnard",
				"Thousand Oaks",
				"San Buenaventura (Ventura)",
				"Camarillo",
			],
			state: "CA"
		},
		{
			msaTitle: "Riverside-San Bernardino-Ontario",
			cityNames: [
				"Ontario",
				"Chino",
				"Redlands",
				"Palm Desert",
				"San Bernardino",
				"Riverside",
				"Temecula",
				"Corona"
			],
			state: "CA"
		},
		{
			msaTitle: "Sacramento-Roseville-Folsom",
			cityNames: [
				"Sacramento",
				"Roseville",
				"Folsom",
				"West Sacramento",
				"Rancho Cordova",
			],
			state: "CA"
		},
		{
			msaTitle: "San Diego-Chula Vista-Carlsbad",
			cityNames: [
				"San Diego",
				"Chula Vista",
				"Carlsbad",
				"Encinitas",
			],
			state: "CA"
		},
		{
			msaTitle: "San Francisco-Oakland-Fremont",
			cityNames: [
				"Redwood City",
				"Livermore",
				"San Rafael",
				"Fremont",
				"San Francisco",
				"Berkeley",
				"Walnut Creek",
				"San Ramon",
				"Pleasanton",
				"Oakland",
				"South San Francisco",
				"San Mateo"
			],
			state: "CA"
		},
		{
			msaTitle: "San Jose-Sunnyvale-Santa Clara",
			cityNames: [
				"Milpitas",
				"Mountain View",
				"San Jose",
				"Palo Alto",
				"Sunnyvale",
				"Santa Clara",
				"Cupertino"
			],
			state: "CA"
		}
	  ]
	},
	{
	  stateFull: "Colorado",
	  state: "CO",
	  metros: [
		{
			msaTitle: "Denver-Aurora-Centennial",
			cityNames: [
				"Denver",
				"Commerce City",
				"Aurora",
				"Broomfield",
				"Centennial"
			],
			state: "CO"
		}
	  ]
	},
	{
	  stateFull: "Connecticut",
	  state: "CT",
	  metros: [
		{
			msaTitle: "Bridgeport-Stamford-Danbury",
			cityNames: [
				"Danbury",
				"Bridgeport",
				"Stamford",
			],
			state: "CT"
		},
		{
			msaTitle: "Hartford-West Hartford-East Hartford",
			cityNames: [
				"Hartford",
				"East Hartford",
				"West Hartford",
				"Middletown",
			],
			state: "CT"
		},
		{
			msaTitle: "Norwich-New London-Willimantic",
			cityNames: [
				"Norwich",
				"New London",
				"Willimantic",
			],
			state: "CT"
		}
	  ]
	},
	{
	  stateFull: "Delaware",
	  state: "DE",
	  metros: [
		{
			msaTitle: "Philadelphia-Camden-Wilmington",
			cityNames: [
				"Philadelphia",
				"Camden",
				"Wilmington",
			],
			state: "PA-NJ-DE-MD"
		}
	  ]
	},
	{
	  stateFull: "Florida",
	  state: "FL",
	  metros: [
		{
			msaTitle: "Crestview-Fort Walton Beach-Destin",
			cityNames: [

			],
			state: "FL"
		},
		{
			msaTitle: "Deltona-Daytona Beach-Ormond Beach",
			cityNames: [],
			state: "FL"
		},
		{
			msaTitle: "Miami-Fort Lauderdale-West Palm Beach",
			cityNames: [],
			state: "FL"
		},
		{
			msaTitle: "North Port-Bradenton-Sarasota",
			cityNames: [],
			state: "FL"
		},
		{
			msaTitle: "Orlando-Kissimmee-Sanford",
			cityNames: [],
			state: "FL"
		},
		{
			msaTitle: "Palm Bay-Melbourne-Titusville",
			cityNames: [],
			state: "FL"
		},
		{
			msaTitle: "Tampa-St. Petersburg-Clearwater",
			cityNames: [],
			state: "FL"
		}
	  ]
	},
	{
	  stateFull: "Georgia",
	  state: "GA",
	  metros: [
		{
			msaTitle: "Atlanta-Sandy Springs-Roswell",
			cityNames: [],
			state: "GA"
		}
	  ]
	},
	{
	  stateFull: "Illinois",
	  state: "IL",
	  metros: [
		{
			msaTitle: "Chicago-Naperville-Elgin",
			cityNames: [],
			state: "IL-IN"
		},
		{
			msaTitle: "Davenport-Moline-Rock Island",
			cityNames: [],
			state: "IA-IL"
		}
	  ]
	},
	{
	  stateFull: "Indiana",
	  state: "IN",
	  metros: [
		{
			msaTitle: "Indianapolis-Carmel-Greenwood",
			cityNames: [],
			state: "IN"
		}
	  ]
	},
	{
	  stateFull: "Iowa",
	  state: "IA",
	  metros: [
		{
			msaTitle: "Davenport-Moline-Rock Island",
			cityNames: [],
			state: "IA-IL"
		}
	  ]
	},
	{
	  stateFull: "Kansas",
	  state: "KS",
	  metros: [
		{
			msaTitle: "Kansas City",
			cityNames: [],
			state: "MO-KS"
		}
	  ]
	},
	{
	  stateFull: "Louisiana",
	  state: "LA",
	  metros: [
		{
			msaTitle: "Slidell-Mandeville-Covington",
			cityNames: [],
			state: "LA"
		}
	  ]
	},
	{
	  stateFull: "Maryland",
	  state: "MD",
	  metros: [
		{
			msaTitle: "Baltimore-Columbia-Towson",
			cityNames: [],
			state: "MD"
		},
		{
			msaTitle: "Washington-Arlington-Alexandria",
			cityNames: [],
			state: "DC-VA-MD-WV"
		}
	  ]
	},
	{
	  stateFull: "Massachusetts",
	  state: "MA",
	  metros: [
		{
			msaTitle: "Boston-Cambridge-Newton",
			cityNames: [],
			state: "MA-NH"
		}
	  ]
	},
	{
	  stateFull: "Michigan",
	  state: "MI",
	  metros: [
		{
			msaTitle: "Detroit-Warren-Dearborn",
			cityNames: [],
			state: "MI"
		},
		{
			msaTitle: "Grand Rapids-Wyoming-Kentwood",
			cityNames: [],
			state: "MI"
		}
	  ]
	},
	{
	  stateFull: "Minnesota",
	  state: "MN",
	  metros: [
		{
			msaTitle: "Minneapolis-St. Paul-Bloomington",
			cityNames: [],
			state: "MN-WI"
		}
	  ]
	},
	{
	  stateFull: "Missouri",
	  state: "MO",
	  metros: [
		{
			msaTitle: "Kansas City",
			cityNames: [],
			state: "MO-KS"
		}
	  ]
	},
	{
	  stateFull: "Nevada",
	  state: "NV",
	  metros: [
		{
			msaTitle: "Las Vegas-Henderson-North Las Vegas",
			cityNames: [],
			state: "NV"
		}
	  ]
	},
	{
	  stateFull: "New York",
	  state: "NY",
	  metros: [
		{
			msaTitle: "Albany-Schenectady-Troy",
			cityNames: [],
			state: "NY"
		},
		{
			msaTitle: "Kiryas Joel-Poughkeepsie-Newburgh",
			cityNames: [],
			state: "NY"
		}
	  ]
	},
	{
	  stateFull: "North Carolina",
	  state: "NC",
	  metros: [
		{
			msaTitle: "Charlotte-Concord-Gastonia",
			cityNames: [],
			state: "NC-SC"
		},
		{
			msaTitle: "Hickory-Lenoir-Morganton",
			cityNames: [],
			state: "NC"
		},
		{
			msaTitle: "Raleigh-Durham-Cary",
			cityNames: [],
			state: "NC"
		}
	  ]
	},
	{
	  stateFull: "Ohio",
	  state: "OH",
	  metros: [
		{
			msaTitle: "Dayton-Kettering-Beavercreek",
			cityNames: [],
			state: "OH"
		}
	  ]
	},
	{
	  stateFull: "Oregon",
	  state: "OR",
	  metros: [
		{
			msaTitle: "Portland-Vancouver-Hillsboro",
			cityNames: [],
			state: "OR-WA"
		}
	  ]
	},
	{
	  stateFull: "Pennsylvania",
	  state: "PA",
	  metros: [
		{
			msaTitle: "Philadelphia-Camden-Wilmington",
			cityNames: [
				"Philadelphia",
				"Camden",
				"Wilmington",
			],
			state: "PA-NJ-DE-MD"
		}
	  ]
	},
	{
	  stateFull: "South Carolina",
	  state: "SC",
	  metros: [
		{
			msaTitle: "Greenville-Anderson-Greer",
			cityNames: [],
			state: "SC"
		},
		{
			msaTitle: "Hilton Head Island-Bluffton-Port Royal",
			cityNames: [],
			state: "SC"
		},
		{
			msaTitle: "Myrtle Beach-Conway-North Myrtle Beach",
			cityNames: [],
			state: "SC"
		}
	  ]
	},
	{
	  stateFull: "Tennessee",
	  state: "TN",
	  metros: [
		{
			msaTitle: "Nashville-Davidson--Murfreesboro--Franklin",
			cityNames: [],
			state: "TN"
		}
	  ]
	},
	{
		stateFull: "Texas",
		state: "TX",
		metros: [
			{
				msaTitle: "Austin-Round Rock-San Marcos",
				cityNames: [],
				state: "TX"
			},
			{
				msaTitle: "Dallas-Fort Worth-Arlington",
				cityNames: [],
				state: "TX"
			},
			{
				msaTitle: "Houston-Pasadena-The Woodlands",
				cityNames: [],
				state: "TX"
			},
			{
				msaTitle: "McAllen-Edinburg-Mission",
				cityNames: [],
				state: "TX"
			}
		]
	},
	{
		stateFull: "Utah",
		state: "UT",
		metros: [
			{
				msaTitle: "Provo-Orem-Lehi",
				cityNames: [],
				state: "UT"
			}
		]
	},
	{
		stateFull: "Virginia",
		state: "VA",
		metros: [
			{
				msaTitle: "Blacksburg-Christiansburg-Radford",
				cityNames: [],
				state: "VA"
			},
			{
				msaTitle: "Virginia Beach-Chesapeake-Norfolk",
				cityNames: [],
				state: "VA-NC"
			},
			{
				msaTitle: "Washington-Arlington-Alexandria",
				cityNames: [],
				state: "DC-VA-MD-WV"
			}
		]
	},
	{
		stateFull: "Washington",
		state: "WA",
		metros: [
			{
				msaTitle: "Bremerton-Silverdale-Port Orchard",
				cityNames: [],
				state: "WA"
			},
			{
				msaTitle: "Olympia-Lacey-Tumwater",
				cityNames: [],
				state: "WA"
			},
			{
				msaTitle: "Portland-Vancouver-Hillsboro",
				cityNames: [],
				state: "OR-WA"
			},
			{
				msaTitle: "Seattle-Tacoma-Bellevue",
				cityNames: [],
				state: "WA"
			}
		]
	},
	{
		stateFull: "West Virginia",
		state: "WV",
		metros: [
			{
				msaTitle: "Washington-Arlington-Alexandria",
				cityNames: [],
				state: "DC-VA-MD-WV"
			}
		]
	}
];

export const defaultBuyBoxFilters = {
	acquisitionStrategy: "m",
	budgetRange: defaultVals.budgetRange,
	propertyTypes: [
        {
			label: "Single-Family",
			icon: null,
			body: null,
			included: true,
			standardIcon: false,
			code: "SingleFamily",
			abbreviation: "SFH"
		},
		{
			label: "Multi-Family",
			icon: null,
			body: null,
			included: true,
			standardIcon: false,
			code: "MultiFamily",
			abbreviation: "MFH"
		}
    ],
	
	units: defaultVals.units,
	unitExactMatch: defaultVals.unitExactMatch,
	bedrooms: defaultVals.bedrooms,
	bedroomExactMatch: defaultVals.bedroomExactMatch,
	bathrooms: defaultVals.bathrooms,
	bathroomExactMatch: defaultVals.bathroomExactMatch,
	squareFootage: defaultVals.squareFootage,
	constructionYears: defaultVals.constructionYears,
	condition: defaultVals.conditionValues,
	creative: defaultVals.creativeFinanceType,
	listingType: defaultVals.listingType,
	daysOnMarket: defaultVals.daysOnMarket,
	backOnMarket: defaultVals.backOnMarket,
	recentPriceReduction: defaultVals.priceReduction,
	neighbourhoodGrades: defaultVals.neighbourhoodGrades,
	zipcodes: defaultVals.zipcodes,
	hideFloodzone: defaultVals.hideFloodzone,
	hideHOA: defaultVals.hideHOA,
	onlyADU: defaultVals.onlyADU,
	onlyExtraBedroom: defaultVals.onlyExtraBedroom,
	oversizedLot: defaultVals.oversizedLot,
	ownerType: defaultVals.ownerType,
	ownershipLength: defaultVals.ownershipLength,
	distressFlags: defaultVals.distressFlags,
	estimatedInterest: defaultVals.estimatedInterest,
	equityLevel: defaultVals.equityLevel,
	mortgageType: defaultVals.mortgageType,
	minCoCR: defaultVals.cocr
}