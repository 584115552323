import React, { useEffect, useState, useRef } from 'react';
import '../styles/Pay.css';
import { Header, Footer, LoadingStatic, CreditCard, SetupForm, Loading, DiscountCode, CountyPricingCard, CountyMobilePricingOverlay } from '../components';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { colour, fontFamily, formatterLong } from '../styles/GlobalStyles';
import { Button, Modal } from '../v4/components';
import { checkOnAuthStateChanged, getDocument, getSetupIntent, getStripeCustomer, recordEvent, setData, createStripeSetupIntent, deleteStripePaymentMethod, writeSubscriptionData, payAutoBilling, submitPayment, updateStripeCustomer, checkStripeError, addToCircle, defaultPricingStateYearly, defaultPricingStateMonthly } from '../functions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TagManager from 'react-gtm-module';
import { Chevron } from '../assets/icons';

const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") ? "test" : "live";
const stripePromise = loadStripe(mode === "test" ? JSON.parse(process.env.REACT_APP_STRIPE).test.publishable : JSON.parse(process.env.REACT_APP_STRIPE).live.publishable);

function Pay() {
    const [searchParams, setSearchParams] = useSearchParams({});
    const getSubscriptionType = searchParams.get('annual');
    const [annualPlan] = useState(getSubscriptionType === null ? null : JSON.parse(getSubscriptionType));
    const defaultPlan = annualPlan === null ? null : annualPlan === true ? defaultPricingStateYearly : defaultPricingStateMonthly;
    const location = useLocation();
    const [state, setState] = useState(location.state === null && defaultPlan === null ? null : location.state !== null ? location.state : defaultPlan);
    const subscriptionId = state === null ? null : state.subscriptionId;
    const plan = state === null ? null : state.plan;
    const [mobileAccordion, setMobileAccordion] = useState(false);
    const [userData, setUserData] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [paymentError, setPaymentError] = useState("");
    const [loading, setLoading] = useState(false);
    const stateSearchParams = searchParams.get('stateId');
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [discountCodes, setDiscountCodes] = useState([]);
    const getDiscountcode = searchParams.get('code');
    const [discountCode, setDiscountCode] = useState(getDiscountcode === null ? "" : getDiscountcode);
    const [discountCodeDisabled, setDiscountCodeDisabled] = useState(true);
    const [discountError, setDiscountError] = useState("");
    const [discountSuccess, setDiscountSuccess] = useState("");
    const [discountApplied, setDiscountApplied] = useState(null);
    const [promoDisabled, setPromoDisabled] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [userId, setUserId] = useState("");
    const [firstRender, setFirstRender] = useState(false);
    const autoBilling = state === null ? false : plan.months === 1 ? true : false;
    const creditCardButton = useRef(null);
    const navigate = useNavigate();

    const options = {
        clientSecret: clientSecret,
        fonts: [
            {
                cssSrc: `https://fonts.googleapis.com/css?family=${fontFamily}`
            }
        ],
        appearance: {
            theme: "stripe",
            variables: {
                fontFamily: fontFamily,
                colorBackground: colour.grayScaleWhite,
                colorText: colour.textPrimary,
                colorDanger: colour.redRed01,
                borderRadius: '3px',
                fontSizeBase: '14px',
                colorPrimary: colour.brownBrown01
            }
        }
    };

    useEffect(() => {
        document.title = "Checkout | Coffee Clozers";

        const submitDiscountCodeEffect = (newCodes, newSubscriptions) => {
            setDiscountCodeDisabled(true);
            const codeIndex = newCodes.findIndex(e => e.label === discountCode);
    
            if ( codeIndex !== -1 ) {
                const relevantProducts = mode === "test" ? newCodes[codeIndex].testProducts : newCodes[codeIndex].products;
                const productIndex = relevantProducts.indexOf(subscriptionId);
    
                for (let index = 0; index < newSubscriptions.length; index++) {
                    const element = newSubscriptions[index];
                    if ( element.discountApplied !== undefined && element.discountApplied !== null ) {
                        const pastAppliedDiscountLabel = element.discountApplied.label;
                        if ( pastAppliedDiscountLabel === discountCode ) {
                            setDiscountError("You have already used this discount code in the past");
                            setDiscountSuccess("");
                            setDiscountCodeDisabled(false);
                            return;
                        }
                    }
                }
    
                const chosenDiscountCode = newCodes[codeIndex];
                const todaySeconds = new Date().getTime() / 1000;
                if ( chosenDiscountCode.expiration.seconds < todaySeconds ) {
                    setDiscountError(`This discount code has expired`);
                    setDiscountSuccess("");
                    setDiscountCodeDisabled(false);
                    return;
                }
    
                if ( productIndex !== -1 ) {
                    setDiscountError("");
                    setDiscountSuccess("Discount code applied successfully!");
                    setDiscountApplied(newCodes[codeIndex]);
                    setDiscountCode("");

                    recordEvent("Affiliate Checkout View", {
                        affiliate: newCodes[codeIndex].affiliateName,
                        code: discountCode,
                        annual: annualPlan,
                        mode: mode
                    });
                }
                else {
                    setDiscountError(newCodes[codeIndex].planError);
                    setDiscountSuccess("");
                    setDiscountCodeDisabled(false);
                }
            }
            else {
                setDiscountError("This discount code is invalid. Please try again.");
                setDiscountSuccess("");
                setDiscountCodeDisabled(false);
            }
        };

        const fetchUserData = async () => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const colRef = "Users";
                const docRef = user.userId;
                setUserId(docRef);
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const userDetails = queryData.data.data;

                    if ( userDetails.creditCard === undefined ) {
                        setupIntent(userDetails, docRef);
                    }
                }

                const subColRef = "Subscriptions";
                const subDocRef = user.userId;
                const subQueryData = await getDocument(subColRef, subDocRef);
                if ( subQueryData.status === 200 ) {
                    setSubscriptions(subQueryData.data.data);
                    setUserData(queryData.data.data);

                    getDiscountCodes(subQueryData.data.data);
                }
                else {
                    setUserData(queryData.data.data);
                    getDiscountCodes([]);
                }
                setPromoDisabled(false);
                recordEvent("Checkout View", {
                    mode: mode
                });
            }
            else {
                if ( annualPlan !== null && discountCode !== "" ) {
                    state.route = `/pay?code=${discountCode}&annual=${annualPlan}`;
                    recordEvent("Affiliate Checkout View Un-Authenticated", {
                        code: discountCode,
                        annual: annualPlan,
                        mode: mode
                    });
                }
                navigate("/sign-up", {
                    state: state
                });
            }
        };

        const setupIntent = async(userDetails, userId) => {
            const getSetupIntentParams = searchParams.get('setup_intent');
            if ( clientSecret !== "" ) {
                return;
            }
            else if ( getSetupIntentParams !== null && userDetails.creditCard === undefined ) {
                const card = await getSetupIntent(getSetupIntentParams, mode);
                if ( card !== "" ) {
                    const amendedCard = {
                        brand: card.brand,
                        last4: card.last4,
                        exp_month: card.exp_month,
                        exp_year: card.exp_year,
                        mode: card.mode,
                        paymentMethod: card.paymentMethod
                    };
                    const colRef = "Users";
                    const newUserDetails = structuredClone(userDetails);
                    newUserDetails.creditCard = amendedCard;
                    await setData(colRef, userId, newUserDetails);
                    setUserData(newUserDetails);
                }
            }
            else {
                let customerId = userDetails.customerId;
                if ( customerId === undefined ) {
                    const customer = {
                        email: userDetails.email,
                        name: `${userDetails.firstName} ${userDetails.lastName}`,
                        phone: userDetails.phoneNumber !== undefined ? userDetails.phoneNumber : "",
                        mode: mode
                    };
                    const getCustomer = await getStripeCustomer(customer);
                    customerId = getCustomer.customerId;
                    const colRef = "Users";
                    const docRef = userId;
                    const data = userDetails;
                    data.customerId = customerId;
                    setUserData(data);
                    await setData(colRef, docRef, data);
                }
                const params = {
                    customerId: customerId,
                    mode: mode
                };
                const getIntent = await createStripeSetupIntent(params);
                if ( getIntent.status === 200 ) {
                    const id = getIntent.setupIntent.client_secret;
                    setClientSecret(id);
                }
            }
        };

        const getDiscountCodes = async(newSubscriptions) => {
            const colRef = "Discount Codes";
            const docRef = "Codes";
            const queryData = await getDocument(colRef, docRef);
            if ( queryData.status === 200 ) {
                const data = queryData.data.data;
                setDiscountCodes(data);
                if ( discountCode !== "" ) {
                    submitDiscountCodeEffect(data, newSubscriptions);
                }
            }
        };

        const querySavedState = async() => {
            const getStateColRef = "Page State";
            const queryState = await getDocument(getStateColRef, stateSearchParams);
            if ( queryState.status === 200 ) {
                const stateData = queryState.data.data;
                setState(stateData);
                setSearchParams({});
                fetchUserData();
            }
            else if ( state === null ) {
                navigate("/home");
            }
        };

        if ( state === null && stateSearchParams !== null ) {
            querySavedState();
        }
        else if ( state === null || state.plan === undefined ) {
            navigate("/home", {
                state: state
            });
        }
        else if ( firstRender === true ) {

        }
        else {
            setFirstRender(true);
            fetchUserData();
        }

    }, [
        annualPlan,
        clientSecret,
        discountCode,
        discountCodes,
        firstRender,
        navigate,
        state,
        searchParams,
        setSearchParams,
        stateSearchParams,
        subscriptionId,
        subscriptions
    ]);

    const showDeleteModal = () => {
        recordEvent("Show Delete Card Modal", {
            mode: mode
        });
        setDeleteModal(true);
    };

    const goBack = () => {
        navigate("/pricing");
    };

    const toggleAccordion = () => {
        setMobileAccordion(!mobileAccordion);
    };

    const changeDiscountCode = (text) => {
        setDiscountCode(text);

        if ( text === "" ) {
            setDiscountCodeDisabled(true);
        }
        else {
            setDiscountCodeDisabled(false);
        }
    };

    const removeDiscountCode = () => {
        setDiscountCode("");
        setDiscountError("");
        setDiscountSuccess("");
        setDiscountApplied(null);
    };

    const submitDiscountCode = () => {
        setDiscountCodeDisabled(true);
        const codeIndex = discountCodes.findIndex(e => e.label === discountCode);

        if ( codeIndex !== -1 ) {
            const relevantProducts = mode === "test" ? discountCodes[codeIndex].testProducts : discountCodes[codeIndex].products;
            const productIndex = relevantProducts.indexOf(subscriptionId);

            for (let index = 0; index < subscriptions.length; index++) {
                const element = subscriptions[index];
                if ( element.discountApplied !== undefined && element.discountApplied !== null ) {
                    const pastAppliedDiscountLabel = element.discountApplied.label;
                    if ( pastAppliedDiscountLabel === discountCode ) {
                        setDiscountError("You have already used this discount code in the past");
                        setDiscountSuccess("");
                        setDiscountCodeDisabled(false);
                        return;
                    }
                }
            }

            const chosenDiscountCode = discountCodes[codeIndex];
            const todaySeconds = new Date().getTime() / 1000;
            if ( chosenDiscountCode.expiration.seconds < todaySeconds ) {
                setDiscountError(`This discount code has expired`);
                setDiscountSuccess("");
                setDiscountCodeDisabled(false);
                return;
            }

            if ( productIndex !== -1 ) {
                setDiscountError("");
                setDiscountSuccess("Discount code applied successfully!");
                setDiscountApplied(discountCodes[codeIndex]);
                setDiscountCode("");

                recordEvent("Affiliate Checkout View", {
                    affiliate: discountCodes[codeIndex].affiliateName,
                    annual: plan.months === 12 ? true : false,
                    mode: mode,
                    code: discountCode
                });
            }
            else {
                setDiscountError(discountCodes[codeIndex].planError);
                setDiscountSuccess("");
                setDiscountCodeDisabled(false);
            }
        }
        else {
            setDiscountError("This discount code is invalid. Please try again.");
            setDiscountSuccess("");
            setDiscountCodeDisabled(false);
        }
    };

    const closeDeleteModal = () => {
        setDeleteModal(false);
    };

    const deleteCard = async(item) => {
        setDisabled(true);
        setDeleteModal(false);
        let clone = JSON.parse(JSON.stringify(userData))
        delete clone.creditCard;
        setClientSecret("");
        setUserData(clone);
        const params = {
            mode: item.mode,
            paymentMethod: item.paymentMethod
        };
        const deletion = await deleteStripePaymentMethod(params);
        if ( deletion.status === 200 ) {
            const colRef = "Users";
            const data = clone;
            await setData(colRef, userId, data);
            setUserData(clone);

            const setupIntentParams = {
				customerId: data.customerId,
				mode: item.mode
			};
			const getIntent = await createStripeSetupIntent(setupIntentParams);
			if ( getIntent.status === 200 ) {
				const id = getIntent.setupIntent.client_secret;
				setClientSecret(id);
				setDisabled(false);
			}
        }
        setDisabled(false);
	};

    const saveCreditCard = () => {
        if ( creditCardButton.current !== null) {
            creditCardButton.current.click();
        }
    };

    const complete = async(newUserDetails) => {
        // 1. Check if auto-billing is present
        // 2. If it IS present, we need to create individual subscriptions for each city as separate items
        // 3. If it is NOT present, we need to create a single payment for the total amount
        // 4. We then need to write the subscription data to the database
        // 5. We then need to let Ariel know via API so she can commence data processing
        if ( newUserDetails === undefined ) {
            newUserDetails = userData;
        }

        setLoading(true);
        const originalPrice = plan.months * plan.originalPrice;
        const totalDiscount = discountApplied === null ? null : plan.discount + discountApplied.discount;
        const discountedTotal = totalDiscount === null ? null : originalPrice - ((totalDiscount / 100) * originalPrice);
        const roundedPrice = Number(plan.price.toFixed(2));
        plan.price = roundedPrice;
        const metadata = {
            months: plan.months,
            price: plan.total,
            subscriptionId: subscriptionId,
            allCities: false,
            discountApplied: discountApplied,
            discountedTotal: discountedTotal,
            plan: plan,
            counties: plan.counties,
            creative: plan.creative
        };
        const sendPayment = autoBilling === true ? await payAutoBilling(newUserDetails, mode, metadata) : await submitPayment(newUserDetails, metadata);
        if ( sendPayment.status === 200 ) {
            const writeData = await writeSubscriptionData(sendPayment, autoBilling, metadata, newUserDetails, discountApplied);
            if ( writeData.status === 200 ) {
                sendPayment.email = writeData.email;
                sendPayment.allCities = false;
                setPaymentError("");

                const tagManagerArgs = {
                    dataLayer: {
                        event: "purchase",
                        live: mode === "live" ? true : false,
                        credits_only: false,
                        value: plan.total,
                        currency: "USD"
                    }
                };
                TagManager.dataLayer(tagManagerArgs);

                if ( plan.creative === true ) {
                    const circleData = {
                        email: sendPayment.email,
                        name: `${newUserDetails.firstName} ${newUserDetails.lastName}`
                    }
                    await addToCircle(circleData);
                }

                const newState = {
                    counties: metadata.counties,
                    email: sendPayment.email
                }
                navigate("/success", {
                    state: newState
                });
                recordEvent("Payment Success", {
                    mode: mode,
                    metadata: metadata,
                    price: plan.total,
                    creative: plan.creative,
                    autoBilling: autoBilling
                });

                if ( discountApplied !== null ) {
                    recordEvent("Affiliate Payment Success", {
                        affiliate: discountApplied.affiliateName,
                        annual: plan.months === 12 ? true : false,
                        mode: mode,
                        price: plan.total,
                        autoBilling: autoBilling,
                        creative: plan.creative
                    });
                }
            }
            setLoading(false);
        }
        else if ( sendPayment.status === 404 ) {
            const newCustomerData = {
                paymentMethod: newUserDetails.creditCard.paymentMethod,
                customerId: newUserDetails.customerId,
                mode: mode
            }
            const setDefaultPaymentMethod = await updateStripeCustomer(newCustomerData);
            if ( setDefaultPaymentMethod.status === 200 ) {
                complete(userData);
            }
            else {
                setPaymentError("There was an error processing your subscription. Please delete your credit card and re-add it.");
                setLoading(false);
            }
        }
        else {
            const error = autoBilling === true ? sendPayment.subscription : sendPayment.paymentMethod;
            const checkedError = await checkStripeError(error);
            setPaymentError(checkedError);
            setLoading(false);
        }
    };

    const calculateDiscountedTotal = () => {
        const originalPrice = plan.originalPrice * plan.months;
        const discount = discountApplied.discount + plan.discount;
        const discountAmount = originalPrice * (discount / 100);
        const finalPrice = originalPrice - discountAmount;
        return finalPrice;
    };

    return (
        <div className="pay-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={false}
                desktopControl={true}
            />
            {
                userData !== null && userData.creditCard !== undefined && deleteModal === true &&
                <div className="dark-bg">
                </div>
            }
            {
                state === null ?
                null
                :
                <div className="pay-inner-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    {
                        userData !== null && userData.creditCard !== undefined && deleteModal === true &&
                        <Modal
                            heading="Delete credit card"
                            subheading={"Are you sure you'd like to delete this card from your account?"}
                            body={<CreditCard creditCard={userData.creditCard} deleteCard={showDeleteModal} checkout={true} showDelete={false} />}
                            type={"destructive"}
                            cancelText={"Cancel"}
                            cancelButtonCTA={closeDeleteModal}
                            actionButtonText={"Delete card"}
                            actionButtonCTA={() => deleteCard(userData.creditCard)}
                            actionButtonDisabled={false}
                            secondaryButtonText=""
                            secondaryButtonCTA={null}
                            exitButtonCTA={closeDeleteModal}
                            loading={false}
                            leftIcon={null}
                            rightIcon={null}
                            cancelLeftIcon={null}
                            cancelRightIcon={null}
                            customClass={"pay-delete-card-modal"}
                        />
                    }
                    <div className="pay-row-container">
                        <div className="pay-left-container margin-x-small">
                            <div className="city-selection-mobile-back-container desktop-none">
                                <div 
                                    className="button-row text-button gap-x-small"
                                    onClick={() => goBack()}
                                >
                                    <Chevron
                                        fill={colour.blueBlue03}
                                        className="rotate-90"
                                    />
                                    <span className="body-regular colour-link">
                                        Back
                                    </span>
                                </div>
                            </div>
                            <div className="city-selection-mobile-progress-bar margin-x-large desktop-none">
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                                    <rect 
                                        width="100%" 
                                        height="4" 
                                        rx="2" 
                                        fill="#F2E1D0"
                                    />
                                    <rect
                                        width={`100%`}
                                        height="4" 
                                        rx="2" 
                                        fill={colour.blueBlue03}
                                    />
                                </svg>
                            </div>
                            <h1 className="heading-small-semibold colour-primary margin-large">
                                Payment method
                            </h1>
                            {
                                userData !== null && userData.creditCard !== undefined ?
                                <span className="body-regular colour-secondary block-text margin-large">
                                    Found card on file
                                </span>
                                :
                                null
                            }
                            {
                                userData === null ?
                                <LoadingStatic />
                                :
                                userData.creditCard !== undefined ?
                                <CreditCard
                                    creditCard={userData.creditCard}
                                    deleteCard={showDeleteModal}
                                    checkout={true}
                                    showDelete={true}
                                />
                                :
                                clientSecret !== "" ?
                                    <Elements
                                        stripe={stripePromise} 
                                        options={options}
                                    >
                                        <SetupForm
                                            clientSecret={clientSecret}
                                            domain="pay"
                                            bundle={false}
                                            userData={userData}
                                            setUserData={setUserData}
                                            setPaymentMethodModal={null}
                                            setAutoBilling={null}
                                            product={plan}
                                            city={null}
                                            loading={loading}
                                            setLoading={setLoading}
                                            setPaymentError={setPaymentError}
                                            payFunc={complete}
                                            creditCardButton={creditCardButton}
                                            setDisabled={setDisabled}
                                            pageState={state}
                                        />
                                    </Elements>
                                :
                                <LoadingStatic />
                            }
                            {
                                paymentError !== "" ?
                                <div className="pay-payment-error-container margin-top-small">
                                    <span className="body-regular colour-error">
                                        {paymentError}
                                    </span>
                                </div>
                                :
                                null
                            }
                            {
                                discountCodes.length > 0 && promoDisabled === false ?
                                <div className="pay-divider-line">
                                </div>
                                :
                                null
                            }
                            {
                                discountCodes.length > 0 && promoDisabled === false ?
                                <DiscountCode
                                    discountCode={discountCode}
                                    changeDiscountCode={changeDiscountCode}
                                    disabled={discountCodeDisabled}
                                    submitDiscountCode={submitDiscountCode}
                                    discountError={discountError}
                                    discountSuccess={discountSuccess}
                                    discountApplied={discountApplied}
                                    removeDiscountCode={removeDiscountCode}
                                />
                                :
                                null
                            }
                        </div>
                        <div className="pay-right-container">
                            <CountyPricingCard
                                deleteCity={null}
                                checkout={true}
                                total={plan.total}
                                discountApplied={discountApplied}
                                title={plan.title}
                                plan={plan}
                            />
                        </div>
                    </div>
                    <div className="pay-checkout-buttons-row margin-top-x-x-large mobile-none">
                        <div 
                            className="button-row text-button gap-x-small"
                            onClick={() => goBack()}
                        >
                            <Chevron
                                fill={colour.blueBlue03}
                                className="rotate-90"
                            />
                            <span className="body-regular colour-link">
                                Back
                            </span>
                        </div>
                        <div className="city-selection-next-button">
                            <Button
                                buttonType="primary-button"
                                size="large"
                                text={`Pay ${discountApplied !== null ? formatterLong.format(calculateDiscountedTotal()).replace(".00", "") : formatterLong.format(plan.total).replace(".00", "")}`}
                                cta={() => userData !== null && userData.creditCard !== undefined ? complete() : saveCreditCard()}
                                disabled={userData === null || loading === true || disabled === true ? true : false}
                                leftIcon={null}
                                leftIconClass=""
                                rightIcon={null}
                                rightIconClass=""
                                alt=""
                                standardIcon={false}
                                badge={false}
                                badgeVal={null}
                                badgeBG={null}
                                buttonRef={null}
                            />
                        </div>
                    </div>
                    {
                        deleteModal === true ?
                        null
                        :
                        <CountyMobilePricingOverlay
                            toggleAccordion={toggleAccordion}
                            mobileAccordion={mobileAccordion}
                            deleteCity={null}
                            complete={complete}
                            checkout={true}
                            total={plan.total}
                            plan={plan}
                            cta={`Pay ${discountApplied !== null ? formatterLong.format(calculateDiscountedTotal()).replace(".00", "") : formatterLong.format(plan.total).replace(".00", "")}`}
                            payment={true}
                            disabled={userData === null || loading === true || disabled === true ? true : false}
                            userData={userData}
                            saveCreditCard={saveCreditCard}
                            discountApplied={discountApplied}
                            title={plan.title}
                        />
                    }
                </div>
            }
            <Footer />
        </div>
    );
}

export default Pay;