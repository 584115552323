import React, { useState } from 'react';
import '../styles/STRRent.css';
import { checkNaN, formatPrice, recordEvent } from '../functions';
import { colour, formatterLong } from '../styles/GlobalStyles';
import { RedirectArrow } from '../assets/icons';
import { FinancialsInput, Tooltip } from '../v4/components';

function STRRent(props) {
    const blurred = props.blurred;
    const strRevenue = props.strRevenue;
    const onChangeSTRRevenue = props.onChangeSTRRevenue;
    const [airDNATooltip, setAirDNATooltip] = useState(false);

    const steps = [
        {
            label: "1. Go to the",
            linkText: "AirDNA free Rentalizer",
            icon: <RedirectArrow fill={colour.blueBlue03} className="" clickFunc={() => openRentaliser()} />,
            link: () => openRentaliser()
        },
        {
            label: `2. Enter the property info & find the "Net operating income"`,
            linkText: null,
            icon: null,
            link: null
        },
        {
            label: "3. Enter that number below.",
            linkText: null,
            icon: null,
            link: null
        }
    ];

    const openRentaliser = () => {
        recordEvent("Open AirDNA Rentaliser", {});
        window.open("https://www.airdna.co/rentalizer", "_blank");
    };

    return (
        <div className="str-rent-container">
            {
                steps.map((item, index) =>
                    <div 
                        className="str-rent-step-element"
                        key={index}
                        onClick={() => item.link === null ? null : item.link()}
                    >
                        <span className="body-regular colour-primary">
                            {item.label}
                        </span>
                        <div className="str-rent-inner-step-element">
                            <span className="body-regular-underline colour-link">
                                {item.linkText}
                            </span>
                            {item.icon}
                        </div>
                    </div>
                )
            }
            <div className="property-spacing-border">
            </div>
            <div className="str-rent-input-container relative-container">
                {
                    airDNATooltip === true &&
                    <Tooltip
                        type="info"
                        title="Net operating income"
                        body="On the AirDNA calculator, copy the number labeled as 'Net Operating Income' and paste it into this text input."
                        position="top"
                        exit={false}
						exitFunc={null}
                    />
                }
                <FinancialsInput
                    label={"Net operating income"}
                    labelType={"default"}
                    info={true}
                    value={blurred === true ? "999" : formatPrice(strRevenue)}
                    onChange={(text) => onChangeSTRRevenue(text.target.value.replaceAll(",", ""))}
                    placeholder={formatPrice(50000)}
                    type="text"
                    leftUnit={"$"}
                    rightUnit={null}
                    unitSwitch={false}
                    leftUnitSelected={true}
                    unitSwitchFunc={null}
                    disabled={blurred}
                    multiline={false}
                    error={checkNaN(strRevenue)}
                    onMouseEnter={() => setAirDNATooltip(true)}
                    onMouseLeave={() => setAirDNATooltip(false)}
                    reset={false}
                    resetFunc={null}
                    resetPosition={null}
                />
            </div>
            <div className="property-spacing-border">
            </div>
            <div className="str-rent-monthly-container">
                <span className="body-regular colour-primary">
                    Average monthly rent
                </span>
                <span className="body-semibold colour-primary">
                    {formatterLong.format(strRevenue / 12).replace(".00", "")}
                </span>
            </div>
        </div>
    )
};

export default STRRent;