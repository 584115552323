import React from 'react';
import '../styles/Button.css';
import { Badge } from './';

function Button(props) {
    const buttonType = props.buttonType;
    const text = props.text;
    const cta = props.cta;
    const disabled = props.disabled;
    const leftIcon = props.leftIcon;
    const rightIcon = props.rightIcon;
    const alt = props.alt;
    const size = props.size;
    const standardIcon = props.standardIcon;
    const badge = props.badge;
    const badgeVal = props.badgeVal;
    const badgeBG = props.badgeBG;
    const buttonRef = props.buttonRef;
    const leftIconClass = `button-icon-${size}`;
    const rightIconClass = `button-icon-${size}`;
    const colour = (buttonType === "primary-button" || buttonType === "accent-button") ? "colour-white" : buttonType === "secondary-button" ? "colour-primary" : (buttonType === "tertiary-button" || buttonType === "light-button" || buttonType === "third-party-button") ? "text-link" : buttonType === "destructive-button" ? "colour-error" : "";
    const sizeClass = size === "large" ? "size-large" : size === "medium" ? "size-medium" : size === "small" ? "size-small" : "size-x-small";
    const textSize = (size === "large" || size === "medium") ? "body-semibold" : "label-semibold";

    return (
        <div 
            className={(sizeClass) + (" ") + (buttonType) + " button-radius-normal cursor-pointer " + (disabled === true ? "button-disabled" : "")}
            onClick={disabled === true ? null : cta}
            ref={buttonRef}
        >
            <div className={`${sizeClass}-padding`}>
                {
                    leftIcon === null ?
                    null
                    :
                    standardIcon === true ?
                    <img
                        src={leftIcon}
                        className={leftIconClass}
                        alt={alt}
                    />
                    :
                    leftIcon
                }
                <span className={(textSize) + (" ") + (colour) + " block-text text-align-center"}>
                    {text}
                </span>
                {
                    rightIcon === null ?
                    null
                    :
                    standardIcon === true ?
                    <img
                        src={rightIcon}
                        className={rightIconClass}
                        alt={alt}
                    />
                    :
                    rightIcon
                }
            </div>
            {
                badge === true &&
                <Badge
                    val={badgeVal}
                    bg={badgeBG}
                    round={true}
                    freeSize={false}
                />
            }
        </div>
    )
};

export default Button;