import React, { useEffect } from 'react';
import '../styles/RecommendationsLoading.css';
import { Header, Footer } from '../components';
import { LoadingHouse } from '../assets/animations';
import { useNavigate, useLocation } from 'react-router-dom';
import { recordEvent } from '../functions';
import { useLottie } from 'lottie-react';

function RecommendationsLoading() {

    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    const options = {
        animationData: LoadingHouse,
        loop: true
    };
    const { View } = useLottie(options);

    useEffect(() => {
        document.title = "Recommendations Loading... | Coffee Clozers";

        setTimeout(() => {
            recordEvent("Recommendations Loading Complete", {});
            navigate("/results", {
                state: state
            });
        }, 5000);
    }, [navigate, state]);

    return (
        <div className="recommendations-loading-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={false}
                desktopControl={false}
            />
            <div className="recommendations-loading-inner-container">
                <div className="recommendations-loading-image-container">
                    {View}
                </div>
                <div className="recommendations-loading-title-container">
                    <h1 className="heading-large-semibold colour-primary text-align-center">
                        Getting properties for you...
                    </h1>
                    <span className="body-regular colour-secondary block-text text-align-center">
                        In just a few moments, you'll have access to 
                        personalized property recommendations that save you time...
                    </span>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default RecommendationsLoading;