import React from 'react';
import '../styles/NewBuyBoxModal.css';
import { BuyBoxFrequency, BuyBoxName } from '../components';
import { ExitScreen } from '../../components';

function NewBuyBoxModal(props) {
    const buyBoxName = props.buyBoxName;
    const setBuyBoxName = props.setBuyBoxName;
    const buyBoxFrequency = props.buyBoxFrequency;
    const setBuyBoxFrequency = props.setBuyBoxFrequency;
    const buyBoxFrequencyDropdown = props.buyBoxFrequencyDropdown;
    const setBuyBoxFrequencyDropdown = props.setBuyBoxFrequencyDropdown;
    const nameError = props.nameError;

    const onChangeBuyBoxName = (value) => {
        if ( value.length <= 50 ) {
            setBuyBoxName(value);
        }
    }

    const onChangeBuyBoxFrequency = (val) => {
        if ( typeof val === "string" ) {
            setBuyBoxFrequency(val);
        }
        else {
            setBuyBoxFrequency(val.value);
        }
        closeDropdown();
    };

    const closeDropdown = () => {
        setBuyBoxFrequencyDropdown(false);
    };

    return (
        <div className="new-buy-box-modal-container relative-container">
            {
                buyBoxFrequencyDropdown === true &&
                <ExitScreen
                    clickScreen={() => closeDropdown()}
                />
            }
            <div className="new-buy-box-modal-element">
                <h2 className="body-semibold colour-primary">
                    Name buy box
                </h2>
                <BuyBoxName
                    title={buyBoxName}
                    onChangeBuyBoxName={onChangeBuyBoxName}
                    error={nameError}
                />
            </div>
            <div className="new-buy-box-modal-element">
                <h2 className="body-semibold colour-primary mobile-none">
                    Notification frequency
                </h2>
                <BuyBoxFrequency
                    selectedOption={buyBoxFrequency}
                    changeSelectedOption={onChangeBuyBoxFrequency}
                    buyBoxFrequencyDropdown={buyBoxFrequencyDropdown}
                    setBuyBoxFrequencyDropdown={setBuyBoxFrequencyDropdown}
                />
            </div>
        </div>
    )
};

export default NewBuyBoxModal;