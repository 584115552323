import React, { useRef, useState } from 'react';
import '../styles/Property.css';
import { Header, Footer, StickyROI, ImageGrid, PropertyDetails, Location, OffMarketImageContainer, ARVCompsAnalysis, RentCompsAnalysis, LastUpdated, Comparables } from '../components';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toggleFavourite, recordEvent, checkNeighbourhoodInfoAvailable, calculateDealOptimisation, checkNaN, goToCreativeCheckout, saveOnboardingComplete } from '../functions';
import { colour, formatterLong } from '../styles/GlobalStyles';
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';
import { PropertyPageEffect } from '../queries';
import { HorizontalTab, AISummaryTab, OwnerInfo, NeighbourhoodPanel, History, FinancialsCalculator, Modal, Toast } from '../v4/components';
import { CopyKeychain, Download, RedirectArrow, Sparkle, TickCircle, Wand } from '../assets/icons';
import { DealOptimiser, DealOptimiserLoading, DealOptimiserResults, OnboardingComplete, ShareWithAgent, Upgrade } from '../v4/modalbody';

function Property() {

	const params = useParams();
	const cityId = decodeURIComponent(params.cityId);
	const id = decodeURIComponent(params.id);
	const pathname = window.location.pathname;
	const propertySearch = pathname.includes("/quick-report") ? true : false;
	const [property, setProperty] = useState([]);
	const [userId, setUserId] = useState(null);
	const [favourites, setFavourites] = useState([]);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const [editedProperty, setEditedProperty] = useState("");
	const [userDetails, setUserDetails] = useState(null);
	const [downPayment, setDownPayment] = useState("");
	const [interestRate, setInterestRate] = useState("");
	const [loanYears, setLoanYears] = useState(30);
	const [closingPercentage, setClosingPercentage] = useState(3);
	const [highLevelProfits, setHighLevelProfits] = useState("");
	const [queryMade, setQueryMade] = useState(false);
	const [bugModal, setBugModal] = useState(false);
	const [neighbourhoodData, setNeighbourhoodData] = useState([]);
	const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
	const [freeTrial, setFreeTrial] = useState(true);
	const [mobileBar, setMobileBar] = useState(false);
	const [blurred, setBlurred] = useState(false);
	const [loading, setLoading] = useState(true);
	const [recurringCosts, setRecurringCosts] = useState([]);
	const [spreadsheetProperty, setSpreadsheetProperty] = useState(null);
	const [costPerSqFoot, setCostPerSqFoot] = useState(0);
	const [totalRehabCost, setTotalRehabCost] = useState(0);
	const [defaultCostPerSqFoot, setDefaultCostPerSqFoot] = useState(0);
	const [userSettings, setUserSettings] = useState(null);
	const [defaultDownPayment, setDefaultDownPayment] = useState(20);
	const [defaultInterestRate, setDefaultInterestRate] = useState(7);
	const [searchParams, setSearchParams] = useSearchParams({});
	const getAcquisition = searchParams.get("acquisition");
	const [acquisitionStrategy, setAcquisitionStrategy] = useState(getAcquisition === null ? "m" : getAcquisition);
	const getExit = searchParams.get("exit");
	const [exitStrategy, setExitStrategy] = useState(getExit === null ? "ltr" : getExit);
	const [selectedCompsPanel, setSelectedCompsPanel] = useState(0);
	const [propertyTaxSetting, setPropertyTaxSetting] = useState(null);
	const [showARVCompsAnalysis, setShowARVCompsAnalysis] = useState(false);
	const [showRentCompsAnalysis, setShowRentCompsAnalysis] = useState(false);
	const [arvComps, setArvComps] = useState([]);
	const [signUpModal, setSignUpModal] = useState(false);
	const [savedSalesCompData, setSavedSalesCompData] = useState(null);
	const [savedRentCompData, setSavedRentCompData] = useState(null);
	const [applyEstimateModal, setApplyEstimateModal] = useState(false);
	// const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const [selectedPanel, setSelectedPanel] = useState(0);
	const [optimiseDealModal, setOptimiseDealModal] = useState(false);
	const [dealOptimiseLoading, setDealOptimiseLoading] = useState(false);
	const [dealOptimiseResults, setDealOptimiseResults] = useState(null);
	const [dealOptimiseError, setDealOptimiseError] = useState("");
	const [optimisedParams, setOptimisedParams] = useState(null);
	const [fullscreenFinancials, setFullscreenFinancials] = useState(false);
	const [creativeUser, setCreativeUser] = useState(false);
	const [offerLoading, setOfferLoading] = useState(false);
	const [offerDownloadComplete, setOfferDownloadComplete] = useState(false);
	const [offerURL, setOfferURL] = useState(null);
	const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);
	const [csvDownloadComplete, setCSVDownloadComplete] = useState(false);
	const [csvDownloadError, setCSVDownloadError] = useState(false);
	const [csvDownloadLink, setCSVDownloadLink] = useState(null);
	const [changeEditedProperty, setChangeEditedProperty] = useState(false);
	const [premiumModal, setPremiumModal] = useState(false);
	const [shareAgentModal, setShareAgentModal] = useState(false);
	const [textCopiedToast, setTextCopiedToast] = useState(false);
	const [onboarding, setOnboarding] = useState(false);
	const [onboardingComplete, setOnboardingComplete] = useState(false);
	const [onboardingStep, setOnboardingStep] = useState(2);
	const [onboardingTooltip, setOnboardingTooltip] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const creativeAcquisition = acquisitionStrategy === "sf" || acquisitionStrategy === "sub" ? true : false;
	const compsRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;

	const aiSummaryVisible = loading === true ? false : (property.dripScoreSummaryDescription === undefined || property.dripScoreSummaryDescription === null || property.dripScoreSummaryDescription.overall === null) ? false : true;
	const panels = [
		{
			label: "AI Summary",
			icon: <Sparkle className="" fill={colour.grayScaleBlack}  />,
			selected: selectedPanel === 0 ? true : false,
			disabled: !aiSummaryVisible,
			visible: aiSummaryVisible
		},
		{
			label: "Details",
			icon: null,
			selected: selectedPanel === 1 ? true : false,
			disabled: false,
			visible: true
		},
		{
			label: "Owner info",
			icon: null,
			selected: selectedPanel === 2 ? true : false,
			disabled: false,
			visible: true
		},
		{
			label: "Neighborhood",
			icon: null,
			selected: selectedPanel === 3 ? true : false,
			disabled: false,
			visible: true
		},
		{
			label: "Comps",
			icon: null,
			selected: selectedPanel === 4 ? true : false,
			disabled: false,
			visible: true
		},
		{
			label: "History",
			icon: null,
			selected: selectedPanel === 5 ? true : false,
			disabled: false,
			visible: true
		}
	];

	const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

	const viewRentalComps = () => {
		if ( compsRef.current !== null ) {
			recordEvent("View Rental Comps", {
				"CityId": cityId,
				"zpid": id
			});
            compsRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
			setSelectedCompsPanel(0);
        }
	};

	const viewArvComps = () => {
		if ( compsRef.current !== null ) {
			recordEvent("View ARV Comps", {
				"CityId": cityId,
				"zpid": id
			});
            compsRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
			setSelectedCompsPanel(1);
        }
	};

	const mapImages = () => {
		if ( property.length !== 0 && property.images !== undefined && property.images !== null && property.images.length > 0 ) {
			const firstImage = property.images[0];
			if ( firstImage.includes("maps.googleapis.com") ) {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	};

	const runComps = () => {
		recordEvent("Run Comps", {
			zpid: id
		});

		if ( selectedCompsPanel === 0 ) {
			setShowRentCompsAnalysis(true);
		}
		else {
			setShowARVCompsAnalysis(true);
		}
	};

	const switchCompsTool = (val) => {
		const type = val === 1 ? "Sales" : "Rent";
		recordEvent(`Switch Comps - ${type}`, {
			zpid: id
		});

		if ( selectedCompsPanel === 0 ) {
			setShowRentCompsAnalysis(false);
			setShowARVCompsAnalysis(true);
		}
		else {
			setShowRentCompsAnalysis(true);
			setShowARVCompsAnalysis(false);
		}
		setSelectedCompsPanel(val);
	};

	const closeCompsAnalysis = () => {
		recordEvent("Comps Analysis Closed", {});
		setShowARVCompsAnalysis(false);
		setShowRentCompsAnalysis(false);
	};

	const saveCompsAnalysis = () => {
		if ( userId === null ) {
			setSignUpModal(true);
		}
		else {
			setShowARVCompsAnalysis(false);
			setShowRentCompsAnalysis(false);
			recordEvent("Comps Analysis Saved", {});
		}
	};

	const changePanel = (index) => {
		setSelectedPanel(index);
	};

	const closeDealOptimiser = () => {
		setOptimiseDealModal(false);
		setDealOptimiseLoading(false);
		setDealOptimiseResults(null);
		setDealOptimiseError("");
		setOptimisedParams(null);
	};

	const checkOptimisedParams = () => {
		if ( optimisedParams === null ) {
			return true;
		}
		else {
			if ( 
				optimisedParams.price === "" || checkNaN(optimisedParams.price) === true || 
				optimisedParams.duration === "" || checkNaN(optimisedParams.duration) === true || 
				optimisedParams.monthlyCosts === "" || checkNaN(optimisedParams.monthlyCosts) === true || 
				optimisedParams.rent === "" || checkNaN(optimisedParams.rent) === true || 
				optimisedParams.minDownPayment === "" || checkNaN(optimisedParams.minDownPayment) === true || 
				optimisedParams.maxDownPayment === "" || checkNaN(optimisedParams.maxDownPayment) === true || 
				optimisedParams.minInterestRate === "" || checkNaN(optimisedParams.minInterestRate) === true || 
				optimisedParams.maxInterestRate === "" || checkNaN(optimisedParams.maxInterestRate) === true ||
				optimisedParams.minSellerEarnings === "" || checkNaN(optimisedParams.minSellerEarnings) === true ||
				optimisedParams.targetCoCR === "" || checkNaN(optimisedParams.targetCoCR) === true
			) {
				return true;
			}
			else {
				return false;
			}
		}
	};

	const runDealOptimisation = async() => {
		setDealOptimiseLoading(true);
		const queryData = await calculateDealOptimisation(optimisedParams);
		if ( queryData.status === 200 ) {
			setDealOptimiseError("");
			const body = queryData.body;
			setDealOptimiseResults(body);
		}
		else if ( queryData.status === 404 ) {
			setDealOptimiseError("We couldn't optimize the deal with your parameters, try reducing your target cash on cash on cash.");
		}
		else {
			setDealOptimiseError("There was an error, contact us for more details");
		}
		setDealOptimiseLoading(false);
	};

	const backToDealOptimiser = () => {
		setDealOptimiseError("");
		setDealOptimiseResults(null);
	};

	const pasteResults = () => {
		const editedPropertyClone = {...editedProperty};
		editedPropertyClone.duration = dealOptimiseResults.balloonYears * 12;
		editedPropertyClone.downPayment = dealOptimiseResults.downPaymentPrct;
		editedPropertyClone.creativeInterestRate = dealOptimiseResults.interestRate;
		editedPropertyClone.monthlyPayment = dealOptimiseResults.monthlyPayment;
		editedPropertyClone.price = dealOptimiseResults.offerPrice;
		editedPropertyClone.monthlyCosts[0].inputs[0].value = dealOptimiseResults.monthlyPayment;
		setEditedProperty(editedPropertyClone);
		setChangeEditedProperty(true);
		closeDealOptimiser();
	};

	const closePremiumModal = () => {
        setPremiumModal(false);
    };

	const goToPricing = () => {
		navigate("/pricing");
	};

	const goToCheckout = () => {
		goToCreativeCheckout(navigate);
	};

	const closeOfferToast = () => {
		setOfferDownloadComplete(false);
		setOfferURL(null);
	};

	const openOffer = () => {
		if ( offerURL !== null ) {
			window.open(offerURL, "_blank");
		}
	};

	const closeCSVDownloadToast = () => {
		setCSVDownloadComplete(false);
		setCSVDownloadLink(null);
	};

	const closeShareAgentModal = () => {
		setShareAgentModal(false);
	};

	const closeLinkCopiedToast = () => {
		setTextCopiedToast(false);
	};

	const openCSVDownload = () => {
		if ( csvDownloadLink !== null ) {
			window.open(csvDownloadLink, "_blank");
		}
	};

	const checkCompsAvailable = () => {
		if ( property.rentComps !== undefined && property.rentComps !== null && property.rentComps.length > 0 ) {
			return true;
		}
		else if ( property.unitPropertyComps !== undefined && property.unitPropertyComps !== null && property.unitPropertyComps.length > 0 ) {
			return true;
		}
		else if ( property.arvComps === true ) {
			return true;
		}
		else {
			return false;
		}
	};

	const closeOnboardingComplete = () => {
		recordEvent("Onboarding Complete", {});
		recordEvent("Onboarding Complete - Close Modal", {});
		setOnboardingComplete(false);
	};

	const openDemo = () => {
		recordEvent("Onboarding Complete", {});
		recordEvent("Onboarding Complete - Book Demo", {});
		window.open("https://calendly.com/liam-maher/30min?back=1", "_blank");
	};

	const viewOnboarding = () => {
		setOnboarding(true);
		setOnboardingComplete(false);
		setOnboardingStep(2);
		setSelectedTab(2);

		recordEvent("View Property Onboarding", {});
	};

	const closeOnboardingTooltip = () => {
		setOnboardingTooltip(false);
		recordEvent("Close Property Onboarding Tooltip", {});
	};

	const skipOnboarding = async() => {
		setOnboarding(false);
		await saveOnboardingComplete(userId);
		setOnboardingTooltip(true);
		recordEvent("Skip Property Onboarding", {});
	};

	return (
		<div className={"property-outer-container " + (bugModal === true || optimiseDealModal === true || fullscreenFinancials === true || premiumModal === true || shareAgentModal === true || onboardingComplete === true ? "relative-container" : mobileBar === true ? "property-mobile-bar-active" : "") + (blurred === true ? " property-blurred" : "")}>
			<Header
				subscriptions={null}
                users={userDetails}
                queries={[false, true]}
				mobileNav={onboardingComplete === true ? false : true}
				desktopControl={true}
				viewOnboarding={viewOnboarding}
				onboardingTooltip={onboardingTooltip}
				closeTooltip={closeOnboardingTooltip}
			/>
			<PropertyPageEffect
				cityId={cityId}
				exitStrategy={exitStrategy}
				setProperty={setProperty}
				setMobileBar={setMobileBar}
				setFreeTrial={setFreeTrial}
				setUserDetails={setUserDetails}
				setFavourites={setFavourites}
				navigate={navigate}
				setBlurred={setBlurred}
				id={id}
				editedProperty={editedProperty}
				queryMade={queryMade}
				setQueryMade={setQueryMade}
				setLoading={setLoading}
				setEditedProperty={setEditedProperty}
				setDownPayment={setDownPayment}
				setInterestRate={setInterestRate}
				setClosingPercentage={setClosingPercentage}
				setTotalRehabCost={setTotalRehabCost}
				setCostPerSqFoot={setCostPerSqFoot}
				setDefaultCostPerSqFoot={setDefaultCostPerSqFoot}
				setRecurringCosts={setRecurringCosts}
				setSpreadsheetProperty={setSpreadsheetProperty}
				propertySearch={propertySearch}
				state={state}
				setUserSettings={setUserSettings}
				setLoanYears={setLoanYears}
				setDefaultDownPayment={setDefaultDownPayment}
				setDefaultInterestRate={setDefaultInterestRate}
				setPropertyTaxSetting={setPropertyTaxSetting}
				setNeighbourhoodBounds={setNeighbourhoodBounds}
				setNeighbourhoodData={setNeighbourhoodData}
				testMode={false}
				setCityId={null}
				setLoadingStep={null}
				setArvComps={setArvComps}
				partner={false}
                partnerId={null}
				setUserId={setUserId}
				setSavedSalesCompData={setSavedSalesCompData}
				setSavedRentCompData={setSavedRentCompData}
				// setInnerWidth={setInnerWidth}
				setPartnerAmendments={null}
				setCreativeUser={setCreativeUser}
				setSelectedPanel={setSelectedPanel}
				setOnboarding={setOnboarding}
			/>
			{
				optimiseDealModal === true || fullscreenFinancials === true || premiumModal === true || shareAgentModal === true || onboardingComplete === true ?
				<div className="dark-bg">
				</div>
				:
				null
			}
			{
				showARVCompsAnalysis === true && property.arvCompsSimilarProp !== null ?
				<ARVCompsAnalysis
					property={property}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodBounds={neighbourhoodBounds}
					comps={arvComps}
					closeCompsAnalysis={closeCompsAnalysis}
					saveCompsAnalysis={saveCompsAnalysis}
					userId={userId}
					signUpModal={signUpModal}
					setSignUpModal={setSignUpModal}
					savedData={savedSalesCompData}
					setSavedData={setSavedSalesCompData}
					editedProperty={editedProperty}
					setEditedProperty={setEditedProperty}
					loanYears={loanYears}
					switchCompsTool={switchCompsTool}
				/>
				:
				showRentCompsAnalysis === true ?
				<RentCompsAnalysis
					property={property}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodBounds={neighbourhoodBounds}
					closeCompsAnalysis={closeCompsAnalysis}
					saveCompsAnalysis={saveCompsAnalysis}
					userId={userId}
					signUpModal={signUpModal}
					setSignUpModal={setSignUpModal}
					savedData={savedRentCompData}
					setSavedData={setSavedRentCompData}
					editedProperty={editedProperty}
					setEditedProperty={setEditedProperty}
					loanYears={loanYears}
					switchCompsTool={switchCompsTool}
				/>
				:
				null
			}
			<div className="property-relative-container">
				{
					property.length === 0 || highLevelProfits === "" || userDetails === null ?
					null
					:
					<StickyROI
						toggleFavourite={toggleFavourite}
						favourites={favourites}
						setFavourites={setFavourites}
						setFavouriteDisable={setFavouriteDisable}
						navigate={navigate}
						favouriteDisable={favouriteDisable}
						freeTrial={freeTrial}
						property={property}
						cityId={cityId}
						downPayment={downPayment}
						blurred={blurred}
						spreadsheetProperty={spreadsheetProperty}
						interestRate={interestRate}
						setPremiumModal={setPremiumModal}
						csvDownloadLoading={csvDownloadLoading}
						setCSVDownloadLoading={setCSVDownloadLoading}
						setCSVDownloadComplete={setCSVDownloadComplete}
						setCSVDownloadError={setCSVDownloadError}
						setCSVDownloadLink={setCSVDownloadLink}
					/>
				}
			</div>
			{
				onboardingComplete === true &&
				<Modal
					heading={"Guided tour completed"}
					subheading={null}
					body={<OnboardingComplete />}
					type="basic"
					cancelText="Back to clozing"
					cancelButtonCTA={closeOnboardingComplete}
					actionButtonText={"Book a demo"}
					actionButtonCTA={openDemo}
					actionButtonDisabled={false}
					secondaryButtonText=""
					secondaryButtonCTA={null}
					exitButtonCTA={closeOnboardingComplete}
					loading={false}
					leftIcon={null}
					rightIcon={<RedirectArrow fill={colour.grayScaleWhite} className="onboarding-complete-redirect" clickFunc={() => null} />}
					cancelLeftIcon={null}
                    cancelRightIcon={null}
					customClass={"onboarding-complete-modal"}
				/>
			}
			{
				optimiseDealModal &&
				<Modal
					heading={(dealOptimiseResults !== null || dealOptimiseError !== "") ? "Results" : dealOptimiseLoading === true ? "Working our magic..." : "Optimize the deal"}
					subheading={(dealOptimiseResults !== null || dealOptimiseError !== "") ? "Here are the terms you should aim for in negotiations!" : dealOptimiseLoading === true ? "This should only take a moment." : "Make the numbers work in your favor."}
					body={(dealOptimiseResults !== null || dealOptimiseError !== "") ? <DealOptimiserResults dealOptimiseError={dealOptimiseError} results={dealOptimiseResults} /> : dealOptimiseLoading === true ? <DealOptimiserLoading /> : <DealOptimiser property={property} blurred={blurred} editedProperty={editedProperty} optimisedParams={optimisedParams} setOptimisedParams={setOptimisedParams} />}
					type="accent"
					cancelText="Cancel"
					cancelButtonCTA={closeDealOptimiser}
					actionButtonText={dealOptimiseResults !== null ? "Paste into calculator" : dealOptimiseError !== "" ? "Edit parameters" : "Optimize deal"}
					actionButtonCTA={dealOptimiseError !== "" ? backToDealOptimiser : dealOptimiseResults !== null ? pasteResults : runDealOptimisation}
					actionButtonDisabled={dealOptimiseLoading === true ? true : checkOptimisedParams() === true ? true : blurred}
					secondaryButtonText=""
					secondaryButtonCTA={null}
					exitButtonCTA={closeDealOptimiser}
					loading={false}
					leftIcon={(dealOptimiseResults !== null || dealOptimiseError !== "") ? null : <Wand fill={colour.grayScaleWhite} className="deal-optimise-icon" />}
					rightIcon={null}
					cancelLeftIcon={null}
                    cancelRightIcon={null}
					customClass={null}
				/>
			}
			{
				textCopiedToast === true &&
				<Toast
					type={"default"}
					label={"Link copied"}
					icon={<CopyKeychain clickFunc={null} fill={colour.grayScaleWhite} className="" />}
					standardIcon={false}
					linkFunc={null}
					linkText={null}
					closeFunc={closeLinkCopiedToast}
					undoFunc={null}
				/>
			}
			{
				(offerLoading === true || offerDownloadComplete === true) &&
				<Toast
					type={offerDownloadComplete === true ? "link" : "loading"}
					label={offerDownloadComplete === true ? "Download successful" : "Downloading offer letter"}
					icon={offerDownloadComplete === true ? <TickCircle fill={colour.grayScaleWhite} /> : <Download className="" fill={colour.grayScaleWhite} />}
					standardIcon={false}
					linkFunc={openOffer}
					linkText={offerURL === null ? null : "Click here to view"}
					closeFunc={closeOfferToast}
					undoFunc={null}
				/>
			}
			{
				(csvDownloadLoading === true || csvDownloadComplete === true || csvDownloadError === true) &&
				<Toast
					type={csvDownloadComplete === true ? "link" : csvDownloadError === true ? "default" : "loading"}
					label={csvDownloadError === true ? "There was an error downloading your data" : csvDownloadComplete === true ? "Download successful" : "Downloading CSV file"}
					icon={csvDownloadError === true ? null : csvDownloadComplete === true ? <TickCircle fill={colour.grayScaleWhite} /> : <Download className="" fill={colour.grayScaleWhite} />}
					standardIcon={false}
					linkFunc={openCSVDownload}
					linkText={csvDownloadLink === null ? null : "Click here to view"}
					closeFunc={closeCSVDownloadToast}
					undoFunc={null}
				/>
			}
			{
				premiumModal === true &&
				<Modal
                    heading="Plus Feature 👀"
                    subheading={"You've discovered a Plus Plan feature!"}
                    body={<Upgrade />}
                    type="secondary"
                    cancelText="Maybe later"
                    cancelButtonCTA={closePremiumModal}
                    actionButtonText="Upgrade now"
                    actionButtonCTA={goToCheckout}
                    actionButtonDisabled={false}
                    secondaryButtonText="See pricing plans"
                    secondaryButtonCTA={goToPricing}
                    exitButtonCTA={closePremiumModal}
                    loading={false}
                    leftIcon={null}
                    rightIcon={null}
					cancelLeftIcon={null}
                    cancelRightIcon={null}
					customClass={null}
                />
			}
			{
				shareAgentModal === true &&
				<Modal
                    heading="Share listing"
                    subheading={"Copy shareable link below."}
                    body={<ShareWithAgent property={property} acquisitionStrategy={acquisitionStrategy} exitStrategy={exitStrategy} setTextCopiedToast={setTextCopiedToast} />}
                    type="none"
                    cancelText={null}
                    cancelButtonCTA={null}
                    actionButtonText={null}
                    actionButtonCTA={null}
                    actionButtonDisabled={false}
                    secondaryButtonText={null}
                    secondaryButtonCTA={null}
                    exitButtonCTA={closeShareAgentModal}
                    loading={false}
                    leftIcon={null}
                    rightIcon={null}
					cancelLeftIcon={null}
                    cancelRightIcon={null}
					customClass={"share-agent-modal"}
                />
			}
			{
				loading === true ?
				<div className="property-loading-container">
					{View}
				</div>
				:
				property.length !== 0 && userDetails !== null ?
				<div className={"property-body-container " + (mobileBar === true ? "relative-container" : "") + (showARVCompsAnalysis === true || showRentCompsAnalysis ? " desktop-none" : "")}>
					{
						property.images !== undefined && property.images !== null && property.images.length > 0 && mapImages() === false ?
						<ImageGrid 
							property={property}
							images={property.images !== undefined ? property.images : [property.imgSrc]}
							title={property.address.streetAddress}
							preview={false}
							blurred={blurred}
						/>
						:
						null
					}
					<div className={"property-inner-body-container " + (fullscreenFinancials === true ? "financials-fullscreen" : "")}>
						<div className="property-inner-body-left-container">
							{
								property.images === undefined || property.images === null || property.images.length === 0 ?
								<OffMarketImageContainer
									property={property}
								/>
								:
								null
							}
							<div className="property-tabs-row margin-x-large">
								{
									panels.map((item, index) =>
										item.visible === false ?
										null
										:
										index === 3 && checkNeighbourhoodInfoAvailable(neighbourhoodData, property) === false ?
										null
										:
										index === 4 && (checkCompsAvailable() === false) ?
										null
										:
										index === 5 && (property.priceHistory === undefined || property.priceHistory === null || property.priceHistory.length === 0) ?
										null
										:
										<HorizontalTab
											label={item.label}
											active={item.selected}
											icon={item.icon}
											disabled={item.disabled}
											func={() => changePanel(index)}
											standardIcon={false}
											key={index}
										/>
									)
								}
							</div>
							{
								selectedPanel === 0 && property.dripScoreSummaryDescription !== undefined && property.dripScoreSummaryDescription !== null && property.dripScoreSummaryDescription.overall !== null ?
								<AISummaryTab
									property={property}
									creativeUser={creativeUser}
									setPremiumModal={setPremiumModal}
								/>
								:
								selectedPanel === 1 ?
								<PropertyDetails
									property={property}
									formatter={formatterLong}
									propertySearch={propertySearch}
								/>
								:
								selectedPanel === 2 ?
								<OwnerInfo
									property={property}
								/>
								:
								selectedPanel === 3 && checkNeighbourhoodInfoAvailable(neighbourhoodData, property) === true ?
								<NeighbourhoodPanel
									property={property}
									neighbourhoodData={neighbourhoodData}
								/>
								:
								selectedPanel === 5 && property.priceHistory !== undefined && property.priceHistory !== null && property.priceHistory.length > 0 ?
								<History
									property={property}
								/>
								:
								null
							}
						</div>
						{
							selectedPanel === 4 ?
							null
							:
							editedProperty !== "" ?
							<FinancialsCalculator
								property={property}
								userDetails={userDetails}
								editedProperty={editedProperty}
								setEditedProperty={setEditedProperty}
								downPayment={downPayment}
								setDownPayment={setDownPayment}
								defaultDownPayment={defaultDownPayment}
								interestRate={interestRate}
								setInterestRate={setInterestRate}
								defaultInterestRate={defaultInterestRate}
								closingPercentage={closingPercentage}
								setClosingPercentage={setClosingPercentage}
								loanYears={loanYears}
								bugModal={bugModal}
								setBugModal={setBugModal}
								highLevelProfits={highLevelProfits}
								setHighLevelProfits={setHighLevelProfits}
								blurred={blurred}
								recurringCosts={recurringCosts}
								spreadsheetProperty={spreadsheetProperty}
								setSpreadsheetProperty={setSpreadsheetProperty}
								costPerSqFoot={costPerSqFoot}
								setCostPerSqFoot={setCostPerSqFoot}
								defaultCostPerSqFoot={defaultCostPerSqFoot}
								totalRehabCost={totalRehabCost}
								setTotalRehabCost={setTotalRehabCost}
								defaultTotalRehabCost={property.livingArea !== undefined && property.livingArea !== null ? Number(property.livingArea) * Number(defaultCostPerSqFoot) : 0}
								userSettings={userSettings}
								cityId={cityId}
								viewRentalComps={viewRentalComps}
								viewArvComps={viewArvComps}
								propertyTaxSetting={propertyTaxSetting}
								setOptimiseDealModal={setOptimiseDealModal}
								acquisitionStrategy={acquisitionStrategy}
								setAcquisitionStrategy={setAcquisitionStrategy}
								exitStrategy={exitStrategy}
								setExitStrategy={setExitStrategy}
								creativeUser={creativeUser}
								creativeAcquisition={creativeAcquisition}
								fullscreenFinancials={fullscreenFinancials}
								setFullscreenFinancials={setFullscreenFinancials}
								searchParams={searchParams}
								setSearchParams={setSearchParams}
								offerLoading={offerLoading}
								setOfferLoading={setOfferLoading}
								setOfferDownloadComplete={setOfferDownloadComplete}
								setOfferURL={setOfferURL}
								changeEditedProperty={changeEditedProperty}
								setChangeEditedProperty={setChangeEditedProperty}
								setPremiumModal={setPremiumModal}
								setShareAgentModal={setShareAgentModal}
								onboarding={onboarding}
								setOnboarding={setOnboarding}
								onboardingStep={onboardingStep}
								selectedTab={selectedTab}
								setSelectedTab={setSelectedTab}
								skipOnboarding={skipOnboarding}
								setOnboardingStep={setOnboardingStep}
								setOnboardingTooltip={setOnboardingTooltip}
								setOnboardingComplete={setOnboardingComplete}
								saveOnboardingComplete={saveOnboardingComplete}
								userId={userId}
							/>
							:
							null
						}
					</div>
					{
						selectedPanel === 1 &&
						<Location
							property={property}
							neighbourhoodData={neighbourhoodData}
							neighbourhoodBounds={neighbourhoodBounds}
							setPremiumModal={setPremiumModal}
						/>
					}
					{
						selectedPanel === 1 &&
						<div className="location-outer-container">
							<div className="property-spacing-border">
							</div>
						</div>
					}
					{
						selectedPanel === 1 &&
						<LastUpdated
							property={property}
						/>
					}
					{
						selectedPanel === 4 && (checkCompsAvailable() === true) ?
						<Comparables
							property={property}
							blurred={blurred}
							propertySearch={propertySearch}
							compsRef={compsRef}
							selectedPanel={selectedCompsPanel}
							setSelectedPanel={setSelectedCompsPanel}
							runComps={runComps}
							savedData={savedSalesCompData}
							setSavedData={setSavedSalesCompData}
							savedRentData={savedRentCompData}
							setSavedRentData={setSavedRentCompData}
							editedProperty={editedProperty}
							setEditedProperty={setEditedProperty}
							loanYears={loanYears}
							applyEstimateModal={applyEstimateModal}
							setApplyEstimateModal={setApplyEstimateModal}
						/>
						:
						null
					}
				</div>
				:
				null
			}
			{
				property.length !== 0 ?
				<Footer />
				:
				null
			}
		</div>
	)
}

export default Property;