import React from 'react';
import '../styles/OnboardingComplete.css';
import { DoubleEspressoMartini } from '../../assets/images';

function OnboardingComplete() {
    return (
        <div className="onboarding-complete-container">
            <div className="onboarding-complete-image-container">
                <img
                    src={DoubleEspressoMartini}
                    className="onboarding-complete-image"
                    alt="Espresso Martini"
                />
            </div>
            <div className="onboarding-complete-text-container">
                <h2 className="heading-large-semibold colour-primary text-align-center">
                    Well done!
                </h2>
                <span className="body-regular colour-primary block-text text-align-center">
                    You've reached the end of the guided tour! 
                    There's much more to discover with Coffee Clozers. 
                    We encourage you to book a free demo with us for a one-on-one tutorial. 
                    If you ever get stuck, we're here to help 😊
                </span>
            </div>
        </div>
    )
};

export default OnboardingComplete;