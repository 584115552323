import React, { useState, useEffect } from 'react';
import '../styles/Properties.css';
import { Header, Footer, Loading, SideNavigation } from '../components';
import { checkOnAuthStateChanged, cloudFunctionV2, getAPIGatewayDetail, getUserDocuments, goToCreativeCheckout, recordEvent, submitCreateList, submitDeleteProperties, submitListChanges, submitPropertyNote } from '../functions';
import { useNavigate, useLocation } from 'react-router-dom';
import { PropertySection, BulkAction, Modal, ZeroState, Toast, PropertiesPagination } from '../v4/components';
import { Download, TickCircle, HeartActive } from '../assets/icons';
import { colour, defaultUserData } from '../styles/GlobalStyles';
import { AddToList, DeleteProperties, DeleteProperty, EditNote, SingleInput, Upgrade } from '../v4/modalbody';

function Properties() {

    const [userId, setUserId] = useState("");
    const [userData, setUserData] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const [favourites, setFavourites] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [searchParams, setSearchParams] = useSearchParams({});
    // const getSelectedOption = searchParams.get("tab");
    // const [selectedOption, setSelectedOption] = useState(getSelectedOption === null ? 0 : parseInt(getSelectedOption));
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    // const [showExitScreen, setShowExitScreen] = useState(false);
    // const [cardsView, setCardsView] = useState(true);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [listModal, setListModal] = useState(false);
    const [lists, setLists] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [listLoading, setListLoading] = useState(false);
    const [createListModal, setCreateListModal] = useState(false);
    const [newListName, setNewListName] = useState("");
    const [noteModal, setNoteModal] = useState(false);
    const [noteZpid, setNoteZpid] = useState("");
    const [newNote, setNewNote] = useState("");
    const [noteLoading, setNoteLoading] = useState(false);
    const [deletePropertiesModal, setDeletePropertiesModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [unfavouriteModal, setUnfavouriteModal] = useState(false);
    const [unfavouriteProperty, setUnfavouriteProperty] = useState(null);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadComplete, setDownloadComplete] = useState(false);
    const [downloadError, setDownloadError] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null);
    const [premiumModal, setPremiumModal] = useState(false);
    const [page, setPage] = useState(1);
    const [totalFavourites, setTotalFavourites] = useState(0);
    const [allProperties, setAllProperties] = useState([]);
    const [propertiesOnPageChange, setPropertiesOnPageChange] = useState(false);
    const favouriteLimit = 8;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        document.title = "Coffee Clozers | Properties";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                setUserId(user.userId);
                const collections = [
                    "Users",
                    "Subscriptions",
                    "Favourites",
                    "Lists"
                ];
                const queryData = await getUserDocuments(collections, user.userId);
                if ( queryData[0].status === 200 ) {
                    setUserData(queryData[0].data);
                }
                if ( queryData[1].status === 200 ) {
                    setSubscriptions(queryData[1].data);
                }
                if ( queryData[2].status === 200 ) {
                    await queryProperties(queryData[2].data);
                    setAllProperties(queryData[2].data);
                }

                if ( queryData[3].status === 200 ) {
                    setLists(queryData[3].data);
                }
            }
            else {
                navigate("/sign-up");
            }
            setLoading(false);
        };

        const queryProperties = async(props) => {
            props.sort((a, b) => {
                const dateA = a.date?.seconds || 0;
                const dateB = b.date?.seconds || 0;
                return dateB - dateA;
            });
            setTotalFavourites(props.length);
            const userSettings = defaultUserData.settings;

            const minVal = (page - 1) * favouriteLimit;
            const maxVal = page * favouriteLimit;
            const propertiesInScope = props.slice(minVal, maxVal);

            const newProps = [];
            for (let index = 0; index < propertiesInScope.length; index++) {
                const zpid = propertiesInScope[index].zpid;
                const cityId = propertiesInScope[index].cityId;
                const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
                const getGatewayQueryString = await getAPIGatewayDetail(cityId, zpid, userSettings);
                const getGatewayParams = {
                    type: "gateway",
                    resourceId: "detail",
                    queryString: getGatewayQueryString
                };
                const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
                if ( getGateway.status === 200 ) {
                    const propertyObject = getGateway.body.prop;
                    if ( propertyObject === null ) {
                        continue;
                    }
                    const propertyInScope = propertiesInScope[index];
                    propertyObject.note = propertyInScope.note !== undefined && propertyObject.note !== null ? propertiesInScope[index].note : null;
                    propertyObject.offerSent = propertyInScope.offerSent !== undefined ? propertiesInScope[index].offerSent : false;
                    newProps.push(propertyObject);
                }
            };
            setFavourites(newProps);
        };

        const pageChangeLoad = async() => {
            await queryProperties(allProperties);
            setLoading(false);
        };

        if ( userId === "" ) {
            fetchUserData();
        }
        else if ( propertiesOnPageChange === true ) {
            setPropertiesOnPageChange(false);
            setLoading(true);
            pageChangeLoad();
        }
    }, [allProperties, navigate, page, userId, propertiesOnPageChange]);

    // const options = [
    //     "All",
    //     "Favorites",
    //     "Searches",
    //     "Offers sent"
    // ];

    // const sortOptions = [
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     },
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     },
    //     {
    //         selected: false,
    //         label: "Alphabetically",
    //         disabled: false
    //     }
    // ];

    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    //     setShowExitScreen(!dropdownOpen);
    // };

    // const selectOption = (index) => {
    //     setSelectedOption(index);
    //     setSearchParams({ tab: index });
    // };

    // const search = () => {

    // };

    // const selectSorting = () => {

    // };

    // const swapView = (view) => {
    //     setCardsView(view);
    // };

    // const exitScreenClick = () => {
    //     setDropdownOpen(false);
    //     setShowExitScreen(false);
    // };

    const closeListModal = () => {
        setListModal(false);
        setSelectedList([]);
    };

    const showCreateListModal = async() => {
        setCreateListModal(true);
        setListModal(false);
    };

    const closeCreateListModal = () => {
        setCreateListModal(false);
    };

    const createList = async() => {
        await submitCreateList(
            setListLoading,
            setLists,
            setCreateListModal,
            setListModal,
            userId,
            newListName
        );
    };

    const confirmList = async() => {
        await submitListChanges(
            setListLoading,
            setLists,
            setListModal,
            setSelectedList,
            setSelectedProperties,
            userId,
            selectedList,
            selectedProperties,
            favourites
        );
    };

    const openNoteModal = (zpid) => {
        setNoteModal(true);
        setNoteZpid(zpid);
        
        // add existing note to newNote
        const favouriteIndex = favourites.findIndex(e => e.zpid === zpid);
        if ( favouriteIndex !== -1 ) {
            setNewNote(favourites[favouriteIndex].note !== undefined && favourites[favouriteIndex].note !== null ? favourites[favouriteIndex].note : "");
        }
    };

    const closeNoteModal = () => {
        setNoteModal(false);
        setNoteZpid("");
        setNewNote("");
    };

    const submitNote = async() => {
        await submitPropertyNote(
            setNoteLoading,
            setNewNote,
            setNoteModal,
            setNoteZpid,
            userId,
            noteZpid,
            newNote,
            favourites,
            setFavourites
        );
    };

    const openDeletePropertiesModal = () => {
        setDeletePropertiesModal(true);
    };

    const closeDeletePropertiesModal = () => {
        setDeletePropertiesModal(false);
    };

    const deleteProperties = async() => {
        await submitDeleteProperties(
            userId,
            selectedProperties,
            setDeletePropertiesModal,
            setDeleteLoading,
            setSelectedProperties,
            setFavourites,
            favourites,
            setLists,
            setSelectedList,
            null,
            null
        );
    };

    const closeUnfavouritePropertiesModal = () => {
        setUnfavouriteModal(false);
        setUnfavouriteProperty(null);
    };

    const openUnfavouritePropertiesModal = (property) => {
        setUnfavouriteModal(true);
        setUnfavouriteProperty(property);
    };

    const deleteSingleProperty = async() => {
        await submitDeleteProperties(
            userId,
            [unfavouriteProperty.zpid],
            setUnfavouriteModal,
            setDeleteLoading,
            setSelectedProperties,
            setFavourites,
            favourites,
            setLists,
            setSelectedList,
            null,
            null
        );
        setUnfavouriteModal(false);
        setUnfavouriteProperty(null);
    };

    const closeToast = () => {
        setDownloadLoading(false);
        setDownloadComplete(false);
        setDownloadLink(null);
        setDownloadError(false);
    };

    const openCSVDownload = () => {
        window.open(downloadLink, "_blank");
    };

    const closePremiumModal = () => {
        setPremiumModal(false);
    };

    const viewPricing = () => {
        navigate("/pricing");
    };

    const upgradeNow = () => {
        goToCreativeCheckout(navigate);
    };

    const explore = () => {
        recordEvent("Properties Zero State - View Sample Market", {});
        navigate("/properties-list/CTY12057")
    };

    return (
        <div className="properties-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="properties-inner-container relative-container">
                {/* {
                    showExitScreen === true &&
                    <ExitScreen
                        clickScreen={() => exitScreenClick()}
                    />
                } */}
                {
                    (listModal === true || createListModal === true || noteModal === true || deletePropertiesModal === true || unfavouriteModal === true) &&
                    <div className="dark-bg">
                    </div>
                }
                {
                    premiumModal === true &&
                    <Modal
                        heading="Plus Feature 👀 "
                        subheading={"You've discovered a Plus Plan feature!"}
                        body={<Upgrade />}
                        type="secondary"
                        cancelText="Maybe later"
                        cancelButtonCTA={closePremiumModal}
                        actionButtonText="Upgrade now"
                        actionButtonCTA={upgradeNow}
                        actionButtonDisabled={false}
                        secondaryButtonText="See pricing plans"
                        secondaryButtonCTA={viewPricing}
                        exitButtonCTA={closePremiumModal}
                        loading={false}
                        leftIcon={null}
					    rightIcon={null}
                        cancelLeftIcon={null}
                        cancelRightIcon={null}
                        customClass={null}
                    />
                }
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                    subscriptions={subscriptions}
                />
                <div className="properties-body-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        <div className="properties-inner-body-container relative-container">
                            {
                                listModal === true ?
                                <Modal
                                    heading="Add to list"
                                    subheading={null}
                                    body={lists.length === 0 ? <ZeroState icon={<HeartActive className="zero-state-icon" fill={colour.grayScaleGray02} />} standardIcon={false} title="No lists yet" subtitle="Create a list to start organizing your properties." showButton={false} /> : <AddToList lists={lists} favourites={favourites} selectedList={selectedList} setSelectedList={setSelectedList} />}
                                    type={lists.length === 0 ? "single" : "secondary"}
                                    cancelText={lists.length === 0 ? "Cancel" : "Clear selection"}
                                    cancelButtonCTA={closeListModal}
                                    actionButtonText={lists.length === 0 ? "Create list" : "Confirm selection"}
                                    actionButtonCTA={lists.length === 0 ? showCreateListModal : confirmList}
                                    actionButtonDisabled={listLoading === true ? true : false}
                                    secondaryButtonText="New list"
                                    secondaryButtonCTA={showCreateListModal}
                                    exitButtonCTA={closeListModal}
                                    loading={listLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={null}
                                />
                                :
                                createListModal === true ?
                                <Modal
                                    heading="Create list"
                                    subheading={null}
                                    body={<SingleInput val={newListName} setVal={setNewListName} placeholder="Name your list" />}
                                    type={"basic"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeCreateListModal}
                                    actionButtonText={"Create list"}
                                    actionButtonDisabled={newListName === "" ? true : false}
                                    actionButtonCTA={createList}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeCreateListModal}
                                    loading={listLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={null}
                                />
                                :
                                noteModal === true ?
                                <Modal
                                    heading="Edit note"
                                    subheading={null}
                                    body={<EditNote val={newNote} setVal={setNewNote} placeholder="Write a note on this listing" />}
                                    type={"basic"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeNoteModal}
                                    actionButtonText={"Save"}
                                    actionButtonDisabled={noteLoading === true || newNote === "" ? true : false}
                                    actionButtonCTA={submitNote}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeNoteModal}
                                    loading={noteLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={null}
                                />
                                :
                                deletePropertiesModal === true ?
                                <Modal
                                    heading="Remove properties"
                                    subheading={null}
                                    body={<DeleteProperties selectedProperties={selectedProperties} />}
                                    type={"destructive"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeDeletePropertiesModal}
                                    actionButtonText={"Remove properties"}
                                    actionButtonDisabled={deleteLoading === true ? true : false}
                                    actionButtonCTA={deleteProperties}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeDeletePropertiesModal}
                                    loading={deleteLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={null}
                                />
                                :
                                null
                            }
                            {
                                unfavouriteModal === true &&
                                <Modal
                                    heading="Remove property"
                                    subheading={null}
                                    body={<DeleteProperty property={unfavouriteProperty} />}
                                    type={"destructive"}
                                    cancelText={"Cancel"}
                                    cancelButtonCTA={closeUnfavouritePropertiesModal}
                                    actionButtonText={"Remove property"}
                                    actionButtonDisabled={deleteLoading === true ? true : false}
                                    actionButtonCTA={deleteSingleProperty}
                                    secondaryButtonText=""
                                    secondaryButtonCTA={null}
                                    exitButtonCTA={closeUnfavouritePropertiesModal}
                                    loading={deleteLoading}
                                    leftIcon={null}
					                rightIcon={null}
                                    cancelLeftIcon={null}
                                    cancelRightIcon={null}
                                    customClass={null}
                                />
                            }
                            <div className="property-top-container">
                                <div className="properties-title-container">
                                    <h1 className="heading-large-semibold colour-primary">
                                        Properties
                                    </h1>
                                </div>
                                {/* <div className="properties-top-bar-container">
                                    <div className="properties-chips-container">
                                        {
                                            options.map((item, index) =>
                                                <FilterChip
                                                    label={item}
                                                    selected={selectedOption}
                                                    index={index}
                                                    disabled={false}
                                                    leftIcon={null}
                                                    rightIcon={null}
                                                    func={selectOption}
                                                    key={index}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="properties-filters-container">
                                        <div 
                                            className="text-button button-row gap-x-small"
                                            onClick={() => search()}
                                        >
                                            <img
                                                src={SearchBlue}
                                                className="properties-search-icon"
                                                alt="Search"
                                            />
                                            <span className="body-regular text-link">
                                                Search
                                            </span>
                                        </div>
                                        <div className="properties-sort-container">
                                            <Dropdown
                                                open={dropdownOpen}
                                                icon={Sort}
                                                label={"Sort"}
                                                disabled={false}
                                                options={sortOptions}
                                                multiselect={false}
                                                toggleDropdown={toggleDropdown}
                                                cta={selectSorting}
                                                sidealign={"left"}
                                                topalign={"bottom"}
                                                indexSelect={false}
                                                selectedIndex={null}
                                            />
                                        </div>
                                        <div className="properties-view-split-container">
                                            <ViewSplit
                                                leftOption={cardsView}
                                                leftImage={CardsView}
                                                leftImageSelected={CardsViewActive}
                                                rightImage={ListView}
                                                rightImageSelected={ListViewActive}
                                                clickFunc={swapView}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="properties-sections-container">
                                {
                                    lists.length > 0 &&
                                    <PropertySection
                                        title="Lists"
                                        type="lists"
                                        data={lists}
                                        favourites={favourites}
                                        setFavourites={setFavourites}
                                        userData={userData}
                                        selectedProperties={selectedProperties}
                                        setSelectedProperties={setSelectedProperties}
                                        subscriptions={subscriptions}
                                        openNoteModal={openNoteModal}
                                        lists={lists}
                                        openUnfavouritePropertiesModal={openUnfavouritePropertiesModal}
                                    />
                                }
                                {
                                    favourites.length === 0 ?
                                    <ZeroState
                                        icon={<HeartActive className="properties-zero-state-icon" fill={colour.grayScaleGray02} />}
                                        standardIcon={false}
                                        title="No properties yet"
                                        subtitle="Saved properties, search history, and properties you've generated offers for will show up here. Start exploring or search for an address to get started."
                                        showButton={true}
                                        buttonText="Start exploring"
                                        actionButtonCTA={explore}
                                    />
                                    :
                                    <PropertySection
                                        title="Other"
                                        type="favourites"
                                        data={favourites}
                                        favourites={favourites}
                                        setFavourites={setFavourites}
                                        userData={userData}
                                        selectedProperties={selectedProperties}
                                        setSelectedProperties={setSelectedProperties}
                                        subscriptions={subscriptions}
                                        openNoteModal={openNoteModal}
                                        lists={lists}
                                        openUnfavouritePropertiesModal={openUnfavouritePropertiesModal}
                                    />
                                }
                            </div>
                        </div>
                    }
                    {
                        selectedProperties.length > 0 && listModal === false &&
                        <BulkAction
                            selectedProperties={selectedProperties}
                            setListModal={setListModal}
                            lists={lists}
                            setSelectedList={setSelectedList}
                            openDeletePropertiesModal={openDeletePropertiesModal}
                            listView={false}
                            properties={favourites}
                            setDownloadLoading={setDownloadLoading}
                            setDownloadComplete={setDownloadComplete}
                            setDownloadError={setDownloadError}
                            setDownloadLink={setDownloadLink}
                        />   
                    }
                    {
                        (downloadLoading === true || downloadComplete === true || downloadError === true) &&
                        <Toast
                            type={downloadComplete === true ? "link" : downloadError === true ? "default" : "loading"}
                            label={downloadError === true ? "There was an error downloading your data" : downloadComplete === true ? "Download successful" : "Downloading CSV file"}
                            icon={downloadError === true ? null : downloadComplete === true ? <TickCircle fill={colour.grayScaleWhite} /> : <Download className="" fill={colour.grayScaleWhite} />}
                            standardIcon={false}
                            linkFunc={openCSVDownload}
					        linkText={downloadLink === null ? null : "Click here to view"}
                            closeFunc={closeToast}
                            undoFunc={null}
                        />
                    }
                    {
                        totalFavourites > favouriteLimit && loading === false &&
                        <PropertiesPagination
                            total={totalFavourites}
                            page={page}
                            setPage={setPage}
                            maxPerPage={favouriteLimit}
                            setPropertiesOnPageChange={setPropertiesOnPageChange}
                        />
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Properties;