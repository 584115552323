import React from 'react';

function QuickReport(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="23" 
            height="21" 
            viewBox="0 0 23 21" 
            fill="none"
            className={className}
        >
            <path 
                d="M11.7375 0.358135C12.0088 0.108398 12.3767 -0.0111512 12.738 0.000816965C13.0992 -0.0106193 13.4665 0.10893 13.7367 0.358267L22.594 8.51713C23.097 8.98043 23.0789 9.71555 22.5536 10.1592C22.0283 10.6028 21.1949 10.5868 20.6919 10.1235L12.7374 2.79579L8.72113 6.49521C7.93079 6.23556 7.07628 6.09945 6.18748 6.11275C5.93474 6.11654 5.68517 6.13236 5.43943 6.15962L11.7375 0.358135Z" 
                fill={fill}
            />
            <path 
                d="M12.5412 15.4726L15.427 17.9918H20.4621C21.1442 17.9918 21.6972 17.5041 21.6972 16.9025V10.7536C21.4855 10.7585 21.2733 10.7187 21.0797 10.6346V16.9025C21.0797 17.2033 20.8035 17.4471 20.4621 17.4471H19.8446V10.6386C19.8446 10.5824 19.8253 10.5303 19.7927 10.4871C19.7372 10.4142 19.6432 10.3662 19.5358 10.3662H12.9833C13.2298 10.9937 13.3695 11.6662 13.3824 12.3664C13.4032 13.4909 13.0953 14.5488 12.5412 15.4726Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M9.80559 17.0863C8.8363 17.6833 7.66496 18.0417 6.40828 18.0605C2.98601 18.1118 0.141672 15.6401 0.0848466 12.5655C0.0280209 9.49099 2.78735 6.9354 6.20171 6.88428C9.62398 6.83305 12.4683 9.30475 12.5251 12.3793C12.5472 13.5722 12.1486 14.679 11.4541 15.5985L14.9495 18.65C15.1745 18.8455 15.2821 19.1067 15.2871 19.3765C15.2979 19.9588 14.8243 20.4275 14.1683 20.4373C13.8521 20.4421 13.5579 20.347 13.325 20.1516L9.80559 17.0863ZM1.74461 12.5407C1.78621 14.7915 3.87527 16.6069 6.38072 16.5694C8.88617 16.5319 10.907 14.655 10.8654 12.4041C10.8238 10.1533 8.73472 8.33788 6.22927 8.37539C3.72383 8.4129 1.70301 10.2898 1.74461 12.5407Z" 
                fill={fill}
            />
        </svg>
    )
};

export default QuickReport;