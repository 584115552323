import React from 'react';
import '../styles/Tooltip.css';
import { colour } from '../../styles/GlobalStyles';
import { Beak, ModalExit } from '../../assets/icons';
import { IconButton } from './';

function Tooltip(props) {
    const type = props.type;
    const title = props.title;
    const body = props.body;
    const position = props.position;
    const className = `tooltip-beak-${position}`;
    const contextTooltipBg = props.contextTooltipBg;
    const visible = props.visible;
    const exit = props.exit;
    const exitFunc = props.exitFunc;

    return (
        type === "info" ?
        <div className="tooltip-outer-container">
            <div className="tooltip-inner-container relative-container">
                {
                    exit === true &&
                    <IconButton
                        size="medium"
                        icon={<ModalExit fill={colour.grayScaleBlack} className="tooltip-exit-icon" />}
                        disabled={false}
                        clickFunc={exitFunc}
                        standardIcon={false}
                        contextTooltip={false}
                        contextTooltipBG={null}
                        contextTooltipText={null}
                        customClass={"tooltip-exit-container"}
                    />
                }
                <div className="tooltip-beak-container">
                     <Beak
                        className={"tooltip-beak " + (className)}
                        fill={colour.grayScaleWhite}
                    />
                </div>
                <div className="tooltip-text-container">
                    {
                        title !== null &&
                        <h2 className="body-semibold colour-primary">
                            {title}
                        </h2>
                    }
                    <span className="body-regular colour-primary">
                        {body}
                    </span>
                </div>
            </div>
        </div>
        :
        <div className={"tooltip-context-outer-container " + (visible === true ? "visible " : "") + (contextTooltipBg === "dark" ? "bg-colour-dark" : "bg-colour-white")}>
            <div className="tooltip-context-inner-container">
                <span className={"body-regular block-text text-align-center " + (contextTooltipBg === "dark" ? "colour-white" : "colour-primary")}>
                    {body}
                </span>
            </div>
        </div>
    )
};

export default Tooltip;