import React from 'react';

function ModalExit(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M3.25756 0.419021C2.78957 -0.100741 1.98883 -0.142706 1.46907 0.32529C0.949308 0.793285 0.907343 1.59402 1.37534 2.11378L10.2961 12.0213L1.41362 21.8862C0.945627 22.406 0.987591 23.2067 1.50735 23.6747C2.02711 24.1427 2.82785 24.1007 3.29585 23.581L12.0002 13.9138L20.7045 23.5809C21.1724 24.1007 21.9732 24.1427 22.4929 23.6747C23.0127 23.2067 23.0547 22.4059 22.5867 21.8862L13.7043 12.0213L22.625 2.11382C23.093 1.59406 23.051 0.793322 22.5312 0.325326C22.0115 -0.142669 21.2107 -0.100704 20.7427 0.419057L12.0002 10.1287L3.25756 0.419021Z" 
                fill={fill}
            />
        </svg>
    )
};

export default ModalExit;