import React from 'react';
import '../styles/OrDivider.css';

function OrDivider() {
    return (
        <div className="or-divider-container">
            <div className="or-divider-line">
            </div>
            <span className="body-regular colour-secondary block-text text-align-center">
                or
            </span>
            <div className="or-divider-line">
            </div>
        </div>
    )
};

export default OrDivider;