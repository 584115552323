import React from 'react';
import '../styles/UnsubscribeText.css';

function UnsubscribeText() {
    return (
        <div className="unsubscribe-text-container">
            <span className="body-regular colour-primary">
                Unsubscribe from all buy box notifications by clicking <span className="body-semibold">“Unsubscribe”</span>
            </span>
            <span className="body-regular colour-primary">
                If you prefer to simply change notification frequency, simple hit <span className="body-semibold">“Cancel”</span>
                and manage notification frequency from the dropdown on each individual Buy Box.
            </span>
        </div>
    )
};

export default UnsubscribeText;