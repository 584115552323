import React, { useEffect } from 'react';
import '../styles/RepeatUser.css';
import { Footer, Header } from '../components';
import { recordEvent } from '../functions';
import { Button } from '../v4/components';

function RepeatUser() {

    useEffect(() => {
        document.title = "Repeat User | Coffee Clozers";
    }, []);

    const openEmail = () => {
        recordEvent("Repeat User - Contact Us Clicked", {});
        window.location.href = "mailto:info@coffeeclozers.com";
    };

    return (
        <div className="repeat-user-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
                desktopControl={true}
            />
            <div className="repeat-user-inner-container">
                <h1 className="heading-large-semibold colour-primary text-align-center">
                    Have you already had your free trial? 🤔
                </h1>
                <div className="repeat-user-body-container">
                    <p className="body-regular colour-primary block-text text-align-center">
                        We may be mistaken but we think you've already
                        used your free trial on another account.
                        <br/>
                        <br/>
                        Please contact us if you feel this is a mistake.
                    </p>
                </div>
                <img
                    src="https://media.tenor.com/eh5z0wkCNzgAAAAd/naughty-naughty-naughty.gif"
                    className="borat-image"
                    alt="Multiple Free Trials Used"
                />
                <div className="repeat-user-button-container">
                    <Button
                        buttonType={"primary-button"}
                        size="large"
                        text={"Contact us"}
                        cta={openEmail}
                        disabled={false}
                        leftIcon={null}
                        leftIconClass=""
                        rightIcon={null}
                        rightIconClass=""
                        alt=""
                        standardIcon={false}
                        badge={false}
                        badgeVal={null}
                        badgeBG={null}
                        buttonRef={null}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RepeatUser;