import React, { useState, useEffect, useRef } from 'react';
import '../styles/FinancialsCalculator.css';
import { ReportFinancialsBug, RentTab, PurchaseTab, RehabTab, MonthlyCostsTab, HoldingCosts, ExitScreen, SellerFinanceCalculator, SubtoCalculator, LeaseOptionRent, STRRent } from '../../components';
import { calculateARVSpread, calculateCashOnCash, calculateMortgagePayment, formatHighLevelProfits, savePropertyChanges, saveSpreadsheetProperty, checkUserSettingsOnCosts, queryOfferREI, checkNaN, calculateSubToMortgagePayment, recordEvent } from '../../functions';
import { BedroomBlack, ADU } from '../../assets';
import { CreativeAnalysis, FinancialsCalculatorFooter, FinancialsCalculatorHeader, OnboardingTooltip, SummaryTab, VerticalTab } from './';
import { formatterLong } from '../../styles/GlobalStyles';
import { ListingsOnboarding } from '../tooltipbody';


function FinancialsCalculator(props) {
    const property = props.property;
	const userDetails = props.userDetails;
	const editedProperty = props.editedProperty;
	const setEditedProperty = props.setEditedProperty;
	const downPayment = props.downPayment;
	const setDownPayment = props.setDownPayment;
	const defaultDownPayment = props.defaultDownPayment;
	const interestRate = props.interestRate;
	const setInterestRate = props.setInterestRate;
	const defaultInterestRate = props.defaultInterestRate;
	const closingPercentage = props.closingPercentage;
	const setClosingPercentage = props.setClosingPercentage;
	const loanYears = props.loanYears;
	const bugModal = props.bugModal;
	const setBugModal = props.setBugModal;
	const highLevelProfits = props.highLevelProfits;
	const setHighLevelProfits = props.setHighLevelProfits;
	const blurred = props.blurred;
	const recurringCosts = props.recurringCosts;
	const spreadsheetProperty = props.spreadsheetProperty;
	const setSpreadsheetProperty = props.setSpreadsheetProperty;
	const costPerSqFoot = props.costPerSqFoot;
	const setCostPerSqFoot = props.setCostPerSqFoot;
	const defaultCostPerSqFoot = props.defaultCostPerSqFoot;
	const totalRehabCost = props.totalRehabCost;
	const setTotalRehabCost = props.setTotalRehabCost;
	const defaultTotalRehabCost = props.defaultTotalRehabCost;
	const userSettings = props.userSettings;
	const cityId = props.cityId;
	const viewRentalComps = props.viewRentalComps;
	const viewArvComps = props.viewArvComps;
	const propertyTaxSetting = props.propertyTaxSetting;
	const setOptimiseDealModal = props.setOptimiseDealModal;
	const acquisitionStrategy = props.acquisitionStrategy;
	const setAcquisitionStrategy = props.setAcquisitionStrategy;
	const exitStrategy = props.exitStrategy;
	const setExitStrategy = props.setExitStrategy;
	const creativeUser = props.creativeUser;
	const creativeAcquisition = props.creativeAcquisition;
	const fullscreenFinancials = props.fullscreenFinancials;
	const setFullscreenFinancials = props.setFullscreenFinancials;
	const offerLoading = props.offerLoading;
	const setOfferLoading = props.setOfferLoading;
	const setOfferDownloadComplete = props.setOfferDownloadComplete;
	const setOfferURL = props.setOfferURL;
	const changeEditedProperty = props.changeEditedProperty;
	const setChangeEditedProperty = props.setChangeEditedProperty;
	const setPremiumModal = props.setPremiumModal;
	const setShareAgentModal = props.setShareAgentModal;
	const onboarding = props.onboarding;
	const onboardingStep = props.onboardingStep;
	const selectedTab = props.selectedTab;
	const setSelectedTab = props.setSelectedTab;
	const sectionContainerRef = useRef(null);
	const skipOnboarding = props.skipOnboarding;
	const setOnboardingStep = props.setOnboardingStep;
	const setOnboarding = props.setOnboarding;
	const setOnboardingTooltip = props.setOnboardingTooltip;
	const setOnboardingComplete = props.setOnboardingComplete;
	const saveOnboardingComplete = props.saveOnboardingComplete;
	const userId = props.userId;
	// const searchParams = props.searchParams;
	// const setSearchParams = props.setSearchParams;

	const multipleUniqueUnits = property.uniqueUnitPropertyDetails === null || property.uniqueUnitPropertyDetails === undefined ? false : property.uniqueUnitPropertyDetails.length > 1 ? true : false;
	const [doneSettings, setDoneSettings] = useState(true);
	const [firstRender, setFirstRender] = useState(false);
	const [brrrrPropertyTaxRate] = useState(propertyTaxSetting === null ? property.apiFinancialParams.propertyTaxRate : propertyTaxSetting);
	const [monthlyCosts, setMonthlyCosts] = useState([]);
	const [arv, setArv] = useState(property.arvComps === true ? property.arvCompsStats.arvPrice : property.price);
	const [defaultArv] = useState(property.arvComps === true ? property.arvCompsStats.arvPrice : property.price);
	const [arvFinancingPercentage, setArvFinancingPercentage] = useState(75);
	const arvFinancing = (arv * Number(arvFinancingPercentage)) / 100;
	const [customArv, setCustomArv] = useState(0);
    const [unitsRent, setUnitsRent] = useState([]);
	const [houseHackUnitsRent, setHouseHackUnitsRent] = useState([]);
	const [houseHackBedroomsRent, setHouseHackBedroomsRent] = useState([]);
	const [multiUnitRent, setMultiUnitRent] = useState([]);
	const [houseHackMultiUnitsRent, setHouseHackMultiUnitsRent] = useState([]);
    const financialOptions = [
		{
            title: "Summary",
            active: selectedTab === 0 ? true : false
        },
        {
            title: exitStrategy === "lo" ? "Lease option" : "Rent",
            active: selectedTab === 1 ? true : false
        },
        {
            title: "Purchase",
            active: selectedTab === 2 ? true : false
        },
        {
            title: "Rehab",
            active: selectedTab === 3 ? true : false
        },
        {
            title: "Monthly costs",
            active: selectedTab === 4 ? true : false
        },
		{
            title: "Holding costs",
            active: selectedTab === 5 ? true : false
        }
    ];

    const [rent, setRent] = useState(property.financials.rent);
	const [houseHackRent, setHouseHackRent] = useState(0);
	const [multiUnitRentTotal, setMultiUnitRentTotal] = useState(0);
	const [multiUnitHouseHackRentTotal, setMultiUnitHouseHackRentTotal] = useState(0);
	const [unitLiving, setUnitLiving] = useState(0);
	const [multiUnitTypeUnitLiving, setMultiUnitTypeUnitLiving] = useState([0, 0]);
	const [defaultHouseHackRent, setDefaultHouseHackRent] = useState(0);
	const [defaultMultiUnitRent, setDefaultMultiUnitRent] = useState(0);
	const [defaultMultiUnitHouseHackRentTotal, setDefaultMultiUnitHouseHackRentTotal] = useState(0);
	const [multiUnitRentRatio, setMultiUnitRentRatio] = useState([1, 1]);
	const [customDiscount, setCustomDiscount] = useState(Math.round(((property.price - property.price) / property.price) * 100));
	const [customPrice, setCustomPrice] = useState(property.price);
	const [monthlyHoldingCosts, setMonthlyHoldingCosts] = useState(0);
	const [holdingPeriod, setHoldingPeriod] = useState(4);
	const [totalHoldingCosts, setTotalHoldingCosts] = useState(monthlyHoldingCosts * holdingPeriod);
	const [defaultValuesPanel, setDefaultValuesPanel] = useState([false, false, false, false, false]);
	const [valueAddOptions, setValueAddOptions] = useState(
        property.addBedOpportunity === true && property.zonedForAdu === true ?
        [
            {
                label: "Add a bedroom",
                active: false,
                icon: BedroomBlack,
                inputLabel: "Additional rent income",
				value: 0,
				original: 0
            },
            {
                label: "ADU",
                active: false,
                icon: ADU,
                inputLabel: "ADU rent income",
				value: 0,
				original: 0
            }
        ]
        :
        property.addBedOpportunity === true ?
        [
            {
                label: "Add a bedroom",
                active: false,
                icon: BedroomBlack,
                inputLabel: "Additional rent income",
				value: 0,
				original: 0
            }
        ]
        :
        property.zonedForAdu === true ?
        [
            {
                label: "ADU",
                active: false,
                icon: ADU,
                inputLabel: "ADU rent income",
				value: 0,
				original: 0
            }
        ]
        :
        []
    );
	const [profitChange, setProfitChange] = useState(false);
	const [acquisitionDropdownOpen, setAcquisitionDropdownOpen] = useState(false);
	const [exitDropdownOpen, setExitDropdownOpen] = useState(false);
	const [creativeInterestRate, setCreativeInterestRate] = useState(3.5);
	const [balloonPayment, setBalloonPayment] = useState(0);
    const [duration, setDuration] = useState(60);
    const [amortisation, setAmortisation] = useState(360);
	const [sellerCompensation, setSellerCompensation] = useState(customPrice);
	const [monthlyPayment, setMonthlyPayment] = useState(0);
	const [remainingMortgage, setRemainingMortgage] = useState(Number(property.ownerAttributes.estMortgageBalance));
	const [sellersMortgage, setSellersMortgage] = useState(Number(property.ownerAttributes.estMortgagePayment));
	const [ownerEquity, setOwnerEquity] = useState((property.price - remainingMortgage) / property.price);

	const [leaseOptionValue, setLeaseOptionValue] = useState(property.price * 1.05);
	const [leaseOptionDownPayment, setLeaseOptionDownPayment] = useState(downPayment);
	const [leaseOptionInterestRate, setLeaseOptionInterestRate] = useState(8);
	const [leaseOptionDollarDownPayment, setLeaseOptionDollarDownPayment] = useState(leaseOptionValue * (leaseOptionDownPayment / 100));
    const [leaseOptionRent, setLeaseOptionRent] = useState(0);

	const [strRevenue, setStrRevenue] = useState("");
	const onboardingStepTwoClass = acquisitionStrategy === "sf" ? "onboarding-tooltip-third-step-sf" : acquisitionStrategy === "sub" ? "onboarding-tooltip-third-step-sub" : "onboarding-tooltip-third-step";

	const maxPercentage = -100;
	const closingCost = (customPrice * Number(closingPercentage)) / 100;
	const downPaymentPercentage = downPayment / 100;
	const downPaymentCost = (customPrice * downPaymentPercentage);
	const cashInDeal = (Number(downPaymentCost) + Number(totalRehabCost) + Number(closingCost) + Number(totalHoldingCosts)) - Number(arvFinancing);

    useEffect(() => {

		// Multi family home units for BOTH 1 unit layout AND house hack 1 unit layout
        const checkUnits = () => {
            const propertyUnits = property.units;
            const propertyRent = property.financials.rent;
            const newUnits = [];
			const newHouseHackUnits = [];
            for (let index = 0; index < propertyUnits; index++) {
                const newUnit = {
                    value: Math.round(propertyRent / propertyUnits),
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);

				const houseHackRentableUnits = propertyUnits - 1;
				const newHouseHackUnit = {
                    value: Math.round(houseHackRentableUnits === 0 ? 0 : index === unitLiving ? 0 : propertyRent / propertyUnits),
                    label: `Unit ${index + 1}`
                };
                newHouseHackUnits.push(newHouseHackUnit);
            };
            setUnitsRent(newUnits);
			setHouseHackUnitsRent(newHouseHackUnits);
			setHouseHackRent(newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0));
			setDefaultHouseHackRent(newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0));
        };

		// Multi family home units for multi unit layout
		// AND house hack multi unit layout
		const checkUniqueUnits = () => {
			const uniqueUnits = property.uniqueUnitPropertyDetails;
			const letters = ["A", "B", "C", "D"];
			
			const newUnits = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				const unitFrequency = Number(element.frequency);
				const newFrequency = {
					units: [],
					label: `Layout ${letters[index]}`
				};

				for (let ii = 0; ii < unitFrequency; ii++) {
					const newObject = {
						value: Number(element.rentcast_rent),
						original: Number(element.rentcast_rent),
						label: `Unit ${ii + 1}${letters[index]}`
					};
					newFrequency.units.push(newObject);
				};
				newUnits.push(newFrequency);
			};
			setMultiUnitRent(newUnits);
			const newTotal = newUnits.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);
			setDefaultMultiUnitRent(newTotal);

			const uniqueRentArray = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				uniqueRentArray.push(Number(element.rentcast_rent));
			};
			const minimumRentValue = Math.min(...uniqueRentArray);
			const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);
			setMultiUnitTypeUnitLiving([minimumValueIndex, 0]);

			const newHouseHackUnits = [];
			for (let index = 0; index < uniqueUnits.length; index++) {
				const element = uniqueUnits[index];
				const unitFrequency = Number(element.frequency);
				const newFrequency = {
					units: [],
					label: `Layout ${letters[index]}`
				};

				for (let ii = 0; ii < unitFrequency; ii++) {
					const newObject = {
						value: minimumValueIndex === index && ii === 0 ? 0 : Number(element.rentcast_rent),
						original: Number(element.rentcast_rent),
						label: `Unit ${ii + 1}${letters[index]}`
					};
					newFrequency.units.push(newObject);
				};
				newHouseHackUnits.push(newFrequency);
			};
			setHouseHackMultiUnitsRent(newHouseHackUnits);
			const newHouseHackTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitHouseHackRentTotal(newHouseHackTotal);
			setDefaultMultiUnitHouseHackRentTotal(newHouseHackTotal);

			if ( uniqueRentArray.length > 1 ) {
				const firstRentRate = uniqueRentArray[0];
				const secondRentRate = uniqueRentArray[1];
				const ratio = secondRentRate / firstRentRate;
				setMultiUnitRentRatio([1, ratio]);
			}
		};

		// Single family home bedrooms for house hack
		const checkBedrooms = () => {
			const bedrooms = property.bedrooms !== null && property.bedrooms !== undefined ? property.bedrooms : 1;
			const newHouseHackBedrooms = [];
			const totalBedroomsRent = property.houseHackingFlag === true ? property.houseHackingFeatures.totalRentByBedroom : 0;
			const individualBedroomRent = totalBedroomsRent / bedrooms;
			for (let index = 0; index < bedrooms; index++) {
				const newBedroom = {
					value: Math.round(index === unitLiving ? 0 : individualBedroomRent),
					label: `Bedroom ${index + 1}`
				};
				newHouseHackBedrooms.push(newBedroom);
			};
			setHouseHackBedroomsRent(newHouseHackBedrooms);
			setHouseHackRent(newHouseHackBedrooms.reduce((a, b) => a + Number(b.value), 0));
			setDefaultHouseHackRent(newHouseHackBedrooms.reduce((a, b) => a + Number(b.value), 0));
		};

		// Fucntion that checks the property type and the selected
		// strategy panel, and returns the appropriate rent estimate
		const getAppropriateRentEstimateEffect = async() => {
			const defaultRent = property.financials.rent;
			if ( exitStrategy === "lo" ) {
				const newRent = await calculateMortgagePayment(leaseOptionValue, leaseOptionDownPayment, leaseOptionInterestRate, (amortisation / 12));
				setLeaseOptionRent(newRent);
				return newRent;
			}
			else if ( exitStrategy === "hh" ) {
				// This is for house hack mode

				if ( property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" ) {
					// Get house hack bedroom rent
					const houseHackTotalRent = property.houseHackingFlag === true ? property.houseHackingFeatures.totalRentByBedroom : 0;
					const bedrooms = property.bedrooms !== undefined && property.bedrooms !== null ? property.bedrooms : 1;
					const individualBedroomRent = houseHackTotalRent / bedrooms;
					const totalBedroomsRent = (individualBedroomRent * (bedrooms - 1));
					return Number(totalBedroomsRent);
				}
				else if ( multipleUniqueUnits === true ) {
					const uniqueUnits = property.uniqueUnitPropertyDetails;
					const uniqueRentArray = [];
					for (let index = 0; index < uniqueUnits.length; index++) {
						const element = uniqueUnits[index];
						uniqueRentArray.push(Number(element.rentcast_rent));
					};
					const minimumRentValue = Math.min(...uniqueRentArray);
					const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);

					const newHouseHackUnits = [];
					for (let index = 0; index < uniqueUnits.length; index++) {
						const element = uniqueUnits[index];
						const unitFrequency = Number(element.frequency);
						const newFrequency = {
							units: []
						};

						for (let ii = 0; ii < unitFrequency; ii++) {
							const newObject = {
								value: minimumValueIndex === index && ii === 0 ? 0 : Number(element.rentcast_rent)
							};
							newFrequency.units.push(newObject);
						};
						newHouseHackUnits.push(newFrequency);
					};
					const newHouseHackTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
						// Use forEach to iterate over the 'items' array of each object
						currentValue.units.forEach(item => {
						// Add the 'value' property to the accumulator
						accumulator += Number(item.value);
						});
						return accumulator; // Return the accumulator for the next iteration
					}, 0);

					return Number(newHouseHackTotal);
				}
				else {
					const units = property.units !== null && property.units !== undefined ? property.units : 1;
					if ( units === 1 ) {
						return Number(defaultRent);
					}
					else {
						const perUnitRent = Number(defaultRent) / units;
						const totalHouseHackUnitRent = perUnitRent * (units - 1);
						return totalHouseHackUnitRent;
					}
				}
			}
			else {
				// This is for non-house hack mode

				if ( multipleUniqueUnits === true ) {
					return Number(defaultRent);
				}
				else {
					return Number(defaultRent);
				}
			}
		};

		const formatMonthlyCosts = async() => {
			// This is where we format monthly costs to be based off of user settings
			const newArray = [
				{
					label: "Property costs",
					inputs: recurringCosts.slice(0, 4)
				},
				{
					label: "Operating expenses",
					inputs: recurringCosts.slice(4, 6)
				},
				{
					label: "Upkeep",
					inputs: recurringCosts.slice(6, 8)
				}
			];
			const relevantRent = await getAppropriateRentEstimateEffect();

			for (let index = 0; index < newArray.length; index++) {
				const element = newArray[index];
				for (let ii = 0; ii < element.inputs.length; ii++) {
					const input = element.inputs[ii];
					const userSettingOriginal = await checkUserSettingsOnCosts(input, userSettings, cityId, index, ii, customPrice, relevantRent, arv);

					if ( userSettingOriginal.change === true ) {
						// Values based on user settings
						newArray[index].inputs[ii].percentage = true;
						newArray[index].inputs[ii].original = Number(userSettingOriginal.original.toFixed(2));
						newArray[index].inputs[ii].value = Number(userSettingOriginal.original.toFixed(2));
						newArray[index].inputs[ii].percentageVal = userSettingOriginal.percentageVal;
					}
					else {
						// Values based on our defaults
						newArray[index].inputs[ii].percentage = false;
						if ( index === 0 ) {
							const value = Number(newArray[index].inputs[ii].value) * 12;
							const percentageVal = (value / customPrice) * 100;
							newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
						}
						else {
							const value = Number(newArray[index].inputs[ii].value);
							const percentageVal = (value / relevantRent) * 100;
							newArray[index].inputs[ii].percentageVal = Number(percentageVal.toFixed(1));
						}
						if ( input.original === undefined ) {
							newArray[index].inputs[ii].original = input.value;
						}
					}

					if ( index === 0 && ii === 3 ) {
						const brrrrPropertyTax = (((brrrrPropertyTaxRate / 100) * arv) / 12).toFixed(2);
						newArray[0].inputs[3].brrrrValue = Number(brrrrPropertyTax);
						newArray[0].inputs[3].brrrrOriginal = Number(brrrrPropertyTax);
						newArray[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;
					}
				}
			}

			if ( acquisitionStrategy === "sf" ) {
				const newMortgage = await calculateMortgagePayment(customPrice, downPayment, Number(creativeInterestRate), (Number(amortisation) / 12));
				newArray[0].inputs[0].value = newMortgage;
			}
			else if ( acquisitionStrategy === "sub" ) {
				const equityMortgage = await calculateSubToMortgagePayment(customPrice, downPayment, creativeInterestRate, ownerEquity, (Number(amortisation) / 12));
				const newMortgage = Number(sellersMortgage) + equityMortgage;
				newArray[0].inputs[0].value = newMortgage;
			}

			saveHoldingCostTotal(newArray);
			setMonthlyCosts(newArray);
			saveDefaultHighLevelProfits(newArray);
		};

		const checkUserSettings = () => {
			if ( userSettings !== null ) {
				const userRecurringCosts = userSettings.recurringCosts;
				let nonDefault = false;

				for (let index = 0; index < userRecurringCosts.length; index++) {
					const element = userRecurringCosts[index];
					if ( element.default === false ) {
						nonDefault = true;
					}
				}

				if ( userSettings.rehabCosts.default === false ) {
					nonDefault = true;
				}

				if ( nonDefault === false ) {
					setDoneSettings(false);
				}
			}
			else {
				setDoneSettings(false);
			}
		};

		const saveDefaultHighLevelProfits = async(newArray) => {
			if ( highLevelProfits === "" ) {
				const relevantRent = await getAppropriateRentEstimateEffect();
				const getNewMonthlyHoldingCosts = await getNewHoldingCostsEffect(newArray);
				const newTotalHoldingCosts = getNewMonthlyHoldingCosts * holdingPeriod;

				const finalRent = Number(relevantRent);
				const totalMonthlyCosts = newArray.reduce((accumulator, currentValue) => {
					currentValue.inputs.forEach(item => {
						if ( item.label === "Management fee" && exitStrategy === "hh" ) {
		
						}
						else {
							accumulator += Number(item.value);
						}
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);

				const newVals = {
					rent: finalRent,
					downPayment: downPaymentCost,
					interestRate: Number(interestRate),
					closingCost: Number(closingCost),
					rehab: Number(totalRehabCost),
					monthlyCosts: totalMonthlyCosts,
					price: Number(customPrice),
					arv: Number(arv),
					holdingCosts: Number(newTotalHoldingCosts),
					arvFinancing: (arv * Number(arvFinancingPercentage)) / 100
				};

				if ( exitStrategy === "lo" ) {
					const tenantBuyerDownPayment = leaseOptionValue * (leaseOptionDownPayment / 100);
					newVals.tenantBuyerDownPayment = tenantBuyerDownPayment;
				}

				formatHighLevelProfits(
					newVals,
					exitStrategy,
					highLevelProfits,
					setHighLevelProfits
				);
				setProfitChange(true);
			}
		}

		const saveEditedProfits = async(stateChange) => {
			let relevantRent;
			if ( exitStrategy === "hh" ) {
				// This is for house hack mode

				if ( property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse" ) {
					// Get house hack bedroom rent
					relevantRent = editedProperty.houseHackRent;
				}
				else if ( multipleUniqueUnits === true ) {
					relevantRent = editedProperty.multiUnitHouseHackRentTotal;
				}
				else {
					relevantRent = editedProperty.houseHackRent;
				}
			}
			else if ( exitStrategy === "lo" ) {
				relevantRent = Number(editedProperty.leaseOptionRent);
			}
			else if ( exitStrategy === "strRevenue" ) {
				relevantRent = Number(editedProperty.strRevenue);
			}
			else {
				// This is for non-house hack mode

				if ( multipleUniqueUnits === true ) {
					relevantRent = editedProperty.multiUnitRentTotal;
				}
				else {
					relevantRent = editedProperty.rent;
				}
			}

			const defaultValueAddRent = editedProperty.valueAddOptions.reduce((accumulator, item) => {
				if ( item.active === true ) {
					accumulator += Number(item.value);
				}
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			const finalRent = Number(relevantRent) + Number(defaultValueAddRent);

			if ( acquisitionStrategy === "sf" && stateChange === false ) {
				const newMortgage = await calculateMortgagePayment(editedProperty.price, editedProperty.downPayment, Number(editedProperty.creativeInterestRate), (Number(editedProperty.amortisation) / 12));
				editedProperty.monthlyCosts[0].inputs[0].value = newMortgage;
			}
			else if ( acquisitionStrategy === "sub" && stateChange === false ) {
				const equityMortgage = await calculateSubToMortgagePayment(editedProperty.price, editedProperty.downPayment, editedProperty.creativeInterestRate, editedProperty.ownerEquity, (Number(editedProperty.amortisation) / 12));
				const newMortgage = Number(sellersMortgage) + equityMortgage;
				editedProperty.monthlyCosts[0].inputs[0].value = newMortgage;
			}
			
			const totalMonthlyCosts = editedProperty.monthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label === "Management fee" && exitStrategy === "hh" ) {
	
					}
					else if ( item.label === "Property taxes" && exitStrategy === "brrrr" ) {
						accumulator += Number(item.brrrrValue);
					}
					else {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			const checkArvFinancingPercentage = editedProperty.arvFinancingPercentage;
			const newVals = {
				rent: finalRent,
				downPayment: (editedProperty.downPayment / 100) * editedProperty.price,
				interestRate: editedProperty.interestRate,
				closingCost: (editedProperty.closingCost / 100) * editedProperty.price,
				rehab: editedProperty.rehab,
				monthlyCosts: totalMonthlyCosts,
				price: editedProperty.price,
				arv: editedProperty.arv,
				holdingCosts: editedProperty.totalHoldingCosts,
				arvFinancing: (editedProperty.arv * Number(checkArvFinancingPercentage)) / 100
			};

			if ( exitStrategy === "lo" ) {
				newVals.tenantBuyerDownPayment = Number(editedProperty.leaseOptionDollarDownPayment);
			}

			formatHighLevelProfits(
				newVals,
				exitStrategy,
				highLevelProfits,
				setHighLevelProfits
			);
			
			setProfitChange(true);
		};

		const saveEditedValues = (stateChange) => {
			setValueAddOptions(editedProperty.valueAddOptions);
			setMonthlyCosts(editedProperty.monthlyCosts);
			setRent(editedProperty.rent);
			setHouseHackRent(editedProperty.houseHackRent);
			setUnitsRent(editedProperty.unitsRent);
			setHouseHackUnitsRent(editedProperty.houseHackUnitsRent);
			setHouseHackBedroomsRent(editedProperty.houseHackBedroomsRent);
			setMultiUnitRent(editedProperty.multiUnitRent);
			setMultiUnitRentTotal(editedProperty.multiUnitRentTotal);
			setMultiUnitRentRatio(editedProperty.multiUnitRentRatio);
			setHouseHackMultiUnitsRent(editedProperty.houseHackMultiUnitsRent);
			setMultiUnitHouseHackRentTotal(editedProperty.multiUnitHouseHackRentTotal);
			setMultiUnitTypeUnitLiving(editedProperty.multiUnitTypeUnitLiving);
			setUnitLiving(editedProperty.unitLiving);
			setDownPayment(editedProperty.downPayment);
			setLeaseOptionDownPayment(editedProperty.downPayment);
			setLeaseOptionDollarDownPayment(leaseOptionValue * (editedProperty.downPayment / 100))
			setInterestRate(editedProperty.interestRate);
			setCreativeInterestRate(editedProperty.creativeInterestRate);
			setClosingPercentage(editedProperty.closingCost);
			setTotalRehabCost(editedProperty.rehab);
			setCustomPrice(editedProperty.price);
			setArv(editedProperty.arv);
			setTotalHoldingCosts(editedProperty.totalHoldingCosts);
			setHoldingPeriod(editedProperty.holdingPeriod);
			setMonthlyHoldingCosts(editedProperty.monthlyHoldingCosts);
			setCostPerSqFoot(editedProperty.costPerSqFoot);
			setArvFinancingPercentage(editedProperty.arvFinancingPercentage);
			setLeaseOptionRent(Number(editedProperty.leaseOptionRent));
			setStrRevenue(Number(editedProperty.strRevenue));
			saveEditedProfits(stateChange);

			if ( property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === false ) {
				const propertyUnits = property.units;
				const propertyRent = property.financials.rent;
				const newHouseHackUnits = [];
				for (let index = 0; index < propertyUnits; index++) {
					const houseHackRentableUnits = propertyUnits - 1;
					const newHouseHackUnit = {
						value: Math.round(houseHackRentableUnits === 0 ? 0 : index === unitLiving ? 0 : propertyRent / propertyUnits),
						label: `Unit ${index + 1}`
					};
					newHouseHackUnits.push(newHouseHackUnit);
				};
				setDefaultHouseHackRent(newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0));
			}
			else if ( property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === true ) {
				const uniqueUnits = property.uniqueUnitPropertyDetails;
				
				const newUnits = [];
				for (let index = 0; index < uniqueUnits.length; index++) {
					const element = uniqueUnits[index];
					const unitFrequency = Number(element.frequency);
					const newFrequency = {
						units: [],
					};

					for (let ii = 0; ii < unitFrequency; ii++) {
						const newObject = {
							value: Number(element.rentcast_rent)
						};
						newFrequency.units.push(newObject);
					};
					newUnits.push(newFrequency);
				};

				const newTotal = newUnits.reduce((accumulator, currentValue) => {
					// Use forEach to iterate over the 'items' array of each object
					currentValue.units.forEach(item => {
					// Add the 'value' property to the accumulator
					accumulator += Number(item.value);
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);
				setDefaultMultiUnitRent(newTotal);

				const uniqueRentArray = [];
				for (let index = 0; index < uniqueUnits.length; index++) {
					const element = uniqueUnits[index];
					uniqueRentArray.push(Number(element.rentcast_rent));
				};
				const minimumRentValue = Math.min(...uniqueRentArray);
				const minimumValueIndex = uniqueRentArray.indexOf(minimumRentValue);

				const newHouseHackUnits = [];
				for (let index = 0; index < uniqueUnits.length; index++) {
					const element = uniqueUnits[index];
					const unitFrequency = Number(element.frequency);
					const newFrequency = {
						units: []
					};

					for (let ii = 0; ii < unitFrequency; ii++) {
						const newObject = {
							value: minimumValueIndex === index && ii === 0 ? 0 : Number(element.rentcast_rent)
						};
						newFrequency.units.push(newObject);
					};
					newHouseHackUnits.push(newFrequency);
				};
				const newHouseHackTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
					// Use forEach to iterate over the 'items' array of each object
					currentValue.units.forEach(item => {
					// Add the 'value' property to the accumulator
					accumulator += Number(item.value);
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);
				setDefaultMultiUnitHouseHackRentTotal(newHouseHackTotal);
			}
			else if ( houseHackBedroomsRent.length === 0 && property.propertyTypeDimension === "Single Family" && multipleUniqueUnits === false ) {
				const bedrooms = property.bedrooms !== null && property.bedrooms !== undefined ? property.bedrooms : 1;
				const newHouseHackBedrooms = [];
				for (let index = 0; index < bedrooms; index++) {
					const totalBedroomsRent = property.houseHackingFlag === true ? property.houseHackingFeatures.totalRentByBedroom : 0;
					const individualBedroomRent = totalBedroomsRent / bedrooms;
					const newBedroom = {
						value: Math.round(index === unitLiving ? 0 : individualBedroomRent)
					};
					newHouseHackBedrooms.push(newBedroom);
				};
				setDefaultHouseHackRent(newHouseHackBedrooms.reduce((a, b) => a + Number(b.value), 0));
			}
		};

		// Function that checks whether default values have been changed
		// so that we can add a change dot where necessary on the strategies panel
		const checkDefaultValues = async() => {
			const defaultValuesPanelClone = [...defaultValuesPanel];
			let getPropertyRent;

			if ( exitStrategy === "hh" ) {
				// This is for house hack mode

				if ( multipleUniqueUnits === true ) {
					const finalRent = Number(multiUnitHouseHackRentTotal);
					const defaultValue = Number(defaultMultiUnitHouseHackRentTotal);
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
				else {
					const finalRent = Number(houseHackRent);
					const defaultValue = Number(defaultHouseHackRent);
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
			}
			else {
				// This is for non-house hack mode

				if ( multipleUniqueUnits === true ) {
					const finalRent = Number(multiUnitRentTotal);
					const defaultValue = Number(defaultMultiUnitRent);
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
				else {
					const finalRent = Number(rent);
					const defaultValue = property.financials.rent;
					getPropertyRent = defaultValue === finalRent ? false : true;
				}
			}

			const getValueAddRentTotal = valueAddOptions.reduce((accumulator, item) => {
				if ( item.active === true ) {
					accumulator += Number(item.value);
				}
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			for (let index = 0; index < defaultValuesPanelClone.length; index++) {
				if ( index === 0 ) {
					continue;
				}
				else if ( index === 1 ) {
					if ( getPropertyRent === true || getValueAddRentTotal > 0 ) {
						defaultValuesPanelClone[index] = true;
					}
					else {
						defaultValuesPanelClone[index] = false;
					}
				}
				else if ( index === 2 ) {
					if ( 
							Number(customPrice) === property.price &&
							Number(defaultDownPayment) === Number(downPayment) &&
							Number(defaultInterestRate) === Number(interestRate)
						) {
						defaultValuesPanelClone[index] = false;
					}
					else {
						defaultValuesPanelClone[index] = true;
					}
				}
				else if ( index === 3 ) {
					if ( Number(totalRehabCost) === Number(defaultTotalRehabCost) && Number(defaultArv) === Number(arv) ) {
						defaultValuesPanelClone[index] = false;
					}
					else {
						defaultValuesPanelClone[index] = true;
					}
				}
				else if ( index === 4 ) {
					let monthlyCostCheck = false;
					for (let ii = 0; ii < monthlyCosts.length; ii++) {
						const element = monthlyCosts[ii];
						for (let iii = 0; iii < element.inputs.length; iii++) {
							if ( ii === 0 && iii === 0 ) {
								// Skip mortgage
								continue;
							}
							const input = element.inputs[iii];
							if ( exitStrategy === "brrrr" && ii === 0 && iii === 3 && Number(input.brrrrOriginal) !== Number(input.brrrrValue) ) {
								monthlyCostCheck = true;
							}
							else if ( exitStrategy === "brrrr" && ii === 0 && iii === 3 && Number(input.brrrrOriginal) === Number(input.brrrrValue) ) {

							}
							else if ( Number(input.original) !== Number(input.value) ) {
								monthlyCostCheck = true;
							}
						}
					}
					defaultValuesPanelClone[index] = monthlyCostCheck;
				}	
			}
			setDefaultValuesPanel(defaultValuesPanelClone);
		};

		// Get holding cost total
		const getNewHoldingCostsEffect = (costs) => {
			const totalMonthlyCosts = costs.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label.includes("HOA") || item.label.includes("Insurance") || item.label.includes("Property tax") ) {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			return totalMonthlyCosts;
		}

		// Save holding cost total
		const saveHoldingCostTotal = (costs) => {
			const totalMonthlyCosts = costs.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label.includes("HOA") || item.label.includes("Insurance") || item.label.includes("Property tax") ) {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMonthlyHoldingCosts(Number(totalMonthlyCosts.toFixed(2)));
			setTotalHoldingCosts(Number((totalMonthlyCosts * holdingPeriod).toFixed(2)));
		};

		if ( profitChange === true ) {
			setProfitChange(false);
			const saveObject = {
				valueAddOptions: valueAddOptions,
				monthlyCosts: monthlyCosts,
				rent: Number(rent),
				houseHackRent: Number(houseHackRent),
				unitsRent: unitsRent,
				houseHackUnitsRent: houseHackUnitsRent,
				houseHackBedroomsRent: houseHackBedroomsRent,
				multiUnitRent: multiUnitRent,
				multiUnitRentTotal: Number(multiUnitRentTotal),
				multiUnitRentRatio: multiUnitRentRatio,
				houseHackMultiUnitsRent: houseHackMultiUnitsRent,
				multiUnitHouseHackRentTotal: Number(multiUnitHouseHackRentTotal),
				multiUnitTypeUnitLiving: multiUnitTypeUnitLiving,
				unitLiving: Number(unitLiving),
				downPayment: Number(downPayment),
				interestRate: Number(interestRate),
				closingCost: Number(closingPercentage),
				rehab: Number(totalRehabCost),
				costPerSqFoot: Number(costPerSqFoot),
				price: Number(customPrice),
				arv: Number(arv),
				totalHoldingCosts: Number(totalHoldingCosts),
				holdingPeriod: Number(holdingPeriod),
				monthlyHoldingCosts: Number(monthlyHoldingCosts),
				arvFinancingPercentage: Number(arvFinancingPercentage),
				acquisitionStrategy: acquisitionStrategy,
				exitStrategy: exitStrategy,
				strRevenue: strRevenue,
				creativeInterestRate: creativeInterestRate,
				balloonPayment: balloonPayment,
				duration: duration,
				amortisation: amortisation,
				sellerCompensation: sellerCompensation,
				monthlyPayment: monthlyPayment,
				remainingMortgage: remainingMortgage,
				sellersMortgage: sellersMortgage,
				ownerEquity: ownerEquity,
				leaseOptionValue: leaseOptionValue,
				leaseOptionDownPayment: leaseOptionDownPayment,
				leaseOptionInterestRate: leaseOptionInterestRate,
				leaseOptionDollarDownPayment: leaseOptionDollarDownPayment,
				leaseOptionRent: leaseOptionRent
			};
			savePropertyChanges(saveObject, property.zpid);
			checkDefaultValues();
			setEditedProperty(saveObject);
		}

		if ( firstRender === false ) {
			setFirstRender(true);
			checkUserSettings();

			if ( editedProperty === null ) {
				// If no edited values are present, resort to default
				formatMonthlyCosts();
				if ( unitsRent.length === 0 && property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === false ) {
					checkUnits();
				}
				else if ( multiUnitRent.length === 0 && property.propertyTypeDimension === "Multi Family" && property.units !== undefined && property.units !== null && property.units > 1 && multipleUniqueUnits === true ) {
					checkUniqueUnits();
				}
				else if ( houseHackBedroomsRent.length === 0 && (property.propertyTypeDimension === "Single Family" || property.propertyTypeDimension === "Townhouse") ) {
					checkBedrooms();
				}
			}
			else {
				saveEditedValues(false);
			}

			if ( onboarding === true ) {
				setSelectedTab(2);
			}
		}
		else if ( changeEditedProperty === true ) {
			setChangeEditedProperty(false);
			saveEditedValues(true);
		}

    }, [
		acquisitionStrategy,
		amortisation,
		arv,
		arvFinancingPercentage,
		balloonPayment,
		brrrrPropertyTaxRate, 
		cityId, 
		changeEditedProperty,
		closingCost, 
		closingPercentage, 
		costPerSqFoot, 
		creativeInterestRate,
		customPrice, 
		defaultArv, 
		defaultDownPayment, 
		defaultHouseHackRent, 
		defaultInterestRate, 
		defaultMultiUnitHouseHackRentTotal, 
		defaultMultiUnitRent, 
		defaultTotalRehabCost, 
		defaultValuesPanel, 
		downPayment, 
		downPaymentCost, 
		duration,
		editedProperty, 
		exitStrategy, 
		firstRender, 
		highLevelProfits, 
		holdingPeriod, 
		houseHackBedroomsRent, 
		houseHackMultiUnitsRent, 
		houseHackRent, 
		houseHackUnitsRent, 
		interestRate, 
		leaseOptionDollarDownPayment,
		leaseOptionDownPayment,
		leaseOptionInterestRate,
		leaseOptionRent,
		leaseOptionValue, 
		monthlyCosts, 
		monthlyHoldingCosts, 
		monthlyPayment,
		multiUnitHouseHackRentTotal, 
		multiUnitRent, multiUnitRentRatio, 
		multiUnitRentTotal, 
		multiUnitTypeUnitLiving, 
		multipleUniqueUnits, 
		onboarding,
		ownerEquity,
		profitChange, 
		property.bedrooms, 
		property.financials.rent, 
		property.houseHackingFeatures.totalRentByBedroom, 
		property.houseHackingFlag, 
		property.price, 
		property.propertyTypeDimension, 
		property.uniqueUnitPropertyDetails, 
		property.units, 
		property.zpid, 
		recurringCosts, 
		remainingMortgage,
		rent, 
		sellerCompensation,
		sellersMortgage,
		setAcquisitionStrategy,
		setChangeEditedProperty,
		setClosingPercentage, 
		setCostPerSqFoot, 
		setDownPayment, 
		setEditedProperty, 
		setExitStrategy,
		setHighLevelProfits, 
		setInterestRate, 
		setSelectedTab,
		setTotalRehabCost, 
		strRevenue,
		totalHoldingCosts, 
		totalRehabCost, 
		unitLiving, 
		unitsRent, 
		userSettings, 
		valueAddOptions
	]);

	const toggleAcquisitionDropdown = () => {
		setAcquisitionDropdownOpen(!acquisitionDropdownOpen);
		setExitDropdownOpen(false);
	};

	const selectAcquisition = async(item) => {
		setAcquisitionDropdownOpen(false);
		setAcquisitionStrategy(item.value);

		if ( onboarding === true && onboardingStep === 2 ) {
			setSelectedTab(2);
		}
		else {
			setSelectedTab(0);
		}
		acquisitionExitChange(item.value, exitStrategy);
	};

	const toggleExitDropdown = () => {
		setExitDropdownOpen(!exitDropdownOpen);
		setAcquisitionDropdownOpen(false);
	};

	const closeDropdowns = () => {
		setExitDropdownOpen(false);
		setAcquisitionDropdownOpen(false);
	};

	const selectExit = async(item) => {
		setExitDropdownOpen(false);
		setExitStrategy(item.value);

		
		if ( onboarding === true && onboardingStep === 2 ) {
			setSelectedTab(2);
		}
		else {
			setSelectedTab(0);
		}
		acquisitionExitChange(acquisitionStrategy, item.value);
	};

	const acquisitionExitChange = async(newAcquisition, newExit) => {
		let newDown = defaultDownPayment;
		if ( newAcquisition === "c" ) {
			setDownPayment(100);
			newDown = 100;
		}
		else {
			if ( defaultDownPayment !== 100 ) {
				setDownPayment(defaultDownPayment);
			}
			else {
				setDownPayment(20);
				newDown = 20;
			}
		}

		if ( newExit === "str" ) {
			const newMonthlyCosts = [...monthlyCosts];
			const newMortgage = await creativeOrTraditionalMortgage(newAcquisition, newDown);
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
			setMonthlyCosts(newMonthlyCosts);

			const newTotalCosts = newAcquisition === "c" ? 0 : newMonthlyCosts[0].inputs[0].value;			
			const updatedRent = strRevenue === 0 ? 0 : Number(strRevenue) / 12;
			
			// Register strategy change
			collectStrategyChangeData(newAcquisition, newExit, updatedRent, newTotalCosts, newDown);
		}
		else if ( newExit === "hh" || newExit === "ltr" || newExit === "lo" ) {
			const newMonthlyCosts = [...monthlyCosts];
			const newMortgage = await creativeOrTraditionalMortgage(newAcquisition, newDown);
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
			setMonthlyCosts(newMonthlyCosts);

			const newTotalCosts = newMonthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(e => {
					if ( e.label === "Management fee" && newExit === "hh" ) {
					}
					else {
						accumulator += Number(e.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			const updatedRent = await getAppropriateRentEstimate(newExit);
			collectStrategyChangeData(newAcquisition, newExit, updatedRent, newTotalCosts, newDown);
		}
		else if ( newExit === "brrrr" ) {
			setAcquisitionStrategy("c");
			setDownPayment(100);

			const newMonthlyCosts = [...monthlyCosts];
			const amountBorrowedInverse = 100 - arvFinancingPercentage;
			const newMortgage = await calculateMortgagePayment(Number(arv), amountBorrowedInverse, Number(interestRate), loanYears);
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));

			const brrrrPropertyTax = (((brrrrPropertyTaxRate / 100) * arv) / 12).toFixed(2);
			newMonthlyCosts[0].inputs[3].brrrrValue = Number(brrrrPropertyTax);
			newMonthlyCosts[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;

			setMonthlyCosts(newMonthlyCosts);

			const newTotalCosts = newMonthlyCosts.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(e => {
					if ( e.label === "Property taxes" ) {
						accumulator += Number(e.brrrrValue);
					}
					else {
						accumulator += Number(e.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);

			const updatedRent = await getAppropriateRentEstimate(newExit);
			// Register strategy change
			collectStrategyChangeData(newAcquisition, newExit, updatedRent, newTotalCosts, newDown);
		}
	};

	const creativeOrTraditionalMortgage = async(newAcquisition, newDown) => {
		if ( newAcquisition === "c" || newAcquisition === "m" ) {
			const newMortgage = await calculateMortgagePayment(customPrice, newDown, Number(interestRate), loanYears);
			return newMortgage;
		}
		else if ( newAcquisition === "sf" ) {
			const newMortgage = await calculateMortgagePayment(customPrice, newDown, Number(creativeInterestRate), (Number(amortisation) / 12));
			return newMortgage;
		}
		else if ( newAcquisition === "sub" ) {
			const equityMortgage = await calculateSubToMortgagePayment(customPrice, newDown, creativeInterestRate, ownerEquity, (Number(amortisation) / 12));
			const newMortgage = Number(sellersMortgage) + equityMortgage;
			return newMortgage;
		}
	};

	const optimiseDeal = async() => {
		if ( creativeUser === false ) {
			setPremiumModal(true);
		}
		else {
			setOptimiseDealModal(true);

			if ( fullscreenFinancials === true ) {
				setFullscreenFinancials(false);
			}
		}
	};

	// Function for changing which financial panel you'd like
	// to look at
    const selectFinancialOption = (index) => {
       setSelectedTab(index);
    };
	
	// Function for changing the TOTAL rent value
	// across all variations
    const onChangeRent = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			setRent(val);
			return;
		}

		// This is for recording total rents a SFH + MFH with 1 unit type
		if ( exitStrategy === "hh" ) {
			// If we're on house hack mode
			const availableUnits = property.propertyTypeDimension === "Single Family" ? property.bedrooms : property.units;
			if ( availableUnits !== undefined && availableUnits !== null && availableUnits > 1 ) {
				const currentlyAccountedUnits = availableUnits - 1;
				const singleUnitRent = Number(val) / currentlyAccountedUnits;
				const fullRent = Math.round(singleUnitRent * availableUnits);
				setRent(fullRent);
			}
			else {
				setRent(val);

				// Register total rent
				collectTotalRent(Number(val));
			}
		}
		else {
			// If we're NOT on house hack mode
			setRent(val);

			// Register total rent
			collectTotalRent(Number(val));
		}

		// For 1 unit layout MFH homes, this is where we segment the rent
		// between each unit
        if ( unitsRent.length !== 0 ) {
            const newUnits = [];
            for (let index = 0; index < unitsRent.length; index++) {
				const newUnit = {
					value: exitStrategy === "hh" ? Math.round(val) : Math.round(val / unitsRent.length),
					label: `Unit ${index + 1}`
				};
				newUnits.push(newUnit);
            };
            setUnitsRent(newUnits);
        }

		// For 1 unit layout MFH homes on a House Hack, this is where we segment the rent
		// between each unit
		if ( houseHackUnitsRent.length !== 0 ) {
            const newUnits = [];
            for (let index = 0; index < houseHackUnitsRent.length; index++) {
				const rentableHouseHackUnits = houseHackUnitsRent.length - 1;
				const divisionDenominator = exitStrategy === "hh" ? rentableHouseHackUnits : houseHackUnitsRent.length;
				const newUnit = {
					value: index === unitLiving ? 0 : rentableHouseHackUnits === 0 ? 0 : Number(val) / divisionDenominator,
					label: `Unit ${index + 1}`
				};
				newUnits.push(newUnit);
            };

			const newHouseHackUnitsTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
            setHouseHackUnitsRent(newUnits);
			if ( exitStrategy === "hh" ) {
				// If we're on the house hack panel, the house hack total should be what is typed
				setHouseHackRent(val);

				// Register total rent
				collectTotalRent(Number(val));
			}
			else {
				// If we're on another panel, the house hack total should be the sum of the rented units
				setHouseHackRent(newHouseHackUnitsTotal);
			}
        }
    };

	// Function for changing the rent on INDIVIDUAL units
	// on a Multi-family home with 1 unit type, and on a
	// Multi-family house hack with 1 unit type
    const onChangeUnitRent = (val, unitIndex) => {
		if ( checkNaN(val) === true && val !== "" )  {
			const unitsClone = [...unitsRent];
			unitsClone[unitIndex].value = val;
			setUnitsRent(unitsClone);
			return;
		}
		
		// Setting up rent for 1 unit layout MFH homes
        const newUnits = [];
        for (let index = 0; index < unitsRent.length; index++) {
            if ( index === unitIndex ) {
                const newUnit = {
                    value: val,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
            else {
                const newUnit = {
                    value: unitsRent[index].value,
                    label: `Unit ${index + 1}`
                };
                newUnits.push(newUnit);
            }
        };
        setUnitsRent(newUnits);
        const newTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
        setRent(newTotal);

		// Setting up rent for house hack units
		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackUnitsRent.length; index++) {
			if ( index === unitIndex ) {
				const newUnit = {
					value: index === unitLiving ? 0 : val,
					label: `Unit ${index + 1}`
				};
				newHouseHackUnits.push(newUnit);
			}
			else {
				const newUnit = {
					value: index === unitLiving ? 0 : houseHackUnitsRent[index].value,
					label: `Unit ${index + 1}`
				};
				newHouseHackUnits.push(newUnit);
			}
        };
		setHouseHackUnitsRent(newHouseHackUnits);

		const newHouseHackTotal = newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newHouseHackTotal);

		if ( exitStrategy === "hh" ) {
			// Register total rent
			collectTotalRent(newHouseHackTotal);
		}
		else {
			// Register total rent
			collectTotalRent(newTotal);
		}
    };

	// Function for changing the total rent for SFH House Hack mode
	const onChangeSFHHouseHackTotal = (val) => {
		// For SFH homes on a House Hack, this is where we segment the rent
		// between each bedroom

		if ( checkNaN(val) === true && val !== "" )  {
			setHouseHackRent(val);
			return;
		}

		if ( houseHackBedroomsRent.length !== 0 ) {
			const newUnits = [];
            for (let index = 0; index < houseHackBedroomsRent.length; index++) {
				const rentableHouseHackBedrooms = houseHackBedroomsRent.length - 1;
				const divisionDenominator = exitStrategy === "hh" ? rentableHouseHackBedrooms : houseHackBedroomsRent.length;
				const newUnit = {
					value: index === unitLiving ? 0 : rentableHouseHackBedrooms === 0 ? 0 : Math.round(val / divisionDenominator),
					label: `Bedroom ${index + 1}`
				};
				newUnits.push(newUnit);
            };
            setHouseHackBedroomsRent(newUnits);
			setHouseHackRent(val);

			// Register total rent
			collectTotalRent(Number(val));
		}
	};

	// Function for changing the rent on INDIVIDUAL bedrooms
	// on a SFH for a House hack
	const onChangeBedroomRent = (val, bedroomIndex) => {
		if ( checkNaN(val) === true && val !== "" )  {
			const newUnits = [...houseHackBedroomsRent];
			newUnits[bedroomIndex].value = val;
			setHouseHackBedroomsRent(newUnits);
			return;
		}

		const newUnits = [];
		for (let index = 0; index < houseHackBedroomsRent.length; index++) {
            if ( index === bedroomIndex ) {
                const newUnit = {
                    value: index === unitLiving ? 0 : val,
                    label: `Bedroom ${index + 1}`
                };
                newUnits.push(newUnit);
            }
            else {
                const newUnit = {
                    value: index === unitLiving ? 0 : houseHackBedroomsRent[index].value,
                    label: `Bedroom ${index + 1}`
                };
                newUnits.push(newUnit);
            }
        };
		setHouseHackBedroomsRent(newUnits);
		const newTotal = newUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing which unit you want to live in on a MFH
	// house hack with 1 unit type
	const onChangeUnitLiving = (val) => {
		setUnitLiving(val);

		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackUnitsRent.length; index++) {
			// const rentableHouseHackUnits = houseHackUnitsRent.length - 1;
			// const perUnitTotal = rentableHouseHackUnits === 0 ? 0 : houseHackRent / rentableHouseHackUnits;
			const existingUnitVal = unitsRent[index].value;

			const newUnit = {
				value: index === val ? 0 : existingUnitVal,
				label: `Unit ${index + 1}`
			};
			newHouseHackUnits.push(newUnit);
        };
		setHouseHackUnitsRent(newHouseHackUnits);
		
		const newTotal = newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing which unit you want to live in on a MFH
	// house hack with multiple unit types
	const onChangeMultiUnitLiving = (val, subVal) => {
		setMultiUnitTypeUnitLiving([val, subVal]);

		const newHouseHackMultiUnits = [...houseHackMultiUnitsRent];
		for (let index = 0; index < newHouseHackMultiUnits.length; index++) {
			const element = newHouseHackMultiUnits[index];
			for (let ii = 0; ii < element.units.length; ii++) {
				if ( index === val && ii === subVal ) {
					newHouseHackMultiUnits[index].units[ii].value = 0;
				}
				else {
					newHouseHackMultiUnits[index].units[ii].value = multiUnitRent[index].units[ii].value;
				}
			}
		};

		setHouseHackMultiUnitsRent(newHouseHackMultiUnits);

		const newTotal = newHouseHackMultiUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitHouseHackRentTotal(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing which bedroom you want to live in on a SFH
	// house hack
	const onChangeBedroomLiving = (val) => {
		setUnitLiving(val);

		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackBedroomsRent.length; index++) {
			const rentableHouseHackBedrooms = houseHackBedroomsRent.length - 1;
			const perBedroomTotal = rentableHouseHackBedrooms === 0 ? 0 : houseHackRent / rentableHouseHackBedrooms;
			const newUnit = {
				value: index === val ? 0 : perBedroomTotal,
				label: `Bedroom ${index + 1}`
			};
			newHouseHackUnits.push(newUnit);
        };
		setHouseHackBedroomsRent(newHouseHackUnits);
		
		const newTotal = newHouseHackUnits.reduce((a, b) => a + Number(b.value), 0);
		setHouseHackRent(newTotal);

		// Register total rent
		collectTotalRent(newTotal);
	};

	// Function for changing the TOTAL rent for MFHs with multiple
	// unique units
	const onChangeMultiUnitTotalRent = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			setMultiUnitRentTotal(val);
			return;
		}

		if ( exitStrategy === "hh" ) {
			// This is if they were in house hack mode
			setMultiUnitHouseHackRentTotal(val);
			const totalNumber = Number(val);
			
			// Register total rent
			collectTotalRent(totalNumber);

			if ( multiUnitRent.length > 1 ) {
				if ( multiUnitRent[0].units.length < 1 ) {
					return;
				}
				
				const livedInUnitIndex = multiUnitTypeUnitLiving[0];
				const livedInUnitLength = houseHackMultiUnitsRent[livedInUnitIndex].units.length;

				// If the unit they're living in is a single unit type, we just divide the total
				// by the number of units being rented
				if ( livedInUnitLength === 1 ) {
					const rentedUnits = houseHackMultiUnitsRent[livedInUnitIndex === 0 ? 1 : 0].units.length;
					const newSegmentedRent = totalNumber / rentedUnits;

					const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
					for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
						const element = houseHackMultiUnitsRentClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
								houseHackMultiUnitsRentClone[index].units[ii].value = 0;
							}
							else {
								houseHackMultiUnitsRentClone[index].units[ii].value = Math.round(newSegmentedRent);
							}
						}
					};
					setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);

					const ratio = multiUnitRentRatio[1];
					const nonHouseHackUnit = livedInUnitIndex === 0 ? newSegmentedRent / ratio : newSegmentedRent * ratio;
					const nonHouseHackArrayClone = [...multiUnitRent];

					for (let index = 0; index < nonHouseHackArrayClone.length; index++) {
						const element = nonHouseHackArrayClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
								nonHouseHackArrayClone[index].units[ii].value = Math.round(nonHouseHackUnit);
							}
							else {
								nonHouseHackArrayClone[index].units[ii].value = Math.round(newSegmentedRent);
							}
						}
					};
					setMultiUnitRent(nonHouseHackArrayClone);

					const nonHouseHackTotal = nonHouseHackArrayClone.reduce((accumulator, currentValue) => {
						// Use forEach to iterate over the 'items' array of each object
						currentValue.units.forEach(item => {
							// Add the 'value' property to the accumulator
							accumulator += Number(item.value);
						});
						return accumulator; // Return the accumulator for the next iteration
					}, 0);
					setMultiUnitRentTotal(nonHouseHackTotal);
				}
				else {
					// If the unit they're living in is a double unit type, we need to determine
					// the ratio of the rent between the two unit types

					// Reduce the firt unit type count by 
					const firstUnitTypeCount = multiUnitRent[0].units.length > 1 ? multiUnitRent[0].units.length - 1 : multiUnitRent[0].units.length;
					const ratio = multiUnitRentRatio[1];
					
					// Equation to find new rent rates
					const newFirstUnitRent = (totalNumber / (ratio + firstUnitTypeCount));
					const newSecondUnitRent = newFirstUnitRent * ratio;

					const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
					for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
						const element = houseHackMultiUnitsRentClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
								houseHackMultiUnitsRentClone[index].units[ii].value = 0;
							}
							else if ( index === 0 ) {
								houseHackMultiUnitsRentClone[index].units[ii].value = Math.round(newFirstUnitRent);
							}
							else {
								houseHackMultiUnitsRentClone[index].units[ii].value = Math.round(newSecondUnitRent);
							}
						}
					};
					setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);


					const nonHouseHackArrayClone = [...multiUnitRent];
					for (let index = 0; index < nonHouseHackArrayClone.length; index++) {
						const element = nonHouseHackArrayClone[index];
						for (let ii = 0; ii < element.units.length; ii++) {
							nonHouseHackArrayClone[index].units[ii].value = Math.round(index === 0 ? newFirstUnitRent : newSecondUnitRent);
						}
					};
					setMultiUnitRent(nonHouseHackArrayClone);

					const nonHouseHackTotal = nonHouseHackArrayClone.reduce((accumulator, currentValue) => {
						// Use forEach to iterate over the 'items' array of each object
						currentValue.units.forEach(item => {
							// Add the 'value' property to the accumulator
							accumulator += Number(item.value);
						});
						return accumulator; // Return the accumulator for the next iteration
					}, 0);
					setMultiUnitRentTotal(nonHouseHackTotal);
				}
			}
		}
		else {
			// This is if they were NOT in house hack mode
			setMultiUnitRentTotal(val);
			const totalNumber = Number(val);

			// Register total rent
			collectTotalRent(totalNumber);
			
			if ( multiUnitRent.length > 1 ) {
				if ( multiUnitRent[0].units.length < 1 ) {
					return;
				}
				const firstUnitTypeCount = multiUnitRent[0].units.length;
				const ratio = multiUnitRentRatio[1];
				
				// Equation to find new rent rates
				const newFirstUnitRent = (totalNumber / (ratio + firstUnitTypeCount));
				const newSecondUnitRent = newFirstUnitRent * ratio;
				
				const multiUnitRentClone = [...multiUnitRent];
				for (let index = 0; index < multiUnitRentClone.length; index++) {
					const element = multiUnitRentClone[index];
					for (let ii = 0; ii < element.units.length; ii++) {
						multiUnitRentClone[index].units[ii].value = index === 0 ? Math.round(newFirstUnitRent) : Math.round(newSecondUnitRent);
					}
				}
				setMultiUnitRent(multiUnitRentClone);

				const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
				for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
					const element = houseHackMultiUnitsRentClone[index];
					for (let ii = 0; ii < element.units.length; ii++) {
						if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
							houseHackMultiUnitsRentClone[multiUnitTypeUnitLiving[0]].units[multiUnitTypeUnitLiving[1]].value = 0;
						}
						else {
							houseHackMultiUnitsRentClone[index].units[ii].value = index === 0 ? Math.round(newFirstUnitRent) : Math.round(newSecondUnitRent);
						}
					}
				}

				setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);
				
				const newHouseHackTotal = houseHackMultiUnitsRentClone.reduce((accumulator, currentValue) => {
					// Use forEach to iterate over the 'items' array of each object
					currentValue.units.forEach(item => {
					  // Add the 'value' property to the accumulator
					  accumulator += Number(item.value);
					});
					return accumulator; // Return the accumulator for the next iteration
				}, 0);
				setMultiUnitHouseHackRentTotal(newHouseHackTotal);
			}
		}
	};


	// Function to change indiviudal text inputs for multiple unique
	// units MFH properties
	const onChangeMultiUnitRent = (val, inputIndex, inputSubIndex) => {
		if ( checkNaN(val) === true && val !== "" )  {
			if ( exitStrategy === "hh" ) {
				// On house hack mode
				const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
				houseHackMultiUnitsRentClone[inputIndex].units[inputSubIndex].value = val;
				setHouseHackMultiUnitsRent(houseHackMultiUnitsRentClone);
			}
			else {
				// NOT on house hack mode
				const multiUnitRentClone = [...multiUnitRent];
				multiUnitRentClone[inputIndex].units[inputSubIndex].value = val;
				setMultiUnitRent(multiUnitRentClone);
			}
			return;
		}

		if ( exitStrategy === "hh" ) {
			// On house hack mode

			const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
			for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
				const element = houseHackMultiUnitsRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex ) {
						houseHackMultiUnitsRentClone[index].units[ii].value = val;
					}
				}
			};
			const newHouseHackTotal = houseHackMultiUnitsRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitHouseHackRentTotal(newHouseHackTotal);

			// Register total rent
			collectTotalRent(newHouseHackTotal);

			const multiUnitRentClone = [...multiUnitRent];
			for (let index = 0; index < multiUnitRentClone.length; index++) {
				const element = multiUnitRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex ) {
						multiUnitRentClone[index].units[ii].value = val;
					}
				}
			};
			setMultiUnitRent(multiUnitRentClone);
			const newTotal = multiUnitRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);
		}
		else {
			// NOT on house hack mode
			const multiUnitRentClone = [...multiUnitRent];
			for (let index = 0; index < multiUnitRentClone.length; index++) {
				const element = multiUnitRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex ) {
						multiUnitRentClone[index].units[ii].value = val;
					}
				}
			};
			setMultiUnitRent(multiUnitRentClone);
			const newTotal = multiUnitRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitRentTotal(newTotal);

			// Register total rent
			collectTotalRent(newTotal);

			const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
			for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
				const element = houseHackMultiUnitsRentClone[index].units;
				for (let ii = 0; ii < element.length; ii++) {
					if ( index === inputIndex && ii === inputSubIndex && (index !== multiUnitTypeUnitLiving[0] || ii !== multiUnitTypeUnitLiving[1]) ) {
						houseHackMultiUnitsRentClone[index].units[ii].value = val;
					}
				}
			};
			const newHouseHackTotal = houseHackMultiUnitsRentClone.reduce((accumulator, currentValue) => {
				// Use forEach to iterate over the 'items' array of each object
				currentValue.units.forEach(item => {
				  // Add the 'value' property to the accumulator
				  accumulator += Number(item.value);
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			setMultiUnitHouseHackRentTotal(newHouseHackTotal);
		}
	};

	// Function for changing the sale price on a Lease Option
	const onChangeLeaseValue = (val) => {
        if ( checkNaN(val) === true && val !== "" )  {
            return;
        }

        setLeaseOptionValue(val);
        recalculateLeaseOption(Number(val), leaseOptionDownPayment, leaseOptionInterestRate);
    };

	// Function for changing the down payment percentage on a lease option
	const onChangeLeaseDownPayment = (val) => {
        if ( checkNaN(val) === true && val !== "" )  {
            return;
        }
        else if ( Number(val) > 100 ) {
            return;
        }
        setLeaseOptionDownPayment(val);
        const newDollar = (val / 100) * leaseOptionValue;
        setLeaseOptionDollarDownPayment(newDollar);
        recalculateLeaseOption(leaseOptionValue, Number(val), leaseOptionInterestRate);
    };

	// Function for changing the dollar down payment on a lease option
    const onChangeLeaseDownPaymentDollar = (val) => {
        if ( checkNaN(val) === true && val !== "" )  {
            return;
        }
        const newPercentage = (Number(val) / leaseOptionValue) * 100;
        setLeaseOptionDownPayment(newPercentage);
        setLeaseOptionDollarDownPayment(val);
        recalculateLeaseOption(leaseOptionValue, newPercentage, leaseOptionInterestRate);
    };

	// Function for changing the interest rate on a lease option
    const onChangeLeaseInterest = (val) => {
        if ( checkNaN(val) === true && val !== "" )  {
            return;
        }
        else if ( Number(val) > 100 ) {
            return;
        }
        setLeaseOptionInterestRate(val);
        recalculateLeaseOption(leaseOptionValue, leaseOptionDownPayment, Number(val));
    };

	// Function to calculate Lease Option rent
	const recalculateLeaseOption = async(newPrice, newDown, newInterest) => {
        const newRent = await calculateMortgagePayment(newPrice, newDown, newInterest, 30);
        setLeaseOptionRent(newRent);
		
		const leaseOptionVals = {
			downPayment: Number(newDown),
			interestRate: Number(newInterest),
			closingCost: closingCost,
			price: Number(newPrice),
			rent: newRent,
			rehab: totalRehabCost,
			monthlyCosts: highLevelProfits.monthlyCosts,
			propertyPrice: customPrice
		}
		updateLeaseOptionCashFlow(leaseOptionVals);
    }

	// Function to change Annual STR Revenue
	const onChangeSTRRevenue = async(val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}
        
		setStrRevenue(val);
        const monthlyRent = (Number(val) / 12);

		const newValsObject = {
			rent: monthlyRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: highLevelProfits.monthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: totalHoldingCosts,
			arvFinancing: Number(arvFinancing)
		};
		updateSTRCashFlow(newValsObject);
    };

	// Function for resetting rent rates
	const resetRentEstimates = () => {
		setRent(property.financials.rent);
		setHouseHackRent(defaultHouseHackRent);
		const newUnits = [];
		for (let index = 0; index < unitsRent.length; index++) {
			const newUnit = {
				value: Math.round(property.financials.rent / unitsRent.length),
				label: `Unit ${index + 1}`
			};
			newUnits.push(newUnit);
		};
		setUnitsRent(newUnits);

		const newHouseHackUnits = [];
		for (let index = 0; index < houseHackUnitsRent.length; index++) {
			const rentableHouseHackUnits = houseHackUnitsRent.length - 1;
			const newUnit = {
				value: rentableHouseHackUnits === 0 ? 0 : Math.round(defaultHouseHackRent / rentableHouseHackUnits),
				label: `Unit ${index + 1}`
			};
			newHouseHackUnits.push(newUnit);
		};
		setHouseHackUnitsRent(newHouseHackUnits);

		const newHouseHackBedrooms = [];
		for (let index = 0; index < houseHackBedroomsRent.length; index++) {
			const rentableHouseHackBedrooms = houseHackBedroomsRent.length - 1;
			const newUnit = {
				value: rentableHouseHackBedrooms === 0 ? 0 : Math.round(defaultHouseHackRent / rentableHouseHackBedrooms),
				label: `Bedroom ${index + 1}`
			};
			newHouseHackBedrooms.push(newUnit);
		};
		setHouseHackBedroomsRent(newHouseHackBedrooms);

		if ( exitStrategy === "hh" ) {
			// Register total rent
			collectTotalRent(defaultHouseHackRent);
		}
		else {
			// Register total rent
			collectTotalRent(property.financials.rent);
		}
	};

	// Function for resetting rent rates for MFHs with multiple
	// unique units
	const resetMFHMultiUnitRentEstimates = () => {
		const newUnits = [];
		const multiUnitRentClone = [...multiUnitRent];
		for (let index = 0; index < multiUnitRentClone.length; index++) {
			const element = multiUnitRentClone[index].units;
			for (let ii = 0; ii < element.length; ii++) {
				multiUnitRentClone[index].units[ii].value = multiUnitRentClone[index].units[ii].original;
			}
			newUnits.push(multiUnitRentClone[index]);
		};
		setMultiUnitRent(newUnits);
		const newUnitsTotal = newUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitRentTotal(newUnitsTotal);

		const newHouseHackUnits = [];
		const houseHackMultiUnitsRentClone = [...houseHackMultiUnitsRent];
		for (let index = 0; index < houseHackMultiUnitsRentClone.length; index++) {
			const element = multiUnitRentClone[index].units;
			for (let ii = 0; ii < element.length; ii++) {
				if ( index === multiUnitTypeUnitLiving[0] && ii === multiUnitTypeUnitLiving[1] ) {
					houseHackMultiUnitsRentClone[index].units[ii].value = 0;
				}
				else {
					houseHackMultiUnitsRentClone[index].units[ii].value = houseHackMultiUnitsRentClone[index].units[ii].original;
				}
			}
			newHouseHackUnits.push(houseHackMultiUnitsRentClone[index]);
		};
		setHouseHackMultiUnitsRent(newHouseHackUnits);
		const newHouseHackUnitsTotal = newHouseHackUnits.reduce((accumulator, currentValue) => {
			// Use forEach to iterate over the 'items' array of each object
			currentValue.units.forEach(item => {
			  // Add the 'value' property to the accumulator
			  accumulator += Number(item.value);
			});
			return accumulator; // Return the accumulator for the next iteration
		}, 0);
		setMultiUnitHouseHackRentTotal(newHouseHackUnitsTotal);

		if ( exitStrategy === "hh" ) {
			// Register total rent
			collectTotalRent(newHouseHackUnitsTotal);
		}
		else {
			// Register total rent
			collectTotalRent(newUnitsTotal);
		}
	};

	// Function to reset a specific monthly cost
	const resetMonthlyCost = async(index, ii) => {
		const newMonthlyCosts = [...monthlyCosts];
		if ( index === 0 && ii === 3 && exitStrategy === "brrrr" ) {
			newMonthlyCosts[0].inputs[3].brrrrValue = Number(newMonthlyCosts[0].inputs[3].brrrrOriginal);
			newMonthlyCosts[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;
		}
		else {
			newMonthlyCosts[index].inputs[ii].value = newMonthlyCosts[index].inputs[ii].original;

			if ( index === 0 ) {
				const originalValue = Number(newMonthlyCosts[index].inputs[ii].original) * 12;
				const percentageOfTotal = (originalValue / property.price) * 100;
				newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
			}
			else {
				const originalValue = Number(newMonthlyCosts[index].inputs[ii].original);
				const percentageOfTotal = (originalValue / property.financials.rent) * 100;
				newMonthlyCosts[index].inputs[ii].percentageVal = Number(percentageOfTotal.toFixed(1));
			}	
		}

		setMonthlyCosts(newMonthlyCosts);
		const newTotal = await getTotalMonthlyCosts(newMonthlyCosts);

		collectMonthlyCosts(newTotal);
	};

	// Function for changing purchase price
	const onChangePrice = async(val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			setCustomPrice(val);
			return;
		}

		const newPrice = Number(val);
		setCustomPrice(newPrice);

		// Register initial costs
		collectInitialCosts(Number(downPayment), Number(interestRate), Number(closingPercentage), newPrice);

		const percentageChange = Math.round(((newPrice - property.price) / property.price) * 100);
		if ( percentageChange < maxPercentage ) {
			setCustomDiscount(maxPercentage);
		}
		else {
			setCustomDiscount(percentageChange);
		}

		const updatedRent = await getNonHouseHackRent();
		updateMonthlyCosts(monthlyCosts, updatedRent, newPrice);
	};

	// Function for changing slider price
	const changePriceSlider = async(event, newValue) => {
		setCustomDiscount(newValue);
		const newPrice = property.price * ((100 + newValue) / 100);
		const roundedPrice = Math.round(newPrice);
		setCustomPrice(roundedPrice);

		// Register initial costs
		collectInitialCosts(Number(downPayment), Number(interestRate), Number(closingPercentage), roundedPrice);
		const updatedRent = await getNonHouseHackRent();
		updateMonthlyCosts(monthlyCosts, updatedRent, roundedPrice);
	};

	// Function for changing down payment
	const onChangeDownPayment = async(text) => {
		if ( checkNaN(text) === true && text !== "" )  {
			return;
		}

		if ( Number(text) <= 100 ) {
			setDownPayment(text);

			// Register initial costs
			collectInitialCosts(Number(text), Number(interestRate), Number(closingPercentage), Number(customPrice));
		}
	};

	// Function for changing interest rate
	const onChangeInterest = async(text) => {
		if ( checkNaN(text) === true && text !== "" )  {
			return;
		}

		if ( Number(text) <= 100 ) {
			setInterestRate(text);

			// Register initial costs
			collectInitialCosts(Number(downPayment), Number(text), Number(closingPercentage), Number(customPrice));
		}
	};

	// Closing cost is a percentage of the
	// total MORTGAGE, not of the purchase price
	const onChangeClosing = async(text) => {
		if ( checkNaN(text) === true && text !== "" )  {
			return;
		}

		if ( Number(text) <= 100 ) {
			setClosingPercentage(text);

			// Register initial costs
			collectInitialCosts(Number(downPayment), Number(interestRate), Number(text), Number(customPrice));
		}
	};

	// Function for changing rehab cost input
	const changeRehabCost = (event, val) => {
        setCostPerSqFoot(val);
        const newTotalCost = val * property.livingArea;
        setTotalRehabCost(newTotalCost);

		// Register rehab costs
		collectRehabCosts(Number(newTotalCost), Number(arv));
    }

	// Change cost per sq. foot
	const onChangeCostPerSqFoot = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}

        setCostPerSqFoot(val);
        const newTotalCost = Number(val) * property.livingArea;
        setTotalRehabCost(newTotalCost);

		// Register rehab costs
		collectRehabCosts(Number(newTotalCost), Number(arv));
    };

	// Change total rehab cost
    const onChangeTotalCost = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}

        setTotalRehabCost(val);
        const newCostPerSqFoot = Math.round(Number(val) / property.livingArea);
        setCostPerSqFoot(newCostPerSqFoot);

		// Register rehab costs
		collectRehabCosts(Number(val), Number(arv));
    };

	// Function for changing ARV
	const onChangeARV = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}

		const newArv = Number(val);
		setArv(newArv);

		// Register rehab costs
		collectRehabCosts(Number(totalRehabCost), Number(val));

		const percentageChange = Math.round(((newArv - defaultArv) / defaultArv) * 100);
		if ( percentageChange < maxPercentage ) {
			setCustomArv(maxPercentage);
		}
		else {
			setCustomArv(percentageChange);
		}
	};

	// Funciton to change ARV slider
	const onChangeARVSlider = (event, newValue) => {
		setCustomArv(newValue);
		const newArv = defaultArv * ((100 + newValue) / 100);
		const roundedArv = Math.round(newArv);
		setArv(roundedArv);

		// Register rehab costs
		collectRehabCosts(Number(totalRehabCost), Number(roundedArv));
	};

	// Function to change monthly holding costs
	const onChangeHoldingCosts = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}

		setMonthlyHoldingCosts(val);
		const newTotal = Number(val) * Number(holdingPeriod);
		setTotalHoldingCosts(newTotal);

		// Register holding costs
		collectHoldingCosts(newTotal);
	};

	// Function to change monthly holding period
	const onChangeHoldingPeriod = (val) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}

		setHoldingPeriod(val);
		const newTotal = Number(monthlyHoldingCosts) * Number(val);
		setTotalHoldingCosts(newTotal);

		// Register holding costs
		collectHoldingCosts(newTotal);
	};

	// Function to change monthly costs
	const onChangeMonthlyCosts = async(val, index, ii) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}

		const newArray = [...monthlyCosts];

		// If we're on BRRRR strategy and they're changing property taxes, save the value
		// to BRRRR values
		if ( exitStrategy === "brrrr" && index === 0 && ii === 3 ) {
			newArray[index].inputs[ii].brrrrValue = val;
			const numberVal = Number(val);
			const yearlyValue = numberVal * 12;
			const percentageOfTotal = (yearlyValue / Number(arv)) * 100;
			newArray[index].inputs[ii].brrrrPercentageVal = percentageOfTotal.toFixed(1);
		}
		else {
			newArray[index].inputs[ii].value = val;
			const numberVal = Number(val);
			if ( index === 0 ) {
				const yearlyValue = numberVal * 12;
				const percentageOfTotal = (yearlyValue / property.price) * 100;
				newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
			}
			else {
				const percentageOfTotal = (numberVal / property.financials.rent) * 100;
				newArray[index].inputs[ii].percentageVal = percentageOfTotal.toFixed(1);
			}

		}
		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

	const onChangeMonthlyCostsPercentage = async(val, index, ii) => {
		if ( checkNaN(val) === true && val !== "" )  {
			return;
		}
		else if ( Number(val) > 100 ) {
			return;
		}

		const newArray = [...monthlyCosts];
		if ( index === 0 && ii === 3 && exitStrategy === "brrrr" ) {
			// If we're on BRRRR strategy and they're changing property taxes, save the value to
			// the BRRRR fields
			newArray[index].inputs[ii].brrrrPercentageVal = val;
			const relativeValue = Number(arv) / 12;
			const dollarValue = (relativeValue * (Number(val) / 100));
			newArray[index].inputs[ii].brrrrValue = Number(dollarValue.toFixed(2));
		}
		else {
			newArray[index].inputs[ii].percentageVal = val;

			// Choose the appropriate relative value - either property price or monthly rent.
			// If the relative value is property price, divide the price by 12, so as to get the
			// monthly cost, rather than the yearly cost.
			const relativeValue = index === 0 ? customPrice / 12 : await getAppropriateRentEstimate(exitStrategy);
			const dollarValue = (relativeValue * (val / 100));
			newArray[index].inputs[ii].value = Number(dollarValue.toFixed(2));
		}
		setMonthlyCosts(newArray);

		const newTotal = await getTotalMonthlyCosts(newArray);
		// Register monthly costs
		collectMonthlyCosts(newTotal);
	};

	// Function to change monthly costs setting from percentage to dollar value
	const togglePercentage = (val, index, ii) => {
		const newArray = [...monthlyCosts];
		newArray[index].inputs[ii].percentage = val;
		setMonthlyCosts(newArray);
	};

	// Function to change ARV financing percentage
	const onChangeARVFinancing = async(text) => {
		if ( checkNaN(text) === true && text !== "" )  {
			return;
		}
		else if ( Number(text) <= 100 ) {
			setArvFinancingPercentage(text);

			const getRent = await getAppropriateRentEstimate(exitStrategy);
			const amountBorrowedInverse = 100 - Number(text);
			const newMortgage = await calculateMortgagePayment(Number(arv), amountBorrowedInverse, Number(interestRate), loanYears);
			const newMonthlyCosts = [...monthlyCosts];
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));

			const newArvFinancing = (Number(arv) * Number(text)) / 100;
			const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
			const newValsObject = {
				rent: getRent,
				downPayment: downPaymentCost,
				interestRate: Number(interestRate),
				closingCost: closingCost,
				rehab: Number(totalRehabCost),
				monthlyCosts: totalMonthlyCosts,
				price: Number(customPrice),
				arv: Number(arv),
				holdingCosts: Number(totalHoldingCosts),
				strategy: 0,
				arvFinancing: newArvFinancing
			};
			updateBRRRRCashFlow(newValsObject);
		}
	};

	// This is where we send all new total rents
	// to then send to a cash flow update function
	const collectTotalRent = async(val) => {
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const finalRent = Number(val);
		
		const newValsObject = {
			rent: finalRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: Number(closingCost),
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: 0,
			arvFinancing: Number(arvFinancing)
		};
		if ( exitStrategy === "ltr" || exitStrategy === "hh" ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( exitStrategy === "brrrr" ) {
			updateBRRRRCashFlow(newValsObject);
		}

		if ( exitStrategy !== "hh" ) {
			updateMonthlyCosts(monthlyCosts, finalRent, customPrice);
		}
	};

	// This is where we send all new initial costs
	// to then send to a cash flow update function
	const collectInitialCosts = async(down, int, close, price) => {
		let mortgagePayment;
		if ( exitStrategy === "brrrr" ) {
			const amountBorrowedInverse = 100 - Number(arvFinancingPercentage);
			mortgagePayment = await calculateMortgagePayment(Number(arv), amountBorrowedInverse, Number(int), loanYears);
		}
		else {
			mortgagePayment = Number(down) === 100 ? 0 : await calculateMortgagePayment(Number(price), Number(down), Number(int), loanYears);
		}

		const newMonthlyCosts = [...monthlyCosts];
		newMonthlyCosts[0].inputs[0].value = Number(mortgagePayment.toFixed(2));
		setMonthlyCosts(newMonthlyCosts);

		const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
		const getClosingCost = await getAppropriateClosingCost(Number(close), Number(price));
		const getRent = await getAppropriateRentEstimate(exitStrategy);
		const newDownPaymentCost = (Number(price) * (Number(down) / 100));
		const newValsObject = {
			rent: getRent,
			downPayment: newDownPaymentCost,
			interestRate: Number(int),
			closingCost: getClosingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(price),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: 0,
			arvFinancing: Number(arvFinancing)
		};
		if ( exitStrategy === "ltr" || exitStrategy === "hh" ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( exitStrategy === "brrrr" ) {
			updateBRRRRCashFlow(newValsObject);
		}
	};

	// This is where we send all new rehab costs
	// to then send to a cash flow update function
	const collectRehabCosts = async(rehab, newArv) => {

		const newMonthlyCosts = [...monthlyCosts];
		if ( exitStrategy === "brrrr" ) {
			// New mortgage payment for BRRRR
			const amountBorrowedInverse = 100 - Number(arvFinancingPercentage);
			const newMortgage = await calculateMortgagePayment(Number(newArv), amountBorrowedInverse, Number(interestRate), loanYears);
			newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));

			// New BRRRR property taxes
			const brrrrPropertyTax = (((brrrrPropertyTaxRate / 100) * newArv) / 12).toFixed(2);
			newMonthlyCosts[0].inputs[3].brrrrValue = Number(brrrrPropertyTax);
			newMonthlyCosts[0].inputs[3].brrrrPercentageVal = brrrrPropertyTaxRate;
		}

		const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
		const getRent = await getAppropriateRentEstimate(exitStrategy);
		const newArvFinancing = (Number(newArv) * arvFinancingPercentage) / 100;
		const newValsObject = {
			rent: getRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(rehab),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(newArv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: 0,
			arvFinancing: newArvFinancing
		};
		
		if ( exitStrategy === "ltr" || exitStrategy === "hh" ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( exitStrategy === "brrrr" ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( exitStrategy === "str" ) {
			newValsObject.rent = (Number(strRevenue) / 12);
			updateSTRCashFlow(newValsObject);
		}
		else if ( exitStrategy === "lo" ) {
			newValsObject.price = Number(leaseOptionValue);
			newValsObject.downPayment = Number(leaseOptionDownPayment);
			newValsObject.rent = Number(leaseOptionRent);
			newValsObject.interestRate = Number(leaseOptionInterestRate);
			newValsObject.propertyPrice = Number(customPrice);
			updateLeaseOptionCashFlow(newValsObject);
		}
	};

	// This is where we send all new monthly costs
	// to then send to a cash flow update function
	const collectMonthlyCosts = async(costs) => {
		const getRent = await getAppropriateRentEstimate(exitStrategy);
		const newValsObject = {
			rent: getRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: costs,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(totalHoldingCosts),
			strategy: 0,
			arvFinancing: Number(arvFinancing)
		};
		
		if ( exitStrategy === "ltr" || exitStrategy === "hh" ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( exitStrategy === "brrrr" ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( exitStrategy === "str" ) {
			newValsObject.rent = (Number(strRevenue) / 12);
			updateSTRCashFlow(newValsObject);
		}
		else if ( exitStrategy === "lo" ) {
			newValsObject.price = Number(leaseOptionValue);
			newValsObject.downPayment = Number(leaseOptionDownPayment);
			newValsObject.rent = Number(leaseOptionRent);
			newValsObject.interestRate = Number(leaseOptionInterestRate);
			newValsObject.propertyPrice = Number(customPrice);
			updateLeaseOptionCashFlow(newValsObject);
		}
	};

	// Function that collcetions holding cost values for the
	// Fix & Flip strategy
	const collectHoldingCosts = async(costs) => {
		const getRent = await getAppropriateRentEstimate(exitStrategy);
		const totalMonthlyCosts = await getTotalMonthlyCosts(monthlyCosts);
		const newValsObject = {
			rent: getRent,
			downPayment: downPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: totalMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: Number(costs),
			strategy: 0,
			arvFinancing: Number(arvFinancing)
		};
		
		updateBRRRRCashFlow(newValsObject);
	};

	// Function that collects property data after a strategy change
	const collectStrategyChangeData = async(newAcquisitionStrategy, newExitStrategy, newRent, newMonthlyCosts, newDown) => {
		const finalRent = Number(newRent);
		const newDownPaymentCost = newAcquisitionStrategy === "c" || newExitStrategy === "brrrr" ? customPrice : ((newDown * customPrice) / 100);

		const newValsObject = {
			rent: finalRent,
			downPayment: newDownPaymentCost,
			interestRate: Number(interestRate),
			closingCost: closingCost,
			rehab: Number(totalRehabCost),
			monthlyCosts: newMonthlyCosts,
			price: Number(customPrice),
			arv: Number(arv),
			holdingCosts: totalHoldingCosts,
			arvFinancing: Number(arvFinancing)
		};
		
		if ( newExitStrategy === "ltr" || newExitStrategy === "hh" ) {
			updateBuyAndHoldCashFlow(newValsObject);
		}
		else if ( newExitStrategy === "brrrr" ) {
			updateBRRRRCashFlow(newValsObject);
		}
		else if ( newExitStrategy === "str" ) {
			updateSTRCashFlow(newValsObject);
		}
		else if ( newExitStrategy === "lo" ) {
			newValsObject.price = Number(leaseOptionValue);
			newValsObject.downPayment = Number(leaseOptionDownPayment);
			newValsObject.interestRate = Number(leaseOptionInterestRate);
			newValsObject.propertyPrice = Number(customPrice);
			setLeaseOptionRent(finalRent);
			updateLeaseOptionCashFlow(newValsObject);
		}
	};

	// Fucntion that checks the property type and the selected
	// strategy panel, and returns the appropriate rent estimate
	const getAppropriateRentEstimate = async(newStrategy) => {
		if ( newStrategy === "lo" ) {
			const newRent = await calculateMortgagePayment(leaseOptionValue, leaseOptionDownPayment, leaseOptionInterestRate, (amortisation / 12));
			return newRent;
		}
		else if ( newStrategy === "hh" ) {
			// This is for house hack mode

			if ( multipleUniqueUnits === true ) {
				const finalRent = Number(multiUnitHouseHackRentTotal);
				return finalRent;
			}
			else {
				const finalRent = Number(houseHackRent);
				return finalRent;
			}
		}
		else {
			// This is for non-house hack mode

			if ( multipleUniqueUnits === true ) {
				const finalRent = Number(multiUnitRentTotal);
				return finalRent;
			}
			else {
				const finalRent = Number(rent);
				return finalRent;
			}
		}
	};

	// Function that gets the rent estimate in NON-house hack mode
	// but WITHOUT value add
	const getNonHouseHackRent = async() => {
		if ( multipleUniqueUnits === true ) {
			const finalRent = Number(multiUnitRentTotal);
			return finalRent;
		}
		else {
			const finalRent = Number(rent);
			return finalRent;
		}
	};

	// Function that gets the total monthly costs
	const getTotalMonthlyCosts = (vals) => {
		if ( exitStrategy === "str" ) {
			const totalMonthlyCosts = vals[0].inputs[0].value;
			return totalMonthlyCosts;
		}
		else {
			const totalMonthlyCosts = vals.reduce((accumulator, currentValue) => {
				currentValue.inputs.forEach(item => {
					if ( item.label === "Management fee" && exitStrategy === "hh" ) {
	
					}
					else if ( item.label === "Property taxes" && exitStrategy === "brrrr" ) {
						accumulator += Number(item.brrrrValue);
					}
					else {
						accumulator += Number(item.value);
					}
				});
				return accumulator; // Return the accumulator for the next iteration
			}, 0);
			return totalMonthlyCosts;
		}
	};

	// Function that calculates the dollar amount of closing costs
	const getAppropriateClosingCost = (val, price) => {
		return Number((price * (val / 100)).toFixed(2));
	};

	// Function that updates monthly costs after a rent value change or
	// a price change
	const updateMonthlyCosts = async(vals, newRent, newPrice) => {
		for (let index = 0; index < vals.length; index++) {
			const element = vals[index];
			for (let ii = 0; ii < element.inputs.length; ii++) {
				const input = element.inputs[ii];
				if ( index === 0 ) {
					// These monthly costs are relative to the property price
					if ( input.percentage === true ) {
						// Change the raw dollar amount to reflect the percentage of the new price
						const dollarAmount = (newPrice * (input.percentageVal / 100)) / 12;
						vals[index].inputs[ii].value = dollarAmount.toFixed(2);
					}
					else {
						// Change the percentage to reflect the dollar amount in relation to the new price
						const percentageAmount = ((input.value * 12) / newPrice) * 100;
						vals[index].inputs[ii].percentageVal = Number(percentageAmount.toFixed(1));
					}
				}
				else {
					// These monthly costs are relative to the monthly rent
					if ( input.percentage === true ) {
						// Change the raw dollar amount to reflect the percentage of the new rent estimate
						const dollarAmount = (newRent * (input.percentageVal / 100));
						vals[index].inputs[ii].value = dollarAmount.toFixed(2);
					}
					else {
						// Change the percentage to reflect the dollar amount in relation to the new rent estimate
						const percentageAmount = (input.value / newRent) * 100;
						vals[index].inputs[ii].percentageVal = Number(percentageAmount.toFixed(1));
					}
				}
			}
		};
		setMonthlyCosts(vals);
	};

	const updateLeaseOptionCashFlow = async(vals) => {
		// Down payment paid from buyer to seller

		// Option fee paid from tenant-buyer to buyer
        const newDownPaymentDollar = (vals.downPayment / 100) * vals.price;

		// Net costs and profit
        const newMonthlyCostsTotal = vals.monthlyCosts;
        const newProfit = vals.rent - newMonthlyCostsTotal;

        const newInitialCosts = Number(vals.rehab) + Number(downPaymentCost) + Number(vals.closingCost) - newDownPaymentDollar;
        const newCashOnCash = await calculateCashOnCash(newProfit, newInitialCosts);

        const profitObject = {
            monthlyProfit: highLevelProfits.monthlyProfit,
            cashOnCash: highLevelProfits.cashOnCash,
            totalCosts: highLevelProfits.totalCosts,
			arvSpread: highLevelProfits.newArvSpread,
			arvProfit: highLevelProfits.arvProfit,
			expensesCovered: highLevelProfits.expensesCovered,
			cashInDeal: null,
			monthlyCosts: newMonthlyCostsTotal,
            leaseOptionProfit: newProfit,
            leaseOptionCashOnCash: newCashOnCash / 100,
            leaseOptionTotalCosts: newInitialCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: highLevelProfits.strCashOnCash
        };
        setHighLevelProfits(profitObject);
		setProfitChange(true);
	};

	// Function for updating ROI for the STR Exit Strategy
	const updateSTRCashFlow = async(vals) => {
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, newInitialCosts);

		const profitObject = {
			monthlyProfit: highLevelProfits.monthlyProfit,
			cashOnCash: highLevelProfits.cashOnCash,
			totalCosts: newInitialCosts,
			arvSpread: highLevelProfits.arvSpread,
			arvProfit: highLevelProfits.arvProfit,
			expensesCovered: highLevelProfits.expensesCovered,
			cashInDeal: null,
			monthlyCosts: vals.monthlyCosts,
			leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: highLevelProfits.leaseOptionCashOnCash,
            leaseOptionTotalCosts: highLevelProfits.leaseOptionTotalCosts,
            strProfit: monthlyProfit,
            strCashOnCash: newCashOnCash / 100
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
	};

	// Function for changes made to the creative finance calculators
	const onChangeCreative = async(vals) => {
		const newMortgage = vals.totalMonthlyPayment;
		const getRent = await getAppropriateRentEstimate(exitStrategy);
		const newInitialCosts = vals.downPayment + vals.closingCost + Number(totalRehabCost);
		const newMonthlyCosts = [...monthlyCosts];
		newMonthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
		const totalMonthlyCosts = await getTotalMonthlyCosts(newMonthlyCosts);
		setMonthlyCosts(newMonthlyCosts);
		
		const monthlyProfit = getRent - totalMonthlyCosts;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, newInitialCosts);

		const leaseOptionInitialCosts = newInitialCosts - leaseOptionDollarDownPayment;
		const leaseOptionProfit = leaseOptionRent - totalMonthlyCosts;
		const leaseOptionCashOnCash = await calculateCashOnCash(leaseOptionProfit, leaseOptionInitialCosts);

		const strProfit = (Number(strRevenue) / 12) - newMonthlyCosts[0].inputs[0].value;
		const strCashOnCash = await calculateCashOnCash(strProfit, newInitialCosts);

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: highLevelProfits.arvSpread,
			arvProfit: highLevelProfits.arvProfit,
			expensesCovered: highLevelProfits.expensesCovered,
			cashInDeal: null,
			monthlyCosts: totalMonthlyCosts,
			leaseOptionProfit: leaseOptionProfit,
            leaseOptionCashOnCash: leaseOptionCashOnCash / 100,
            leaseOptionTotalCosts: leaseOptionInitialCosts,
            strProfit: strProfit,
            strCashOnCash: strCashOnCash / 100
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
	};

	// Function for updating ROI for the Turnkey or House Hack strategies
	// based up on user input changes
	const updateBuyAndHoldCashFlow = async(vals) => {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, newInitialCosts);
		const newArvSpread = await calculateARVSpread(vals.price, vals.rehab, vals.arv, 0);
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: null,
			monthlyCosts: vals.monthlyCosts,
			leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: highLevelProfits.leaseOptionCashOnCash,
            leaseOptionTotalCosts: highLevelProfits.leaseOptionTotalCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: highLevelProfits.strCashOnCash
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
	};

	// Function for updating ROI for the BRRRR strategy
	// based up on user input changes
	const updateBRRRRCashFlow = async(vals) => {
		const newInitialCosts = vals.downPayment + vals.closingCost + vals.rehab + vals.holdingCosts;
		const newCashInDeal = newInitialCosts - vals.arvFinancing;
		const monthlyProfit = vals.rent - vals.monthlyCosts;
		const newCashOnCash = await calculateCashOnCash(monthlyProfit, newCashInDeal);
		const newArvSpread = await calculateARVSpread(vals.price, vals.rehab, vals.arv, vals.holdingCosts);
		const expensesCovered = (vals.rent / vals.monthlyCosts);
		const arvProfit = vals.arv - vals.price - vals.rehab;

		const profitObject = {
			monthlyProfit: monthlyProfit,
			cashOnCash: newCashOnCash / 100,
			totalCosts: newInitialCosts,
			arvSpread: newArvSpread,
			arvProfit: arvProfit,
			expensesCovered: expensesCovered,
			cashInDeal: newCashInDeal,
			monthlyCosts: vals.monthlyCosts,
			leaseOptionProfit: highLevelProfits.leaseOptionProfit,
            leaseOptionCashOnCash: highLevelProfits.leaseOptionCashOnCash,
            leaseOptionTotalCosts: highLevelProfits.leaseOptionTotalCosts,
            strProfit: highLevelProfits.strProfit,
            strCashOnCash: highLevelProfits.strCashOnCash
		};
		setHighLevelProfits(profitObject);
		setProfitChange(true);
		saveSpreadsheetProperty(
			spreadsheetProperty,
			setSpreadsheetProperty,
			vals.price,
			monthlyProfit,
			newCashOnCash,
			vals.rent,
			monthlyCosts,
			vals.rehab,
			property.uniqueUnitPropertyDetails,
			vals.interestRate,
			closingPercentage
		);
	};

	const shareWithAgent = async() => {
		recordEvent("Share With Agent", {
			zpid: property.zpid,
			"Acquisition Strategy": acquisitionStrategy === "c" ? "Cash" : "Mortgage"
		});
		setShareAgentModal(true);
	};
	
	const generateOffer = async() => {
		setOfferLoading(true);
		const newWindow = window.open("", "_blank");

		const params = {
            overrides: {
                market_value: formatterLong.format(customPrice).replace(".00", ""),
                cf_down_payment_amount: `${downPayment}%`,
                cf_interest_rate: `${creativeInterestRate}%`,
                cf_amortization_term_months: `${amortisation} months`,
                cf_balloon_term_months: `${duration} months`,
            },
            address: `${property.address.streetAddress}, ${property.address.city} ${property.address.state} ${property.address.zipcode}`,
            templateId: acquisitionStrategy === "sf" ? "Owner Finance Offer with Balloon" : "Subject-to with Seller Finance"
        };

		if ( acquisitionStrategy === "sub" ) {
			params.overrides.mortgage_amount = formatterLong.format(Number(remainingMortgage)).replace(".00", "");
		}

        const queryData = await queryOfferREI(params);
        if ( queryData.status === 200 ) {
			if ( newWindow === null ) {
				window.open(queryData.data, "_blank");
				newWindow.close();
			}
			else {
				newWindow.location.href = queryData.data;
			}
			setOfferDownloadComplete(true);
			setOfferURL(queryData.data);
			recordEvent("Offer Generated", {
				zpid: property.zpid,
				"Acquisition Strategy": acquisitionStrategy === "sf" ? "Seller Finance"	: "Subject-to",
			})
		}
		else {
			newWindow.close();
		}
		setOfferLoading(false);
	};

	const nextOnboardingStep = async() => {
		if ( onboardingStep === 2 && creativeAcquisition === true ) {
			recordEvent("Property - Next Onboarding Step", {});
			setOnboardingStep(3);
		}
		else {
			if ( creativeAcquisition === true ) {
				generateOffer();
			}
			setOnboarding(false);
			setOnboardingComplete(true);
			await saveOnboardingComplete(userId);
			setOnboardingTooltip(true);
			recordEvent("V4 Onboarding Complete");
		}
	};

    return (
        <div className={fullscreenFinancials === true && selectedTab === 0 ? "financials-calculator-outer-wrapper-fullscreen financials-calculator-summary-tab-active" : fullscreenFinancials === true ? "financials-calculator-outer-wrapper-fullscreen" : selectedTab === 0 ? "financials-calculator-summary-tab-active financials-calculator-outer-wrapper" : "financials-calculator-outer-wrapper"}>
			{
				(acquisitionDropdownOpen === true || exitDropdownOpen === true) &&
				<ExitScreen
					clickScreen={closeDropdowns}
				/>
			}
			{
				onboarding === true &&
				<OnboardingTooltip
					body={<ListingsOnboarding step={onboardingStep} setOnboardingHover={null} />}
					position={onboardingStep === 2 ? "right" : "bottom"}
					cta={nextOnboardingStep}
					skip={skipOnboarding}
					totalSteps={creativeAcquisition === true ? 4 : 3}
					step={onboardingStep}
					customClass={onboardingStep === 2 ? onboardingStepTwoClass : "onboarding-tooltip-fourth-step"}
				/>
			}
			<div className="financials-calculator-outer-container">
				<FinancialsCalculatorHeader
					acquisitionDropdownOpen={acquisitionDropdownOpen}
					toggleAcquisitionDropdown={toggleAcquisitionDropdown}
					selectAcquisition={selectAcquisition}
					exitDropdownOpen={exitDropdownOpen}
					toggleExitDropdown={toggleExitDropdown}
					selectExit={selectExit}
					optimiseDeal={optimiseDeal}
					acquisitionStrategy={acquisitionStrategy}
					exitStrategy={exitStrategy}
					creativeUser={creativeUser}
					fullscreenFinancials={fullscreenFinancials}
					setFullscreenFinancials={setFullscreenFinancials}
				/>
				<div className="property-spacing-border-dark">
            	</div>
				<div className="financials-calculator-inner-container">
					<div className="financials-calculator-body-container">
                        <div className="financials-calculator-tabs-container">
                            {
                                financialOptions.map((item, index) =>
									exitStrategy !== "brrrr" && index === 5 ?
									null
									:
                                    <VerticalTab
                                        selected={item.active}
                                        label={item.title}
                                        icon={null}
                                        cta={() => selectFinancialOption(index)}
                                        external={false}
                                        account={false}
                                        userData={null}
                                        standardIcon={true}
                                        badge={false}
                                        key={index}
										reset={defaultValuesPanel[index]}
                                    />
                                )
                            }
                        </div>
                        <div 
							className={"financials-calculator-section-container " + (financialOptions[2].active === true && acquisitionStrategy === "sf" ? "financials-calculator-sf-section-container" : "")}
							ref={sectionContainerRef}
						>
                            {
								highLevelProfits !== "" && financialOptions[0].active === true ?
								<SummaryTab
									downPaymentCost={downPaymentCost}
									closingCost={closingCost}
									totalRehabCost={totalRehabCost}
									highLevelProfits={highLevelProfits}
									rent={exitStrategy === "lo" ? leaseOptionRent : exitStrategy === "str" ? Number(strRevenue) / 12 : multipleUniqueUnits && exitStrategy === "hh" ? Number(multiUnitHouseHackRentTotal) : exitStrategy === "hh" ? Number(houseHackRent) : multipleUniqueUnits === true ? Number(multiUnitRentTotal) : Number(rent)}
									monthlyCosts={monthlyCosts}
									acquisitionStrategy={acquisitionStrategy}
									exitStrategy={exitStrategy}
									sellersMortgage={sellersMortgage}
									leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
									cashInDeal={cashInDeal}
								/>
								:
								financialOptions[1].active === true && exitStrategy === "lo" ?
								<LeaseOptionRent
									blurred={blurred}
									property={property}
									leaseOptionValue={leaseOptionValue}
									leaseOptionDownPayment={leaseOptionDownPayment}
									leaseOptionDollarDownPayment={leaseOptionDollarDownPayment}
									leaseOptionInterestRate={leaseOptionInterestRate}
									leaseOptionRent={leaseOptionRent}
									setLeaseOptionRent={setLeaseOptionRent}
									onChangeLeaseValue={onChangeLeaseValue}
									onChangeLeaseDownPayment={onChangeLeaseDownPayment}
									onChangeLeaseDownPaymentDollar={onChangeLeaseDownPaymentDollar}
									onChangeLeaseInterest={onChangeLeaseInterest}
								/>
								:
								financialOptions[1].active === true && exitStrategy === "str" ?
								<STRRent
									blurred={blurred}
									strRevenue={strRevenue}
									onChangeSTRRevenue={onChangeSTRRevenue}
								/>
								:
                                financialOptions[1].active === true ?
                                <RentTab
                                    rent={rent}
                                    onChangeRent={onChangeRent}
                                    blurred={blurred}
                                    defaultRent={property.financials.rent}
									exitStrategy={exitStrategy}
                                    property={property}
                                    unitsRent={unitsRent}
									houseHackUnitsRent={houseHackUnitsRent}
                                    onChangeUnitRent={onChangeUnitRent}
									houseHackRent={houseHackRent}
									unitLiving={unitLiving}
									setUnitLiving={setUnitLiving}
									defaultHouseHackRent={defaultHouseHackRent}
									onChangeUnitLiving={onChangeUnitLiving}
									houseHackBedroomsRent={houseHackBedroomsRent}
									onChangeBedroomRent={onChangeBedroomRent}
									onChangeBedroomLiving={onChangeBedroomLiving}
									resetRentEstimates={resetRentEstimates}
									multipleUniqueUnits={multipleUniqueUnits}
									multiUnitRent={multiUnitRent}
									multiUnitRentTotal={multiUnitRentTotal}
									defaultMultiUnitRent={defaultMultiUnitRent}
									houseHackMultiUnitsRent={houseHackMultiUnitsRent}
									multiUnitTypeUnitLiving={multiUnitTypeUnitLiving}
									multiUnitHouseHackRentTotal={multiUnitHouseHackRentTotal}
									defaultMultiUnitHouseHackRentTotal={defaultMultiUnitHouseHackRentTotal}
									multiUnitRentRatio={multiUnitRentRatio}
									onChangeMultiUnitTotalRent={onChangeMultiUnitTotalRent}
									onChangeMultiUnitLiving={onChangeMultiUnitLiving}
									resetMFHMultiUnitRentEstimates={resetMFHMultiUnitRentEstimates}
									onChangeMultiUnitRent={onChangeMultiUnitRent}
									onChangeSFHHouseHackTotal={onChangeSFHHouseHackTotal}
									valueAddOptions={valueAddOptions}
									viewRentalComps={viewRentalComps}
                                />
                                :
								financialOptions[2].active === true && acquisitionStrategy === "sf" ?
								<SellerFinanceCalculator
									property={property}
									customPrice={customPrice}
									setCustomPrice={setCustomPrice}
									downPayment={downPayment}
									setDownPayment={setDownPayment}
									interestRate={creativeInterestRate}
									setInterestRate={setCreativeInterestRate}
									balloonPayment={balloonPayment}
									setBalloonPayment={setBalloonPayment}
									duration={duration}
									setDuration={setDuration}
									amortisation={amortisation}
									setAmortisation={setAmortisation}
									setMonthlyPayment={setMonthlyPayment}
									setSellerCompensation={setSellerCompensation}
									defaultDownPayment={defaultDownPayment}
									onChangeSellerFinance={onChangeCreative}
									closingPercentage={closingPercentage}
									closingCost={closingCost}
									setClosingPercentage={setClosingPercentage}
								/>
								:
								financialOptions[2].active === true && acquisitionStrategy === "sub" ?
								<SubtoCalculator
									property={property}
									customPrice={customPrice}
									setCustomPrice={setCustomPrice}
									downPayment={downPayment}
									setDownPayment={setDownPayment}
									interestRate={creativeInterestRate}
									setInterestRate={setCreativeInterestRate}
									balloonPayment={balloonPayment}
									setBalloonPayment={setBalloonPayment}
									duration={duration}
									setDuration={setDuration}
									amortisation={amortisation}
									setAmortisation={setAmortisation}
									monthlyPayment={monthlyPayment}
									setMonthlyPayment={setMonthlyPayment}
									setSellerCompensation={setSellerCompensation}
									defaultDownPayment={defaultDownPayment}
									ownerEquity={ownerEquity}
									setOwnerEquity={setOwnerEquity}
									remainingMortgage={remainingMortgage}
									setRemainingMortgage={setRemainingMortgage}
									sellersMortgage={sellersMortgage}
									setSellersMortgage={setSellersMortgage}
									onChangeSubto={onChangeCreative}
									closingPercentage={closingPercentage}
									closingCost={closingCost}
									setClosingPercentage={setClosingPercentage}
									onboarding={onboarding}
								/>
								:
                                financialOptions[2].active === true ?
								<PurchaseTab
									customPrice={customPrice}
									onChangePrice={onChangePrice}
									acquisitionStrategy={acquisitionStrategy}
									exitStrategy={exitStrategy}
									customDiscount={customDiscount}
									changeSlider={changePriceSlider}
									maxPercentage={maxPercentage}
									downPayment={downPayment}
									onChangeDownPayment={onChangeDownPayment}
									interestRate={interestRate}
									onChangeInterest={onChangeInterest}
									closingPercentage={closingPercentage}
									onChangeClosing={onChangeClosing}
									property={property}
									closingCost={closingCost}
									defaultDownPayment={defaultDownPayment}
									defaultInterestRate={defaultInterestRate}
									onChangeARVFinancing={onChangeARVFinancing}
									arvFinancing={arvFinancing}
									arvFinancingPercentage={arvFinancingPercentage}
									cashInDeal={cashInDeal}
									totalRehabCost={totalRehabCost}
									onboarding={onboarding}
									sectionContainerRef={sectionContainerRef}
								/>
								:
								financialOptions[3].active === true ?
								<RehabTab
									property={property}
									costPerSqFoot={costPerSqFoot}
									setCostPerSqFoot={setCostPerSqFoot}
									totalRehabCost={totalRehabCost}
									setTotalRehabCost={setTotalRehabCost}
									changeRehabCost={changeRehabCost}
									onChangeCostPerSqFoot={onChangeCostPerSqFoot}
									onChangeTotalCost={onChangeTotalCost}
									defaultCostPerSqFoot={defaultCostPerSqFoot}
									arv={arv}
									onChangeARV={onChangeARV}
									onChangeARVSlider={onChangeARVSlider}
									customArv={customArv}
									maxPercentage={maxPercentage}
									defaultTotalCost={defaultTotalRehabCost}
									defaultArv={defaultArv}
									viewArvComps={viewArvComps}
								/>
								:
								financialOptions[4].active === true ?
								<MonthlyCostsTab
									exitStrategy={exitStrategy}
									monthlyCosts={monthlyCosts}
									onChangeMonthlyCosts={onChangeMonthlyCosts}
									togglePercentage={togglePercentage}
									onChangeMonthlyCostsPercentage={onChangeMonthlyCostsPercentage}
									resetMonthlyCost={resetMonthlyCost}
									doneSettings={doneSettings}
								/>
								:
								financialOptions[5].active === true ?
								<HoldingCosts
									monthlyHoldingCosts={monthlyHoldingCosts}
									holdingPeriod={holdingPeriod}
									totalHoldingCosts={totalHoldingCosts}
									onChangeHoldingCosts={onChangeHoldingCosts}
									onChangeHoldingPeriod={onChangeHoldingPeriod}
								/>
								:
								null
                            }
                        </div>
					</div>
					{
						acquisitionStrategy === "sf" && financialOptions[2].active === true &&
						<CreativeAnalysis
							customPrice={customPrice}
							sellerCompensation={sellerCompensation}
							monthlyPayment={monthlyPayment}
							property={property}
						/>
					}
				</div>
				<div className="property-spacing-border-dark">
            	</div>
				{
					highLevelProfits !== "" &&
					<FinancialsCalculatorFooter
						selectedTab={selectedTab}
						highLevelProfits={highLevelProfits}
						blurred={blurred}
						cashInDeal={cashInDeal}
						generateOffer={generateOffer}
						creativeAcquisition={creativeAcquisition}
						shareWithAgent={shareWithAgent}
						acquisitionStrategy={acquisitionStrategy}
						exitStrategy={exitStrategy}
						offerLoading={offerLoading}
					/>
				}
			</div>
			{
				fullscreenFinancials === false &&
				<ReportFinancialsBug
					userDetails={userDetails}
					bugModal={bugModal}
					setBugModal={setBugModal}
					blurred={blurred}
				/>
			}
		</div>
    );
};

export default FinancialsCalculator;