import React from 'react';

function Lock(props) {
    const className = props.className;
    const fill = props.fill;
    return (
        <svg 
            width="14" 
            height="16" 
            viewBox="0 0 14 16" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M2.14421 5.70336C2.19682 2.11915 4.44586 4.65982e-07 6.99999 0C9.55412 -5.26632e-07 11.8032 2.11915 11.8558 5.70336H12.7895C13.0026 5.70336 13.1754 5.87624 13.1754 6.08949V14.6164C13.1754 15.3805 12.5562 16 11.7924 16H2.2076C1.44377 16 0.824554 15.3805 0.824554 14.6164V6.08949C0.824554 5.87624 0.997372 5.70336 1.21052 5.70336H2.14421ZM4.20291 5.70336C4.25085 3.05864 5.77672 2.05933 6.99999 2.05933C8.22327 2.05933 9.74914 3.05864 9.79708 5.70336H4.20291ZM7.80679 10.9138C8.15578 10.6626 8.38303 10.2528 8.38303 9.78984C8.38303 9.02569 7.76382 8.40623 6.99999 8.40623C6.23617 8.40623 5.61695 9.02569 5.61695 9.78984C5.61695 10.2396 5.83145 10.6392 6.16367 10.8919L5.90947 12.9764C5.89075 13.1298 6.01039 13.265 6.16487 13.265H7.80296C7.95743 13.265 8.07707 13.1298 8.05835 12.9764L7.80679 10.9138Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Lock;