import React from 'react';
import '../styles/BulkAction.css';
import { Button, IconButton } from './';
import { sendPropertiesForExcel } from '../../functions';
import { colour } from '../../styles/GlobalStyles';
import { Bin, Download } from '../../assets/icons';

function BulkAction(props) {
    const selectedProperties = props.selectedProperties;
    const setListModal = props.setListModal;
    const lists = props.lists;
    const setSelectedList = props.setSelectedList;
    const openDeletePropertiesModal = props.openDeletePropertiesModal;
    const listView = props.listView;
    const properties = props.properties;
    const setDownloadLoading = props.setDownloadLoading;
    const setDownloadComplete = props.setDownloadComplete;
    const setDownloadError = props.setDownloadError;
    const setDownloadLink = props.setDownloadLink;

    // const compareProperties = () => {

    // };

    const downloadProperties = async() => {
        setDownloadLoading(true);
        setDownloadError(false);
        const downloadProps = [];
        for (let index = 0; index < properties.length; index++) {
            const element = properties[index];
            const zpid = element.zpid;
            if ( selectedProperties.indexOf(zpid) !== -1 ) {
                downloadProps.push(element);
            }
        };

        const downloadData = await sendPropertiesForExcel(downloadProps, "Selected Properties", null, 20, 0);
		if ( downloadData.status === 200 ) {
			window.open(downloadData.data, "_blank");
            setDownloadComplete(true);
            setDownloadError(false);
            setDownloadLink(downloadData.data);
        }
        else {
            setDownloadError(true);
        }

        setDownloadLoading(false);
    };

    const addToList = () => {
        const relevantLists = [];
        for (let index = 0; index < selectedProperties.length; index++) {
            const zpid = selectedProperties[index];
            
            for (let ii = 0; ii < lists.length; ii++) {
                const list = lists[ii];
                const listProperties = list.properties;
                const propertyIndex = listProperties.findIndex(e => e.zpid === zpid);
                if (propertyIndex !== -1) {
                    relevantLists.push(list.id);
                }
            }
        }
        setSelectedList(relevantLists);
        setListModal(true);
    };

    return (
        <div className="bulk-action-outer-container">
            <div className="bulk-action-side-container">
                <span className="body-regular colour-white white-space-no-wrap">
                    {selectedProperties.length} selected
                </span>
                {/* <Button
                    buttonType="tertiary-button"
                    text="Compare"
                    cta={compareProperties}
                    disabled={selectedProperties.length < 2 ? true : false}
                    leftIcon={null}
                    rightIcon={null}
                    alt="Compare"
                    size="large"
                    standardIcon={true}
                    badge={false}
                    badgeVal={null}
                    badgeBG={null}
                    buttonRef={null}
                /> */}
            </div>
            <div className="bulk-action-side-container">
                <IconButton
                    size="medium"
                    icon={<Bin fill={colour.redRed01} className="" />}
                    disabled={false}
                    clickFunc={openDeletePropertiesModal}
                    standardIcon={false}
                    contextTooltip={true}
                    contextTooltipBG={"light"}
                    contextTooltipText={"Delete properties"}
                    customClass={null}
                />
                <IconButton
                    size="medium"
                    icon={<Download fill={colour.grayScaleWhite} className="" />}
                    disabled={false}
                    clickFunc={downloadProperties}
                    standardIcon={false}
                    contextTooltip={true}
                    contextTooltipBG={"light"}
                    contextTooltipText={"Download CSV"}
                    customClass={null}
                />
                <Button
                    buttonType="light-button"
                    text={listView === true ? "Edit list" : "Add to list"}
                    cta={addToList}
                    disabled={false}
                    leftIcon={null}
                    rightIcon={null}
                    alt="Add to list"
                    size="large"
                    standardIcon={true}
                    badge={false}
                    badgeVal={null}
                    badgeBG={null}
                    buttonRef={null}
                />
            </div>
        </div>
    )
};

export default BulkAction;