import React, { useEffect, useState, createRef, useRef } from 'react';
import { Header, PropertyListTopBar, NewPropertyCard, NewPropertyMap, EmergingAreaModal, Pagination, MobilePropertyCardOverlay, SignUpModal, PropertyListButton, TextLoading, StrategyLoading, FSBOOnly, ExitScreen, Loading } from '../components';
import { checkOnAuthStateChanged, getMiddle, recordEvent, getUserDocuments, cloudFunctionV2, saveFiltersToDB, filtersEncoder, getAPIGatewayListingsV4, getDocument, goToCreativeCheckout, updateHideHeatmapSetting, updateShownHeatmapModal, downloadAllPropertiesExcel, sendPropertiesForExcel, createFiltersBuyBox } from '../functions';
import '../styles/PropertyList.css';
import { isBrowser, isMobile } from 'react-device-detect';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatterLong, defaultUserData, neighbourhoodDataOptions, colour, numberFormatter } from '../styles/GlobalStyles';
import { ClearFilters, Dropdown, FilterChip, Filters, Modal, OnboardingTooltip, Toast } from '../v4/components';
import { CoCRSort, NeighbourhoodHeatmapText, Upgrade, MapSettings, MobileSorting, MarketUnavailable, NewBuyBoxModal } from '../v4/modalbody';
import { Download, MultiFamily, SingleFamily, Sort, TickCircle } from '../assets/icons';
import { defaultVals } from '../defaults';
import { ListingsOnboarding } from '../v4/tooltipbody'

function PropertyList() {
	const params = useParams();
	const cityId = params.id;
	const [userDetails, setUserDetails] = useState(null);
	const [cityStateName, setCityStateName] = useState("");
	const [properties, setProperties] = useState([]);
	const [mapProperties, setMapProperties] = useState([]);
	const [allProperties, setAllProperties] = useState([]);
	const [centreCoordinates, setCentreCoordinates] = useState({lat: 0, lng: 0});
	const [newCentre, setNewCentre] = useState(null);
	const [filtersModalOpen, setFiltersModalOpen] = useState(false);
	const [viewedProperties, setViewedProperties] = useState([]);
	const [propertiesOnPage, setPropertiesOnPage] = useState(0);
	const [propertiesOnPageChange, setPropertiesOnPageChange] = useState(false);
	const [overlay, setOverlay] = useState(true);
	const [mobileAbstract, setMobileAbstract] = useState("");
	const [noProperties, setNoProperties] = useState(false);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const [hoverCard, setHoverCard] = useState("");
	const [propertyRefs, setPropertyRefs] = useState([]);
	const [onboarding, setOnboarding] = useState(false);
	const [onboardingStep, setOnboardingStep] = useState(0);
	const [onboardingHover, setOnboardingHover] = useState(null);
	const [onboardingAuto, setOnboardingAuto] = useState(true);
	const [onboardingTooltip, setOnboardingTooltip] = useState(false);
	const [onboardingInitialised, setOnboardingInitialised] = useState(false);
	const [cityName, setCityName] = useState("");
	const cardsRef = useRef(null);
	const [loading, setLoading] = useState(true);
	const [freeTrial, setFreeTrial] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [cardIndex, setCardIndex] = useState(null);
	const [cardClick, setCardClick] = useState(false);
	const [totalProperties, setTotalProperties] = useState(0);
	const [filteredPropertyCount, setFilteredPropertyCount] = useState(0);
	const [selectedTracts, setSelectedTracts] = useState([]);
	const getPage = searchParams.get('page');
	const getDownPayment = searchParams.get('downPayment');
	const [page, setPage] = useState(getPage === null ? 1 : Number(getPage));
	const getSorting = searchParams.get('sort');
	const [sorting, setSorting] = useState(getSorting === null ? 0 : Number(getSorting));
	const [showResetButton, setShowResetButton] = useState(false);
	const [mapSidePanelOpen, setMapSidePanelOpen] = useState(null);
	const [freeTrialExpired, setFreeTrialExpired] = useState(false);
	const temporaryId = false;
	const [signUpModal, setSignUpModal] = useState(false);
	const [changeMade, setChangeMade] = useState(null);
	const [addressSearchedProperty, setAddressSearchedProperty] = useState(null);
	const [googleMapProperty, setGoogleMapProperty] = useState(null);
	const [userSubscriptions, setUserSubscriptions] = useState([]);
	const [premiumModal, setPremiumModal] = useState(false);
	const [mapSettingsModal, setMapSettingsModal] = useState(false);
	const [mobileSortModal, setMobileSortModal] = useState(false);
	const [csvDownloadLoading, setCSVDownloadLoading] = useState(false);
	const [csvDownloadComplete, setCSVDownloadComplete] = useState(false);
	const [csvDownloadError, setCSVDownloadError] = useState(false);
	const [csvDownloadLink, setCSVDownloadLink] = useState(null);

	const getFilters = searchParams.get('filters');
	const parsedFilters = getFilters !== null ? JSON.parse(getFilters) : null;
	const propertyTypeFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "propertyType");
	const mfhIncluded = propertyTypeFilterIndex === -1 ? false : parsedFilters[propertyTypeFilterIndex].value.propertyTypes.includes("MFH");
	const [propertyTypes, setPropertyTypes] = useState(propertyTypeFilterIndex === -1 ? defaultVals.propertyTypes : [
		{
            label: "Single-Family",
            icon: <SingleFamily className="" fill={colour.blueBlue03} />,
            body: null,
			included: propertyTypeFilterIndex !== -1 && parsedFilters[propertyTypeFilterIndex].value.propertyTypes.includes("SFH") ? true : false,
            standardIcon: false,
			code: "SingleFamily",
			abbreviation: "SFH"
        },
        {
            label: "Multi-Family",
            icon: <MultiFamily className="" fill={colour.blueBlue03} />,
            body: null,
			included: propertyTypeFilterIndex !== -1 && mfhIncluded ? true : false,
            standardIcon: false,
			code: "MultiFamily",
			abbreviation: "MFH"
        }
	]);
	const [units, setUnits] = useState(mfhIncluded === true ? parsedFilters[propertyTypeFilterIndex].value.units : defaultVals.units);
	const [unitExactMatch, setUnitExactMatch] = useState(mfhIncluded === true ? parsedFilters[propertyTypeFilterIndex].value.match : defaultVals.unitExactMatch);
	const budgetRangeFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "price");
    const [budgetRange, setBudgetRange] = useState(budgetRangeFilterIndex !== -1 ? parsedFilters[budgetRangeFilterIndex].value : defaultVals.budgetRange);
	const bedroomFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "beds");
    const [bedrooms, setBedrooms] = useState(bedroomFilterIndex !== -1 ? parsedFilters[bedroomFilterIndex].value : defaultVals.bedrooms);
	const [bedroomExactMatch, setBedroomExactMatch] = useState(bedroomFilterIndex !== -1 ? parsedFilters[bedroomFilterIndex].match : defaultVals.bedroomExactMatch);
	const bathroomFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "baths");
	const [bathrooms, setBathrooms] = useState(bathroomFilterIndex !== -1 ? parsedFilters[bathroomFilterIndex].value : defaultVals.bathrooms);
	const [bathroomExactMatch, setBathroomExactMatch] = useState(bathroomFilterIndex !== -1 ? parsedFilters[bathroomFilterIndex].match : defaultVals.bathroomExactMatch);
	const squareFootageFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "sqft");
	const [squareFootage, setSquareFootage] = useState(squareFootageFilterIndex !== -1 ? parsedFilters[squareFootageFilterIndex].value : defaultVals.squareFootage);
	const constructionYearsFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "construction");
	const [constructionYears, setConstructionYears] = useState(constructionYearsFilterIndex !== -1 ? parsedFilters[constructionYearsFilterIndex].value : defaultVals.constructionYears);
	const conditionFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "condition");
	const [conditionValues, setConditionValues] = useState(conditionFilterIndex !== -1 ? parsedFilters[conditionFilterIndex].value : defaultVals.conditionValues);
	const creativeFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "creative");
	const [creativeFinanceType, setCreativeFinanceType] = useState(creativeFilterIndex !== -1 ? parsedFilters[creativeFilterIndex].value : defaultVals.creativeFinanceType);
	const listingTypeIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "listingType");
	const [listingType, setListingType] = useState(listingTypeIndex !== -1 ? parsedFilters[listingTypeIndex].value : defaultVals.listingType);
	const daysOnMarketFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "daysOnMarket");
	const [daysOnMarket, setDaysOnMarket] = useState(daysOnMarketFilterIndex !== -1 ? parsedFilters[daysOnMarketFilterIndex].value : defaultVals.daysOnMarket);
	const backOnMarketFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "backOnMarket");
	const [backOnMarket, setBackOnMarket] = useState(backOnMarketFilterIndex !== -1 ? parsedFilters[backOnMarketFilterIndex].value : defaultVals.backOnMarket);
	const priceReductionFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "priceReduction");
	const [recentPriceReduction, setRecentPriceReduction] = useState(priceReductionFilterIndex !== -1 ? parsedFilters[priceReductionFilterIndex].value : defaultVals.priceReduction);
	const neighbourhoodGradesFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "neighbourhoodGrades");
	const [neighbourhoodGrades, setNeighbourhoodGrades] = useState(neighbourhoodGradesFilterIndex !== -1 ? parsedFilters[neighbourhoodGradesFilterIndex].value : defaultVals.neighbourhoodGrades);
	const zipcodeFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "zipcodes");
	const [zipcodes, setZipcodes] = useState(zipcodeFilterIndex !== -1 ? parsedFilters[zipcodeFilterIndex].value : defaultVals.zipcodes);
	const [allZipCodes, setAllZipCodes] = useState([]);
	const hideFloodzoneFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "floodzone");
	const [hideFloodzone, setHideFloodzone] = useState(hideFloodzoneFilterIndex !== -1 ? parsedFilters[hideFloodzoneFilterIndex].value : defaultVals.hideFloodzone);
	const hideHOAFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "hoa");
	const [hideHOA, setHideHOA] = useState(hideHOAFilterIndex !== -1 ? parsedFilters[hideHOAFilterIndex].value : defaultVals.hideHOA);
	const onlyADUFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "adu");
	const [onlyADU, setOnlyADU] = useState(onlyADUFilterIndex !== -1 ? parsedFilters[onlyADUFilterIndex].value : defaultVals.onlyADU);
	const onlyExtraBedroomFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "extraBedroom");
    const [onlyExtraBedroom, setOnlyExtraBedroom] = useState(onlyExtraBedroomFilterIndex !== -1 ? parsedFilters[onlyExtraBedroomFilterIndex].value : defaultVals.onlyExtraBedroom);
	const oversizedLotFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "oversizedLot");
	const [oversizedLot, setOversizedLot] = useState(oversizedLotFilterIndex !== -1 ? parsedFilters[oversizedLotFilterIndex].value : defaultVals.oversizedLot);
	const ownerTypeFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "ownerType");
	const [ownerType, setOwnerType] = useState(ownerTypeFilterIndex !== -1 ? parsedFilters[ownerTypeFilterIndex].value : defaultVals.ownerType);
	const ownerLengthFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "ownershipLength");
	const [ownershipLength, setOwnershipLength] = useState(ownerLengthFilterIndex !== -1 ? parsedFilters[ownerLengthFilterIndex].value : defaultVals.ownershipLength);
	const distressFlagsFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "distress");
	const [distressFlags, setDistressFlags] = useState(distressFlagsFilterIndex !== -1 ? parsedFilters[distressFlagsFilterIndex].value : defaultVals.distressFlags);
	const estimatedInterestFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "interest");
	const [estimatedInterest, setEstimatedInterest] = useState(estimatedInterestFilterIndex !== -1 ? parsedFilters[estimatedInterestFilterIndex].value : defaultVals.estimatedInterest);
	const equityLevelFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "equity");
	const [equityLevel, setEquityLevel] = useState(equityLevelFilterIndex !== -1 ? parsedFilters[equityLevelFilterIndex].value : defaultVals.equityLevel);
	const mortgageTypeFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "mortgageType");
	const [mortgageType, setMortgageType] = useState(mortgageTypeFilterIndex !== -1 ? parsedFilters[mortgageTypeFilterIndex].value : defaultVals.mortgageType);
	const minCoCRFilterIndex = parsedFilters === null ? -1 : parsedFilters.findIndex(e => e.label === "cocr");
	const [minCoCR, setMinCoCR] = useState(minCoCRFilterIndex !== -1 ? parsedFilters[minCoCRFilterIndex].value : defaultVals.cocr);
	const [favourites, setFavourites] = useState([]);
	const [financialPreferences, setFinancialPreferences] = useState(null);
	const getStrategyParam = searchParams.get('strategy');
	const [strategy, setStrategy] = useState(getStrategyParam === null ? 0 : Number(JSON.parse(getStrategyParam)));
	const [emergingMarkets, setEmergingMarkets] = useState([]);
	const [emergingAreaModal, setEmergingAreaModal] = useState(false);
	const [emergingAreaTract, setEmergingAreaTract] = useState(null);
	const [activeMarket, setActiveMarket] = useState(null);
	const [creativeSubscription, setCreativeSubscription] = useState(false);
	const [alreadyShownNeighbourhoodHeatmap, setAlreadyShownNeighbourhoodHeatmap] = useState(false);
	const [neighbourhoodHeatmapModal, setNeighbourhoodHeatmapModal] = useState(false);
	const [dontShowNeighbourhoodHeatmapMessage, setDontShowNeighbourhoodHeatmapMessage] = useState(false);

	const [neighbourhoodData, setNeighbourhoodData] = useState([]);
	const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
	const [neighbourhoodOption, setNeighbourhoodOption] = useState(0);
	const [settingsNeighbourhoodOption, setSettingsNeighbourhoodOption] = useState(0);
	const [tracts, setTracts] = useState([]);
	const [clickedTract, setClickedTract] = useState("");
	const [chosenTract, setChosenTract] = useState(null);
	const [hoverMapLegend, setHoverMapLegend] = useState(null);
	const [loadingStage, setLoadingStage] = useState(0);
	const [firstLoading, setFirstLoading] = useState(true);
	const [strategiesLoading, setStrategiesLoading] = useState(true);
	const [strategyAnimations, setStrategyAnimations] = useState([
		true,
		false,
		false,
		false,
		false,
		false
	]);
	const [hideHeatmap, setHideHeatmap] = useState(true);
	const [settingsHideHeatmap, setSettingsHideHeatmap] = useState(true);
	const getCreativeStrategy = searchParams.get('creative');
	const [creativeStrategy, setCreativeStrategy] = useState(getCreativeStrategy === null ? false : JSON.parse(getCreativeStrategy));
	const [mapTypeDropdownOpen, setMapTypeDropdownOpen] = useState(false);
	const [settingsMapTypeDropdownOpen, setSettingsMapTypeDropdownOpen] = useState(false);
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
	const [notRecommendedModal, setNotRecommendedModal] = useState(false);
	const [modalStrategy, setModalStrategy] = useState(getStrategyParam === null ? 0 : Number(JSON.parse(getStrategyParam)));
	const [modalSorting, setModalSorting] = useState(getSorting === null ? 0 : Number(getSorting));
	const [createBuyBoxModal, setCreateBuyBoxModal] = useState(false);
	const [newBuyBoxName, setNewBuyBoxName] = useState("");
	const [newBuyBoxNameError, setNewBuyBoxNameError] = useState(false);
	const [newBuyBoxFrequency, setNewBuyBoxFrequency] = useState("daily");
	const [newBuyBoxFrequencyDropdown, setNewBuyBoxFrequencyDropdown] = useState(false);
	const [newBuyBoxLoading, setNewBuyBoxLoading] = useState(false);
	const [buyBoxToast, setBuyBoxToast] = useState(false);
	const hostname = window.location.hostname;
	const liveSite = hostname === "localhost" || window.location.hostname.includes("192.") || hostname.includes("refi") ? false : true;
	const navigate = useNavigate();
	const onboardingHoverClass = onboardingHover === null ? "" : ` new-property-list-onboarding-hover-${onboardingHover}`;

	const onboardingSteps = [
		{
			customClass: "onboarding-tooltip-first-step"
		},
		{
			customClass: "onboarding-tooltip-second-step"
		}
	];

	const sortOptions = [
		{
			label: "Sort",
            selected: sorting === 0 ? true : false,
            disabled: true,
			abbreviation: "Sort"
		},
		{
			label: "Strategies",
            selected: sorting === 1 ? true : false,
            disabled: false,
			abbreviation: "Strategies"
		},
		{
			label: "Newest to oldest",
            selected: sorting === 2 ? true : false,
            disabled: false,
			abbreviation: "New to old"
		},
		{
			label: "Oldest to newest",
            selected: sorting === 3 ? true : false,
            disabled: false,
			abbreviation: "Old to new"
		},
		{
			label: "Cash on cash return",
            selected: sorting === 4 ? true : false,
            disabled: false,
			abbreviation: "CoCR"
		},
		{
			label: "Price - lowest to highest",
            selected: sorting === 5 ? true : false,
            disabled: false,
			abbreviation: "Price low - high"
		},
		{
			label: "Price - highest to lowest",
            selected: sorting === 6 ? true : false,
            disabled: false,
			abbreviation: "Price high - low"
		},
		{
			label: "ARV Spread - highest to lowest",
            selected: sorting === 7 ? true : false,
            disabled: false,
			abbreviation: "ARV Spread"
		}
	];

	useEffect(() => {
		const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);

			if ( window.innerWidth >= 1000 ) {
				setMapSettingsModal(false);
				setMobileSortModal(false);
			}
		};
	  
		window.addEventListener('resize', handleWindowResize);

		async function checkLoggedIn() {
			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const userId = user.userId;
				const collections = [
					"Users",
					"Subscriptions",
					"Financial Preferences",
					"Favourites"
				];

				const queryUserData = await getUserDocuments(collections, userId);
				if ( queryUserData[0].status === 200 ) {
					const userData = queryUserData[0].data;
					const freeTrialCheck = userData.freeTrial;
					const freeTrialeEndDate = freeTrialCheck[0].endDate.seconds;
					setUserDetails(userData);

					if ( userData.hideHeatmap !== undefined && userData.hideHeatmap === false ) {
						setHideHeatmap(userData.hideHeatmap);
						setSettingsHideHeatmap(userData.hideHeatmap);
					}

					if ( neighbourhoodGradesFilterIndex !== -1 ) {
						setHideHeatmap(false);
					}

					if ( userData.shownHeatmapModal !== undefined && userData.shownHeatmapModal === true ) {
						setAlreadyShownNeighbourhoodHeatmap(userData.shownHeatmapModal);
					}

					if ( queryUserData[3].status === 200 ) {
						setFavourites(queryUserData[3].data);
					}

					const today = new Date();
					const todaySeconds = today.getTime() / 1000;
					
					const adminIds = JSON.parse(process.env.REACT_APP_ADMINIDS);
        			const isAdmin = adminIds.includes(user.userId);
					if ( userData.freeTrial === undefined || userData.freeTrial.length === 0 ) {
						navigate('/home');
					}
					else if ( isAdmin === true ) {
						setCreativeSubscription(true);
						const checkViewedProperties = userData.viewedProperties;
						if ( checkViewedProperties !== undefined ) {
							setViewedProperties(userData.viewedProperties);
						}

						if ( queryUserData[2].status === 200 ) {
							const settings = queryUserData[2].data;
							queryGatewayProperties(settings);
						}
						else {
							const settings = defaultUserData.settings;
							queryGatewayProperties(settings);
						}
						getNeighbourhoodData();

						const subscriptions = queryUserData[1].data;
						setUserSubscriptions(subscriptions);
					}
					else if ( queryUserData[1].status === 200 ) {
						const subscriptions = queryUserData[1].data;
						const countySubscriptionIndex = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds);
						const checkCreativeSub = subscriptions.findIndex(e => e.endDate.seconds > todaySeconds && e.creative === true);
						setUserSubscriptions(subscriptions);
			
						if ( countySubscriptionIndex !== -1 ) {
                            
							if ( checkCreativeSub !== -1 ) {
								setCreativeSubscription(true);
							}

                            const checkViewedProperties = userData.viewedProperties;
                            if ( checkViewedProperties !== undefined ) {
                                setViewedProperties(userData.viewedProperties);
                            }

							if ( queryUserData[2].status === 200 ) {
								const settings = queryUserData[2].data;
								queryGatewayProperties(settings);
							}
							else {
								const settings = defaultUserData.settings;
								queryGatewayProperties(settings);
							}
							getNeighbourhoodData();
						}
						else if ( freeTrialeEndDate > todaySeconds ) {
							setFreeTrial(true);
							setCreativeSubscription(true);
							const checkViewedProperties = userData.viewedProperties;
							if ( checkViewedProperties !== undefined ) {
								setViewedProperties(userData.viewedProperties);
							}

							if ( queryUserData[2].status === 200 ) {
								const settings = queryUserData[2].data;
								queryGatewayProperties(settings);
							}
							else {
								const settings = defaultUserData.settings;
								queryGatewayProperties(settings);
							}
							getNeighbourhoodData();
						}
                        else {
                            setLoading(false);
                            setFreeTrialExpired(true);
							setPremiumModal(true);
                        }
					}
					else if ( freeTrialeEndDate > todaySeconds ) {
						setFreeTrial(true);
						setCreativeSubscription(true);
						const checkViewedProperties = userData.viewedProperties;
						if ( checkViewedProperties !== undefined ) {
							setViewedProperties(userData.viewedProperties);
						}

						if ( userData.onboardingV4 !== true ) {
							setOnboarding(true);

							setTimeout(() => {
								setOnboardingInitialised(true);
							}, 1500);
						}

						if ( queryUserData[2].status === 200 ) {
							const settings = queryUserData[2].data;
							queryGatewayProperties(settings);
						}
						else {
							const settings = defaultUserData.settings;
							queryGatewayProperties(settings);
						}
						getNeighbourhoodData();
					}
					else {
						setLoading(false);
                        setFreeTrialExpired(true);
						setPremiumModal(true);
                    }
				}
				else {
					navigate("/sign-up");
				}
			}
			else {
				document.title = "Property Listings";
				navigate('/sign-up', {
					state: {
						cityId: cityId
					}
				});
			}
		}

		async function queryGatewayProperties(settings) {
			setLoadingStage(1);
			setFinancialPreferences(settings);
			const newFilters = parsedFilters === null ? null : await filtersEncoder(parsedFilters);

			if ( neighbourhoodGradesFilterIndex !== -1 ) {
				setHideHeatmap(false);
			}

			const strategyVals = [
				"ltr",
				"househack",
				"brrrr",
				"leaseoption"
			];

			const sortingVals = [
				"",
				"",
				"onMarketTimeLow",
				"onMarketTimeHigh",
				"cashOnCashHigh",
				"priceLow",
				"priceHigh",
				"arvSpreadHigh"
			];
			const getGatewayQueryString = await getAPIGatewayListingsV4(settings, creativeStrategy, cityId, newFilters, page, strategyVals[strategy], sortingVals[sorting], newCentre, false);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const gatewayData = {
				type: "gateway",
				resourceId: "listingsV4",
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, gatewayData);
			let queryData = [];
			if ( getGateway.status === 200 ) {
				if ( getGateway.body.length === 0 ) {
					setNoProperties(true);
					setLoading(false);
					setStrategiesLoading(false);
					setFirstLoading(false);
					return;
				}
				const getGatewayBody = getGateway.body[0];
				const getGatewayData = getGatewayBody.data;
				setActiveMarket(getGatewayData.activeRegionFlag);
				
				queryData = getGatewayData.props;
				
				const countyName = getGatewayBody.regionName;
				setCityName(countyName);
				setCityStateName(countyName);
				document.title = `Coffee Clozers - ${countyName}`;

				setTotalProperties(getGatewayData.totalPropertyCount);
				setFilteredPropertyCount(getGatewayData.filteredPropertyCount);
				if ( liveSite === false ) {
					console.log("Gateway query");
				}
			}
			else {
				setNoProperties(true);
				setLoading(false);
				setStrategiesLoading(false);
				setFirstLoading(false);
			}

			if ( queryData.length !== 0 ) {
				getNeighbourhoodData();

				const newMapArray = [];
				for (let index = 0; index < queryData.length; index++) {
					const element = queryData[index];
					queryData[index].financials.renovation = 0;
					queryData[index].purchaseCosts = {
						closingCostRate: 0.03
					};

					if ( element.financials.cashOnCash !== null && element.latitude !== null && element.longitude !== null ) {
						const newMapObject = {
							financials: element.financials,
							longitude: element.longitude,
							latitude: element.latitude,
							address: element.address,
							zpid: element.zpid,
							topPick: element.topPick
						}
						newMapArray.push(newMapObject);
					}

					if ( element.arvComps === true && element.fixerUpper === true && element.propertyTypeDimension === "Single Family" ) {
						const arvPrice = element.arvPrice;
						const purchasePrice = element.price;
						const arvSpread = (arvPrice - purchasePrice) / purchasePrice;
						element.arvSpread = arvSpread;
					}
					else {
						element.arvSpread = -1;
					}
				}

				setNoProperties(false);
				const props = queryData.slice(0, 20);
				parseFloat(findCentre(props)[0]);
				parseFloat(findCentre(props)[1]);
				setProperties(props);

				const imageArrayLength = [];
				for (let index = 0; index < props.length; index++) {
					const element = props[index];
					const propImages = element.images;
					if ( propImages !== null ) {
						imageArrayLength.push(propImages.length);
					}
					else {
						imageArrayLength.push(0);
					}
				};

				setMapProperties(queryData);
				setAllProperties(queryData);
				setPropertiesOnPage(props.length < 21 ? props.length : 20);
				setLoading(false);
				setFirstLoading(false);
				setStrategiesLoading(false);
				queryZipCodes();

				const propertyLength = props.length;
				setPropertyRefs((elRefs) =>
					Array(propertyLength)
					.fill()
					.map((_, i) => elRefs[i] || createRef()),
				);
			}
			else {
				parseFloat(findCentre(queryData)[0]);
				parseFloat(findCentre(queryData)[1]);
				setNoProperties(true);
				setStrategiesLoading(false);
				setLoading(false);
			}
		};

		const queryZipCodes = async() => {
			if ( allZipCodes.length > 0 ) {
				return;
			}

			const colRef = cityId.includes("CTY") ? "County ZIP Codes" : "City ZIP Codes";
			const queryData = await getDocument(colRef, cityId);
			if ( queryData.status === 200 ) {
				const data = queryData.data.data;
				data.sort((a, b) => a.localeCompare(b));
				setAllZipCodes(data);
			}
		};

		async function findCentre(markers) {
			const latMiddle = getMiddle('latitude', markers);
			const lngMiddle = getMiddle('longitude', markers);
			const centreObject = {
				lat: latMiddle,
				lng: lngMiddle
			};
			setCentreCoordinates(centreObject);
		}

		const getNeighbourhoodData = async() => {
			const neighbourhoodPath = cityId.includes("CTY") ? `geo_output/map_neighborhood/county/${cityId.replace("CTY", "")}.json` : `output/map_neighborhood/${cityId}.json`;
			const params = {
				s3Data: {
					key: neighbourhoodPath,
					bucketName: "residentialpropertydata"
				},
				type: "s3"
			}
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			const queryData = await cloudFunctionV2(awsURL, params);
			setLoadingStage(2);
			if ( queryData.status === 200 ) {
				const body = queryData.body;
				setNeighbourhoodData(body);

				const newBounds = [];
				const getTracts = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					getTracts.push(element.region.tractId);
					const tractPolygon = element.tractPolygon.flat(1);
					newBounds.push(tractPolygon);
				};
				setTracts(getTracts);
				setNeighbourhoodBounds(newBounds);

				const emergingMarketArray = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					if ( element.region.emergingMarket === true ) {
						emergingMarketArray.push(element);
					}
				};
				setEmergingMarkets(emergingMarketArray);
			}
		}

		if ( userDetails === null ) {
			setLoading(true);
			checkLoggedIn();

			if ( liveSite === false ) {
				console.log("script running");
			}
		}
		else if ( changeMade === null && loading === true ) {
			// Exit out if we're already loading stuff
			if ( liveSite === false ) {
				console.log("EXITED OUT")
			}
		}
		else if ( changeMade !== null ) {
			setChangeMade(null);
			setLoading(true);
			queryGatewayProperties(financialPreferences);

			if ( neighbourhoodData.length === 0 ) {
				getNeighbourhoodData();
			}

			if ( liveSite === false ) {
				console.log("script running");
			}
		}

		let intervalId;
		let timeoutId;

		// Start only if onboardingAuto, onboarding are true, and properties.length > 0
		if (onboardingAuto && onboarding && properties.length > 0 && onboardingStep === 0) {

			// Delay for 1.5 seconds before setting onboardingHover to 0

			if ( onboardingInitialised === false ) {
				timeoutId = setTimeout(() => {

					setOnboardingHover(0);
					setOnboardingInitialised(true);

					// Start the interval to cycle steps every 3 seconds
					let step = onboardingHover;
					intervalId = setInterval(() => {
						step = (step + 1);

						const checkedStep = step > 2 ? 0 : step;
						setOnboardingHover(checkedStep);
					}, 3000);
					
				}, 1500); // 1.5-second delay
			}
			else {
				// Start the interval to cycle steps every 3 seconds
				let step = onboardingHover;
				intervalId = setInterval(() => {
					step = (step + 1);

					const checkedStep = step > 2 ? 0 : step;
					setOnboardingHover(checkedStep);
				}, 3000);
			}
			
		}

		// Cleanup function to stop interval and timeout
		return () => {
			if (intervalId) clearInterval(intervalId);
			if (timeoutId) clearTimeout(timeoutId);
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [
		allZipCodes.length,
		cityId,
		creativeStrategy,
		liveSite,
		navigate,
		page,
		parsedFilters,
		sorting,
		strategy,
		financialPreferences,
		userDetails,
		changeMade,
		newCentre,
		loading,
		neighbourhoodGradesFilterIndex,
		neighbourhoodData.length,
		onboarding,
		onboardingAuto,
		properties.length,
		onboardingStep,
		onboardingInitialised,
		onboardingHover
	]);

	const sortingOptions = [
		"",
		"",
		"Newest to oldest",
		"Oldest to newest",
		"CoCR",
		"Price - lowest to highest",
		"Price - highest to lowest",
		"ARV Spread - highest to lowest"
	];

	const loadingStages = [
        "1. Getting MLS data",
        "2. Generating neighborhood grade map",
        "3. Calculating cash-flow"
    ];

	const downloadExcelFile = async() => {
		const downPayment = financialPreferences.initialCosts[0].value === "" ? 20 : financialPreferences.initialCosts[0].value;
		const sheetStrategy = strategy === 0 || strategy === 5 ? 0 : strategy - 1;
		setCSVDownloadLoading(true);
		setCSVDownloadError(false);
		setCSVDownloadLink(null);

		const strategyVals = [
			"ltr",
			"househack",
			"brrrr",
			"leaseoption"
		];

		const sortingVals = [
			"",
			"",
			"onMarketTimeLow",
			"onMarketTimeHigh",
			"cashOnCashHigh",
			"priceLow",
			"priceHigh",
			"arvSpreadHigh"
		];

		const newFilters = parsedFilters === null ? null : await filtersEncoder(parsedFilters);
		const sheetDownloadParams = {
			settings: financialPreferences,
			creativeStrategy: creativeStrategy,
			cityId: cityId,
			filters: newFilters,
			page: 1,
			strategy: strategyVals[strategy], 
			sorting: sortingVals[sorting], 
			centre: null
		}
		const newWindow = window.open("", "_blank"); 
		const downloadData = await downloadAllPropertiesExcel(cityStateName, cityId, downPayment, sheetStrategy, sheetDownloadParams);
		if ( downloadData.status === 200 ) {
			if ( newWindow === null ) {
				window.open(downloadData.data, "_blank");
				newWindow.close();
			}
			else {
				newWindow.location.href = downloadData.data;
			}

			setCSVDownloadLink(downloadData.data);
			setCSVDownloadLoading(false);
			setCSVDownloadComplete(true);
			recordEvent("Download CSV", {
				city: cityId,
				cityTitle: cityStateName,
				all: true,
				zpid: ""
			});
		}
		else {
			newWindow.close();
			setCSVDownloadLoading(false);
			setCSVDownloadError(true);
		}
	}

	const downloadExcelFileFreeTrial = async() => {
		const downPayment = financialPreferences.initialCosts[0].value === "" ? 20 : financialPreferences.initialCosts[0].value;
		const sheetStrategy = strategy === 0 || strategy === 5 ? 0 : strategy - 1;
		const first10Props = properties.slice(0, 10);
		setCSVDownloadLoading(true);
		const newWindow = window.open("", "_blank");
		const downloadData = await sendPropertiesForExcel(first10Props, `${cityStateName} - Free Report`, cityId, downPayment, sheetStrategy);
		if ( downloadData.status === 200 ) {

			if ( newWindow === null ) {
				window.open(downloadData.data, "_blank");
				newWindow.close();
			}
			else {
				newWindow.location.href = downloadData.data;
			}

			setCSVDownloadLink(downloadData.data);
			setCSVDownloadLoading(false);
			setCSVDownloadComplete(true);
			recordEvent("Download CSV Free Trial", {
				city: cityId,
				cityTitle: cityStateName,
				all: true,
				zpid: ""
			});
		}
		else {
			newWindow.close();
			setCSVDownloadLoading(false);
			setCSVDownloadError(true);
		}
	}

	const changeSorting = async(val) => {
		if ( val === sorting ) {
			return;
		}
		else if ( temporaryId === false ) {
			setChangeMade("sorting");
			setPage(1);
			const params = Object.fromEntries(searchParams.entries());
    		params.sort = val;
			params.page = 1;
			setSearchParams(params);
			setSortDropdownOpen(false);
			setSorting(val);
			setModalSorting(val);
		}
		else {
			setSignUpModal(true);
		}
	}

	const clickOutside = (e) => {
		setCardIndex(null);
		setAddressSearchedProperty(null);
		setGoogleMapProperty(null);
	};

	const openOverlay = () => {
		setOverlay(!overlay);
		recordEvent("Overlay Toggle", {
			overlay: !overlay
		});

		if ( overlay === true ) {
			setTimeout(() => {
				if ( cardsRef.current !== null ) {
					cardsRef.current.scroll({
						top: 0,
						behavior: 'smooth'
					});
				}
			}, 250);
		}
	};

	const strategies = [
		{
            label: "LTR",
			val: "ltr",
			disabled: false,
			selected: strategy === 0 ? true : false,
			visible: true
        },
		{
            label: "Househack",
			val: "househack",
			disabled: false,
			selected: strategy === 1 ? true : false,
			visible: true
        },
		{
            label: "BRRRR",
			val: "brrrr",
			disabled: creativeStrategy,
			selected: strategy === 2 ? true : false,
			visible: !creativeStrategy
        },
		{
			label: "Lease option",
			val: "leaseoption",
			disabled: creativeSubscription === false || creativeStrategy === false ? true : false,
			selected: strategy === 3 ? true : false,
			visible: creativeStrategy
		}
    ];

	const changeSelectedPanel = async(index) => {
		if ( strategiesLoading === true ) {
			return;
		}

		setChangeMade("strategy");
		setStrategy(index);
		setModalStrategy(index);
		const strategyAnimationsClone = [...strategyAnimations];
		setStrategyAnimations(strategyAnimationsClone);
		setStrategiesLoading(true);
		setPage(1);
		recordEvent("Change Strategy", {
			strategy: strategies[index].label
		});
		
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		const params = Object.fromEntries(searchParams.entries());
		params.strategy = index;
		params.page = 1;
		setSearchParams(params);
    };

	const arrowClicked = (event) => {
		event.stopPropagation();
	};

	const clickTract = (val) => {
		setCardIndex(null);
		setMobileAbstract("");

		if ( cardIndex === null && mobileAbstract === "" ) {
			recordEvent("Click Tract", {
				tract: val
			});
			setClickedTract(val);
			setMapSidePanelOpen(true);

			for (let index = 0; index < neighbourhoodData.length; index++) {
				const element = neighbourhoodData[index];
				if ( element.region.tractId === val ) {
					setChosenTract(element);
				}
			}
		}
	};

	const onChangeNeighbourhoodOption = async(val) => {
		if ( alreadyShownNeighbourhoodHeatmap === false && val === 0 ) {
			setNeighbourhoodHeatmapModal(true);
		}
		setHideHeatmap(false);
		setNeighbourhoodOption(val);
		setSettingsNeighbourhoodOption(val);
		recordEvent("Change Neighbourhood Option", {
			"Neighbourhood Option": neighbourhoodDataOptions[val].label
		});
		setSettingsHideHeatmap(false);
		await updateHideHeatmapSetting(false);
    };

	const toggleCreativeStrategy = (newVal) => {
		if ( creativeSubscription === false && freeTrial === false ) {
			setPremiumModal(true);
			return;
		}
		else if ( newVal === creativeStrategy ) {
			return;
		}
		else if ( newVal === true && strategy === 2 ) {
			setStrategy(0);
			setModalStrategy(0);

			const params = Object.fromEntries(searchParams.entries());
    		params.page = 1;
			params.strategy = 0;
			params.creative = newVal;
			setSearchParams(params);
		}
		else if ( newVal === false && strategy === 3 ) {
			setStrategy(0);
			setModalStrategy(0);

			const params = Object.fromEntries(searchParams.entries());
    		params.page = 1;
			params.strategy = 0;
			params.creative = newVal;
			setSearchParams(params);
		}
		else {
			const params = Object.fromEntries(searchParams.entries());
    		params.page = 1;
			params.strategy = strategy;
			params.creative = newVal;
			setSearchParams(params);
		}

		setPage(1);
		setCreativeStrategy(newVal);
		setStrategiesLoading(true);
		setChangeMade("strategy");
	};

	const closeNeighbourhoodHeatmapModal = () => {
		setNeighbourhoodHeatmapModal(false);
		setDontShowNeighbourhoodHeatmapMessage(false);
	};

	const acceptNeighbourhoodHeatmapModal = async() => {
		setNeighbourhoodHeatmapModal(false);

		if ( dontShowNeighbourhoodHeatmapMessage === true ) {
			await updateShownHeatmapModal(true);
			setAlreadyShownNeighbourhoodHeatmap(true);
		}
	};

	const closeFiltersModal = () => {
		setFiltersModalOpen(false);
	};

	const clearFilters = async() => {
		const newPropertyTypes = [...propertyTypes];
		newPropertyTypes[0].included = false;
		newPropertyTypes[1].included = false;
		setPropertyTypes(newPropertyTypes);
		setUnits(defaultVals.units);
		setUnitExactMatch(defaultVals.unitExactMatch);
		setBudgetRange(defaultVals.budgetRange);
		setBedrooms(defaultVals.bedrooms);
		setBedroomExactMatch(defaultVals.bedroomExactMatch);
		setBathrooms(defaultVals.bathrooms);
		setBathroomExactMatch(defaultVals.bathroomExactMatch);
		setSquareFootage(defaultVals.squareFootage);
		setConstructionYears(defaultVals.constructionYears);
		setConditionValues(defaultVals.conditionValues);
		setCreativeFinanceType(defaultVals.creativeFinanceType);
		setListingType(defaultVals.listingType);
		setDaysOnMarket(defaultVals.daysOnMarket);
		setBackOnMarket(defaultVals.backOnMarket);
		setRecentPriceReduction(defaultVals.priceReduction);
		setNeighbourhoodGrades(defaultVals.neighbourhoodGrades);
		setZipcodes(defaultVals.zipcodes);
		setHideFloodzone(defaultVals.hideFloodzone);
		setHideHOA(defaultVals.hideHOA);
		setOnlyADU(defaultVals.onlyADU);
		setOnlyExtraBedroom(defaultVals.onlyExtraBedroom);
		setOversizedLot(defaultVals.oversizedLot);
		setOwnerType(defaultVals.ownerType);
		setOwnershipLength(defaultVals.ownershipLength);
		setDistressFlags(defaultVals.distressFlags);
		setEstimatedInterest(defaultVals.estimatedInterest);
		setEquityLevel(defaultVals.equityLevel);
		setMortgageType(defaultVals.mortgageType);
		setMinCoCR(defaultVals.cocr);

		searchParams.delete("filters");
		setSearchParams(searchParams);
		setChangeMade("filters");
		setFiltersModalOpen(false);
		recordEvent("Clear All Filters", {});
	};

	const clickExitScreen = () => {
		setSortDropdownOpen(false);
		setMapTypeDropdownOpen(false);
	};

	const saveFilters = async() => {
		const newFilters = {
			budgetRange: budgetRange,
			propertyType: {
				propertyTypes: propertyTypes,
				units: units,
				match: unitExactMatch
			},
			bedrooms: {
				value: bedrooms,
				match: bedroomExactMatch
			},
			bathrooms: {
				value: bathrooms,
				match: bathroomExactMatch
			},
			squareFootage: squareFootage,
			constructionYears: constructionYears,
			condition: conditionValues,
			creative: creativeFinanceType,
			listingType: listingType,
			daysOnMarket: daysOnMarket,
			backOnMarket: backOnMarket,
			recentPriceReduction: recentPriceReduction,
			neighbourhoodGrades: neighbourhoodGrades,
			zipcodes: zipcodes,
			hideFloodzone: hideFloodzone,
			hideHOA: hideHOA,
			onlyADU: onlyADU,
			onlyExtraBedroom: onlyExtraBedroom,
			oversizedLot: oversizedLot,
			ownerType: ownerType,
			ownershipLength: ownershipLength,
			distressFlags: distressFlags,
			estimatedInterest: estimatedInterest,
			equityLevel: equityLevel,
			mortgageType: mortgageType,
			minCoCR: minCoCR
		};
		setPage(1);
		await saveFiltersToDB(newFilters, searchParams, setSearchParams);
		recordEvent("Save Filters", {});
		setChangeMade("filters");
		setStrategiesLoading(true);
		setFiltersModalOpen(false);
	};

	const openNotRecommendedModal = () => {
		setNotRecommendedModal(true);
	};

	const closeNotRecommendedModal = () => {
		setNotRecommendedModal(false);
	};

	const closePremiumModal = () => {
		setPremiumModal(false);
	};

	const viewPricing = () => {
		navigate("/pricing");
	};

	const upgradeNow = () => {
		goToCreativeCheckout(navigate);
	};

	const closeMapSettingsModal = () => {
		setMapSettingsModal(false);
		setSettingsHideHeatmap(hideHeatmap);
		setSettingsNeighbourhoodOption(neighbourhoodOption);
		setSettingsMapTypeDropdownOpen(false);
	};

	const toggleSettingsHideHeatmap = () => {
		setSettingsHideHeatmap(!settingsHideHeatmap);
	};

	const toggleSettingsMapTypeDropdown = () => {
        setSettingsMapTypeDropdownOpen(!settingsMapTypeDropdownOpen);
    };

	const onChangeSettingsNeighbourhoodOption = (obj, val) => {
		setSettingsHideHeatmap(false);
		setSettingsNeighbourhoodOption(val);
		setSettingsMapTypeDropdownOpen(false);
    };


	const saveMapSettings = async() => {
		if ( alreadyShownNeighbourhoodHeatmap === false && settingsHideHeatmap === false ) {
			setNeighbourhoodHeatmapModal(true);
		}
		setHideHeatmap(settingsHideHeatmap);
		setNeighbourhoodOption(settingsNeighbourhoodOption);
		setMapSettingsModal(false);
		await updateHideHeatmapSetting(settingsHideHeatmap);
	};

	const openMobileSortModal = () => {
		setMobileSortModal(true);
	};

	const closeMobileSortModal = () => {
		setMobileSortModal(false);
		setModalSorting(sorting);
		setModalStrategy(strategy);
	};

	const saveMobileSortModal = () => {
		setMobileSortModal(false);

		if ( modalSorting === sorting && modalStrategy === strategy ) {
			return;
		}
		else if ( modalSorting !== sorting ) {
			changeSorting(modalSorting);
			setStrategy(modalStrategy);
		}
		else if ( modalStrategy !== strategy ) {
			changeSelectedPanel(modalStrategy);
			setSorting(modalSorting);
		}
	};

	const toggleDontShowNeighbourhoodHeatmapMessage = () => {
		setDontShowNeighbourhoodHeatmapMessage(!dontShowNeighbourhoodHeatmapMessage);
	};

	const closeCSVDownloadToast = () => {
		setCSVDownloadComplete(false);
		setCSVDownloadError(false);
		setCSVDownloadLink(null);
	};

	const viewSheet = () => {
        if ( csvDownloadLink !== null ) {
            window.open(csvDownloadLink, "_blank");
        }
    };

	const viewFreeTrialMarkets = () => {
		recordEvent("FSBO Paywall - View Free Markets", {});
		navigate("/available-markets");
	};

	const goToPricing = () => {
		recordEvent("FSBO Paywall - View Pricing", {});
		navigate("/pricing");
	};

	const openCreateBuyBoxModal = () => {
		setFiltersModalOpen(false);
		setCreateBuyBoxModal(true);

		setNewBuyBoxName(`My ${cityStateName} Filters Buy Box`);
	};

	const closeCreateBuyBoxModal = () => {
		setCreateBuyBoxModal(false);
	};

	const createNewBuyBox = async() => {
		setNewBuyBoxLoading(true);
		if ( newBuyBoxName === "" ) {
			setNewBuyBoxNameError(true);
			setNewBuyBoxLoading(false);
			return;
		}

		setNewBuyBoxNameError(false);
		const checkedPropertyTypes = propertyTypes.filter(e => e.included === true);
		const finalPropertyTypes = checkedPropertyTypes.length === 0 ? [{
            label: "Single-Family",
            icon: <SingleFamily className="" fill={colour.blueBlue03} />,
            body: null,
			included: true,
            standardIcon: false,
			code: "SingleFamily",
			abbreviation: "SFH"
        },
        {
            label: "Multi-Family",
            icon: <MultiFamily className="" fill={colour.blueBlue03} />,
            body: null,
			included: true,
            standardIcon: false,
			code: "MultiFamily",
			abbreviation: "MFH"
        }] : checkedPropertyTypes;

		const newFilters = {
			budgetRange: budgetRange,
			propertyTypes: finalPropertyTypes,
			units: units,
			unitExactMatch: unitExactMatch,
			bedrooms: bedrooms,
			bedroomExactMatch: bedroomExactMatch,
			bathrooms: bathrooms,
			bathroomExactMatch: bathroomExactMatch,
			squareFootage: squareFootage,
			constructionYears: constructionYears,
			condition: conditionValues,
			creative: creativeFinanceType,
			listingType: listingType,
			daysOnMarket: daysOnMarket,
			backOnMarket: backOnMarket,
			recentPriceReduction: recentPriceReduction,
			neighbourhoodGrades: neighbourhoodGrades,
			zipcodes: zipcodes,
			hideFloodzone: hideFloodzone,
			hideHOA: hideHOA,
			onlyADU: onlyADU,
			onlyExtraBedroom: onlyExtraBedroom,
			oversizedLot: oversizedLot,
			ownerType: ownerType,
			ownershipLength: ownershipLength,
			distressFlags: distressFlags,
			estimatedInterest: estimatedInterest,
			equityLevel: equityLevel,
			mortgageType: mortgageType,
			minCoCR: minCoCR
		};
		await createFiltersBuyBox(
			newFilters, 
			newBuyBoxName, 
			newBuyBoxFrequency, 
			cityStateName, 
			cityId, 
			zipcodes,
			strategy,
			creativeStrategy
		);
		setNewBuyBoxFrequency("daily");
		setNewBuyBoxName("");
		setNewBuyBoxLoading(false);
		setCreateBuyBoxModal(false);
		setBuyBoxToast(true);
		recordEvent("Buy Box Created From Filters", {});
	};

	const closeBuyBoxToast = () => {
		setBuyBoxToast(false);
	};

	const skipOnboarding = async() => {
		closeOnboarding();
		recordEvent("Skip Listings Onboarding", {});
	};

	const closeOnboarding = () => {
		setOnboarding(false);
		setOnboardingStep(0);
		setOnboardingHover(null);
		setOnboardingAuto(false);
		setOnboardingTooltip(true);
	}

	const nextOnboardingStep = () => {
		if ( onboardingStep === 0 ) {
			setOnboardingStep(1);
			setOnboardingHover(null);
			setOnboardingAuto(false);
			recordEvent("Listings - Next Onboarding Step", {});
		}
		else {
			const item = properties[0];
			const creativeType = item.freeClearFlag === true ? "sf" : "sub";
			const buyingCash = item.financials.mortgagePayment === 0 ? true : false;
			const acquisitionParam = creativeStrategy === true ? `?acquisition=${creativeType}` : `?acquisition=${buyingCash === true ? "c" : "m"}`;
			const exitStrategyText = strategy === 0 ? "ltr" : strategy === 1 ? "hh" : strategy === 2 ? "brrrr" : "lo";
			const exitParam = `&exit=${exitStrategyText}`;
			const strategyParam = `${acquisitionParam}${exitParam}`;

			window.open(`/properties/${cityId}/${item.zpid}${strategyParam}`, "_blank");
			recordEvent("Listings Onboarding - View First Property", {
				cityId: cityId,
				zpid: item.zpid
			});
			closeOnboarding();
		}
	};

	const viewOnboarding = () => {
		setOnboarding(true);
		setOnboardingAuto(true);
		setOnboardingHover(null);
		setOnboardingStep(0);
		setOnboardingInitialised(false);
		setTimeout(() => {
			setOnboardingInitialised(true);
		}, 1500);
		recordEvent("View Listings Onboarding", {});
	};

	const closeOnboardingTooltip = () => {
		setOnboardingTooltip(false);
		recordEvent("Close Listings Onboarding Tooltip", {});
	};

	return (
		<div className="new-property-list-outer-container">
			{
				(innerWidth < 1000 && loading === true) ?
				<TextLoading
					stage={loadingStage}
					stages={loadingStages}
				/>
				:
				null
			}
			{
				filtersModalOpen === true || emergingAreaModal === true || neighbourhoodHeatmapModal === true || notRecommendedModal === true || premiumModal === true || mapSettingsModal === true || mobileSortModal === true || createBuyBoxModal === true || (onboarding === true && innerWidth > 1000 && properties.length > 0) ?
				<div className="dark-bg"></div>
				:
				null
			}
			{
				freeTrial === true && activeMarket === false &&
				<div className="blur-bg">
				</div>
			}
			<Header
				temporaryId={temporaryId}
				listingsCityId={cityId}
				subscriptions={userSubscriptions}
                users={userDetails}
                queries={[false, false]}
				mobileNav={filtersModalOpen === true || mapSettingsModal === true || mobileSortModal === true || neighbourhoodHeatmapModal === true || premiumModal === true || createBuyBoxModal === true || (freeTrial === true && activeMarket === false) ? false : true}
				desktopControl={(freeTrial === true && activeMarket === false) ? false : true}
				viewOnboarding={viewOnboarding}
				onboardingTooltip={onboardingTooltip}
				closeTooltip={closeOnboardingTooltip}
			/>
			<div className={"new-property-list-inner-container " + (isBrowser === true ? "new-property-list-inner-container-desktop" : "") + (isMobile === true ? " new-property-list-inner-container-mobile" : "")}>
				{
					signUpModal === true ?
					<SignUpModal
						setSignUpModal={setSignUpModal}
					/>
					:
					premiumModal === true ?
					<Modal
						heading="Plus Feature 👀 "
						subheading={"You've discovered a Plus Plan feature!"}
						body={<Upgrade />}
						type="secondary"
						cancelText="Maybe later"
						cancelButtonCTA={closePremiumModal}
						actionButtonText="Upgrade now"
						actionButtonCTA={upgradeNow}
						actionButtonDisabled={false}
						secondaryButtonText="See pricing plans"
						secondaryButtonCTA={viewPricing}
						exitButtonCTA={closePremiumModal}
						loading={false}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
						cancelRightIcon={null}
						customClass={"modal-upgrade-container"}
					/>
					:
					neighbourhoodHeatmapModal === true ?
					<Modal
						heading="Neighborhood heatmap"
						subheading={null}
						body={<NeighbourhoodHeatmapText dontShowNeighbourhoodHeatmapMessage={dontShowNeighbourhoodHeatmapMessage} toggleSelected={toggleDontShowNeighbourhoodHeatmapMessage} />}
						type="single"
						cancelText=""
						cancelButtonCTA={null}
						actionButtonText="Got it"
						actionButtonCTA={acceptNeighbourhoodHeatmapModal}
						actionButtonDisabled={false}
						secondaryButtonText=""
						secondaryButtonCTA={null}
						exitButtonCTA={closeNeighbourhoodHeatmapModal}
						loading={false}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
                        cancelRightIcon={null}
						customClass={"neighbourhood-grade-heatmap-modal"}
					/>
					:
					mapSettingsModal === true && innerWidth < 1000 ?
					<Modal
						heading="Map settings"
						subheading={null}
						body={<MapSettings hideHeatmap={settingsHideHeatmap} toggleHideHeatmap={toggleSettingsHideHeatmap} neighbourhoodOption={settingsNeighbourhoodOption} mapTypeDropdownOpen={settingsMapTypeDropdownOpen} toggleDropdown={toggleSettingsMapTypeDropdown} selectOption={onChangeSettingsNeighbourhoodOption} />}
						type="basic"
						cancelText="Cancel"
						cancelButtonCTA={closeMapSettingsModal}
						actionButtonText="Save"
						actionButtonCTA={saveMapSettings}
						actionButtonDisabled={false}
						secondaryButtonText=""
						secondaryButtonCTA={null}
						exitButtonCTA={closeMapSettingsModal}
						loading={false}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
                        cancelRightIcon={null}
						customClass={"map-settings-modal"}
					/>
					:
					mobileSortModal === true && innerWidth < 1000 ?
					<Modal
						heading="Sort"
						subheading={null}
						body={<MobileSorting strategy={modalStrategy} setStrategy={setModalStrategy} strategies={strategies} sorting={modalSorting} setSorting={setModalSorting} sortOptions={sortOptions} creativeStrategy={creativeStrategy} creativeSubscription={creativeSubscription} />}
						type="basic"
						cancelText="Cancel"
						cancelButtonCTA={closeMobileSortModal}
						actionButtonText="Save"
						actionButtonCTA={saveMobileSortModal}
						actionButtonDisabled={false}
						secondaryButtonText=""
						secondaryButtonCTA={null}
						exitButtonCTA={closeMobileSortModal}
						loading={false}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
                        cancelRightIcon={null}
						customClass={"mobile-sorting-modal"}
					/>
					:
					null
				}
				{
					freeTrial === true && activeMarket === false &&
					<Modal
						heading="Not available on Free Trial"
						subheading={"This market isn't available during your free trial."}
						body={<MarketUnavailable market={cityStateName} viewFreeTrialMarkets={viewFreeTrialMarkets} />}
						type="secondary"
						cancelText={null}
						cancelButtonCTA={null}
						actionButtonText={innerWidth < 800 ? "See free markets" : "View free trial markets"}
						actionButtonCTA={viewFreeTrialMarkets}
						actionButtonDisabled={false}
						secondaryButtonText={innerWidth < 800 ? "Upgrade" : `Unlock ${cityStateName}`}
						secondaryButtonCTA={goToPricing}
						exitButtonCTA={null}
						loading={false}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
                        cancelRightIcon={null}
						customClass={"inactive-market-modal"}
					/>
				}
				{
					(csvDownloadLoading === true || csvDownloadComplete === true || csvDownloadError === true) &&
					<Toast
						type={csvDownloadComplete === true ? "link" : csvDownloadError === true ? "default" : "loading"}
						label={csvDownloadError === true ? "There was an error downloading your data" : csvDownloadComplete === true ? "Download successful" : "Downloading CSV file"}
						icon={csvDownloadError === true ? null : csvDownloadComplete === true ? <TickCircle fill={colour.grayScaleWhite} /> : <Download className="" fill={colour.grayScaleWhite} />}
						standardIcon={false}
						linkFunc={csvDownloadComplete === true ? viewSheet : null}
						linkText={csvDownloadComplete === true ? "Click here to view" : null}
						closeFunc={closeCSVDownloadToast}
						undoFunc={null}
					/>
				}
				{
					buyBoxToast === true &&
					<Toast
						type={"default"}
						label={"Buy Box saved successfully"}
						icon={<TickCircle fill={colour.grayScaleWhite} />}
						standardIcon={false}
						linkFunc={null}
						linkText={null}
						closeFunc={closeBuyBoxToast}
						undoFunc={null}
					/>
				}
				{
					emergingAreaModal === true ?
					<EmergingAreaModal
						setEmergingAreaModal={setEmergingAreaModal}
						setMapSidePanelOpen={setMapSidePanelOpen}
						setChosenTract={setChosenTract}
						setEmergingAreaTract={setEmergingAreaTract}
						emergingAreaTract={emergingAreaTract}
						setClickedTract={setClickedTract}
					/>
					:
					null
				}
				{
					freeTrialExpired === true || signUpModal === true ?
					<div className="free-trial-expired-blur">
					</div>
					:
					null
				}
				{
					(sortDropdownOpen === true) &&
					<ExitScreen
						clickScreen={clickExitScreen}
					/>
				}
				{
					notRecommendedModal === true &&
					<Modal
						heading="Sort by CoCR"
						subheading={"Sorting by cash on cash return is not recommended, here’s why:"}
						body={<CoCRSort />}
						type="single"
						cancelText=""
						cancelButtonCTA={null}
						actionButtonText="I understand"
						actionButtonCTA={closeNotRecommendedModal}
						actionButtonDisabled={false}
						secondaryButtonText=""
						secondaryButtonCTA={null}
						exitButtonCTA={closeNotRecommendedModal}
						loading={false}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
                        cancelRightIcon={null}
						customClass={null}
					/>
				}
				{
					userDetails !== null || temporaryId === true ?
					<PropertyListTopBar
						changeSorting={changeSorting}
						clearFilters={clearFilters}
						creativeStrategy={creativeStrategy}
						downloadCSV={downloadExcelFile}
						downloadCSVFreeTrial={downloadExcelFileFreeTrial}
						freeTrial={freeTrial}
						hideHeatmap={hideHeatmap}
						mapTypeDropdownOpen={mapTypeDropdownOpen}
						neighbourhoodOption={neighbourhoodOption}
						onChangeNeighbourhoodOption={onChangeNeighbourhoodOption}
						searchParams={searchParams}
						setAddressSearchedProperty={setAddressSearchedProperty}
						setCardIndex={setCardIndex}
						setFiltersModalOpen={setFiltersModalOpen}
						setGoogleMapProperty={setGoogleMapProperty}
						setHideHeatmap={setHideHeatmap}
						setMapTypeDropdownOpen={setMapTypeDropdownOpen}
						sortDropdownOpen={sortDropdownOpen}
						sortOptions={sortOptions}
						setSortDropdownOpen={setSortDropdownOpen}
						sorting={sorting}
						toggleCreativeStrategy={toggleCreativeStrategy}
						setMapSettingsModal={setMapSettingsModal}
						setSettingsHideHeatmap={setSettingsHideHeatmap}
						alreadyShownNeighbourhoodHeatmap={alreadyShownNeighbourhoodHeatmap}
						setNeighbourhoodHeatmapModal={setNeighbourhoodHeatmapModal}
						csvDownloadLoading={csvDownloadLoading}
					/>
					:
					null
				}
				{
					filtersModalOpen === true ?
						<Filters
							searchParams={searchParams}
							setSearchParams={setSearchParams}
							setLoading={setLoading}
							setChangeMade={setChangeMade}
							exitButtonCTA={closeFiltersModal}
							clearAllFilters={clearFilters}
							actionButtonCTA={saveFilters}
							loading={false}
							actionButtonDisabled={false}
							budgetRange={budgetRange}
							setBudgetRange={setBudgetRange}
							propertyTypes={propertyTypes}
							setPropertyTypes={setPropertyTypes}
							units={units}
							setUnits={setUnits}
							unitExactMatch={unitExactMatch}
							setUnitExactMatch={setUnitExactMatch}
							bedrooms={bedrooms}
							setBedrooms={setBedrooms}
							bedroomExactMatch={bedroomExactMatch}
							setBedroomExactMatch={setBedroomExactMatch}
							bathrooms={bathrooms}
							setBathrooms={setBathrooms}
							bathroomExactMatch={bathroomExactMatch}
							setBathroomExactMatch={setBathroomExactMatch}
							squareFootage={squareFootage}
							setSquareFootage={setSquareFootage}
							constructionYears={constructionYears}
							setConstructionYears={setConstructionYears}
							conditionValues={conditionValues}
							setConditionValues={setConditionValues}
							creativeFinanceType={creativeFinanceType}
							setCreativeFinanceType={setCreativeFinanceType}
							listingType={listingType}
							setListingType={setListingType}
							daysOnMarket={daysOnMarket}
							setDaysOnMarket={setDaysOnMarket}
							backOnMarket={backOnMarket}
							setBackOnMarket={setBackOnMarket}
							recentPriceReduction={recentPriceReduction}
							setRecentPriceReduction={setRecentPriceReduction}
							neighbourhoodGrades={neighbourhoodGrades}
							setNeighbourhoodGrades={setNeighbourhoodGrades}
							hideFloodzone={hideFloodzone}
							setHideFloodzone={setHideFloodzone}
							hideHOA={hideHOA}
							setHideHOA={setHideHOA}
							onlyADU={onlyADU}
							setOnlyADU={setOnlyADU}
							onlyExtraBedroom={onlyExtraBedroom}
							setOnlyExtraBedroom={setOnlyExtraBedroom}
							oversizedLot={oversizedLot}
							setOversizedLot={setOversizedLot}
							ownerType={ownerType}
							setOwnerType={setOwnerType}
							ownershipLength={ownershipLength}
							setOwnershipLength={setOwnershipLength}
							distressFlags={distressFlags}
							setDistressFlags={setDistressFlags}
							estimatedInterest={estimatedInterest}
							setEstimatedInterest={setEstimatedInterest}
							equityLevel={equityLevel}
							setEquityLevel={setEquityLevel}
							minCoCR={minCoCR}
							setMinCoCR={setMinCoCR}
							creativeStrategy={creativeStrategy}
							zipcodes={zipcodes}
							setZipcodes={setZipcodes}
							allZipCodes={allZipCodes}
							mortgageType={mortgageType}
							setMortgageType={setMortgageType}
							openCreateBuyBoxModal={openCreateBuyBoxModal}
						/>
					:
					null
				}
				{
					createBuyBoxModal === true &&
					<Modal
						heading="Save filters as Buy Box"
						subheading={"Create a new buy box from your filter selections to easily come back to this view."}
						body={<NewBuyBoxModal buyBoxName={newBuyBoxName} setBuyBoxName={setNewBuyBoxName} buyBoxFrequency={newBuyBoxFrequency} setBuyBoxFrequency={setNewBuyBoxFrequency} buyBoxFrequencyDropdown={newBuyBoxFrequencyDropdown} setBuyBoxFrequencyDropdown={setNewBuyBoxFrequencyDropdown} nameError={newBuyBoxNameError} setNameError={setNewBuyBoxNameError} />}
						type="basic"
						cancelText="Cancel"
						cancelButtonCTA={closeCreateBuyBoxModal}
						actionButtonText="Create Buy Box"
						actionButtonCTA={createNewBuyBox}
						actionButtonDisabled={newBuyBoxLoading}
						secondaryButtonText={null}
						secondaryButtonCTA={null}
						exitButtonCTA={closeCreateBuyBoxModal}
						loading={newBuyBoxLoading}
						leftIcon={null}
						rightIcon={null}
						cancelLeftIcon={null}
						cancelRightIcon={null}
						customClass={"modal-buy-box-container"}
					/>
				}
				{
					onboarding === true && innerWidth > 1000 && properties.length > 0 &&
					<OnboardingTooltip
						body={<ListingsOnboarding step={onboardingStep} onboardingHover={onboardingHover} setOnboardingHover={setOnboardingHover} setOnboardingAuto={setOnboardingAuto} />}
						position="left"
						cta={nextOnboardingStep}
						skip={skipOnboarding}
						totalSteps={creativeStrategy === true ? 4 : 3}
						step={onboardingStep}
						customClass={onboardingSteps[onboardingStep].customClass}
					/>
				}
				<div className={"new-property-list-inner-row " + (onboardingHover !== null ? onboardingHoverClass : onboardingStep === 1 ? "onboarding-step-1-active" : "")}>
					{
						mobileAbstract !== "" && overlay === false ?
						null
						:
						<PropertyListButton
							clickFunc={() => openOverlay()}
							overlay={overlay}
						/>
					}
					{
						mobileAbstract !== "" && mapSettingsModal === false ?
						<MobilePropertyCardOverlay
							item={mobileAbstract}
							index={0}
							key={0}
							favourites={favourites}
							setFavourites={setFavourites}
							formatter={formatterLong}
							abstract={true}
							viewedProperties={viewedProperties}
							setViewedProperties={setViewedProperties}
							cityId={cityId}
							overlay={overlay}
							setOverlay={setOverlay}
							strategy={strategy}
							creativeStrategy={creativeStrategy}
						/>
						:
						null
					}
					{
						loading === true && firstLoading === false && (innerWidth >= 1000 && (( properties.length === 0 && allProperties.length !== 0 ) || noProperties === true)) ?
						<div className="new-property-list-left-container">
							<StrategyLoading
								index={strategy}
							/>
						</div>
						:
						(innerWidth >= 1000 && (( properties.length === 0 && allProperties.length !== 0 ) || noProperties === true)) ?
						<div className="new-property-list-left-container">
							<ClearFilters
								func={clearFilters}
							/>
						</div>
						:
						activeMarket === false && creativeSubscription === false && innerWidth >= 1000 ?
						<FSBOOnly
						
						/>
						:
						<div 
							ref={cardsRef} className={"new-property-list-left-container new-property-list-left-container-strategies " + (overlay === false ? "new-property-list-hide-overlay" : "new-property-list-overlay-animate-in" )}
							onClick={(e) => clickOutside(e)}
						>
							{
								sorting < 2 ?
								<div className="new-property-list-strategies-outer-container">
									<div className="new-property-list-strategies-row">
										<div className="new-property-list-strategies-left-container mobile-none">
											{
												strategies.map((item, index) =>
													item.visible &&
													<FilterChip
														label={item.label}
														selected={strategy}
														index={index}
														disabled={item.disabled}
														leftIcon={null}
														rightIcon={null}
														func={changeSelectedPanel}
														key={index}
													/>
												)
											}
										</div>
										<div className="new-property-list-mobile-sorting desktop-none">
											<Dropdown
												open={false}
												icon={<Sort className="" fill={colour.grayScaleBlack} />}
												label={sorting === 0 ? "Sort" : sortOptions[sorting].abbreviation}
												disabled={false}
												options={sortOptions}
												multiselect={false}
												toggleDropdown={openMobileSortModal}
												cta={null}
												sidealign={"left"}
												topalign={"bottom"}
												indexSelect={false}
												selectedIndex={null}
											/>
										</div>
										<div className="new-property-list-property-count-container">
											<span className="body-regular colour-secondary">
												Showing {numberFormatter.format(filteredPropertyCount)} of {numberFormatter.format(totalProperties)}
											</span>
										</div>
									</div>
								</div>
								:
								sorting === 4 ?
								<div className="new-property-list-strategies-outer-container">
									<div className="new-property-list-strategies-row">
										<div className="new-property-list-not-recommended-container mobile-none relative-container">
											<span className="body-regular colour-secondary">
												Sorting by{" "}
												<span className="body-semibold colour-primary">{sortingOptions[sorting]}</span>
												{" "}
												(Not recommended. <span onClick={() => openNotRecommendedModal()} className="colour-link underline">See why</span>)
											</span>
										</div>
										<div className="new-property-list-mobile-sorting desktop-none">
											<Dropdown
												open={false}
												icon={<Sort className="" fill={colour.grayScaleBlack} />}
												label={sorting === 0 ? "Sort" : sortOptions[sorting].abbreviation}
												disabled={false}
												options={sortOptions}
												multiselect={false}
												toggleDropdown={openMobileSortModal}
												cta={null}
												sidealign={"left"}
												topalign={"bottom"}
												indexSelect={false}
												selectedIndex={null}
											/>
										</div>
										<div className="new-property-list-property-count-container">
											<span className="body-regular colour-secondary">
												Showing {numberFormatter.format(filteredPropertyCount)} of {numberFormatter.format(totalProperties)}
											</span>
										</div>
									</div>
								</div>
								:
								<div className="new-property-list-strategies-outer-container">
									<div className="new-property-list-strategies-row">
										<div className="new-property-list-not-recommended-container mobile-none relative-container">
											<span className="body-regular colour-secondary">
												Sorting by{" "}
												<span className="body-semibold colour-primary">{sortingOptions[sorting]}</span>
											</span>
										</div>
										<div className="new-property-list-mobile-sorting desktop-none">
											<Dropdown
												open={false}
												icon={<Sort className="" fill={colour.grayScaleBlack} />}
												label={sorting === 0 ? "Sort" : sortOptions[sorting].abbreviation}
												disabled={false}
												options={sortOptions}
												multiselect={false}
												toggleDropdown={openMobileSortModal}
												cta={null}
												sidealign={"left"}
												topalign={"bottom"}
												indexSelect={false}
												selectedIndex={null}
											/>
										</div>
										<div className="new-property-list-property-count-container">
											<span className="body-regular colour-secondary">
												Showing {numberFormatter.format(filteredPropertyCount)} of {numberFormatter.format(totalProperties)}
											</span>
										</div>
									</div>
								</div>
							}
							<div className={"new-property-list-cards-container " + (strategiesLoading === true || loading === true ? "new-property-list-cards-strategies-loading-container" : "")}>
								{
									(innerWidth < 1000 && (( properties.length === 0 && allProperties.length !== 0 ) || noProperties === true)) ?
									<div className="new-property-list-left-container">
										<ClearFilters
											func={clearFilters}
										/>
									</div>
									:
									activeMarket === false && creativeSubscription === false && innerWidth < 1000 ?
									<FSBOOnly
									
									/>
									:
									strategiesLoading === true ?
									<StrategyLoading
										index={strategy}
									/>
									:
									loading === true && firstLoading === false ?
									<StrategyLoading
										index={strategy}
									/>
									:
									addressSearchedProperty !== null ?
									<NewPropertyCard
										item={addressSearchedProperty}
										index={0}
										userDetails={userDetails}
										formatter={formatterLong}
										abstract={false}
										viewedProperties={viewedProperties}
										setViewedProperties={setViewedProperties}
										cityId={cityId}
										setHoverCard={setHoverCard}
										propertyRefs={propertyRefs}
										setCardClick={setCardClick}
										temporaryId={temporaryId}
										setSignUpModal={setSignUpModal}
										overlay={overlay}
										setOverlay={setOverlay}
										propertySearch={false}
										partnerView={false}
										partnerEditProperty={null}
                                		partnerSoldProperty={null}
										partnerData={null}
										changeStatus={null}
										setShowExitScreen={null}
										selectOption={null}
										openStatus={null}
										chooseStatus={null}
										state={null}
										favourites={favourites}
										setFavourites={setFavourites}
										strategy={strategy}
										arrowClicked={arrowClicked}
										imageLimit={10}
										bulkActions={false}
										note={null}
										openNoteModal={null}
										openUnfavouritePropertiesModal={null}
										creativeStrategy={creativeStrategy}
										activeMarket={activeMarket}
										onboarding={onboarding}
										closeOnboarding={closeOnboarding}
									/>
									:
									properties.map((item, index) =>
										<NewPropertyCard
											item={item}
											index={index}
											key={index}
											userDetails={userDetails}
											formatter={formatterLong}
											abstract={false}
											viewedProperties={viewedProperties}
											setViewedProperties={setViewedProperties}
											cityId={cityId}
											setHoverCard={setHoverCard}
											propertyRefs={propertyRefs}
											setCardClick={setCardClick}
											temporaryId={temporaryId}
											setSignUpModal={setSignUpModal}
											overlay={overlay}
											setOverlay={setOverlay}
											propertySearch={false}
											partnerView={false}
											partnerEditProperty={null}
                                			partnerSoldProperty={null}
											partnerData={null}
											changeStatus={null}
											setShowExitScreen={null}
											selectOption={null}
											openStatus={null}
											chooseStatus={null}
											state={null}
											favourites={favourites}
											setFavourites={setFavourites}
											strategy={strategy}
											arrowClicked={arrowClicked}
											imageLimit={5}
											bulkActions={false}
											note={null}
											openNoteModal={null}
											openUnfavouritePropertiesModal={null}
											creativeStrategy={creativeStrategy}
											activeMarket={activeMarket}
											onboarding={onboarding}
											closeOnboarding={closeOnboarding}
										/>
									)
								}
							</div>
							{
								addressSearchedProperty !== null ?
								null
								:
								activeMarket === false && creativeSubscription === false ?
								null
								:
								loading === true ?
								null
								:
								properties.length !== 0 ?
								<Pagination
									setMapProperties={setMapProperties}
									page={page}
									queryLength={showResetButton === false ? allProperties.length : mapProperties.length}
									propertiesOnPage={propertiesOnPage}
									propertiesOnPageChange={propertiesOnPageChange}
									setPropertiesOnPageChange={setPropertiesOnPageChange}
									cityId={cityId}
									setProperties={setProperties}
									setPage={setPage}
									cardsRef={cardsRef}
									searchParams={searchParams}
									setSearchParams={setSearchParams}
									allProperties={allProperties}
									temporaryId={temporaryId}
									setSignUpModal={setSignUpModal}
									setLoading={setLoading}
									setChangeMade={setChangeMade}
									everyProperty={allProperties}
									filteredPropertyCount={filteredPropertyCount}
								/>
								:
								null
							}
						</div>
					}
					<div className={"new-property-list-map-container " + (firstLoading === true ? "relative-container" : "")}>
						{
							loading === true &&
							<div className="new-property-map-loading-container desktop-none">
								<Loading />
							</div>
						}
						{
							
							firstLoading === true && noProperties === false ?
							<TextLoading
								stage={loadingStage}
								stages={loadingStages}
							/>
							:
							allProperties.length !== 0 || noProperties === true ?
							<NewPropertyMap
								properties={activeMarket === false && creativeSubscription === false ? [] : mapProperties}
								userDetails={userDetails}
								setUserDetails={setUserDetails}
								formatterLong={formatterLong}
								centreCoordinates={centreCoordinates}
								viewedProperties={viewedProperties}
								setViewedProperties={setViewedProperties}
								allProperties={allProperties}
								setMobileAbstract={setMobileAbstract}
								innerWidth={innerWidth}
								cityId={cityId}
								hoverCard={hoverCard}
								propertyRefs={propertyRefs}
								setCardProperties={setProperties}
								setMapProperties={setMapProperties}
								noProperties={noProperties}
								setPropertiesOnPageChange={setPropertiesOnPageChange}
								setPage={setPage}
								setSearchParams={setSearchParams}
								cardIndex={cardIndex}
								setCardIndex={setCardIndex}
								cardClick={cardClick}
								setCardClick={setCardClick}
								sorting={sorting}
								searchParams={searchParams}
								showResetButton={showResetButton}
								setShowResetButton={setShowResetButton}
								selectedTracts={selectedTracts}
								setSelectedTracts={setSelectedTracts}
								mapSidePanelOpen={mapSidePanelOpen}
								setMapSidePanelOpen={setMapSidePanelOpen}
								temporaryId={temporaryId}
								setSignUpModal={setSignUpModal}
								overlay={overlay}
								setOverlay={setOverlay}
								loading={loading}
								setChangeMade={setChangeMade}
								setProperties={setProperties}
								mapProperties={mapProperties}
								setAllProperties={setAllProperties}
								addressSearchedProperty={addressSearchedProperty}
								setAddressSearchedProperty={setAddressSearchedProperty}
								getDownPayment={getDownPayment}
								getSearchQueryState={null}
								cityName={cityName}
								googleMapProperty={googleMapProperty}
								setGoogleMapProperty={setGoogleMapProperty}
								favourites={favourites}
								setFavourites={setFavourites}
								strategy={strategy}
								neighbourhoodData={neighbourhoodData}
								neighbourhoodBounds={neighbourhoodBounds}
								tracts={tracts}
								neighbourhoodOption={neighbourhoodOption}
								clickTract={clickTract}
								chosenTract={chosenTract}
								clickedTract={clickedTract}
								hoverMapLegend={hoverMapLegend}
								setHoverMapLegend={setHoverMapLegend}
								bubbles={true}
								arrowClicked={arrowClicked}
								// emergingMarkets={emergingMarkets}
								emergingMarkets={[]}
								setEmergingMarkets={setEmergingMarkets}
								setEmergingAreaModal={setEmergingAreaModal}
								setEmergingAreaTract={setEmergingAreaTract}
								neighbourhoodGradeMin={neighbourhoodGrades[0]}
								neighbourhoodGradeMax={neighbourhoodGrades[1]}
								hideHeatmap={hideHeatmap}
								newCentre={newCentre}
								setNewCentre={setNewCentre}
								creativeStrategy={creativeStrategy}
								activeMarket={activeMarket}
								cityStateName={cityStateName}
								onboarding={onboarding}
							/>
							:
							null
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PropertyList;