import React, { useState } from 'react';
import '../styles/BuyBoxCard.css';
import { colour, formatterCompact, neighbourhoodColours, numberFormatter } from '../../styles/GlobalStyles';
import { DropdownArrow } from '../../assets';
import { Badge, Button, ComboList, Dropdown, IconButton } from './';
import { addRecentMarket, changeBuyBoxFrequency, downloadAllPropertiesExcel, filtersEncoder, getBuyBoxFilterParams, queryFinancialPreferences, recordEvent } from '../../functions';
import { Loading } from '../../components';
import { Circle, SquareFootage, Bathroom, Bedroom, Bell, Condition, BellNone, Meatball, Download } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { Delete02Icon, Edit02Icon, TextFontIcon } from 'hugeicons-react';

function BuyBoxCard(props) {
    const [loading, setLoading] = useState(false);
    const buyBoxFrequencyDropdown = props.buyBoxFrequencyDropdown;
    const setBuyBoxFrequencyDropdown = props.setBuyBoxFrequencyDropdown;
    const item = props.item;
    const filters = item.filters;
    const setShowExitScreen = props.setShowExitScreen;
    const actionDropdown = props.actionDropdown;
    const setActionDropdown = props.setActionDropdown;
    const setDropdownId = props.setDropdownId;
    const setBuyBoxes = props.setBuyBoxes;
    const userId = props.userId;
    const setDarkBg = props.setDarkBg;
    const setRenameModal = props.setRenameModal;
    const setBuyBoxName = props.setBuyBoxName;
    const setBuyBoxDeleteModal = props.setBuyBoxDeleteModal;
    const setBuyBoxDeleteId = props.setBuyBoxDeleteId;
    const setMobileActionMobile = props.setMobileActionMobile;
    const setCSVDownloadLoading = props.setCSVDownloadLoading;
    const setCSVDownloadComplete = props.setCSVDownloadComplete;
    const setCSVDownloadError = props.setCSVDownloadError;
    const setCSVDownloadLink = props.setCSVDownloadLink;
    const payingCustomer = props.payingCustomer;
    const setPremiumModal = props.setPremiumModal;
    const neighbourhoodGradeMin = filters.neighbourhoodGrades !== undefined ? filters.neighbourhoodGrades[0] : 1;
    const neighbourhoodGradeMax = filters.neighbourhoodGrades !== undefined ? filters.neighbourhoodGrades[1] : 8;
    // const subscriptions = props.subscriptions;
    const navigate = useNavigate();

    const renderPropertyTypes = () => {
        const propertyTypes = filters.propertyTypes === undefined ? "" : filters.propertyTypes;
        if ( propertyTypes === "" ) {
            return "No property types";
        }
        else if ( (propertyTypes.includes("SFH") && propertyTypes.includes("MFH")) ) {
            return `SFH, MFH (${filters.units === undefined ? "2" : filters.units}${filters.unitExactMatch === true ? "" : "+"} units)`;
        }
        else if ( propertyTypes[0].included === true ) {
            return `MFH(${filters.units === undefined ? "2" : filters.units}${filters.unitExactMatch === true ? "" : "+"})`;
        }
        else {
            return "SFH";
        }
    };

    const acquisitionStrategyOptions = [
        {
            label: "Mortgage",
            value: "m"
        },
        {
            label: "Cash",
            value: "c"
        },
        {
            label: "Seller-finance",
            value: "sf"
        },
        {
            label: "Subto",
            value: "sub"
        }
    ];

    const exitStrategyOptions = [
        {
            label: "LTR",
            value: "ltr"
        },
        {
            label: "BRRRR",
            value: "brrrr"
        },
        {
            label: "Househack",
            value: "hh"
        },
        {
            label: "Lease option",
            value: "lo"
        }
    ];

    const styles = {
        first: {
            backgroundColor: neighbourhoodColours[0]
        },
        second: {
            backgroundColor: neighbourhoodColours[1]
        },
        third: {
            backgroundColor: neighbourhoodColours[2]
        },
        fourth: {
            backgroundColor: neighbourhoodColours[3]
        },
        fifth: {
            backgroundColor: neighbourhoodColours[4]
        },
        sixth: {
            backgroundColor: neighbourhoodColours[5]
        },
        seventh: {
            backgroundColor: neighbourhoodColours[6]
        },
        eighth: {
            backgroundColor: neighbourhoodColours[7]
        }
    };

    const neighbourhoodOptions = [
        {
            label: "D",
            labelMin: "D",
            style: styles.eighth
        },
        {
            label: "C-",
            labelMin: "C-",
            style: styles.seventh
        },
        {
            label: "C",
            labelMin: "C",
            style: styles.sixth
        },
        {
            label: "C+",
            labelMin: "C+",
            style: styles.fifth
        },
        {
            label: "B-",
            labelMin: "B-",
            style: styles.fourth
        },
        {
            label: "B",
            labelMin: "B",
            style: styles.third
        },
        {
            label: "B+",
            labelMin: "B+",
            style: styles.second
        },
        {
            label: "A",
            labelMin: "A",
            style: styles.first
        }
    ];
    const neighbourhoodGrades = [
        {
            label: neighbourhoodOptions[neighbourhoodGradeMax - 1].label,
            labelMin: neighbourhoodOptions[neighbourhoodGradeMax - 1].labelMin,
            style: neighbourhoodOptions[neighbourhoodGradeMax - 1].style,
            value: neighbourhoodGradeMax
        },
        {
            label: neighbourhoodOptions[neighbourhoodGradeMin - 1].label,
            labelMin: neighbourhoodOptions[neighbourhoodGradeMin - 1].labelMin,
            style: neighbourhoodOptions[neighbourhoodGradeMin - 1].style,
            value: neighbourhoodGradeMin
        }
    ];

    const specs = [
        {
            label: `${filters.bedrooms === undefined ? "1" : filters.bedrooms}${filters.bedroomExactMatch === true ? "" : "+"}`,
            icon: <Bedroom fill={colour.blueBlue02} className="" />
        },
        {
            label: `${filters.bathrooms === undefined ? "1" : filters.bathrooms}${filters.bathroomExactMatch === true ? "" : "+"}`,
            icon: <Bathroom fill={colour.blueBlue02} className="" />
        },
        {
            label: filters.squareFootage === undefined || (filters.squareFootage[0] === 0 && filters.squareFootage[1] === 999999) ? "Any sqft" : `${numberFormatter.format(filters.squareFootage[0])}${filters.squareFootage[1] === 999999 ? "+" : "-"}${filters.squareFootage[1] === 999999 ? "" : numberFormatter.format(filters.squareFootage[1])}`,
            icon: <SquareFootage fill={colour.blueBlue02} className="" />
        },
        {
            label: filters.constructionYears === undefined ? "Any year built" : `${filters.constructionYears[0]}${filters.constructionYears[1] === "" ? "+" : "-"}${filters.constructionYears[1]}`,
            icon: <Condition fill={colour.blueBlue02} className="" />
        }
    ];

    const frequencies = [
        {
            label: "Daily",
            value: "daily",
            selected: item.frequency === "daily" ? true : false
        },
        {
            label: "Weekly",
            value: "weekly",
            selected: item.frequency === "weekly" ? true : false
        },
        {
            label: 'Monthly',
            value: 'monthly',
            selected: item.frequency === "monthly" ? true : false
        },
        {
            label: 'Never',
            value: 'never',
            selected: item.frequency === "never" ? true : false
        }
    ];

    const actionOptions = [
        {
            leftIcon: <Edit02Icon size={16} color={colour.grayScaleBlack} />,
            rightIcon: null,
            func: () => editBuyBox(),
            label: "Edit",
            colour: "colour-regular"
        },
        {
            leftIcon: <Download className="" fill={colour.grayScaleBlack} />,
            rightIcon: null,
            func: () =>  payingCustomer === false ? openPremiumModal() : downloadCSV(),
            label: "Download CSV",
            colour: "colour-regular"
        },
        {
            leftIcon: <TextFontIcon size={16} color={colour.grayScaleBlack} />,
            rightIcon: null,
            func: () => viewRenameModal(),
            label: "Rename",
            colour: "colour-regular"
        },
        // {
        //     leftIcon: <Share className="" fill={colour.grayScaleBlack} />,
        //     rightIcon: null,
        //     func: () => null,
        //     label: "Get shareable link",
        //     colour: "colour-regular"
        // },
        {
            leftIcon: <Delete02Icon size={16} color={colour.redRed02} />,
            rightIcon: null,
            func: () => deleteBuyBox(),
            label: "Delete",
            colour: "colour-error"
        }
    ];

    const openPremiumModal = () => {
        setPremiumModal(true);
    };

    const editBuyBox = () => {
        navigate("/edit-buy-box", {
            state: {
                buyBox: item
            }
        })
    };

    const downloadCSV = async() => {
        const queryPreferences = await queryFinancialPreferences(userId);
        const financialPreferences = queryPreferences.data;
        const downPayment = financialPreferences.initialCosts[0].value === "" ? 20 : financialPreferences.initialCosts[0].value;
		const sheetStrategy = item.exitStrategy === "brrrr" ? 2 : 0;
		setCSVDownloadLoading(true);
        setCSVDownloadError(false);
        setCSVDownloadLink(null);

		const creativeStrategy = item.acquisitionStrategy === "c" || item.acquisitionStrategy === "m" ? false : true;
        const filters = item.filters;
		const filterParams = await getBuyBoxFilterParams(filters, item, true);
		const newFilters = await filtersEncoder(filterParams);
		const sheetDownloadParams = {
			settings: financialPreferences,
			creativeStrategy: creativeStrategy,
			cityId: item.msaCode,
			filters: newFilters,
			page: 1,
			strategy: item.exitStrategy,
			sorting: "", 
			centre: null
		}

        const downloadData = await downloadAllPropertiesExcel(item.title, item.msaCode, downPayment, sheetStrategy, sheetDownloadParams);
        if ( downloadData.status === 200 ) {
            window.open(downloadData.data, "_blank");
            setCSVDownloadLink(downloadData.data);
            setCSVDownloadLoading(false);
            setCSVDownloadComplete(true);
            recordEvent("Download Buy Box CSV", {
                city: item.msaCode,
                title: item.title,
                all: true
            });
        }
        else {
            setCSVDownloadLoading(false);
            setCSVDownloadError(true);
        }
    };

    const checkFrequency = (frequency) => {
        const frequencyIndex = frequencies.findIndex(e => e.value === frequency);
        return frequencies[frequencyIndex].label;
    };

    const toggleDropdown = () => {
        setBuyBoxFrequencyDropdown(!buyBoxFrequencyDropdown);
        setShowExitScreen(!buyBoxFrequencyDropdown);
        setDropdownId(item.id);
    };

    const openActionDropdown = () => {
        setActionDropdown(!actionDropdown);
        setShowExitScreen(!actionDropdown);
        setDropdownId(item.id);
    };

    const selectOption = async(option) => {
        const val = option.value;
        await onChangeBuyBoxFrequency(val, item.id, item);
        toggleDropdown();
    };

    const onChangeBuyBoxFrequency = async(frequency) => {
        setLoading(true);
        const buyBoxChange = await changeBuyBoxFrequency(frequency, item.id);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
        }
        setLoading(false);
    };

    const viewRenameModal = () => {
        recordEvent("Rename Buy Box", {
            id: item.id
        });
        setRenameModal(true);
        setActionDropdown(false);
        setDarkBg(true);
        setDropdownId(item.id);
        setBuyBoxName(item.title);
    };

    const deleteBuyBox = async() => {
        setBuyBoxDeleteModal(true);
        setBuyBoxDeleteId(item.id);
        setDarkBg(true);
        setActionDropdown(false);
    };

    const mobileEditBuyBox = () => {
        setMobileActionMobile(true);
        setDropdownId(item.id);
        setBuyBoxName(item.title);
        setDarkBg(true);
    };

    const checkStrategy = (index) => {
        if ( index === 0 ) {
            const acquisitionIndex = acquisitionStrategyOptions.findIndex(e => e.value === item.acquisitionStrategy);
            return acquisitionStrategyOptions[acquisitionIndex].label;
        }
        else {
            const exitIndex = exitStrategyOptions.findIndex(e => e.value === item.exitStrategy);
            return exitStrategyOptions[exitIndex].label;
        }
    };

    const viewBuyBox = async(e) => {
        const target = e.target;
        const targetClass = target.classList.value;
        const parentTargetClass = target.parentNode.classList.value;
        if ( targetClass.includes("dropdown") || parentTargetClass.includes("dropdown") ) {
            return;
        }
        else if ( targetClass.includes("secondary-button") || parentTargetClass.includes("secondary-button") ) {
            return;
        }
        else if ( targetClass.includes("size-medium-padding") || parentTargetClass.includes("size-medium-padding") ) {
            return;
        }
        else if ( targetClass.includes("combo-list") || parentTargetClass.includes("combo-list") ) {
            return;
        }
        else if ( targetClass.includes("icon-button-element") || parentTargetClass.includes("icon-button-element") ) {
            return;
        }
        else if ( targetClass.includes("buy-box-meatball-icon") || parentTargetClass.includes("buy-box-meatball-icon") ) {
            return;
        }
        
        const filterParams = await getBuyBoxFilterParams(filters, item, false);
        window.open(`/properties-list/${item.msaCode}${filterParams}`, "_blank");
        if ( userId !== null ) {
            await addRecentMarket(item, userId, null);
        }
    };

    return (
        <div 
            className="buy-box-card-outer-container cursor-pointer"
            onClick={(e) => viewBuyBox(e)}
        >
            <div className="buy-box-card-inner-container relative-container">
                {
                    loading === true &&
                    <Loading />
                }
                <div className="buy-box-card-left-container">
                    <div className="buy-box-card-left-top-container">
                        <div className="buy-box-card-title-container">
                            <div className="buy-box-card-mobile-title-row desktop-none">
                                <h4 className="heading-small-semibold colour-link">
                                    {item.title}
                                </h4>
                                <IconButton
                                    size="large"
                                    icon={<Meatball className="buy-box-meatball-icon" fill={colour.blueBlue03} />}
                                    disabled={false}
                                    clickFunc={() => mobileEditBuyBox()}
                                    standardIcon={false}
                                    contextTooltip={false}
                                    contextTooltipBG={null}
                                    contextTooltipText={null}
                                    customClass={null}
                                />
                            </div>
                            <h4 className="heading-small-semibold colour-link mobile-none">
                                {item.title}
                            </h4>
                            {
                                item.newListings !== 0 &&
                                <Badge
                                    val={numberFormatter.format(item.newListings)}
                                    bg="badge-accent-bg mobile-none"
                                    round={false}
                                    freeSize={false}
                                />
                            }
                            <div className="buy-box-card-update-row desktop-none">
                                <span className="body-regular colour-tertiary">
                                    Updated {" "}
                                    <span className="colour-primary text-lowercase">
                                        {item.lastUpdateWords}
                                    </span>
                                </span>
                                {
                                    item.newListings !== 0 &&
                                    <Badge
                                        val={numberFormatter.format(item.newListings)}
                                        bg="badge-accent-bg desktop-none"
                                        round={false}
                                        freeSize={false}
                                    />
                                }
                            </div>
                        </div>
                        <div className="buy-box-spacing-border desktop-none">
                        </div>
                        <div className="buy-box-card-left-row-container">
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    {
                                        filters.budgetRange === undefined ?
                                        "Any budget"
                                        :
                                        filters.budgetRange[0] === 0 && filters.budgetRange[1] === 1000000000 ?
                                        "Any budget"
                                        :
                                        `${formatterCompact.format(filters.budgetRange[0])}${filters.budgetRange[1] === 1000000000 ? "+" : " - "}${filters.budgetRange[1] === 1000000000 ? "" : formatterCompact.format(filters.budgetRange[1])}`
                                    }
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    •
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    {checkStrategy(0)}
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    •
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    {checkStrategy(1)}
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    •
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    {renderPropertyTypes()}
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <span className="body-regular colour-secondary">
                                    •
                                </span>
                            </div>
                            <div className="buy-box-card-left-element">
                                <Circle
                                    fill={neighbourhoodGrades[0].style.backgroundColor}
                                    className=""
                                />
                                <span className="body-regular colour-secondary">
                                    {neighbourhoodGrades[0].labelMin} -
                                </span>
                                <Circle
                                    fill={neighbourhoodGrades[1].style.backgroundColor}
                                    className=""
                                />
                                <span className="body-regular colour-secondary">
                                    {neighbourhoodGrades[1].labelMin}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="buy-box-card-specs-row">
                        {
                            specs.map((element, index) =>
                                <div 
                                    className="buy-box-card-specs-element"
                                    key={index}
                                >
                                    {element.icon}
                                    <span className="body-regular colour-quaternary">
                                        {element.label}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="buy-box-card-right-container mobile-none">
                    <span className="body-regular colour-primary ">
                        <span className="colour-tertiary">
                            Updated{" "}
                        </span>
                        <span className="text-lowercase">
                            {item.lastUpdateWords}
                        </span>
                    </span>
                    <Dropdown
                        open={buyBoxFrequencyDropdown}
                        icon={item.frequency === "never" ? <BellNone fill={colour.grayScaleBlack} className="" /> : <Bell fill={colour.grayScaleBlack} className="" />}
                        label={checkFrequency(item.frequency)}
                        disabled={false}
                        options={frequencies}
                        multiselect={false}
                        toggleDropdown={toggleDropdown}
                        cta={selectOption}
                        sidealign={"right"}
                        topalign={"bottom"}
                        indexSelect={false}
                        selectedIndex={null}
                    />
                    <div className={"relative-container " + (actionDropdown === true ? "buy-box-card-action-dropdown" : "")}>
                        <Button
                            buttonType="secondary-button"
                            size="medium"
                            text={"Actions"}
                            cta={openActionDropdown}
                            disabled={false}
                            leftIcon={null}
                            rightIcon={DropdownArrow}
                            alt="Arrow"
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                        {
                            actionDropdown === true &&
                            <ComboList
                                options={actionOptions}
                                noIcons={false}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BuyBoxCard;