import React, { useEffect } from 'react';
import '../styles/Welcome.css';
import { Header, Footer } from '../components';
import { checkOnAuthStateChanged, recordEvent } from '../functions';
import { useNavigate } from 'react-router-dom';
import { Button, Dots } from '../v4/components';
import { colour } from '../styles/GlobalStyles';

function Welcome() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Welcome | Coffee Clozers";

        const fetchUserData = async() => {
            const user = await checkOnAuthStateChanged()
            if ( user.status !== 200 ) {
                navigate("/sign-up");
            }
        };
        fetchUserData();

    }, [navigate]);

    const complete = () => {
        navigate("/recommendations");
        recordEvent("Welcome Complete", {});
    };

    return (
        <div className="welcome-outer-container bg-colour-white">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={false}
                desktopControl={false}
            />
            <div className="welcome-inner-container">
                <Dots
                    limit={3}
                    selectedFill={colour.grayScaleGray03}
                    selectedStroke=""
                    unselectedFill={colour.grayScaleGray01}
                    unselectedStroke=""
                    selectedIndex={0}
                    className=""
                />
                <div className="welcome-body-container">
                    <div className="welcome-title-container">
                        <h1 className="heading-large-semibold colour-primary text-align-center">
                            You're on your way to finding cash-flow
                        </h1>
                        <span className="body-regular colour-secondary text-align-center block-text">
                            We rank properties from best to worst based on cash-flow. 
                            To get started, help us learn more about your goals.
                        </span>
                    </div>
                    <div className="welcome-button-container">
                        <Button
                            buttonType="primary-button"
                            size="large"
                            text={"Get started"}
                            cta={complete}
                            disabled={false}
                            leftIcon={null}
                            leftIconClass=""
                            rightIcon={null}
                            rightIconClass=""
                            alt=""
                            standardIcon={true}
                            badge={false}
                            badgeVal={null}
                            badgeBG={null}
                            buttonRef={null}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Welcome;