import React from 'react';
import '../styles/OwnerInfo.css';
import { User, CashOnly, FreeClear, InvestmentProperty, MultipleParcels, RedirectArrow } from '../../assets/icons';
import { colour, formatterLong, percentage } from '../../styles/GlobalStyles';
import { Clock05Icon, Invoice03Icon, WorryIcon } from 'hugeicons-react';
import { DistressFlag, Highlights } from './';
import { checkHighlights } from '../../functions';

function OwnerInfo(props) {
    const property = props.property;
    const listingSubType = property.listingSubType;
    const newConstruction = listingSubType.newConstruction !== undefined ? listingSubType.newConstruction : false;
    const badgeBG = newConstruction === true ? "bg-colour-dark" : property.ownerAttributes.motivationLabel === "low" ? "bg-colour-red" : property.ownerAttributes.motivationLabel === "medium" ? "bg-colour-yellow" : "bg-colour-green";
    const motivationLevel = newConstruction === true ? "New construction" : property.ownerAttributes.motivationLabel === "low" ? "Likely Low" : property.ownerAttributes.motivationLabel === "medium" ? "Likely Medium" : "Likely High";
    const remainingMortgage = property.ownerAttributes.estMortgageBalance;
    const equity = property.ownerAttributes.estEquityRatio;
    const loanTypeCode = property.ownerAttributes.loanTypeCode;
    const loanType = loanTypeCode === undefined || loanTypeCode === null ? "N/A" : loanTypeCode === "COV" ? "Conventional" : loanTypeCode === "FHA" ? "FHA" : loanTypeCode === "VA" ? "VA" : "Other";

    const details = [
        {
            label: property.ownerAttributes.ownerType === null ? "N/A" : `${property.ownerAttributes.ownerType} owner`,
            icon: <User fill={colour.grayScaleGray03} className="" />
        },
        {
            label: property.ownerAttributes.ownershipLength === null ? "N/A" : Number(property.ownerAttributes.ownershipLength) < 12 ? `${property.ownerAttributes.ownershipLength} month${Number(property.ownerAttributes.ownershipLength) % 12 === 1 ? "" : "s"}` : `${Math.floor(Number(property.ownerAttributes.ownershipLength) / 12)} year${property.ownerAttributes.ownershipLength >= 12 && property.ownerAttributes.ownershipLength < 24 ? "" : "s"}${Number(property.ownerAttributes.ownershipLength) % 12 === 0 ? "" : ` ${Number(property.ownerAttributes.ownershipLength) % 12} month${Number(property.ownerAttributes.ownershipLength) % 12 === 1 ? "" : "s"}`}`,
            icon: <Clock05Icon size={16} color={colour.grayScaleGray03} />
        }
    ];

    const elements = [
        {
            icon: <Invoice03Icon size={24} color={colour.grayScaleBlack} />,
            title: "Financed",
            description: "The owner purchased with a traditional mortgage.",
            active: property.ownerAttributes.purchaseMethod === "Cash Purchase" ? false : true
        },
        {
            icon: <CashOnly className="" fill={colour.grayScaleBlack} />,
            title: "Cash",
            description: "The owner purchased with cash.",
            active: property.ownerAttributes.purchaseMethod === "Cash Purchase" ? true : false
        },
        {
            icon: <FreeClear className="" fill={colour.grayScaleBlack} />,
            title: "Free & clear",
            description: "This makes it a good candidate for a seller-finance deal.",
            active: property.freeClearFlag
        },
        {
            icon: <InvestmentProperty className="" fill={colour.grayScaleBlack} />,
            title: "Investment property",
            description: "Based on county records, it's likely the owner uses this as an investment property.",
            active: property.ownerAttributes.investorBuyer === true ? true : false
        },
        {
            icon: <MultipleParcels className="" fill={colour.grayScaleBlack} />,
            title: "Multiple parcels",
            description: "This owner owns multiple properties or land parcels.",
            active: property.multipleParcels
        }
    ];

    const equityElements = [
        {
            label: "Estimated interest rate on owner's mortgage",
            value: property.ownerAttributes.estInterestRate === undefined || property.ownerAttributes.estInterestRate === null ? "N/A" : percentage.format(property.ownerAttributes.estInterestRate)
        },
        {
            label: "Estimated equity level",
            value: percentage.format(equity)
        },
        {
            label: "Estimated mortgage balance",
            value: formatterLong.format(Number(remainingMortgage)).replace(".00", "")
        },
        {
            label: "Estimated mortgage payment",
            value: formatterLong.format(Number(property.ownerAttributes.estMortgagePayment)).replace(".00", "")
        },
        {
            label: "Mortgage type",
            value: loanType
        }
    ];

    const distressFlags = [
        {
            value: property.ownerAttributes.absenteeOwner === true ? true : false,
            icon: "🫥",
            label: "Absentee owner"
        },
        {
            value: property.ownerAttributes.inStateAbsenteeOwner === true ? true : false,
            icon: "😶‍🌫️",
            label: "In-State Absentee owner"
        },
        {
            value: property.ownerAttributes.death === true ? true : false,
            icon: "🕊️",
            label: "Recent death"
        },
        {
            value: property.ownerAttributes.inherited_flag === true ? true : false,
            icon: "🫴",
            label: "Inherited"
        },
        {
            value: property.ownerAttributes.preForeclosure === true ? true : false,
            icon: "📉",
            label: "Pre-Foreclosure"
        },
        {
            value: property.ownerAttributes.spousalDeath === true ? true : false,
            icon: "💍🕊️",
            label: "Spousal Death"
        },
        {
            value: property.ownerAttributes.vacant === true ? true : false,
            icon: "🏚️",
            label: "Vacant"
        }
    ];

    const checkDistressFlags = () => {
        const trueIndex = distressFlags.findIndex(item => item.value === true);
        return trueIndex !== -1 ? true : false;
    };

    const openReAPI = () => {
        window.open("https://www.realestateapi.com", "_blank");
    };

    return (
        <div className="owner-info-outer-container">
            <div className="owner-info-inner-container">
				<div className="owner-info-title-container">
					<h2 className="heading-small-regular colour-primary">
                        Owner information
					</h2>
                </div>
                <div className="owner-info-details-row">
                    {
                        details.map((item, index) =>
                            <div 
                                className="owner-info-details-element"
                                key={index}
                            >
                                <div className="owner-info-details-icon-container">
                                    {item.icon}
                                </div>
                                <span className="body-regular colour-primary">
                                    {item.label}
                                </span>
                            </div>
                        )
                    }
                </div>
                {
                    property.ownerAttributes.ownerName === null && property.ownerAttributes.mailingAddress === null ?
                    null
                    :
                    <div className="property-spacing-border">
                    </div>
                }
                {
                    property.ownerAttributes.ownerName === null && property.ownerAttributes.mailingAddress === null ?
                    null
                    :
                    <div className="owner-info-personal-info-container">
                        <h2 className="body-semibold colour-primary">
                            Owner
                        </h2>
                        {
                            property.ownerAttributes.ownerName !== null &&
                            <span className="body-regular colour-primary">
                                {property.ownerAttributes.ownerName}
                            </span>
                        }
                        {
                            property.ownerAttributes.mailingAddress !== null &&
                            <span className="body-regular colour-secondary">
                                {property.ownerAttributes.mailingAddress}
                            </span>
                        }
                    </div>
                }
                {
					checkHighlights(elements) === false ?
					null
					:    
                    <div className="property-spacing-border">
                    </div>
                }
                {
					checkHighlights(elements) === false ?
					null
					:
					<Highlights
                        property={property}
                        label={null}
                        elements={elements}
                        conditionView={false}
                    />
				}
                <div className="property-spacing-border">
                </div>
                <div className="owner-info-seller-motivation-container">
                    <div className="owner-info-title-container">
                        <h2 className="heading-small-regular colour-primary">
                            Seller motivation
                        </h2>
                        <span className="body-regular colour-secondary">
                            Understanding the owner's motivation to sell or potential distress signals can help 
                            determine suitability for creative finance, low ball offers, etc.
                        </span>
                    </div>
                    {
                        newConstruction === true &&
                        <div className={"owner-details-badge-container margin-medium " + (badgeBG)}>
                            <span className="body-semibold colour-white block-text text-align-center">
                                {motivationLevel}
                            </span>
                        </div>
                    }
                    {
                        equityElements.map((item, index) =>
                            <div 
                                className="owner-details-equity-element"
                                key={index}
                            >
                                <h3 className="body-semibold colour-primary">
                                    {item.label}
                                </h3>
                                <span className="body-regular colour-secondary">
                                    {item.value}
                                </span>
                            </div>
                        )
                    }
                    <span className="body-regular colour-secondary">
                        Please note, the above information is estimated and won't always be  accurate. 
                        The only way to verify this is by speaking to the seller.
                    </span>
                </div>
                <div className="property-spacing-border">
                </div>
                {
                    checkDistressFlags() === true &&
                    <div className="owner-info-distress-flags-container">
                        <div className="owner-info-distress-flags-title-container">
                            <div className="owner-info-distrss-flags-heading-container">
                                <WorryIcon
                                    size={16}
                                    color={colour.grayScaleBlack}
                                />
                                <h3 className="body-semibold colour-primary">
                                    Distress flags
                                </h3>
                            </div>
                            <span className="body-regular colour-secondary">
                                Distress signs help you assess a seller's situation. 
                                You can use these to guide offer structuring and 
                                negotiate creative finance terms.
                            </span>
                        </div>
                        <div className="owner-info-distress-flags-grid">
                            {
                                distressFlags.map((item, index) =>
                                    item.value === true &&
                                    <DistressFlag
                                        label={item.label}
                                        emoji={item.icon}
                                        key={index}
                                    />
                                )
                            }
                        </div>
                    </div>
                }
                {
                    checkDistressFlags() === true &&
                    <div className="property-spacing-border">
                    </div>
                }
                <div className="owner-info-sources-container">
                    <h3 className="body-semibold colour-primary">
                        Sources
                    </h3>
                    <div className="owner-info-sources-element">
                        <span className="body-regular colour-secondary">
                            We source owner information from Real Estate API (REAPI)
                        </span>
                        <div className="owner-info-sources-row">
                            <span 
                                className="body-regular-underline colour-link"
                                onClick={() => openReAPI()}
                            >
                                Learn more
                            </span>
                            <RedirectArrow
                                fill={colour.blueBlue03}
                                className="cursor-pointer"
                                clickFunc={() => openReAPI()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OwnerInfo;