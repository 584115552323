import React from 'react';

function CopyKeychain(props) {
    const className = props.className;
    const fill = props.fill;
    const clickFunc = props.clickFunc;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="17" 
            height="16" 
            viewBox="0 0 17 16" 
            fill="none"
            className={className}
            onClick={() => clickFunc()}
        >
            <path 
                d="M14.4508 1.40091C16.3187 3.26879 16.3187 6.29722 14.4508 8.1651L12.9007 9.71523C12.5116 10.1044 11.8807 10.1044 11.4915 9.71523C11.1024 9.32608 11.1024 8.69516 11.4915 8.30602L12.8623 6.93525C14.0509 5.7466 14.1312 3.89971 13.0416 2.81012C11.952 1.72052 10.1052 1.80082 8.91651 2.98947L7.54574 4.36024C7.1566 4.74938 6.52567 4.74938 6.13653 4.36024C5.74739 3.9711 5.74739 3.34018 6.13653 2.95104L7.68666 1.40091C9.55454 -0.46697 12.583 -0.46697 14.4508 1.40091Z" 
                fill={fill}
            />
            <path 
                d="M11.0688 4.783C10.6018 4.31603 9.84468 4.31603 9.37771 4.783L5.43193 8.72878C4.96496 9.19575 4.96496 9.95286 5.43193 10.4198C5.8989 10.8868 6.65601 10.8868 7.12298 10.4198L11.0688 6.47405C11.5357 6.00708 11.5357 5.24997 11.0688 4.783Z" 
                fill={fill}
            />
            <path 
                d="M2.04984 13.8019C3.91771 15.6698 6.94615 15.6698 8.81402 13.8019L10.3642 12.2518C10.7533 11.8627 10.7533 11.2317 10.3642 10.8426C9.97501 10.4534 9.34409 10.4534 8.95494 10.8426L7.58417 12.2134C6.39552 13.402 4.54864 13.4823 3.45904 12.3927C2.36945 11.3031 2.44974 9.45624 3.6384 8.26759L5.00917 6.89681C5.39831 6.50767 5.39831 5.87675 5.00917 5.48761C4.62003 5.09847 3.9891 5.09846 3.59996 5.48761L2.04984 7.03773C0.181956 8.90561 0.181956 11.934 2.04984 13.8019Z" 
                fill={fill}
            />
        </svg>
    )
};

export default CopyKeychain;