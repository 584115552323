import React from 'react';
import '../styles/FinancialsInput.css';
import { Info } from '../../assets/icons';
import { colour } from '../../styles/GlobalStyles';

function FinancialsInput(props) {
    const label = props.label;
    const labelType = props.labelType;
    const info = props.info;
    const onMouseEnter = props.onMouseEnter;
    const onMouseLeave = props.onMouseLeave;
    const labelStyle = labelType === "default" ? "body-semibold colour-primary" : "label-semibold colour-primary";
    const value = props.value;
    const onChange = props.onChange;
    const placeholder = props.placeholder;
    const type = props.type;
    const leftUnit = props.leftUnit;
    const rightUnit = props.rightUnit;
    const unitSwitch = props.unitSwitch;
    const leftUnitSelected = props.leftUnitSelected;
    const unitSwitchFunc = props.unitSwitchFunc;
    const disabled = props.disabled;
    const multiline = props.multiline;
    const error = props.error;
    const reset = props.reset;
    const resetFunc = props.resetFunc;
    const resetPosition = props.resetPosition;
    const secondaryLabel = props.secondaryLabel;
    const secondaryLabelGrey = props.secondaryLabelGrey;

    return (
        <div className={"financials-input-container " + (error === true ? "financials-input-error " : "") + (labelType === null || labelType === "inside" ? "" : labelType === "default" ? "gap-small" : "gap-x-small")}>
            {
                labelType === null || labelType === "inside" ?
                null
                :
                <div className={"relative-container " + (info === true ? "financials-input-label-container " : secondaryLabelGrey !== undefined && secondaryLabelGrey !== null ? "financials-input-secondary-label-container " : " ") + (info === false ? "" : labelType === "default" ? "gap-x-small" : "gap-x-x-small")}>
                    <label className={labelStyle}>
                        {label}{
                            secondaryLabel !== undefined && secondaryLabel !== null &&
                            <span className="body-regular colour-primary">
                                {" "}{secondaryLabel}
                            </span>
                        }
                    </label>
                    {
                        info === true &&
                        <Info
                            fill={colour.grayScaleGray03}
                            className="cursor-pointer"
                            onMouseEnter={() => onMouseEnter()}
                            onMouseLeave={() => onMouseLeave()}
                        />
                    }
                    {
                        secondaryLabelGrey !== undefined && secondaryLabelGrey !== null &&
                        <span className="body-regular colour-secondary">
                            {secondaryLabelGrey}
                        </span>
                    }
                </div>
            }
            <div className={resetPosition === "default" ? "financials-input-outer-wrapper-row" : "financials-input-outer-wrapper-column"}>
                <div className={"financials-input-wrapper " + (disabled === true ? "disabled " : "")}>
                    {
                        labelType !== "inside" ?
                        null
                        :
                        <div className={"financials-input-label-container financials-input-label-inside-container gap-x-small" }>
                            <label className={labelStyle}>
                                {label}
                            </label>
                        </div>
                    }
                    <div className="financials-input-inner-wrapper">
                        {
                            leftUnit === null ?
                            null
                            :
                            <span 
                                className={"body-regular cursor-pointer " + (leftUnitSelected === true ? "colour-primary " : "colour-tertiary ") + (disabled === true ? "disabled" : "colour-primary-hover")}
                                onClick={() => (unitSwitch === false || disabled === true) ? null : unitSwitchFunc(false)}
                            >
                                {leftUnit}
                            </span>
                        }
                        {
                            multiline === true ?
                            <textarea
                                value={value}
                                onChange={onChange}
                                className={"financials-input body-regular colour-primary " + (disabled === true ? "disabled" : "")}
                                placeholder={placeholder}
                                rows={6}
                                disabled={disabled}
                            />
                            :
                            <input
                                type={type}
                                value={value}
                                onChange={onChange}
                                className={"financials-input body-regular colour-primary " + (leftUnitSelected === true ? "text-align-left " : "text-align-right ") + (disabled === true ? "disabled" : "")}
                                placeholder={placeholder}
                                disabled={disabled}
                            />
                        }
                        {
                            rightUnit === null ?
                            null
                            :
                            <span 
                                className={"body-regular cursor-pointer " + (leftUnitSelected === false || unitSwitch === false ? "colour-primary " : "colour-tertiary ") + (disabled === true ? "disabled" : "colour-primary-hover")}
                                onClick={() => (unitSwitch === false || disabled === true) ? null : unitSwitchFunc(true)}
                            >
                                {rightUnit}
                            </span>
                        }
                    </div>
                </div>
                {
                    reset === true &&
                    <span 
                        className="body-regular-underline colour-link financials-input-reset-element"
                        onClick={() => resetFunc()}
                    >
                        Reset
                    </span>
                }
            </div>
        </div>
    )
};

export default FinancialsInput;