import React from 'react';

function Bin(props) {
    const fill = props.fill;
    const className = props.className;
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
            className={className}
        >
            <path 
                d="M5.91943 11.2944C5.86279 11.2134 5.83008 11.114 5.83008 11.0059V7.00586C5.83008 6.72583 6.05005 6.50586 6.33008 6.50586C6.61011 6.50586 6.83008 6.72583 6.83008 7.00586V11.0059C6.83008 11.2859 6.61011 11.5059 6.33008 11.5059C6.1582 11.5059 6.00903 11.4231 5.91943 11.2944Z" 
                fill={fill}
            />
            <path 
                d="M9.16992 11.0059C9.16992 11.2859 9.38989 11.5059 9.66992 11.5059C9.94995 11.5059 10.1699 11.2859 10.1699 11.0059V7.00586C10.1699 6.72583 9.94995 6.50586 9.66992 6.50586C9.38989 6.50586 9.16992 6.72583 9.16992 7.00586V11.0059Z" 
                fill={fill}
            />
            <path 
                fillRule="evenodd" 
                clipRule="evenodd" 
                d="M2.5376 4.16602H2C1.71997 4.16602 1.5 3.94604 1.5 3.66602C1.5 3.38599 1.71997 3.16602 2 3.16602H5.05005L5.32007 2.57593C5.59082 1.98364 5.75415 1.62012 6.02222 1.35278C6.0708 1.3042 6.12305 1.25879 6.17993 1.21582C6.26001 1.15601 6.3501 1.09595 6.42993 1.05591C6.54712 0.991455 6.66699 0.945801 6.79712 0.913574C7.11108 0.835938 7.4856 0.835938 8.03003 0.835938C8.80005 0.835938 9.18994 0.835938 9.59009 1.0459C9.67993 1.09595 9.76001 1.146 9.84009 1.20605C9.96851 1.30225 10.074 1.41138 10.1711 1.54224C10.3462 1.77808 10.4941 2.08496 10.7 2.51587L11.02 3.17603H12.927C12.9602 3.17236 12.9946 3.17212 13.03 3.17578L13.0334 3.17603H14C14.28 3.17603 14.5 3.396 14.5 3.67603C14.5 3.95605 14.28 4.17603 14 4.17603H13.4712L13.0901 10.3857C12.9802 12.1858 12.9202 13.0859 12.4202 13.8159C12.1702 14.1758 11.8501 14.4758 11.4702 14.7058C10.7102 15.1658 9.81006 15.1658 8.01001 15.1658H8C6.2002 15.1658 5.29004 15.1658 4.53003 14.7058C4.3833 14.6169 4.24536 14.5176 4.11694 14.4082C3.91309 14.2349 3.7334 14.0366 3.58008 13.8159C3.07568 13.094 3.021 12.2056 2.91382 10.4546L2.91016 10.3958L2.5376 4.16602ZM3.54126 4.16602H5.37012V4.17603H12.4675L12.0901 10.3259L12.0894 10.3347C11.99 11.9387 11.9395 12.7468 11.5901 13.2458C11.4202 13.4958 11.2002 13.6958 10.9402 13.856C10.4302 14.1758 9.62012 14.1758 8 14.1758C6.38013 14.1758 5.57007 14.1758 5.05005 13.856C4.8894 13.7632 4.74414 13.6477 4.6167 13.5142C4.5376 13.4314 4.46509 13.3416 4.40015 13.2458C4.06006 12.7559 4.01001 11.9458 3.91016 10.3259L3.54126 4.16602ZM6.1543 3.16602H9.90991L9.80005 2.94604C9.52002 2.37598 9.38989 2.11597 9.23999 1.99585C9.20142 1.9668 9.16284 1.94751 9.12451 1.92822L9.12012 1.92603C8.94995 1.83594 8.65991 1.83594 8.02002 1.83594C7.66846 1.83594 7.4165 1.83594 7.23267 1.85254C7.08179 1.86621 6.97656 1.89087 6.8999 1.93604C6.86011 1.95605 6.82007 1.98584 6.78003 2.01587C6.62988 2.13599 6.5 2.40601 6.22998 2.99585L6.1543 3.16602Z" 
                fill={fill}
            />
        </svg>
    )
};

export default Bin;