import React, { useState } from 'react';
import '../styles/History.css';
import { formatterLong, percentage } from '../../styles/GlobalStyles';
import moment from 'moment';
import { FilterChip } from './';

function History(props) {
    const property = props.property;
    const [selectedPanel, setSelectedPanel] = useState(0);

    const options = [
        {
            label: "Price history",
            disabled: false,
            selected: selectedPanel === 0 ? true : false,
            labels: [
                "Date",
                "Activity",
                "Price",
                "Price per sqft"
            ]
        },
        {
            label: "Tax history",
            disabled: false,
            selected: selectedPanel === 1 ? true : false,
            labels: [
                "Year",
                "Property taxes",
                "Tax assessment"
            ]
        }
    ];

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    return (
        <div className="history-outer-container">
            <div className="history-inner-container">
                <div className="history-title-container">
                    <h2 className="heading-small-regular colour-primary">
                        {options[selectedPanel].label}
                    </h2>
                </div>
                <div className="history-chips-row">
                    {
                        options.map((item, index) =>
                            index === 1 && (property.taxHistory === undefined || property.taxHistory === null || Object.keys(property.taxHistory).length === 0 || property.taxHistory.length === 0) ?
                            null
                            :
                            <FilterChip
                                label={item.label}
                                selected={selectedPanel}
                                index={index}
                                disabled={item.disabled}
                                leftIcon={null}
                                rightIcon={null}
                                func={changeSelectedPanel}
                                key={index}
                            />
                        )
                    }
                </div>
                <div className="history-body-container">
                    <table className="history-table-container">
                        <thead>
                            <tr className="history-table-heading-container">
                                {
                                    options[selectedPanel].labels.map((label, index) =>
                                        <th
                                            className="history-table-heading body-semibold colour-primary"
                                            key={index}
                                        >
                                            {label}
                                        </th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody className="history-table-body-container">
                            {
                                selectedPanel === 0 ?
                                property.priceHistory.map((item, index) =>
                                    <tr 
                                        className="history-table-body-element"
                                        key={index}
                                    >
                                        <td className="history-table-body body-regular colour-primary">
                                            {moment(item.date).format("MMM D, YYYY")}
                                        </td>
                                        <td className="history-table-body body-regular colour-primary">
                                            {item.event}
                                        </td>
                                        <td className="history-table-body body-regular colour-primary">
                                            {formatterLong.format(item.price).replace(".00", "")}
                                        </td>
                                        <td className="history-table-body body-regular colour-primary">
                                            {formatterLong.format(item.pricePerSquareFoot).replace(".00", "")}
                                        </td>
                                    </tr>
                                )
                                :
                                property.taxHistory.map((item, index) =>
                                    <tr 
                                        className="history-table-body-element"
                                        key={index}
                                    >
                                        <td className="history-table-body">
                                            <span className="body-regular colour-primary">
                                                {moment(item.time).format("YYYY")}
                                            </span>
                                        </td>
                                        <td className="history-table-body">
                                            <span className="body-regular colour-primary">
                                                {
                                                    item.taxPaid === null ?
                                                    "N/A"
                                                    :
                                                    formatterLong.format(Math.round(item.taxPaid)).replace(".00", "")
                                                }
                                            </span>
                                            {
                                                item.taxIncreaseRate !== 0 &&
                                                <span className={"label-semibold " + (item.taxIncreaseRate > 0 ? "colour-green" : "colour-error")}>
                                                    {percentage.format(item.taxIncreaseRate)}
                                                </span>
                                            }
                                        </td>
                                        <td className="history-table-body">
                                            <span className="body-regular colour-primary">
                                                {formatterLong.format(Math.round(item.value)).replace(".00", "")}
                                            </span>
                                            {
                                                item.valueIncreaseRate !== 0 &&
                                                <span className={"label-semibold " + (item.valueIncreaseRate > 0 ? "colour-green" : "colour-error")}>
                                                    {percentage.format(item.valueIncreaseRate)}
                                                </span>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default History;