import React, { useEffect, useState } from 'react';
import '../styles/PropertiesPagination.css';
import { IconButton } from './';
import { colour } from '../../styles/GlobalStyles';
import { Chevron } from '../../assets/icons';

function PropertiesPagination(props) {
    const page = props.page;
    const setPage = props.setPage;
    const total = props.total;
    const maxPerPage = props.maxPerPage;
    const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
    const numberOfPages = Math.ceil(total / maxPerPage);
    const [pageRange, setPageRange] = useState(null);

    useEffect(() => {
        if ( pageRange !== null ) {
            return;
        }
        else if ( page > 7 ) {
            const minPage = page - 6;
            const maxPage = page + 1;
            setPageRange([minPage, maxPage]);
        }
        else {
            setPageRange([0, 8]);
        }
    }, [page, pageRange]);

    const changePage = (newPage) => {
        setPage(newPage);
        setPropertiesOnPageChange(true);
    };

    return (
        pageRange !== null &&
        <div className="properties-pagination-container">
            {
                page !== 1 && numberOfPages !== 1 ?
                <IconButton
                    size="large"
                    icon={<Chevron className="pagination-chevron-left cursor-pointer" fill={colour.grayScaleBlack} />}
                    disabled={false}
                    clickFunc={() => changePage(page - 1)}
                    standardIcon={false}
                    contextTooltip={false}
                    contextTooltipBG={null}
                    contextTooltipText={null}
                    customClass={null}
                />
                :
                null
            }
            {
                1 < (pageRange[0] + 1) ?
                <div onClick={() => changePage(1)} className={"pagination-element " + (page === 1 ? "pagination-element-selected" : "" )}>
                    <span className={page === 1 ? "body-semibold colour-primary" : "body-regular colour-primary"}>
                        1
                    </span>
                </div>
                :
                null
            }
            {
                1 < (pageRange[0] + 1) ?
                <div className="pagination-element pagination-element-dots">
                    <span className="body-regular colour-primary">
                        [...]
                    </span>
                </div>
                :
                null
            }
            {
                Array.from({ length: numberOfPages }, (_, index) => index).map(index =>
                    index <= pageRange[1] && index >= pageRange[0] ? 
                    <div onClick={() => changePage(index + 1)} className={"pagination-element " + (index + 1 === page ? "pagination-element-selected" : "" )} key={index}>
                        <span className={index + 1 === page ? "body-semibold colour-primary" : "body-regular colour-primary"}>
                            {index + 1}
                        </span>
                    </div>
                    :
                    null
                )
            }
            {
                numberOfPages > (pageRange[1] + 2) ?
                <div className="pagination-element pagination-element-dots">
                    <span className="body-regular colour-primary">
                        [...]
                    </span>
                </div>
                :
                null
            }
            {
                numberOfPages > (pageRange[1] + 1) ?
                    <div onClick={() => changePage(numberOfPages)} className={"pagination-element " + (numberOfPages === page ? "pagination-element-selected" : "" )}>
                        <span className={numberOfPages === page ? "body-semibold colour-primary" : "body-regular colour-primary"}>
                            {numberOfPages}
                        </span>
                    </div>
                :
                null
            }
            {
                page !== numberOfPages && numberOfPages !== 1 ?
                <IconButton
                    size="large"
                    icon={<Chevron className="pagination-chevron-right cursor-pointer" fill={colour.grayScaleBlack} />}
                    disabled={false}
                    clickFunc={() => changePage(page + 1)}
                    standardIcon={false}
                    contextTooltip={false}
                    contextTooltipBG={null}
                    contextTooltipText={null}
                    customClass={null}
                />
                :
                null
            }
        </div>
    )
};

export default PropertiesPagination;